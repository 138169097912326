import styled from 'styled-components';

export const NotLoggedInDiv = styled.div`
display: flex;
height: 100vh;
width: 100vw;

& img {
    object-fit: cover;
    height: 100vh;
    width: 67vw;

    @media (max-width: 990px) {
      width: 60vw;
    }
  }
`;

export const RightDiv = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 33vw;
& img {
    object-fit: contain;
    height: 8%;
    width: 45%;
  }

  @media (max-width: 990px) {
    width: 40vw;
  }
`;

export const SignInDiv = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
margin-top: 30px;
`;


export const LoadingDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
margin-top: 30px;

& img {
  height: 20%;
}
`;





export const SignInTextDiv = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 24px;
font-weight: 600;
line-height: 22px;
letter-spacing: 0px;
text-align: center;
`;

export const ErrorDiv = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 20px;
font-weight: 500;
line-height: 22px;
letter-spacing: 0px;
text-align: center;
color: red;
margin-top: 30px;
`;


export const SignInButtonDiv = styled.div`
width: 75%;
`;

export const SignInButton = styled.button`
font-family: 'Poppins', sans-serif;
font-size: 14px;
font-weight: 600;
color: #FFFFFF;
line-height: 30px;
letter-spacing: 0px;

background-color: #048FA5;
border-radius: 8px;
border: none;
height: 48px;
width: 100%;
padding: 0px 10px;
margin-top: 30px;
cursor: pointer;
`;