import styled from "styled-components";

export const MainDiv = styled.div`
  padding: 30px 40px;
  box-sizing: border-box;
  min-height: calc(100vh - 80px);

  background-color: #f8f8f8;
`;

export const PageHeading = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0px;
`;

export const HeadingText = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0px;
  color: #181917;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NoSearchDiv = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;

  color: #181917;

  & img {
    margin-bottom: 30px;
  }

  & p {
    text-align: center;
    width: 40%;
    margin: 0;
  }
`;

export const StartSearchButton = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 46px;
  padding: 0 30px;

  background-color: #048fa5;
  color: #ffffff;
  margin-top: 30px;
  border: 1px solid #048fa5;
  border-radius: 8px;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
`;


export const DownloadButton = styled.button`
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  background: #048FA5;
  color : #FFFFFF;
  box-shadow: none; 
  border-radius: 8px;
  border: 2px solid #048FA5;
  height : 48px;
  padding: 0px 10px;
  cursor: pointer;
`;




export const TableCellStyled = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  color: #181917;
`;

export const StatusSpan = styled.span`
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 2px 8px;
  border-radius: 6px;

  color: ${(props) =>
    (props?.status?.toLowerCase() === "inprogress" || props?.status?.toLowerCase() === "in progress" || props?.status?.toLowerCase() === "started")
      ? "#FFA217"
      : props?.status?.toLowerCase() === "completed"
      ? "#048FA5"
      : props?.status?.toLowerCase() === "error"
      ? "#F04639"
      : props?.status?.toLowerCase() === "inqueue"
      ? "#865fcf"
      : "#000000"} !important;
  background-color: ${(props) =>
    (props?.status?.toLowerCase() === "inprogress" || props?.status?.toLowerCase() === "in progress" || props?.status?.toLowerCase() === "started")
      ? "#FFF1DC"
      : props?.status?.toLowerCase() === "completed"
      ? "#C7EBF1"
      : props?.status?.toLowerCase() === "error"
      ? "#FFE4DE"
      : props?.status?.toLowerCase() === "inqueue"
      ? "#ded8e9"
      : "#FFFFFF"};
`;

export const BodyDiv = styled.div`
  > div > div > div > div > {
  }
`;

export const BackLinkDiv = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #048FA5;
  // opacity: 20%;

  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  & img {
    padding-right: 8px;
  }
`;

export const SearchDataDiv = styled.div`
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
`;

export const FiltersDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 3;
`;

export const DateDiv = styled.div`
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "30px")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "0px"};
  width: ${(props) => (props.width ? props.width : "250px")};
  min-width: 175px;

  > div.react-datepicker-wrapper {
    width: 100%;
  }

  > div > div.react-datepicker__input-container input {
    width: 100%;
  }

  // > div > div > div > div > div > div > div.react-datepicker__year-wrapper {
  //     max-width: 210px;
  // }
  > div > div > div > div > div > div > div > div.react-datepicker__year-text {
    width: 3.5rem;
  }
`;

export const DateInputField = styled.input`
  background: ${(props) =>
      props.value === null || props.value === "" ? 'url("/calendar.svg")' : ""}
    no-repeat;
  background-position: right 14px center;

  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 8px;
  height: 46px;
  padding: 14px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 24px;
  font-family: "Poppins", sans-serif;

  background-size: 20px;

  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    color: #919eab;
  }
`;

export const InputDiv = styled.div`
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "0px")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "0px"};
  width: ${(props) => (props.width ? props.width : "300px")};
  min-width: 260px;
`;

export const InputField = styled.input`
  background: url("/input-search-icon.svg") no-repeat;
  background-position: right 10px center;

  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 8px;
  width: 100%;
  height: 46px;
  padding: 14px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 24px;
  font-family: "Poppins", sans-serif;

  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    color: #919eab;
  }
`;

export const NoDataDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 450px);
  width: calc(100vw - 265px);
`;

export const RequestDataDiv = styled.div`
  color: #181917;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

export const TargetNamesCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #919eab3d;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 20px 30px;

  display: flex;
  flex-direction: column;

  & p {
    color: #181917;
    line-height: 24px;
  }
`;

export const NamesList = styled.span`
  width: 100%;
  color: #048fa5;
  padding-top: 15px;
`;

export const TimeDetailsDiv = styled.div`
  color: #6b797b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  padding-top: 15px;

  & p {
    margin: 0;
    color: #6b797b;
  }
`;

export const DatabaseListingDiv = styled.div`
  background-color: #ffffff;
  border: 1px solid #919eab3d;
  border-radius: 10px;
  margin-bottom: 30px;
`;

export const PortalHeading = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  color: #181917;
  padding: 20px 30px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #919eab3d;

  & p {
    color: #048fa5;
    font-size: 16px;
    margin: 0;

    display: flex;
  }

  & span {
    cursor: pointer;
    color: #048fa5;
    font-size: 16px;
  }
`;

export const ListDiv = styled.div`
  padding: 30px;
`;

export const ListHeading = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  display: flex;
  justify-content: space-between;

  & p {
    color: #637381;
    margin: 0;
  }
`;

export const ListEntry = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  width: 100%;

  & p {
    color: #181917;
    margin: 0;
    cursor: pointer;
  }

  & a{
    text-decoration: none;
    color: inherit;
    max-width: 70%;
    word-wrap: break-word;
  }
`;

export const NoFindingsDiv = styled.div`
display: flex;
justify-content: center;
align-items: center;
font-weight: 400;
`;

export const PortalsListDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-radius: 8px;
  text-align: left;
  box-sizing: border-box;
  padding: 30px 30px 0px;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "0px"};

  & p {
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    width: ${props => props.noOfValues > 3 ? '33%' : '100%'};
    margin: 0;
    padding: 10px 20px 0px;
    box-sizing: border-box;
    color: ${(props) => (props.type === "error" ? "#F04639" : "#000000")};
  }

  & p:after {
    content: "";
    background-color: ${(props) =>
      props.type === "error" ? "#F04639" : "#000000"};
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    left: 0;
    top: 17px;
  }
`;

export const ListText = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  color: #181917;
`;

export const DetailsPreviewDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 8px;
  width: calc(30% - 30px);

  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  color: #181917;
  position: sticky;
  top: 100px;
  right: 20px;

  & span {
    font-weight: 600;
  }
`;

export const ValuesList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
  border-bottom: ${(props) =>
    props.borderBottom === true ? "1px solid #C9C9C9" : "none"};

  & p {
    margin: 5px 0;
  }

  & p:first-child {
    margin-top: 0px;
  }
`;

export const PortalNameDiv = styled.div`
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #919eab3d;

  & p {
    cursor: pointer;
  }
`;

export const PortalsDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
  overflow: scroll;
`;

export const DatabaseDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  & p{
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    // width: 33%;
    word-wrap: break-word;
  }
`;

export const PortalListHeading = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  margin-bottom: 10px;
  color: ${(props) => (props.type === "error" ? "#F04639" : "#048FA5")};
`;
