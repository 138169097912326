import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const DeleteDialog = ({ open, onClose, onDelete, uniqueServiceCount }) => {
  // Construct the message for the dialog with line breaks
  const message = uniqueServiceCount > 0 
    ? `This category is mapped with ${uniqueServiceCount} service${uniqueServiceCount > 1 ? 's' : ''}.If you <br />delete this category, then it will be removed from <br />the mapped services. Do you really want to delete<br /> this category?`
    : 'Do you really want to delete this category?';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-dialog-title"
      maxWidth="xs"
      fullWidth={false}
      PaperProps={{
        style: {
          width: '500px', // Set the width to 500px
        },
      }}
    >
      <DialogTitle 
        id="delete-dialog-title" 
        style={{ 
          textAlign: 'center', 
          paddingBottom: '0px', // Remove bottom padding from title
          marginBottom: '16px'   // Add bottom margin to create gap between icon and message
        }}
      >
        <IconButton 
          color="error" 
          aria-label="delete" 
          style={{ 
            padding: '0px',  // Remove padding from IconButton
            margin: '0px',   // Remove margin from IconButton
            marginTop: '32px' // Further increased margin-top to lower the icon from the top
          }}
        >
          <img 
            src={`${process.env.PUBLIC_URL}/delete-icon1.svg`} 
            alt="Delete Icon" 
            style={{ width: 40, height: 40 }} 
          />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ 
        paddingTop: '0px', // Remove top padding from content
        paddingBottom: '16px', // Increase bottom padding to create more space below buttons
        marginTop: '16px', // Add margin-top to create gap between message and buttons
        marginBottom: '0px' // Remove margin-bottom to shift content upward
      }}>
        <Typography 
          component="div" 
          dangerouslySetInnerHTML={{ __html: message }}
          style={{ textAlign: 'center' }} // Center align text
        />
      </DialogContent>
      <DialogActions style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        padding: '8px 8px 16px 8px', // Increase bottom padding to move buttons up
        marginTop: '0px' // Ensure no space above buttons
      }}>
        <IconButton
          onClick={onClose}
          color="primary"
          aria-label="cancel"
          style={{ 
            width: '116px', 
            height: '48px', 
            marginRight: '0px', // Set marginRight to 0px to remove space
            transform: 'scale(1.2)' // Scale up the size of the button's content
          }}
        >
          <img 
            src={`${process.env.PUBLIC_URL}/cancel-button.svg`} 
            alt="Cancel Icon" 
            style={{ width: '100%', height: '100%' }} 
          />
        </IconButton>
        <IconButton
          onClick={onDelete}
          color="error"
          aria-label="delete"
          style={{ 
            width: '116px', 
            height: '48px',
            marginLeft: '-2px', // Use negative marginLeft to reduce space further
            transform: 'scale(1.2)' // Scale up the size of the button's content
          }}
        >
          <img 
            src={`${process.env.PUBLIC_URL}/delete-button.svg`} 
            alt="Delete Icon" 
            style={{ width: '100%', height: '100%' }} 
          />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
