import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Styled from "./signInStyled";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { fetchData } from "../../utils/fetch";
import { isValidToken } from "../../utils/utilFunctions";


const SignIn = () => {
  const [graphData, setGraphData] = useState(null);
  const [mail, setMail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [uniqueId, setUniqueId] = useState(null);
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();



  useEffect(() => {

    if(!isAuthenticated)
      setLoading(false);
    // console.log('=============>',inProgress, accounts, instance, graphData)

    if(!!graphData)
      return;
    
    if (inProgress === 'none' && accounts.length > 0) {
        const request = {
            account: accounts[0],
            scopes: ["user.read"]
        };
        // Retrieve an access token
        instance.acquireTokenSilent(request)
            .then(response => {
              setUniqueId(response?.uniqueId);
                if (response.accessToken) {
                  fetchData('https://graph.microsoft.com/v1.0/me', response.accessToken)
                    .then(response => {
                      setGraphData(response)
                      setMail(response?.mail)
                    }) 
                    .catch(error => console.log(error))
                }
                return null;
            })
            .catch(error => {
              setLoading(false);
              setErrorMessage("Token Error:")
              console.log('token error', error)
            });
    }
}, [inProgress, accounts, instance, graphData]);

useEffect(()=>{
  if(isAuthenticated && mail && uniqueId && errorMessage === null && isValidToken())
    navigate("/search");
  else if(mail && uniqueId)
    fetch(`${process.env.REACT_APP_API_PATH || 'http://localhost:3002/api/v1/'}user/sign-in`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uniqueId: uniqueId,
        email: mail
      }),
    }).then(res => res.json()).then(resp => {
      console.log(resp)
      if(resp?.data?.exists === false){
        setErrorMessage(resp?.data?.accessMessage || "please contact Admin")
        localStorage.removeItem("token");
        localStorage.removeItem("userAccess");
        localStorage.removeItem("userId");
        setLoading(false)
      } else if(resp?.data?.exists === true){
        localStorage.setItem("token", resp?.data?.token);
        localStorage.setItem('userId', resp?.data?.userId);
        localStorage.setItem("userAccess", resp?.data?.userAccess);
        if(isValidToken())
          navigate("/search");
      } else {
        setLoading(false);
        setErrorMessage('There is some error...');

      }
    });
}, [mail, uniqueId])

  

  const handleLogin = () => {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
  };

  const handleLogout = () => {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
  };

  
  return (
    <Styled.NotLoggedInDiv>
      <img src="/home-image.svg" alt="Home Page" />
      <Styled.RightDiv>
        <img src="/fios-logo.svg" alt="fios" />
        {loading === false ? 
          <Styled.SignInDiv>
            <Styled.SignInTextDiv>
              Sign in to FIOS Automation
            </Styled.SignInTextDiv>
            <Styled.SignInButtonDiv>
              <Styled.SignInButton
                onClick={() =>
                  isAuthenticated === false ? handleLogin() : handleLogout()
                }
              >
                {isAuthenticated === false ? "SIGN IN" : "SIGN OUT"}
              </Styled.SignInButton>
            </Styled.SignInButtonDiv>
          </Styled.SignInDiv>
          :
          <Styled.LoadingDiv>
            <img src="/rolling-loader.svg" alt="" />
            <Styled.SignInTextDiv style={{color: '#048FA5', marginTop: '10px'}}>
              Verifying Your Access...
            </Styled.SignInTextDiv>
          </Styled.LoadingDiv>
        }
        {errorMessage === null ? (
          ""
        ) : (
          <Styled.ErrorDiv>
            {`${errorMessage} ${errorMessage === 'Token Error:' ?  'Signout and Try logging in again.' : 'Signout Your Social Account'}`}
          </Styled.ErrorDiv>
        )}
      </Styled.RightDiv>
    </Styled.NotLoggedInDiv>
  );
};

export default SignIn;
