import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Layout from "../../components/Layout";
import * as Styled from "./categoriesStyled";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ServiceChangeModal from "../../components/Modal";
import * as axios from "../../utils/axios";
import DeleteDialog from './DeleteDialog'; // Ensure the path is correct


const Categories = () => {
  const [searchParams] = useSearchParams();
  const [categoryID, setCategoryID] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState(null);
  const [addCategory, setAddCategory] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [page, setPage] = useState(0);
  const [categoriesData, setCategoriesData] = useState([]);
  const [portalsDataWithService, setPortalsDataWithService] = useState([]);
  const [portalsToUpdate, setPortalsToUpdate] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [total, setTotal] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);
  const [uniqueServiceCount, setUniqueServiceCount] = useState(0);


  const columns = [
    {
      id: "category_name",
      label: "Category Name",
      getValue: (row) => (row?.name ? row.name : "-"),
    },    
    {
      id: "no_of_portals",
      label: "No. of Portals",
      getValue: (row) => (row?.no_of_portals ? row?.no_of_portals : "-"),
    },
    {
      id: "action",
      label: "Action",
      getValue: (row) => (
        <div style={{ display: 'flex', width: 'fit-content' }}>
          <div 
            style={{ 
              display: 'flex', 
              alignItems: 'center', 
              marginRight: '10px',
              position: 'relative',
              cursor: 'pointer'
            }} 
            onClick={() => handleEdit(row)}
          >
            <img src="/edit-icon.svg" alt="Edit" style={{ width: '24px', height: '24px' }} />
            <img 
              src="/edit-category-hover.svg" 
              alt="Edit category" 
              style={{ 
                position: 'absolute',
                bottom: '100%', // Position above the icon
                left: '50%',
                transform: 'translateX(-50%)',
                width: '100px', // Adjust size as needed
                height: 'auto',
                opacity: 0,
                transition: 'opacity 0.3s',
                zIndex: 2 // Ensure image is above the icon
              }}
            />
            <div 
              style={{ 
                position: 'absolute', 
                top: '0', 
                left: '0', 
                height: '100%', 
                width: '100%', 
                zIndex: 1 // This ensures the hover image is above this div
              }} 
              onMouseEnter={(e) => e.currentTarget.previousElementSibling.style.opacity = '1'}
              onMouseLeave={(e) => e.currentTarget.previousElementSibling.style.opacity = '0'}
            />
          </div>
          <div 
            onClick={() => handleDelete(row?.id)} 
            style={{ 
              display: 'flex', 
              alignItems: 'center',
              position: 'relative',
              cursor: 'pointer'
            }}
          >
            <img src="/delete-icon.svg" alt="Delete" style={{ width: '24px', height: '24px' }} />
            <img 
              src="/delete-category-hover.svg" 
              alt="Delete category" 
              style={{ 
                position: 'absolute',
                bottom: '100%', // Position above the icon
                left: '50%',
                transform: 'translateX(-50%)',
                width: '100px', // Adjust size as needed
                height: 'auto',
                opacity: 0,
                transition: 'opacity 0.3s',
                zIndex: 2 // Ensure image is above the icon
              }}
            />
            <div 
              style={{ 
                position: 'absolute', 
                top: '0', 
                left: '0', 
                height: '100%', 
                width: '100%', 
                zIndex: 1 // This ensures the hover image is above this div
              }} 
              onMouseEnter={(e) => e.currentTarget.previousElementSibling.style.opacity = '1'}
              onMouseLeave={(e) => e.currentTarget.previousElementSibling.style.opacity = '0'}
            />
          </div>
        </div>
      )
    },
  ];
  

  const handleDelete = async (categoryId) => {
    try {
      // Perform a query to check if the category is mapped to any services
      const serviceCountResponse = await axios.get(`categories/check-services-count/${categoryId}`);
      const responseData = serviceCountResponse.data;
      const count = parseInt(responseData.data.uniqueServiceCount, 10);
      setUniqueServiceCount(count);
      setCategoryIdToDelete(categoryId);
      setDialogOpen(true);
    } catch (error) {
      console.log(error);
      setErrorMessage("An error occurred while fetching service count.");
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.deleteRequest(`categories/delete/${categoryIdToDelete}`);
      if (response.status === 200) {
        // Assuming you have a function to update categories data
        setCategoriesData((prevData) => prevData.filter((category) => category.id !== categoryIdToDelete));
        setErrorMessage(null);
      } else {
        setErrorMessage("Failed to delete the category. Please try again.");
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("An error occurred while deleting the category.");
    } finally {
      setDialogOpen(false);
      setCategoryIdToDelete(null);
      setUniqueServiceCount(0);
    }
  };

  const handleCancelDelete = () => {
    setDialogOpen(false);
    setCategoryIdToDelete(null);
  };




  const capitalize = (val) => {
    if (val && val !== "") {
      return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
    } else {
      return "";
    }
  };

  useEffect(() => {
    if(categoryID === null)
      fetchCategoriesData();
    else
      fetchPortalsDataWithService();
  }, [page, rowsPerPage, categoryID]);

  const userAccess = localStorage.getItem("userAccess");
  const navigate = useNavigate();

  useEffect(() => {
    if (userAccess === "0") navigate("/search");
  }, []);

  const handleEdit = ({id,name}) => {
    setCategoryID(id);
    setCategoryName(name);
    setNewCategoryName(name);
    navigate(`/categories?categoryID=${id}`)
  } 

  useEffect(() => {
    setCategoryID(searchParams.get("categoryID"));
    if(searchParams.get("categoryID") === null){
      setModalOpen(false);
      setCategoryName(null);
      setAddCategory(false);
      setErrorMessage(null);
      setCategoryID(null);
      setPortalsToUpdate({});
      setPortalsDataWithService([]);
    }
  }, [window.location.href]);


  const fetchPortalsDataWithService = async () => {
    try {
      const response = await axios.get(`categories/get-portals-with-category-check/${categoryID}`);
      setPortalsDataWithService(response?.data?.data?.portals); // Set the fetched user data to the state variable
      setCategoryName(response?.data?.data?.category_name)
    } catch (error) {
      console.log(error);
    }
  }

  const fetchCategoriesData = async () => {
    try {
      const response = await axios.get(`categories/get-all-categories-portals`);
      setCategoriesData(response?.data?.data?.services); // Set the fetched user data to the state variable
      setTotal(response?.data?.data?.services?.[0].total_count);
    } catch (error) {
      console.log(error);
    }
  };

  const updatePortals = (checked, portal) => {
    portalsToUpdate[portal?.id] === undefined ?
      setPortalsToUpdate({...portalsToUpdate,
        [portal?.id] : checked === true ? 'add' : 'remove'
      })
    :
      setPortalsToUpdate((prevData) => {
        const newData = {...prevData}
        delete newData[portal?.id]
        return newData;
      })

    setErrorMessage(null);
  }

  const handleBack = () => {
    setCategoryID(null);
    setCategoryName(null);
    setAddCategory(false);
    setErrorMessage(null);
    setPortalsToUpdate({});
    setPortalsDataWithService([]);
    navigate("/categories");
  }



 
    const handleAddService = async () => {
      try {
        // Validation checks
        if (addCategory) {
          if (!newCategoryName) {
            setErrorMessage("Provide a name for the new service.");
            return;
          } else if (Object.keys(portalsToUpdate).length === 0) {
            setErrorMessage("Add at least one portal to the service.");
            return;
          }
        }
    
        // Make the API request
        const response = await axios.post(`portal/update-portals`, {
          categoryId: categoryID,
          portalsList: portalsToUpdate,
          categoryName: newCategoryName,
        });
    
        // Handle the response
        if (response?.status === 200) {
          setModalOpen(true);
        } else {
          // Handle other unexpected status codes
          setErrorMessage("There was an issue with your request. Please try again later.");
        }
      } catch (error) {
        // Handle errors
        if (error.response?.status === 400) { // Handle the specific error for existing category
          setErrorMessage("Category name already exists. Please choose a different name.");
        } else {
          console.log(error);
          setErrorMessage("An error occurred while updating the category.");
        }
      }
    };
    
  const handleAddnew = () => {
    setAddCategory(true);
    navigate("/categories?categoryID=00000000-0000-0000-0000-000000000000");
  }

  return (
    <Layout>
      <Styled.MainDiv>
        {categoryName === null ?
          ''
        :
          <Styled.BackLinkDiv onClick={handleBack}>
            <img src="/back-icon.svg" alt="<-" /> Back
          </Styled.BackLinkDiv>
        }
        <Styled.PageHeading>
          {categoryID === '00000000-0000-0000-0000-000000000000' ? 'Add New Category' : 
            categoryName === null ?
              'All Categories'
            :
              categoryName
          }

          {addCategory === false && categoryID === null ? 
            <Styled.Button width={'120px'} onClick={handleAddnew}>
              Add New
            </Styled.Button>
            :
            ''
          }
        </Styled.PageHeading>
        <Styled.HeadingText>
          {
            categoryName === null ?
            'View, add and edit the categories and their portals from here.'
          :
            'Add/Edit categories and select their portals here.'
          }
        </Styled.HeadingText>
        <Styled.DataDiv>
          {categoryID === null && addCategory === false ? (
            <Paper
              sx={{
                border: "1px solid #919EAB3D",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    "& .MuiTableRow-hover:hover": {
                      backgroundColor: "#ECFDFF !important",
                      cursor: "pointer",
                    },
                    "& .MuiTableRow-hover:hover > td > div": {
                      color: "#181917",
                      fontWeight: "600",
                    },
                    "& .MuiTableRow-hover:hover > td:first-child": {
                      borderLeft: "3px solid #048FA5 !important",
                    },
                  }}
                >
                  <TableHead
                    sx={{
                      "& th": { backgroundColor: "#F4F6F8", cursor: "default" },
                    }}
                  >
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#637381",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {categoriesData &&
                      categoriesData.map((row) => (
                        <TableRow hover tabIndex={-1} key={row.id}>
                          {columns.map((column, columnIndex) => (
                            <TableCell key={column.id} align={column.align}>
                              <Styled.TableCellStyled>
                                {column.getValue && column.getValue(row) !== ""
                                  ? column.getValue(row)
                                  : "-"}
                              </Styled.TableCellStyled>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {categoriesData?.length > 0 && total > rowsPerPage && (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(e) => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                    setPage(0);
                  }}
                />
              )}
            </Paper>
          ) : (
            <Styled.EditPortalsDiv>
              <Styled.InputDiv>
                <Styled.InputLabel>Category Name</Styled.InputLabel>
                <Styled.InputField
                  placeholder="Enter category name"
                  defaultValue={categoryName}
                  onChange={(e)=>{setNewCategoryName(e.target.value)}}
                />
              </Styled.InputDiv>
              <Styled.InputLabel marginBottom={'10px'} marginTop={'20px'} required={false}>
                Select Portals
              </Styled.InputLabel>
              <Styled.PortalsList>
                {portalsDataWithService?.map(portal => (
                  <Styled.PortalItem key={portal?.id}>
                    <input type="checkbox" onClick={(e) => updatePortals(e.target.checked,portal)} defaultChecked={portal?.exists_in_category} />
                    <Styled.InputLabel required={false} marginBottom = {'0'} marginLeft = {'10px'}>
                      {portal?.name}
                    </Styled.InputLabel>
                  </Styled.PortalItem>
                ))}
              </Styled.PortalsList>

              <Styled.Button onClick={handleAddService}>
                {addCategory === true ?
                  'Add Category'
                :
                  'Edit Category'
                }
              </Styled.Button>

              {errorMessage !== null ? (
                  <Styled.InputErrorDiv>
                    {errorMessage}
                  </Styled.InputErrorDiv>
                ) : (
                  ""
                )}
            </Styled.EditPortalsDiv>
          )}
        </Styled.DataDiv>
      </Styled.MainDiv>
      <ServiceChangeModal modalOpen={modalOpen} height={'25%'} minHeight={'25%'} >
          <img src="/thumbs-up-icon.svg" alt="OK" />
          <p>{`Category has been successfully ${addCategory === true ? 'created!' : 'edited!'}`}</p>
          <Styled.Button onClick={() => navigate("/categories")}>
            Okay!
          </Styled.Button>
        </ServiceChangeModal>
        <DeleteDialog
        open={dialogOpen}
        onClose={handleCancelDelete}
        onDelete={handleConfirmDelete}
        uniqueServiceCount={uniqueServiceCount}
      />
    </Layout>
  );
};

export default Categories;
