// import { useNavigate } from "react-router-dom";     todo
import * as Styled from "./modalStyled";

const Modal = (props) => {
  // const navigate = useNavigate();        todo 
  const {
    children,
    modalOpen,
    setModalOpen,
    padding,
    width,
    height,
    minHeight,
    minWidth,
    overflow
  } = props;

  return (
    <Styled.MainDiv modalOpen={modalOpen}>
      <Styled.OverLayDiv id="overlay" />
      <Styled.ModalContentDiv
        width={width}
        height={height}
        minHeight={minHeight}
        minWidth={minWidth}
        padding={padding}
        overflow={overflow}
      >
        {children}
      </Styled.ModalContentDiv>
    </Styled.MainDiv>
  );
};

export default Modal;
