import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Layout from "../../components/Layout";
import * as Styled from "./servicesStyled";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ServiceChangeModal from "../../components/Modal";
import * as axios from "../../utils/axios";

const Services = () => {
  const [searchParams] = useSearchParams();
  const [serviceID, setServiceID] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [newServiceName, setNewServiceName] = useState(null);
  const [addService, setAddService] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [page, setPage] = useState(0);
  const [servicesData, setServicesData] = useState([]);
  const [categoriesDataWithService, setCategoriesDataWithService] = useState([]);
  const [categoriesToUpdate, setCategoriesToUpdate] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [total, setTotal] = useState(null);

  const columns = [
    {
      id: "service_name",
      label: "Service Name",
      getValue: (row) => (row?.name ? capitalize(row?.name) : "-"),
    },
    {
      id: "no_of_categories",
      label: "No. of Categories",
      getValue: (row) => (row?.no_of_categories ? row?.no_of_categories : "-"),
    },
    {
      id: "no_of_portals",
      label: "No. of Portals",
      getValue: (row) => (row?.no_of_portals ? row?.no_of_portals : "-"),
    },
    {
      id: "action",
      label: "Action",
      getValue: (row) => (
      <div style={{display: 'flex', width: 'fit-content'}} onClick={() => handleEdit(row)}>
        <img src="/edit-icon.svg" alt="~"/>
        <p style={{ margin: "0", fontSize: "14px", padding: '5px 5px 0px' }}>
          Edit
        </p>
      </div>)
    },
  ];

  const capitalize = (val) => {
    if (val && val !== "") {
      return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (serviceID === null)
      fetchServicesData();
    else
      fetchCategoriesDataWithService();
  }, [page, rowsPerPage, serviceID]);

  const userAccess = localStorage.getItem("userAccess");
  const navigate = useNavigate();

  useEffect(() => {
    if (userAccess === "0") navigate("/search");
  }, []);

  const handleEdit = ({id,name}) => {
    setServiceID(id);
    setServiceName(name);
    setNewServiceName(name);
    navigate(`/services?serviceID=${id}`)
  } 

  useEffect(() => {
    setServiceID(searchParams.get("serviceID"));
    if (searchParams.get("serviceID") === null){
      setModalOpen(false);
      setServiceName(null);
      setAddService(false);
      setErrorMessage(null);
      setServiceID(null);
      setCategoriesToUpdate({});
      setCategoriesDataWithService([]);
    }
  }, [window.location.href]);


  const fetchCategoriesDataWithService = async () => {
    try {
      const response = await axios.get(`services/get-categories-with-service-check/${serviceID}`);
      setCategoriesDataWithService(response?.data?.data?.categories); // Set the fetched user data to the state variable
      setServiceName(response?.data?.data?.service_name)
    } catch (error) {
      console.log(error);
    }
  }

  const fetchServicesData = async () => {
    try {
      const response = await axios.get(`services/get-all-services-categories-portals`);
      setServicesData(response?.data?.data?.services); // Set the fetched user data to the state variable
      setTotal(response?.data?.data?.services?.[0].total_count);
    } catch (error) {
      console.log(error);
    }
  };

  const updateCategories = (checked, category) => {
    categoriesToUpdate[category?.id] === undefined ?
      setCategoriesToUpdate({...categoriesToUpdate,
        [category?.id] : checked === true ? 'add' : 'remove'
      })
    :
      setCategoriesToUpdate((prevData) => {
        const newData = {...prevData}
        delete newData[category?.id]
        return newData;
      })

    setErrorMessage(null);
  }

  const handleBack = () => {
    setServiceID(null);
    setServiceName(null);
    setAddService(false);
    setErrorMessage(null);
    setCategoriesToUpdate({});
    setCategoriesDataWithService([]);
    navigate("/services");
  }

  const handleAddService = async () => {
    try {
      if (addService === true){
        if (newServiceName === null){
          setErrorMessage("Provide name for new service.")
          return;
        } else if (Object.keys(categoriesToUpdate).length === 0){
          setErrorMessage("Add atleast one category in service.");
          return;
        }
      }
        
      const response = await axios.post(`categories/update-categories`, {
        serviceId: serviceID,
        categoriesList: categoriesToUpdate,
        serviceName: newServiceName,
      });
      
      if (response?.status === 200)
        setModalOpen(true);
      else
        setErrorMessage("There is some error, Please try after sometime.")
    } catch (error) {
      console.log(error);
    }
  }

  const handleAddnew = () => {
    setAddService(true);
    navigate("/services?serviceID=00000000-0000-0000-0000-000000000000");
  }

  return (
    <Layout>
      <Styled.MainDiv>
        {serviceName === null ?
          ''
        :
          <Styled.BackLinkDiv onClick={handleBack}>
            <img src="/back-icon.svg" alt="<-" /> Back
          </Styled.BackLinkDiv>
        }
        <Styled.PageHeading>
          {serviceID === '00000000-0000-0000-0000-000000000000' ? 'Add New Service' : 
            serviceName === null ?
              'All Services'
            :
              serviceName
          }

          {addService === false && serviceID === null ? 
            <Styled.Button width={'120px'} onClick={handleAddnew}>
              Add New
            </Styled.Button>
            :
            ''
          }
        </Styled.PageHeading>
        <Styled.HeadingText>
          {
            serviceName === null ?
            'View, add and edit the services and their categories from here.'
          :
            'Add/Edit service and select their categories here.'
          }
        </Styled.HeadingText>
        <Styled.DataDiv>
          {serviceID === null && addService === false ? (
            <Paper
              sx={{
                border: "1px solid #919EAB3D",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    "& .MuiTableRow-hover:hover": {
                      backgroundColor: "#ECFDFF !important",
                      cursor: "pointer",
                    },
                    "& .MuiTableRow-hover:hover > td > div": {
                      color: "#181917",
                      fontWeight: "600",
                    },
                    "& .MuiTableRow-hover:hover > td:first-child": {
                      borderLeft: "3px solid #048FA5 !important",
                    },
                  }}
                >
                  <TableHead
                    sx={{
                      "& th": { backgroundColor: "#F4F6F8", cursor: "default" },
                    }}
                  >
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#637381",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {servicesData &&
                      servicesData.map((row) => (
                        <TableRow hover tabIndex={-1} key={row.id}>
                          {columns.map((column, columnIndex) => (
                            <TableCell key={column.id} align={column.align}>
                              <Styled.TableCellStyled>
                                {column.getValue && column.getValue(row) !== ""
                                  ? column.getValue(row)
                                  : "-"}
                              </Styled.TableCellStyled>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {servicesData?.length > 0 && total > rowsPerPage && (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(e) => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                    setPage(0);
                  }}
                />
              )}
            </Paper>
          ) : (
            <Styled.EditPortalsDiv>
              <Styled.InputDiv>
                <Styled.InputLabel>Service Name</Styled.InputLabel>
                <Styled.InputField
                  placeholder="Enter service name"
                  defaultValue={serviceName}
                  onChange={(e)=>{setNewServiceName(e.target.value)}}
                />
              </Styled.InputDiv>
              <Styled.InputLabel marginBottom={'10px'} marginTop={'20px'} required={false}>
                Select Categories
              </Styled.InputLabel>
              <Styled.PortalsList>
                {categoriesDataWithService?.map(category => (
                  <Styled.PortalItem key={category?.id}>
                    <input type="checkbox" onClick={(e) => updateCategories(e.target.checked,category)} defaultChecked={category?.exists_in_service} />
                    <Styled.InputLabel required={false} marginBottom = {'0'} marginLeft = {'10px'}>
                      {category?.name}
                    </Styled.InputLabel>
                  </Styled.PortalItem>
                ))}
              </Styled.PortalsList>

              <Styled.Button onClick={handleAddService}>
                {addService === true ?
                  'Add Service'
                :
                  'Edit Service'
                }
              </Styled.Button>

              {errorMessage !== null ? (
                  <Styled.InputErrorDiv>
                    {errorMessage}
                  </Styled.InputErrorDiv>
                ) : (
                  ""
                )}
            </Styled.EditPortalsDiv>
          )}
        </Styled.DataDiv>
      </Styled.MainDiv>
      <ServiceChangeModal modalOpen={modalOpen} height={'25%'} minHeight={'25%'} >
          <img src="/thumbs-up-icon.svg" alt="OK" />
          <p>{`Service has been successfully ${addService === true ? 'created!' : 'edited!'}`}</p>
          <Styled.Button onClick={() => navigate("/services")}>
            Okay!
          </Styled.Button>
        </ServiceChangeModal>
    </Layout>
  );
};

export default Services;
