 export function Main() {
  let file = [
    {
      State: "Andaman and Nicobar",
      District: "Port Blair",
      Court: "PORT BLAIR COURT COMPLEX",
    },
    {
      State: "Andhra Pradesh",
      District: "Ananthapur",
      Court: "Court of Senior Civil Judge,, Penukonda (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Ananthapur",
      Court: "II Addl. District Court, Hindupur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Ananthapur",
      Court: "Court of Junior Civil Judge,, Guntakal (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Ananthapur",
      Court: "Court of Junior Civil Judge,, Uravakonda (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Ananthapur",
      Court: "Junior Civil Court, Puttaparthy",
    },
    {
      State: "Andhra Pradesh",
      District: "Ananthapur",
      Court: "Court of Senior Civil Judge,, Dharmavaram (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Ananthapur",
      Court: "Court of Senior Civil Judge,, Kadiri (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Ananthapur",
      Court:
        "Court of Spl. Judl. Magistrate of I Class for Railways, Guntakal (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Ananthapur",
      Court: "Court of Junior Civil Judge,, Kalyandurg (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Ananthapur",
      Court: "Court of Junior Civil Judge,, Madakasira (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Ananthapur",
      Court: "VI Addl. District Judge (FTC) Anantapur at Gooty, Gooty (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Ananthapur",
      Court: "Prl. District & Sessions Court, ANANTHAPURAMU (District)",
    },
    {
      State: "Andhra Pradesh",
      District: "Ananthapur",
      Court: "Court of Junior Civil Judge,, Rayadurg (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Ananthapur",
      Court: "Court of Junior Civil Judge,, Tadipatri (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "Court of Senior Civil Judge,, Punganur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "Court of Senior Civil Judge,, Puttur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "III Addl. District Court,, Tirupathi (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "II Addl District Court Madanapalle",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "Court of Junior Civil Judge,, Pakala (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "Court of Junior Civil Judge,, Kuppam (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "Court of Senior Civil Judge Piler",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "Court of Senior Civil Judge,, Srikalahasti (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "Prl District Courts, Chittoor",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "Court of Junior Civil Judge,, Vayalpadu (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "Court of Junior Civil Judge,, Nagari (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "Court of Junior Civil Judge,, Satyavedu (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "Court of Junior Civil Judge,, Palamaner (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "XI Additional District Court, Piler",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "Court of Junior Civil Judge,, Thamballapalle (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "Senior Civil Court, Palamaner",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "VI Junior Civil Court, Tirupathi",
    },
    {
      State: "Andhra Pradesh",
      District: "Chittoor",
      Court: "Court of Prl. Junior Civil Judge,, Punganur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "PDJ Court Complex ,Rajahmundry",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "JFCM Addateegala",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "III Addl District Court Kakinada",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "II Addl District Court Amalapuram",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "Court of Senior Civil Judge,, Razole (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "Senior Civil Courts, Pithapuram",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "III AJFCM Rajamahendravaram",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "Court of Senior Civil Judge,, Ramachandrapuram (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "Court of Senior Civil Judge,, Peddapuram (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "Court of Prl. Junior Civil Judge,, Kothapet (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "Court of Junior Civil Judge,, Tuni (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "Court of Junior Civil Judge,, Mummidivaram (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "Court of Junior Civil Judge, Anaparthy (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "Court of Junior Civil Judge,, Alamuru (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court:
        "Court of Judicial Magistrate of First Class, Rampachodavaram (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "Spl JFCM Kakinada",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "Court of Addl. Junior Civil Judge (new), Tuni (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "Munsif Magistrate Courts,Rajamundry",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "Junior Civil Courts, Prathipadu",
    },
    {
      State: "Andhra Pradesh",
      District: "East Godavari",
      Court: "ACB Court at Rajamahedravaram",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "Court of Junior Civil Judge, Piduguralla (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "II Addl JCJ Court, Sattenapalli",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "X Addl District Court Narasaraopet",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "Court of Senior Civil Judge, Mangalagiri (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court:
        "Court of Addl. Junior Civil Judge, (Formerly II AJCJ, Bapatla), Chilakaluripet (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "PDJ Court Complex, Guntur",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "Court of Senior Civil Judge,, Bapatla (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "Court of Senior Civil Judge,, Gurazala (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "Junior Civil Courts, Macherla",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "Court of Prl. Junior Civil Judge,, Sattenapalli (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "XI Addl. District Court (FTC), Tenali (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "Spl JFCM for Railways Guntur",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "Court of II Addl. Junior Civil Judge, Tenali (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "Court of Senior Civil Judge, Repalle (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "Court of Addl. Junior Civil Judge,, Ponnur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "Court of Prl. Junior Civil Judge,, Ponnur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "Court of Prl. Junior Civil Judge,, Chilakaluripet (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Guntur",
      Court: "Court of Junior Civil Judge, Vinukonda (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Kadapa",
      Court: "Court of Junior Civil Judge, Lakkireddipalli (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Kadapa",
      Court: "Court of Junior Civil Judge, Sidhout (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Kadapa",
      Court: "Court of Junior Civil Judge, Nandalur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Kadapa",
      Court: "District Court Complex Kadapa",
    },
    {
      State: "Andhra Pradesh",
      District: "Kadapa",
      Court: "Addl SCJ Court Complex Badvel",
    },
    {
      State: "Andhra Pradesh",
      District: "Kadapa",
      Court: "III Add Dist Court Complex Rajampet",
    },
    {
      State: "Andhra Pradesh",
      District: "Kadapa",
      Court: "Court of Junior Civil Judge, Kamalapuram (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Kadapa",
      Court: "Court of Junior Civil Judge, Jammalamadugu (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Kadapa",
      Court: "Junior Civil Judge Court, Mydukur",
    },
    {
      State: "Andhra Pradesh",
      District: "Kadapa",
      Court: "II Additional District Judge Court, Proddatur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Kadapa",
      Court: "Court of Junior Civil Judge, Railway Kodur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Kadapa",
      Court: "V Addl Dist Court Complex Rayachoty",
    },
    {
      State: "Andhra Pradesh",
      District: "Kadapa",
      Court: "Junior Civil Judge Pulivendula",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "Junior Civil Courts, Vuyyuru",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "Prl JCJ Court Gannavaram",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "II Addl DJ Court Vijayawada",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "Addl Junior Civil Court Gannavaram",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "JCJ Court Complex,Movva",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "II Metropolitan Magistrate, Vijayawada (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "Junior Civil Courts Mylavaram",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "III Metropolitan Magistrate, Vijayawada (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "I Metropolitan Magistrate, Vijayawada (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "Junior Civil Courts Kaikaluru",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "XV-ADJ Court Complex Nuzvid",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "Senior Civil Judge Court, Nandigama (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "Prl District Courts, Machilipatnam",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "Senior Civil Judge Court, Avanigadda",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "Prl. Junior Civil Judge Court, Tiruvur",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "Junior Civil Judge Court, Bantumilli (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "Prl. Junior Civil Judge Court, Jaggaiahpet",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "XI-ADJ Court Complex Gudivada",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "XVI Addl District Courts, Nandigama",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "Senior Civil Judges Court Kaikaluru",
    },
    {
      State: "Andhra Pradesh",
      District: "Krishna",
      Court: "Senior Civil Judge Court Gannavaram",
    },
    {
      State: "Andhra Pradesh",
      District: "Kurnool",
      Court: "V Add District Complex, Allagadda",
    },
    {
      State: "Andhra Pradesh",
      District: "Kurnool",
      Court: "Prl JCJ Court Dhone",
    },
    {
      State: "Andhra Pradesh",
      District: "Kurnool",
      Court: "II Additional JCJ Court, Adoni",
    },
    {
      State: "Andhra Pradesh",
      District: "Kurnool",
      Court: "PDJ Court Complex, Kurnool",
    },
    {
      State: "Andhra Pradesh",
      District: "Kurnool",
      Court: "Senior Civil Judge`s Court, Atmakur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Kurnool",
      Court: "Senior Civil Judge`s Court, Nandikotkur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Kurnool",
      Court: "PJCJ court complex Kurnool",
    },
    {
      State: "Andhra Pradesh",
      District: "Kurnool",
      Court: "Junior Civil Judge`s Court, Pattikonda (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Kurnool",
      Court: "II Addl District Court Adoni",
    },
    {
      State: "Andhra Pradesh",
      District: "Kurnool",
      Court: "Junior Civil Judge`s Court, Yemmiganur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Kurnool",
      Court: "Junior Civil Judge`s Court, Banaganapalli (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Kurnool",
      Court: "Junior Civil Judge`s Court, Koilkuntla (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Kurnool",
      Court: "Junior Civil Judge`s Court, Alur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Kurnool",
      Court: "III Addl District Complex, Nandyal",
    },
    {
      State: "Andhra Pradesh",
      District: "Nellore",
      Court: "Junior Civil Judge`s Court, Venkatagiri (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Nellore",
      Court: "Prl.Junior Civil Judge`s Court, Kavali (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Nellore",
      Court: "Principal District & Sessions Court, Nellore (District)",
    },
    {
      State: "Andhra Pradesh",
      District: "Nellore",
      Court: "Addl Dist Court Complex Gudur",
    },
    {
      State: "Andhra Pradesh",
      District: "Nellore",
      Court: "Senior Civil Judge`s Court, Kovur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Nellore",
      Court: "Senior Civil Judge`s Court, Kavali (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Nellore",
      Court: "Junior Civil Judge`s Court, Udayagiri (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Nellore",
      Court: "Junior Civil Judges Court Sullurpet",
    },
    {
      State: "Andhra Pradesh",
      District: "Nellore",
      Court: "Junior Civil Judge`s Court, Kota (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Nellore",
      Court: "Junior Civil Judge`s Court, Atmakur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Nellore",
      Court: "JMFC for Railways, Nellore (District)",
    },
    {
      State: "Andhra Pradesh",
      District: "Nellore",
      Court: "JCJ Naidupeta",
    },
    {
      State: "Andhra Pradesh",
      District: "Prakasham",
      Court: "Junior Civil Judge's Court, Podili (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Prakasham",
      Court: "Junior Civil Judge's Court, Kanigiri (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Prakasham",
      Court: "Prl District Court Ongole",
    },
    {
      State: "Andhra Pradesh",
      District: "Prakasham",
      Court: "Senior Civil Judge, Darsi (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Prakasham",
      Court: "Senior Civil Judge, Parchur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Prakasham",
      Court: "JCJ Court Complex, Giddalur",
    },
    {
      State: "Andhra Pradesh",
      District: "Prakasham",
      Court: "Senior Civil Judge, Chirala (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Prakasham",
      Court: "SCJ Court Complex, Kandukur",
    },
    {
      State: "Andhra Pradesh",
      District: "Prakasham",
      Court: "Senior Civil Judge, Addanki (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Prakasham",
      Court: "VI Addl District Court, Markapur",
    },
    {
      State: "Andhra Pradesh",
      District: "Srikakulam",
      Court: "Court of Junior Civil Judge,, Palasa (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Srikakulam",
      Court: "Court of Senior Civil Judge,, Rajam (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Srikakulam",
      Court: "Prl. District& Sessions Court,, Srikakulam (District)",
    },
    {
      State: "Andhra Pradesh",
      District: "Srikakulam",
      Court: "Court of Senior Civil Judge,Tekkali",
    },
    {
      State: "Andhra Pradesh",
      District: "Srikakulam",
      Court: "VI Addl District Court Sompeta",
    },
    {
      State: "Andhra Pradesh",
      District: "Srikakulam",
      Court: "Court of Junior Civil Judge,, Amadalavalasa (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Srikakulam",
      Court: "Court of Junior Civil Judge,, Ichapuram (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Srikakulam",
      Court: "Court of Junior Civil Judge,, Kotabommali (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Srikakulam",
      Court: "Junior Civil Judge Court, Ponduru",
    },
    {
      State: "Andhra Pradesh",
      District: "Srikakulam",
      Court: "Junior Civil Judge Court Palakonda",
    },
    {
      State: "Andhra Pradesh",
      District: "Srikakulam",
      Court: "Junior Civil Judge Court,kothuru",
    },
    {
      State: "Andhra Pradesh",
      District: "Srikakulam",
      Court: "JCJ Court, Narasannapeta",
    },
    {
      State: "Andhra Pradesh",
      District: "Srikakulam",
      Court: "JCJ Court, Pathapatnam",
    },
    {
      State: "Andhra Pradesh",
      District: "Visakapatnam",
      Court: "Court of Judicial Magistrate of First Class, Araku (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Visakapatnam",
      Court: "X Addl District Court Anakapalle",
    },
    {
      State: "Andhra Pradesh",
      District: "Visakapatnam",
      Court: "SCJ Narsipatnam",
    },
    {
      State: "Andhra Pradesh",
      District: "Visakapatnam",
      Court: "IIMM Railways VSKP",
    },
    {
      State: "Andhra Pradesh",
      District: "Visakapatnam",
      Court:
        "Court of Judicial Magistrate of First Class, Chintapalli (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Visakapatnam",
      Court: "PDJ Court Visakhapatnam",
    },
    {
      State: "Andhra Pradesh",
      District: "Visakapatnam",
      Court: "CBI Court, Visakhapatnam",
    },
    {
      State: "Andhra Pradesh",
      District: "Visakapatnam",
      Court: "Court of Judicial Magistrate of First Class, Paderu (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Visakapatnam",
      Court: "Court of Junior Civil Judge,, Madugula (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Visakapatnam",
      Court: "Senior Civil Judge Chodavaram",
    },
    {
      State: "Andhra Pradesh",
      District: "Visakapatnam",
      Court: "Court of Senior Civil Judge,, Yellamanchili (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Visakapatnam",
      Court: "Gajuwaka Court Complex Gajuwaka",
    },
    {
      State: "Andhra Pradesh",
      District: "Visakapatnam",
      Court: "JCJ Bheemunipatnam",
    },
    {
      State: "Andhra Pradesh",
      District: "Vizianagaram",
      Court: "Court of Judicial Magistrate of First Class, Kurupam (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Vizianagaram",
      Court: "Court of Junior Civil Judge,, Cheepurupalli (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Vizianagaram",
      Court: "Court of Senior Civil Judge,, Bobbili (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Vizianagaram",
      Court: "Court of Junior Civil Judge,, Srungavarapukota (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Vizianagaram",
      Court: "Court of Junior Civil Judge,, GajapathiNogaram (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Vizianagaram",
      Court: "Court of Junior Civil Judge,, Kothavalasa (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Vizianagaram",
      Court: "Court of Junior Civil Judge,, Salur (Taluka)",
    },
    {
      State: "Andhra Pradesh",
      District: "Vizianagaram",
      Court: "II Additional District Court,PVP",
    },
    {
      State: "Andhra Pradesh",
      District: "Vizianagaram",
      Court: "PDJ Court VIZIANAGARAM",
    },
    {
      State: "Andhra Pradesh",
      District: "West Godavari",
      Court: "SCJ Court Complex Tadepalligudem",
    },
    {
      State: "Andhra Pradesh",
      District: "West Godavari",
      Court: "IV ADJ Court Complex, Tanuku",
    },
    {
      State: "Andhra Pradesh",
      District: "West Godavari",
      Court: "JCJ Court, Chintalapudi",
    },
    {
      State: "Andhra Pradesh",
      District: "West Godavari",
      Court: "PDJ Court Complex Eluru",
    },
    {
      State: "Andhra Pradesh",
      District: "West Godavari",
      Court: "Junior Civil Courts, Nidadavole",
    },
    {
      State: "Andhra Pradesh",
      District: "West Godavari",
      Court: "Junior Civil Courts Jangareddigudem",
    },
    {
      State: "Andhra Pradesh",
      District: "West Godavari",
      Court: "Junior Civil Court, Bhimadole",
    },
    {
      State: "Andhra Pradesh",
      District: "West Godavari",
      Court: "III ADJ Court Complex, Bhimavaram",
    },
    {
      State: "Andhra Pradesh",
      District: "West Godavari",
      Court: "II AJFCM Court, Bhimavaram",
    },
    {
      State: "Andhra Pradesh",
      District: "West Godavari",
      Court: "SCJ Court Complex Kovvur",
    },
    {
      State: "Andhra Pradesh",
      District: "West Godavari",
      Court: "I AJCJ Court, Bhimavaram",
    },
    {
      State: "Andhra Pradesh",
      District: "West Godavari",
      Court: "X ADJ Court Complex, Narsapur",
    },
    {
      State: "Andhra Pradesh",
      District: "West Godavari",
      Court: "JCJ Court Complex, Palakol",
    },
    {
      State: "Arunachal Pradesh",
      District: "East Siang",
      Court: "District and Session Court,Pasighat",
    },
    {
      State: "Arunachal Pradesh",
      District: "Lohit",
      Court: "District And Sessions Court, Tezu",
    },
    {
      State: "Arunachal Pradesh",
      District: "Papum Pare",
      Court: "District and Sessions Judge, Yupia",
    },
    {
      State: "Arunachal Pradesh",
      District: "Papum Pare",
      Court: "CJM-cum-Civil Judge, Yupia",
    },
    {
      State: "Arunachal Pradesh",
      District: "West Kameng",
      Court: "District and Sessions Court,Bomdila",
    },
    {
      State: "Assam",
      District: "Bajali",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Baksa",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Barpeta",
      Court: "District Judge's Court Complex",
    },
    {
      State: "Assam",
      District: "Barpeta",
      Court: "CJM Complex",
    },
    {
      State: "Assam",
      District: "Barpeta",
      Court: "Family Court",
    },
    {
      State: "Assam",
      District: "Biswanath",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Biswanath",
      Court: "SDJM Court Complex,Gohpur",
    },
    {
      State: "Assam",
      District: "Bongaigaon",
      Court: "SDJM (M) Court Complex, Abhayapuri",
    },
    {
      State: "Assam",
      District: "Bongaigaon",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Cachar",
      Court: "Industrial Tribunal",
    },
    {
      State: "Assam",
      District: "Cachar",
      Court: "Family Court",
    },
    {
      State: "Assam",
      District: "Cachar",
      Court: "SDJM Lakhipur",
    },
    {
      State: "Assam",
      District: "Cachar",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Charaideo",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Chirang",
      Court: "SDJM Court Complex, Bijni",
    },
    {
      State: "Assam",
      District: "Chirang",
      Court: "District Court Complex Chirang",
    },
    {
      State: "Assam",
      District: "Darrang",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Dhemaji",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Dhemaji",
      Court: "SDJM (M) Court Complex, Jonai",
    },
    {
      State: "Assam",
      District: "Dhubri",
      Court: "Family Court Complex",
    },
    {
      State: "Assam",
      District: "Dhubri",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Dhubri",
      Court: "SDJM (M) Court Complex, Bilasipara",
    },
    {
      State: "Assam",
      District: "Dibrugarh",
      Court: "Industrial Tribunal Dibrugarh",
    },
    {
      State: "Assam",
      District: "Dibrugarh",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Dibrugarh",
      Court: "Labour Court",
    },
    {
      State: "Assam",
      District: "Dibrugarh",
      Court: "SDJM Court Complex, Naharkatia",
    },
    {
      State: "Assam",
      District: "Dima Hasao",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Goalpara",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Golaghat",
      Court: "SDJM Court Complex , Bokakhat",
    },
    {
      State: "Assam",
      District: "Golaghat",
      Court: "SDJM Court Complex, Dhansiri, Sarupathar",
    },
    {
      State: "Assam",
      District: "Golaghat",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Hailakandi",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Hojai",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Jorhat",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Jorhat",
      Court: "SDJM Court Complex, Titabar",
    },
    {
      State: "Assam",
      District: "Kamrup",
      Court: "JMFC Court Complex Hajo",
    },
    {
      State: "Assam",
      District: "Kamrup",
      Court: "District Sessions Judge, Amingaon",
    },
    {
      State: "Assam",
      District: "Kamrup",
      Court: "SDJM Court Complex, Rangia",
    },
    {
      State: "Assam",
      District: "Kamrup Metro",
      Court: "MAC Tribunal",
    },
    {
      State: "Assam",
      District: "Kamrup Metro",
      Court: "Railway Magistrate Court Complex",
    },
    {
      State: "Assam",
      District: "Kamrup Metro",
      Court: "Family Court",
    },
    {
      State: "Assam",
      District: "Kamrup Metro",
      Court: "Chief Judicial Magistrate's Complex",
    },
    {
      State: "Assam",
      District: "Kamrup Metro",
      Court: "Industrial Tribunal",
    },
    {
      State: "Assam",
      District: "Kamrup Metro",
      Court: "District Judge's Court Complex",
    },
    {
      State: "Assam",
      District: "Kamrup Metro",
      Court: "Additional CBI Court, Chandmari",
    },
    {
      State: "Assam",
      District: "Kamrup Metro",
      Court: "Labour Court",
    },
    {
      State: "Assam",
      District: "Kamrup Metro",
      Court: "MACT No 2 and 3",
    },
    {
      State: "Assam",
      District: "Karbi Anglong",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Karbi Anglong",
      Court: "SDJM Court Complex, Bokajan",
    },
    {
      State: "Assam",
      District: "Karimganj",
      Court: "Chief Judicial Magistrate's Complex",
    },
    {
      State: "Assam",
      District: "Karimganj",
      Court: "District Judge's Court",
    },
    {
      State: "Assam",
      District: "Kokrajhar",
      Court: "SDJM,Gossaigaon",
    },
    {
      State: "Assam",
      District: "Kokrajhar",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Lakhimpur",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Lakhimpur",
      Court: "SDJM (M) Court Compex, Dhakuakhana",
    },
    {
      State: "Assam",
      District: "Majuli",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Morigaon",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Nagaon",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Nagaon",
      Court: "SDJM Court Complex Koliabor",
    },
    {
      State: "Assam",
      District: "Nalbari",
      Court: "Chief Judicial Magistrate's Complex",
    },
    {
      State: "Assam",
      District: "Nalbari",
      Court: "District Judge's Court Complex",
    },
    {
      State: "Assam",
      District: "Nalbari",
      Court: "JMFC Court Complex, Tihu",
    },
    {
      State: "Assam",
      District: "Nalbari",
      Court: "Family Court Complex",
    },
    {
      State: "Assam",
      District: "Sivasagar",
      Court: "District Judge Court Complex",
    },
    {
      State: "Assam",
      District: "Sivasagar",
      Court: "SDJM Nazira",
    },
    {
      State: "Assam",
      District: "Sivasagar",
      Court: "Chief Judicial Magistrate's Complex",
    },
    {
      State: "Assam",
      District: "Sonitpur",
      Court: "District Judge's Court, Sonitpur",
    },
    {
      State: "Assam",
      District: "South Salmara",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Tinsukia",
      Court: "District Court Complex",
    },
    {
      State: "Assam",
      District: "Tinsukia",
      Court: "SDJM (M) Court , Chapakhowa",
    },
    {
      State: "Assam",
      District: "Tinsukia",
      Court: "SDJM (M) Court Complex, Margherita",
    },
    {
      State: "Assam",
      District: "Udalguri",
      Court: "District Court Complex Udalguri",
    },
    {
      State: "Bihar",
      District: "Araria",
      Court: "Civil Court, Araria",
    },
    {
      State: "Bihar",
      District: "Aurangabad",
      Court: "Sub divisional judicial court, Daudnagar",
    },
    {
      State: "Bihar",
      District: "Aurangabad",
      Court: "Civil Court Complex, Aurangabad",
    },
    {
      State: "Bihar",
      District: "Banka",
      Court: "Civil Court Complex, Banka",
    },
    {
      State: "Bihar",
      District: "Begusarai",
      Court: "Subordinate Court Manjhaul",
    },
    {
      State: "Bihar",
      District: "Begusarai",
      Court: "Bakhri Court",
    },
    {
      State: "Bihar",
      District: "Begusarai",
      Court: "Balia Sub DIvision",
    },
    {
      State: "Bihar",
      District: "Begusarai",
      Court: "DJ Building",
    },
    {
      State: "Bihar",
      District: "Begusarai",
      Court: "Teghra Sub Division",
    },
    {
      State: "Bihar",
      District: "Bettiah",
      Court: "Civil Court, Bettiah",
    },
    {
      State: "Bihar",
      District: "Bettiah",
      Court: "Civil Court, Bagha",
    },
    {
      State: "Bihar",
      District: "Bettiah",
      Court: "Narkatiaganj Sub Division",
    },
    {
      State: "Bihar",
      District: "Bhagalpur",
      Court: "Bhagalpur Civil Court",
    },
    {
      State: "Bihar",
      District: "Bhagalpur",
      Court: "Khalgaon Civil Court",
    },
    {
      State: "Bihar",
      District: "Bhagalpur",
      Court: "Civil Court Complex, Naugachia",
    },
    {
      State: "Bihar",
      District: "Bhojpur",
      Court: "Piro Court",
    },
    {
      State: "Bihar",
      District: "Bhojpur",
      Court: "Bhojpur Civil Court",
    },
    {
      State: "Bihar",
      District: "Bhojpur",
      Court: "Jagdishpur Court",
    },
    {
      State: "Bihar",
      District: "Buxar",
      Court: "Civil court complex, Buxar",
    },
    {
      State: "Bihar",
      District: "Buxar",
      Court: "Dumraon Sub Division",
    },
    {
      State: "Bihar",
      District: "Darbhanga",
      Court: "Civil Court Darbhanga",
    },
    {
      State: "Bihar",
      District: "Darbhanga",
      Court: "Biraul Court",
    },
    {
      State: "Bihar",
      District: "Darbhanga",
      Court: "Civil Court, Benipur",
    },
    {
      State: "Bihar",
      District: "Gaya",
      Court: "Gaya Civil Court",
    },
    {
      State: "Bihar",
      District: "Gaya",
      Court: "Subordinate Court, Sherghati",
    },
    {
      State: "Bihar",
      District: "Gopalganj",
      Court: "Civil Court, Gopalganj",
    },
    {
      State: "Bihar",
      District: "Jamui",
      Court: "Civil Court Complex, Jamui",
    },
    {
      State: "Bihar",
      District: "Jehanabad",
      Court: "Jehanabad Civil Court",
    },
    {
      State: "Bihar",
      District: "Jehanabad",
      Court: "Arwal Sub Div Court",
    },
    {
      State: "Bihar",
      District: "Kaimur at Bhabhua",
      Court: "District Judge's Building",
    },
    {
      State: "Bihar",
      District: "Kaimur at Bhabhua",
      Court: "Mohaniya",
    },
    {
      State: "Bihar",
      District: "Katihar",
      Court: "Civil Court Complex, Katihar",
    },
    {
      State: "Bihar",
      District: "Katihar",
      Court: "Barsoi Sub Division",
    },
    {
      State: "Bihar",
      District: "Khagaria",
      Court: "Gogri Sub Division",
    },
    {
      State: "Bihar",
      District: "Khagaria",
      Court: "Civil Court, Khagaria",
    },
    {
      State: "Bihar",
      District: "Kishanganj",
      Court: "Civil Court, Kishanganj",
    },
    {
      State: "Bihar",
      District: "Lakhisarai",
      Court: "Civil Court Lakhisarai",
    },
    {
      State: "Bihar",
      District: "Madhepura",
      Court: "Civil Court, Madhepura",
    },
    {
      State: "Bihar",
      District: "Madhepura",
      Court: "Uda Kishunganj Court",
    },
    {
      State: "Bihar",
      District: "Madhubani",
      Court: "Civil Court Jhanjharpur",
    },
    {
      State: "Bihar",
      District: "Madhubani",
      Court: "Benipatti Court",
    },
    {
      State: "Bihar",
      District: "Madhubani",
      Court: "Civil Court Madhubani (Server Room 1)",
    },
    {
      State: "Bihar",
      District: "Motihari",
      Court: "Areraj Sub Division",
    },
    {
      State: "Bihar",
      District: "Motihari",
      Court: "Civil Court Complex, Motihari",
    },
    {
      State: "Bihar",
      District: "Motihari",
      Court: "Sikrahna Sub Division",
    },
    {
      State: "Bihar",
      District: "Munger",
      Court: "Civil Court Munger",
    },
    {
      State: "Bihar",
      District: "Muzaffarpur",
      Court: "Muzaffarpur Civil Court",
    },
    {
      State: "Bihar",
      District: "Muzaffarpur",
      Court: "West Civil Court",
    },
    {
      State: "Bihar",
      District: "Nalanda",
      Court: "Civil Court, Hilsa",
    },
    {
      State: "Bihar",
      District: "Nalanda",
      Court: "Nalanda Civil Court",
    },
    {
      State: "Bihar",
      District: "Nawada",
      Court: "Main Complex, Nawadah",
    },
    {
      State: "Bihar",
      District: "Patna",
      Court: "Civil Court, Patna City",
    },
    {
      State: "Bihar",
      District: "Patna",
      Court: "Barh Court",
    },
    {
      State: "Bihar",
      District: "Patna",
      Court: "Paliganj Sub Division",
    },
    {
      State: "Bihar",
      District: "Patna",
      Court: "Masaurhi Court",
    },
    {
      State: "Bihar",
      District: "Patna",
      Court: "Civil Court, Danapur",
    },
    {
      State: "Bihar",
      District: "Patna",
      Court: "Civil Court, Patna Sadar",
    },
    {
      State: "Bihar",
      District: "Purnea",
      Court: "Banmankhi Court",
    },
    {
      State: "Bihar",
      District: "Purnea",
      Court: "Dhamdaha Sub Division",
    },
    {
      State: "Bihar",
      District: "Purnea",
      Court: "Baisi Sub Division",
    },
    {
      State: "Bihar",
      District: "Purnea",
      Court: "Civil Court Purnea",
    },
    {
      State: "Bihar",
      District: "Rohtas Sasaram",
      Court: "Main Building, Rohtas",
    },
    {
      State: "Bihar",
      District: "Rohtas Sasaram",
      Court: "Dehri Court",
    },
    {
      State: "Bihar",
      District: "Rohtas Sasaram",
      Court: "Main Building, Bikramganj",
    },
    {
      State: "Bihar",
      District: "Saharsa",
      Court: "Civil Court, Saharsa",
    },
    {
      State: "Bihar",
      District: "Samastipur",
      Court: "Civil Court, Dalsingsarai",
    },
    {
      State: "Bihar",
      District: "Samastipur",
      Court: "Civil Court, Rosera",
    },
    {
      State: "Bihar",
      District: "Samastipur",
      Court: "Sahpur Patori Civil Court",
    },
    {
      State: "Bihar",
      District: "Samastipur",
      Court: "Main Building, Samastipur",
    },
    {
      State: "Bihar",
      District: "Saran at Chapra",
      Court: "Sonepur Civil Court",
    },
    {
      State: "Bihar",
      District: "Saran at Chapra",
      Court: "Main Building, Saran",
    },
    {
      State: "Bihar",
      District: "Sheikhpura",
      Court: "Sheikhpura Civil Court",
    },
    {
      State: "Bihar",
      District: "Sheohar",
      Court: "Sheohar Civil Court",
    },
    {
      State: "Bihar",
      District: "Sitamarhi",
      Court: "New building, Sitamarhi",
    },
    {
      State: "Bihar",
      District: "Sitamarhi",
      Court: "Pupri Sub Division",
    },
    {
      State: "Bihar",
      District: "Siwan",
      Court: "Civil Court, Siwan",
    },
    {
      State: "Bihar",
      District: "Supaul",
      Court: "Supaul Civil Court",
    },
    {
      State: "Bihar",
      District: "Supaul",
      Court: "Birpur Court",
    },
    {
      State: "Bihar",
      District: "Vaishali",
      Court: "Civil Court Vaishali",
    },
    {
      State: "Chandigarh",
      District: "Chandigarh",
      Court: "District Court Chandigarh",
    },
    {
      State: "Chhattisgarh",
      District: "Balod",
      Court: "Civil Court Gunderdehi",
    },
    {
      State: "Chhattisgarh",
      District: "Balod",
      Court: "Civil Court Dondilohara",
    },
    {
      State: "Chhattisgarh",
      District: "Balod",
      Court: "Civil Court Dallirajhara",
    },
    {
      State: "Chhattisgarh",
      District: "Balod",
      Court: "District and Sessions Court Balod",
    },
    {
      State: "Chhattisgarh",
      District: "Balodabazar",
      Court: "Civil Court Kasdol, Kasdol",
    },
    {
      State: "Chhattisgarh",
      District: "Balodabazar",
      Court: "District and Sessions C Balodabazar",
    },
    {
      State: "Chhattisgarh",
      District: "Balodabazar",
      Court: "Civil Court Simga, Simga",
    },
    {
      State: "Chhattisgarh",
      District: "Balodabazar",
      Court: "Civil Court Bhatapara, Bhatapara",
    },
    {
      State: "Chhattisgarh",
      District: "Balrampur Ramanujganj",
      Court: "Civil Court Wadrafnagar",
    },
    {
      State: "Chhattisgarh",
      District: "Balrampur Ramanujganj",
      Court: "Civil Court Balrampur",
    },
    {
      State: "Chhattisgarh",
      District: "Balrampur Ramanujganj",
      Court: "Civil Court Rajpur",
    },
    {
      State: "Chhattisgarh",
      District: "Balrampur Ramanujganj",
      Court: "District Court Balrampur",
    },
    {
      State: "Chhattisgarh",
      District: "Bastar",
      Court: "District And Sessions Court Bastar",
    },
    {
      State: "Chhattisgarh",
      District: "Bemetara",
      Court: "District Sessions Court Bemetara",
    },
    {
      State: "Chhattisgarh",
      District: "Bemetara",
      Court: "Civil Court Saja, Saja",
    },
    {
      State: "Chhattisgarh",
      District: "Bilaspur",
      Court: "Civil Court Takhatpur",
    },
    {
      State: "Chhattisgarh",
      District: "Bilaspur",
      Court: "Civil Court Marwahi, Marwahi",
    },
    {
      State: "Chhattisgarh",
      District: "Bilaspur",
      Court: "Civil Court Bilha",
    },
    {
      State: "Chhattisgarh",
      District: "Bilaspur",
      Court: "Civil Court Pendraroad",
    },
    {
      State: "Chhattisgarh",
      District: "Bilaspur",
      Court: "Civil Court Kota",
    },
    {
      State: "Chhattisgarh",
      District: "Bilaspur",
      Court: "Dist and Sessions Court Bilaspur",
    },
    {
      State: "Chhattisgarh",
      District: "Bilaspur",
      Court: "Family Court Bilaspur",
    },
    {
      State: "Chhattisgarh",
      District: "Dantewada",
      Court: "Civil Court Sukma",
    },
    {
      State: "Chhattisgarh",
      District: "Dantewada",
      Court: "Civil Court Bijapur",
    },
    {
      State: "Chhattisgarh",
      District: "Dantewada",
      Court: "Civil Court Konta",
    },
    {
      State: "Chhattisgarh",
      District: "Dantewada",
      Court: "District and Sessio Court Dantewada",
    },
    {
      State: "Chhattisgarh",
      District: "Dantewada",
      Court: "Civil Court Bacheli",
    },
    {
      State: "Chhattisgarh",
      District: "Dhamtari",
      Court: "Civil Court Nagri",
    },
    {
      State: "Chhattisgarh",
      District: "Dhamtari",
      Court: "DJ ADJ Court Dhamtari",
    },
    {
      State: "Chhattisgarh",
      District: "Dhamtari",
      Court: "Civil Court Kurud",
    },
    {
      State: "Chhattisgarh",
      District: "Durg",
      Court: "Bhilai",
    },
    {
      State: "Chhattisgarh",
      District: "Durg",
      Court: "District and Sessions Court Durg",
    },
    {
      State: "Chhattisgarh",
      District: "Durg",
      Court: "Civil Court Patan,Patan-T",
    },
    {
      State: "Chhattisgarh",
      District: "Janjgir",
      Court: "Civil Court Jaijaipur,Jaijaipur-T",
    },
    {
      State: "Chhattisgarh",
      District: "Janjgir",
      Court: "Civil Court Akaltara, Akaltara",
    },
    {
      State: "Chhattisgarh",
      District: "Janjgir",
      Court: "Civil Court, Sakti",
    },
    {
      State: "Chhattisgarh",
      District: "Janjgir",
      Court: "Civil Court Pamgarh,Pamgarh-T",
    },
    {
      State: "Chhattisgarh",
      District: "Janjgir",
      Court: "District and Sessions Court Janjgir",
    },
    {
      State: "Chhattisgarh",
      District: "Janjgir",
      Court: "Civil Court Champa",
    },
    {
      State: "Chhattisgarh",
      District: "Janjgir",
      Court: "Civil Court Nawagarh",
    },
    {
      State: "Chhattisgarh",
      District: "Janjgir",
      Court: "Civil Court Malkharouda",
    },
    {
      State: "Chhattisgarh",
      District: "Janjgir",
      Court: "Civil Court Dabhara, Dabhara",
    },
    {
      State: "Chhattisgarh",
      District: "Jashpur",
      Court: "Civil Court Kunkuri",
    },
    {
      State: "Chhattisgarh",
      District: "Jashpur",
      Court: "Civil Court Bagicha",
    },
    {
      State: "Chhattisgarh",
      District: "Jashpur",
      Court: "Civil Court Patthalgaon",
    },
    {
      State: "Chhattisgarh",
      District: "Jashpur",
      Court: "Distt Session Court Jashpur",
    },
    {
      State: "Chhattisgarh",
      District: "Kanker",
      Court: "Civil Court Pankhajur",
    },
    {
      State: "Chhattisgarh",
      District: "Kanker",
      Court: "District and Sessions Court Kanker",
    },
    {
      State: "Chhattisgarh",
      District: "Kanker",
      Court: "Civil Court Bhanupratappur",
    },
    {
      State: "Chhattisgarh",
      District: "Kawardha",
      Court: "District and Sessions CourtKawardha",
    },
    {
      State: "Chhattisgarh",
      District: "Kawardha",
      Court: "Civil Court Pandariya",
    },
    {
      State: "Chhattisgarh",
      District: "Kondagaon",
      Court: "Civil Court Narayanpur",
    },
    {
      State: "Chhattisgarh",
      District: "Kondagaon",
      Court: "Civil Court Keshkal",
    },
    {
      State: "Chhattisgarh",
      District: "Kondagaon",
      Court: "District and Sessi Court Kondagaon",
    },
    {
      State: "Chhattisgarh",
      District: "Korba",
      Court: "Civil Court Pali, Pali",
    },
    {
      State: "Chhattisgarh",
      District: "Korba",
      Court: "Civil Court Katghora Katghora",
    },
    {
      State: "Chhattisgarh",
      District: "Korba",
      Court: "Distt Session Court Korba",
    },
    {
      State: "Chhattisgarh",
      District: "Korba",
      Court: "Civil Court Kartala,Kartala-T",
    },
    {
      State: "Chhattisgarh",
      District: "Koriya",
      Court: "Civil Court Chirmiri, Chirmiri-T",
    },
    {
      State: "Chhattisgarh",
      District: "Koriya",
      Court: "Civil Court Janakpur",
    },
    {
      State: "Chhattisgarh",
      District: "Koriya",
      Court: "Distt Session Court Koriya",
    },
    {
      State: "Chhattisgarh",
      District: "Koriya",
      Court: "Civil Court Manendragarh",
    },
    {
      State: "Chhattisgarh",
      District: "Mahasamund",
      Court: "Civil Court Basna",
    },
    {
      State: "Chhattisgarh",
      District: "Mahasamund",
      Court: "Civil Court Pithoura",
    },
    {
      State: "Chhattisgarh",
      District: "Mahasamund",
      Court: "Civil Court Saraipali",
    },
    {
      State: "Chhattisgarh",
      District: "Mahasamund",
      Court: "DJ ADJ Court Mahasamund",
    },
    {
      State: "Chhattisgarh",
      District: "Mungeli",
      Court: "District and Sessions Court Mungeli",
    },
    {
      State: "Chhattisgarh",
      District: "Mungeli",
      Court: "Civil Court Lormi",
    },
    {
      State: "Chhattisgarh",
      District: "Raigarh",
      Court: "Civil Court Gharghora",
    },
    {
      State: "Chhattisgarh",
      District: "Raigarh",
      Court: "Civil Court Dharamjaigarh",
    },
    {
      State: "Chhattisgarh",
      District: "Raigarh",
      Court: "Civil Court Kharsiya",
    },
    {
      State: "Chhattisgarh",
      District: "Raigarh",
      Court: "Civil Court Saranggarh,Saranggarh-T",
    },
    {
      State: "Chhattisgarh",
      District: "Raigarh",
      Court: "Distt Session Court Raigarh",
    },
    {
      State: "Chhattisgarh",
      District: "Raigarh",
      Court: "Civil Court Bhatgaon, Bhatgaon",
    },
    {
      State: "Chhattisgarh",
      District: "Raigarh",
      Court: "Civil Court Bilaigarh, Bilaigarh",
    },
    {
      State: "Chhattisgarh",
      District: "Raipur",
      Court: "Civil Court Devbhog",
    },
    {
      State: "Chhattisgarh",
      District: "Raipur",
      Court: "Civil Court Tilda",
    },
    {
      State: "Chhattisgarh",
      District: "Raipur",
      Court: "Civil Court Rajim",
    },
    {
      State: "Chhattisgarh",
      District: "Raipur",
      Court: "Comm Court Dist Level Naya Raipur",
    },
    {
      State: "Chhattisgarh",
      District: "Raipur",
      Court: "Civil Court Gariyaband",
    },
    {
      State: "Chhattisgarh",
      District: "Raipur",
      Court: "Distt Session Court Raipur",
    },
    {
      State: "Chhattisgarh",
      District: "Rajnandgaon",
      Court: "Civil Court Chhuikhadan",
    },
    {
      State: "Chhattisgarh",
      District: "Rajnandgaon",
      Court: "Civil Court Dongargarh",
    },
    {
      State: "Chhattisgarh",
      District: "Rajnandgaon",
      Court: "Distt Session Court Rajnandgaon",
    },
    {
      State: "Chhattisgarh",
      District: "Rajnandgaon",
      Court: "Civil Court Khairagarh,Khairagarh-T",
    },
    {
      State: "Chhattisgarh",
      District: "Rajnandgaon",
      Court: "Civil Court Ambagarh Chowki",
    },
    {
      State: "Chhattisgarh",
      District: "Surajpur",
      Court: "District and Sessions CourtSurajpur",
    },
    {
      State: "Chhattisgarh",
      District: "Surajpur",
      Court: "Civil Court Pratappur, Pratappur",
    },
    {
      State: "Chhattisgarh",
      District: "Surguja at Ambikapur",
      Court: "Civil Court Sitapur",
    },
    {
      State: "Chhattisgarh",
      District: "Surguja at Ambikapur",
      Court: "District and Sessions Court Sarguja",
    },
    {
      State: "Delhi",
      District: "Central",
      Court: "Rouse Avenue Court Complex",
    },
    {
      State: "Delhi",
      District: "Central",
      Court: "Tis Hazari Court Complex",
    },
    {
      State: "Delhi",
      District: "East",
      Court: "Karkardooma Court Complex",
    },
    {
      State: "Delhi",
      District: "New Delhi",
      Court: "Patiala House Court Complex",
    },
    {
      State: "Delhi",
      District: "North",
      Court: "Rohini Court Complex",
    },
    {
      State: "Delhi",
      District: "North East",
      Court: "Karkardooma Court Complex",
    },
    {
      State: "Delhi",
      District: "North West",
      Court: "Rohini Court Complex",
    },
    {
      State: "Delhi",
      District: "Shahdara",
      Court: "Karkardooma Court Complex",
    },
    {
      State: "Delhi",
      District: "South",
      Court: "Saket Court Complex",
    },
    {
      State: "Delhi",
      District: "South East",
      Court: "Saket Court Complex",
    },
    {
      State: "Delhi",
      District: "South West",
      Court: "Dwarka Court Complex",
    },
    {
      State: "Delhi",
      District: "West",
      Court: "Tis Hazari Court Complex",
    },
    {
      State: "Diu and Daman",
      District: "Daman",
      Court: "Court Complex, Daman",
    },
    {
      State: "Diu and Daman",
      District: "Diu",
      Court: "Court Complex, Diu",
    },
    {
      State: "DNH at Silvasa",
      District: "Silvassa",
      Court: "Silvassa, District and Sessions Ct",
    },
    {
      State: "Goa",
      District: "North Goa",
      Court: "Sattari at Valpoi Civil and Crim Ct",
    },
    {
      State: "Goa",
      District: "North Goa",
      Court: "Ponda, Civil and Criminal Court",
    },
    {
      State: "Goa",
      District: "North Goa",
      Court: "Mapusa, Dist and Session Court II",
    },
    {
      State: "Goa",
      District: "North Goa",
      Court: "Tiswadi, Civil and Criminal Court",
    },
    {
      State: "Goa",
      District: "North Goa",
      Court: "Mapusa,District and Session Court I",
    },
    {
      State: "Goa",
      District: "North Goa",
      Court: "Panaji, Goa, Civil and Criminal Ct",
    },
    {
      State: "Goa",
      District: "North Goa",
      Court: "Pernem,Civil and Criminal Court",
    },
    {
      State: "Goa",
      District: "North Goa",
      Court: "Mapusa, Fast Track Court",
    },
    {
      State: "Goa",
      District: "North Goa",
      Court: "Bicholim, Civil and Criminal Court",
    },
    {
      State: "Goa",
      District: "South Goa",
      Court: "Canacona, Civil and Criminal Court",
    },
    {
      State: "Goa",
      District: "South Goa",
      Court: "Margao, Civil and Criminal Court",
    },
    {
      State: "Goa",
      District: "South Goa",
      Court: "Vasco da Gama,Civil and Criminal Ct",
    },
    {
      State: "Goa",
      District: "South Goa",
      Court: "Sanguem, Civil and Criminal Court",
    },
    {
      State: "Goa",
      District: "South Goa",
      Court: "Margao, District and Sessions Ct",
    },
    {
      State: "Goa",
      District: "South Goa",
      Court: "Quepem, Civil and Criminal Court",
    },
    {
      State: "Gujarat",
      District: "Ahmedabad",
      Court: "Multistoreyed Building, Laldarwaja",
    },
    {
      State: "Gujarat",
      District: "Ahmedabad",
      Court: "Taluka Court , Mandal",
    },
    {
      State: "Gujarat",
      District: "Ahmedabad",
      Court: "Mirzapur, Ahmedabad",
    },
    {
      State: "Gujarat",
      District: "Ahmedabad",
      Court: "CMM COURT COMPLEX",
    },
    {
      State: "Gujarat",
      District: "Ahmedabad",
      Court: "Dhandhuka",
    },
    {
      State: "Gujarat",
      District: "Ahmedabad",
      Court: "DHOLKA,AHMEDABAD.",
    },
    {
      State: "Gujarat",
      District: "Ahmedabad",
      Court: "FTC, VIRAMGAM, AHMEDABAD.",
    },
    {
      State: "Gujarat",
      District: "Ahmedabad",
      Court: "SANAND,AHMEDABAD.",
    },
    {
      State: "Gujarat",
      District: "Ahmedabad",
      Court: "VIRAMGAM,AHMEDABAD.",
    },
    {
      State: "Gujarat",
      District: "Ahmedabad",
      Court: "BAVLA, AHMEDABAD",
    },
    {
      State: "Gujarat",
      District: "Ahmedabad",
      Court: "Bhadra, Laldarwaja",
    },
    {
      State: "Gujarat",
      District: "Ahmedabad",
      Court: "Railway Court, Ahmedabad",
    },
    {
      State: "Gujarat",
      District: "Ahmedabad",
      Court: "Taluka Court Dholera",
    },
    {
      State: "Gujarat",
      District: "Ahmedabad",
      Court: "Taluka Court, Detroj",
    },
    {
      State: "Gujarat",
      District: "Ahmedabad",
      Court: "Addl. Court,Dholka",
    },
    {
      State: "Gujarat",
      District: "Amreli",
      Court: "Khambha, Amreli",
    },
    {
      State: "Gujarat",
      District: "Amreli",
      Court: "JAFRABAD, AMRELI",
    },
    {
      State: "Gujarat",
      District: "Amreli",
      Court: "Amreli, FTC",
    },
    {
      State: "Gujarat",
      District: "Amreli",
      Court: "ADJ COURT, DHARI",
    },
    {
      State: "Gujarat",
      District: "Amreli",
      Court: "KUNKAVAV VADIA, AMRELI",
    },
    {
      State: "Gujarat",
      District: "Amreli",
      Court: "Babara, Amreli",
    },
    {
      State: "Gujarat",
      District: "Amreli",
      Court: "BAGASARA,AMRELI.",
    },
    {
      State: "Gujarat",
      District: "Amreli",
      Court: "DHARI, AMRELI",
    },
    {
      State: "Gujarat",
      District: "Amreli",
      Court: "Labour Court, Amreli",
    },
    {
      State: "Gujarat",
      District: "Amreli",
      Court: "LATHI,AMRELI.",
    },
    {
      State: "Gujarat",
      District: "Amreli",
      Court: "RAJULA,AMRELI.",
    },
    {
      State: "Gujarat",
      District: "Amreli",
      Court: "District and Civil Court Complex",
    },
    {
      State: "Gujarat",
      District: "Amreli",
      Court: "LILIA, AMRELI",
    },
    {
      State: "Gujarat",
      District: "Amreli",
      Court: "SAVARKUNDLA,AMRELI.",
    },
    {
      State: "Gujarat",
      District: "Anand",
      Court: "FAMILY COURT, ANAND",
    },
    {
      State: "Gujarat",
      District: "Anand",
      Court: "PETLAD,ANAND.",
    },
    {
      State: "Gujarat",
      District: "Anand",
      Court: "DISTRICT COURT, ANAND",
    },
    {
      State: "Gujarat",
      District: "Anand",
      Court: "CIVIL COURT, ANAND.",
    },
    {
      State: "Gujarat",
      District: "Anand",
      Court: "BORSAD,ANAND.",
    },
    {
      State: "Gujarat",
      District: "Anand",
      Court: "KHAMBHAT,ANAND.",
    },
    {
      State: "Gujarat",
      District: "Anand",
      Court: "TARAPUR, ANAND",
    },
    {
      State: "Gujarat",
      District: "Anand",
      Court: "UMRETH,ANAND.",
    },
    {
      State: "Gujarat",
      District: "Anand",
      Court: "ANKLAV, ANAND",
    },
    {
      State: "Gujarat",
      District: "Anand",
      Court: "SOJITTRA, ANAND",
    },
    {
      State: "Gujarat",
      District: "Anand",
      Court: "LABOUR COURT, ANAND",
    },
    {
      State: "Gujarat",
      District: "Aravalli at Modasa",
      Court: "CIVIL COURT, MODASA",
    },
    {
      State: "Gujarat",
      District: "Aravalli at Modasa",
      Court: "MEGHRAJ, MODASA",
    },
    {
      State: "Gujarat",
      District: "Aravalli at Modasa",
      Court: "BHILODA, MODASA",
    },
    {
      State: "Gujarat",
      District: "Aravalli at Modasa",
      Court: "BAYAD, MODASA",
    },
    {
      State: "Gujarat",
      District: "Aravalli at Modasa",
      Court: "FAMILY COURT, MODASA",
    },
    {
      State: "Gujarat",
      District: "Aravalli at Modasa",
      Court: "MALPUR, MODASA",
    },
    {
      State: "Gujarat",
      District: "Aravalli at Modasa",
      Court: "DISTRICT COURT, MODASA",
    },
    {
      State: "Gujarat",
      District: "Aravalli at Modasa",
      Court: "DHANSURA, MODASA",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "DISTRICT COURT COMPLEX, PALANPUR",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "Taluka Court, Lakhni",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "Palanpur, FTC",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "VADGAM, BANASKANTHA",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "LABOUR COURT, PALANPUR",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "THARAD, BANASKANTHA.",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "BHABHAR, BANASKANTHA",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "District Court No II, Palanpur",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "DANTIWADA, BANASKANTHA",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "DANTA, BANASKANTHA.",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "ADDITIONAL DISTRICT COURT, THARAD",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "SHIHORI, BANASKANTHA.",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "WAV, BANASKANTHA.",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "DHANERA, BANASKANTHA.",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "DEODAR, BANASKANTHA.",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "DEESA, BANASKANTHA",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "Amirgadh, Banaskantha",
    },
    {
      State: "Gujarat",
      District: "Banaskanth at Palanpur",
      Court: "Suigam Civil Court",
    },
    {
      State: "Gujarat",
      District: "Bharuch",
      Court: "TALUKA COURT, NETRANG",
    },
    {
      State: "Gujarat",
      District: "Bharuch",
      Court: "JHAGADIA,BHARUCH.",
    },
    {
      State: "Gujarat",
      District: "Bharuch",
      Court: "ANKLESHWAR,BHARUCH",
    },
    {
      State: "Gujarat",
      District: "Bharuch",
      Court: "FAMILY COURT, Bharuch",
    },
    {
      State: "Gujarat",
      District: "Bharuch",
      Court: "JAMBUSAR,BHARUCH.",
    },
    {
      State: "Gujarat",
      District: "Bharuch",
      Court: "WAGRA,BHARUCH.",
    },
    {
      State: "Gujarat",
      District: "Bharuch",
      Court: "District and Civil Court Complex",
    },
    {
      State: "Gujarat",
      District: "Bharuch",
      Court: "HANSOT,BHARUCH",
    },
    {
      State: "Gujarat",
      District: "Bharuch",
      Court: "VALIA,BHARUCH.",
    },
    {
      State: "Gujarat",
      District: "Bharuch",
      Court: "INDUSTRIAL COURT, BHARUCH",
    },
    {
      State: "Gujarat",
      District: "Bharuch",
      Court: "AMOD,BHARUCH",
    },
    {
      State: "Gujarat",
      District: "Bhavnagar",
      Court: "GARIADHAR,BHAVNAGAR.",
    },
    {
      State: "Gujarat",
      District: "Bhavnagar",
      Court: "FAST TRACK COURT, MAHUVA.",
    },
    {
      State: "Gujarat",
      District: "Bhavnagar",
      Court: "VALLABHIPUR,BHAVNAGAR.",
    },
    {
      State: "Gujarat",
      District: "Bhavnagar",
      Court: "UMRALA,BHAVNAGAR.",
    },
    {
      State: "Gujarat",
      District: "Bhavnagar",
      Court: "SIHOR,BHAVNAGAR.",
    },
    {
      State: "Gujarat",
      District: "Bhavnagar",
      Court: "PALITANA,BHAVNAGAR.",
    },
    {
      State: "Gujarat",
      District: "Bhavnagar",
      Court: "FAMILY COURT, BHAVNAGAR",
    },
    {
      State: "Gujarat",
      District: "Bhavnagar",
      Court: "MAHUVA,BHAVNAGAR.",
    },
    {
      State: "Gujarat",
      District: "Bhavnagar",
      Court: "GOGHA,BHAVNAGAR.",
    },
    {
      State: "Gujarat",
      District: "Bhavnagar",
      Court: "Industrial Court, Bhavnagar",
    },
    {
      State: "Gujarat",
      District: "Bhavnagar",
      Court: "TALAJA,BHAVNAGAR.",
    },
    {
      State: "Gujarat",
      District: "Bhavnagar",
      Court: "Railway Court, Bhavnagar",
    },
    {
      State: "Gujarat",
      District: "Bhavnagar",
      Court: "BHAVNAGAR FTC",
    },
    {
      State: "Gujarat",
      District: "Bhavnagar",
      Court: "TALUKA COURT, JESAR",
    },
    {
      State: "Gujarat",
      District: "Bhavnagar",
      Court: "District Court Building, Bhavnagar",
    },
    {
      State: "Gujarat",
      District: "BOTAD",
      Court: "CIVIL COURT, BOTAD",
    },
    {
      State: "Gujarat",
      District: "BOTAD",
      Court: "TALUKA COURT, BARWALA",
    },
    {
      State: "Gujarat",
      District: "BOTAD",
      Court: "DISTRICT COURT, BOTAD",
    },
    {
      State: "Gujarat",
      District: "BOTAD",
      Court: "TALUKA COURT, GADHADA",
    },
    {
      State: "Gujarat",
      District: "BOTAD",
      Court: "TALUKA COURT, RANPUR",
    },
    {
      State: "Gujarat",
      District: "Chhota Udepur",
      Court: "District Court, Chhota Udepur",
    },
    {
      State: "Gujarat",
      District: "Chhota Udepur",
      Court: "Taluka Court, Kawant",
    },
    {
      State: "Gujarat",
      District: "Chhota Udepur",
      Court: "Taluka Court, Naswadi",
    },
    {
      State: "Gujarat",
      District: "Chhota Udepur",
      Court: "Taluka Court, JetpurPavi",
    },
    {
      State: "Gujarat",
      District: "Chhota Udepur",
      Court: "TALUKA SEVASADAN BODELI",
    },
    {
      State: "Gujarat",
      District: "Chhota Udepur",
      Court: "Civil Court, Chhota Udepur",
    },
    {
      State: "Gujarat",
      District: "Chhota Udepur",
      Court: "Taluka Court, Sankheda",
    },
    {
      State: "Gujarat",
      District: "Dahod",
      Court: "LABOUR COURT, DAHOD",
    },
    {
      State: "Gujarat",
      District: "Dahod",
      Court: "DISTRICT AND CIVIL COURT COMPLEX",
    },
    {
      State: "Gujarat",
      District: "Dahod",
      Court: "DEVGADH BARIA, DAHOD",
    },
    {
      State: "Gujarat",
      District: "Dahod",
      Court: "Dahod FTC (Family Court)",
    },
    {
      State: "Gujarat",
      District: "Dahod",
      Court: "SANJELI, DAHOD",
    },
    {
      State: "Gujarat",
      District: "Dahod",
      Court: "GARBADA, DAHOD",
    },
    {
      State: "Gujarat",
      District: "Dahod",
      Court: "FATEPURA, DAHOD",
    },
    {
      State: "Gujarat",
      District: "Dahod",
      Court: "JHALOD, DAHOD",
    },
    {
      State: "Gujarat",
      District: "Dahod",
      Court: "DHANPUR, DAHOD",
    },
    {
      State: "Gujarat",
      District: "Dahod",
      Court: "LIMKHEDA, DAHOD",
    },
    {
      State: "Gujarat",
      District: "Devbhumi Dwarka at Khambhaliya",
      Court: "TALUKA COURT, KALYANPUR",
    },
    {
      State: "Gujarat",
      District: "Devbhumi Dwarka at Khambhaliya",
      Court: "TALUKA COURT, BHANVAD",
    },
    {
      State: "Gujarat",
      District: "Devbhumi Dwarka at Khambhaliya",
      Court: "DISTRICT COURT, KHAMBHALIA",
    },
    {
      State: "Gujarat",
      District: "Devbhumi Dwarka at Khambhaliya",
      Court: "TALUKA COURT, OKHAMANDAL",
    },
    {
      State: "Gujarat",
      District: "Devbhumi Dwarka at Khambhaliya",
      Court: "CIVIL COURT, KHAMBHALIA",
    },
    {
      State: "Gujarat",
      District: "Devbhumi Dwarka at Khambhaliya",
      Court: "SESSIONS AND CIVIL COURT , DWARKA",
    },
    {
      State: "Gujarat",
      District: "GANDHINAGAR",
      Court: "District and Civil Court Complex",
    },
    {
      State: "Gujarat",
      District: "GANDHINAGAR",
      Court: "MANSA,GANDHINAGAR.",
    },
    {
      State: "Gujarat",
      District: "GANDHINAGAR",
      Court: "FAMILY COURT, GANDHINAGAR",
    },
    {
      State: "Gujarat",
      District: "GANDHINAGAR",
      Court: "DEHGAM,GANDHINAGAR.",
    },
    {
      State: "Gujarat",
      District: "GANDHINAGAR",
      Court: "Kalol,gandhinagar",
    },
    {
      State: "Gujarat",
      District: "Gir Somnath at Veraval",
      Court: "KODINAR, VERAVAL",
    },
    {
      State: "Gujarat",
      District: "Gir Somnath at Veraval",
      Court: "DISTRICT COURT, VERAVAL",
    },
    {
      State: "Gujarat",
      District: "Gir Somnath at Veraval",
      Court: "FTC, UNA, VERAVAL",
    },
    {
      State: "Gujarat",
      District: "Gir Somnath at Veraval",
      Court: "SUTRAPADA, VERAVAL",
    },
    {
      State: "Gujarat",
      District: "Gir Somnath at Veraval",
      Court: "UNA, VERAVAL",
    },
    {
      State: "Gujarat",
      District: "Gir Somnath at Veraval",
      Court: "CIVIL COURT, VERAVAL",
    },
    {
      State: "Gujarat",
      District: "Gir Somnath at Veraval",
      Court: "TALUKA COURT, GIRGADHADA",
    },
    {
      State: "Gujarat",
      District: "Gir Somnath at Veraval",
      Court: "TALALA, VERAVAL",
    },
    {
      State: "Gujarat",
      District: "Jamnagar",
      Court: "LALPUR,JAMANGAR",
    },
    {
      State: "Gujarat",
      District: "Jamnagar",
      Court: "District and Civil Court Complex",
    },
    {
      State: "Gujarat",
      District: "Jamnagar",
      Court: "FAMILY COURT, JAMNAGAR",
    },
    {
      State: "Gujarat",
      District: "Jamnagar",
      Court: "SEVASADAN-4, JAMNAGAR",
    },
    {
      State: "Gujarat",
      District: "Jamnagar",
      Court: "CIVIL COURT COMPLEX, JAMNAGAR.",
    },
    {
      State: "Gujarat",
      District: "Jamnagar",
      Court: "DHROL,JAMANGAR",
    },
    {
      State: "Gujarat",
      District: "Jamnagar",
      Court: "JODIYA,JAMNAGAR.",
    },
    {
      State: "Gujarat",
      District: "Jamnagar",
      Court: "KALAVAD, JAMNAGAR",
    },
    {
      State: "Gujarat",
      District: "Jamnagar",
      Court: "JAMJODHPUR,JAMNAGAR.",
    },
    {
      State: "Gujarat",
      District: "Junagadh",
      Court: "MENDARDA, JUNAGADH",
    },
    {
      State: "Gujarat",
      District: "Junagadh",
      Court: "VANTHALI, JUNAGADH",
    },
    {
      State: "Gujarat",
      District: "Junagadh",
      Court: "MANGROL,JUNAGADH.",
    },
    {
      State: "Gujarat",
      District: "Junagadh",
      Court: "MANAVADAR,JUNAGADH.",
    },
    {
      State: "Gujarat",
      District: "Junagadh",
      Court: "VISVADAR,JUNAGADH.",
    },
    {
      State: "Gujarat",
      District: "Junagadh",
      Court: "MALIA-HATINA,JUNAGADH.",
    },
    {
      State: "Gujarat",
      District: "Junagadh",
      Court: "FTC, JUNAGADH.",
    },
    {
      State: "Gujarat",
      District: "Junagadh",
      Court: "BHESAN,JUNAGADH",
    },
    {
      State: "Gujarat",
      District: "Junagadh",
      Court: "KESHOD,JUNAGADH.",
    },
    {
      State: "Gujarat",
      District: "Junagadh",
      Court: "Additional District Court, Keshod",
    },
    {
      State: "Gujarat",
      District: "Junagadh",
      Court: "District and Civil Court Complex",
    },
    {
      State: "Gujarat",
      District: "Junagadh",
      Court: "LABOUR COURT, JUNAGADH",
    },
    {
      State: "Gujarat",
      District: "kachchh at Bhuj",
      Court: "LAKHPAT, KUCHCHH",
    },
    {
      State: "Gujarat",
      District: "kachchh at Bhuj",
      Court: "Family Court, Bhuj",
    },
    {
      State: "Gujarat",
      District: "kachchh at Bhuj",
      Court: "CIVIL COURT, BHUJ.",
    },
    {
      State: "Gujarat",
      District: "kachchh at Bhuj",
      Court: "GANDHIDHAM,KACHCHH.",
    },
    {
      State: "Gujarat",
      District: "kachchh at Bhuj",
      Court: "District Court Complex, Bhuj",
    },
    {
      State: "Gujarat",
      District: "kachchh at Bhuj",
      Court: "LABOUR COURT, BHUJ",
    },
    {
      State: "Gujarat",
      District: "kachchh at Bhuj",
      Court: "NAKHTRANA,KACHCHH.",
    },
    {
      State: "Gujarat",
      District: "kachchh at Bhuj",
      Court: "ANJAR, KUCHCHH",
    },
    {
      State: "Gujarat",
      District: "kachchh at Bhuj",
      Court: "RAHPAR,KACHCHH.",
    },
    {
      State: "Gujarat",
      District: "kachchh at Bhuj",
      Court: "NALIYA,KACHCHH.",
    },
    {
      State: "Gujarat",
      District: "kachchh at Bhuj",
      Court: "CIVIL COURT, GANDHIDHAM, KACHCHH",
    },
    {
      State: "Gujarat",
      District: "kachchh at Bhuj",
      Court: "BHACHAU,KACHCHH.",
    },
    {
      State: "Gujarat",
      District: "kachchh at Bhuj",
      Court: "MANDAVI,KACHCHH.",
    },
    {
      State: "Gujarat",
      District: "kachchh at Bhuj",
      Court: "KHAVDA, KUCHCHH",
    },
    {
      State: "Gujarat",
      District: "kachchh at Bhuj",
      Court: "MUNDRA,KACHCHH.",
    },
    {
      State: "Gujarat",
      District: "Kheda at Nadiad",
      Court: "Family Court, Nadiad",
    },
    {
      State: "Gujarat",
      District: "Kheda at Nadiad",
      Court: "CIVIL COURT, THASARA, KHEDA.",
    },
    {
      State: "Gujarat",
      District: "Kheda at Nadiad",
      Court: "DISTRICT COURT COMPLEX, NADIAD",
    },
    {
      State: "Gujarat",
      District: "Kheda at Nadiad",
      Court: "KATHLAL, KHEDA",
    },
    {
      State: "Gujarat",
      District: "Kheda at Nadiad",
      Court: "CIVIL COURT COMPLEX, NADIAD",
    },
    {
      State: "Gujarat",
      District: "Kheda at Nadiad",
      Court: "CIVIL COURT, MAHEMDABAD, KHEDA.",
    },
    {
      State: "Gujarat",
      District: "Kheda at Nadiad",
      Court: "MATAR,KHEDA.",
    },
    {
      State: "Gujarat",
      District: "Kheda at Nadiad",
      Court: "DAKOR,KHEDA.",
    },
    {
      State: "Gujarat",
      District: "Kheda at Nadiad",
      Court: "TALUKA COURT, VASO",
    },
    {
      State: "Gujarat",
      District: "Kheda at Nadiad",
      Court: "INDUSTRIAL COURT, KHEDA",
    },
    {
      State: "Gujarat",
      District: "Kheda at Nadiad",
      Court: "MAUDHA, KHEDA",
    },
    {
      State: "Gujarat",
      District: "Kheda at Nadiad",
      Court: "KHEDA.",
    },
    {
      State: "Gujarat",
      District: "Kheda at Nadiad",
      Court: "KAPADWANJ,KHEDA.",
    },
    {
      State: "Gujarat",
      District: "Kheda at Nadiad",
      Court: "GALTESHWAR, KHEDA",
    },
    {
      State: "Gujarat",
      District: "Mahesana",
      Court: "SATLASANA, MAHESANA",
    },
    {
      State: "Gujarat",
      District: "Mahesana",
      Court: "Family Court, Mahesana",
    },
    {
      State: "Gujarat",
      District: "Mahesana",
      Court: "District and Civil Court Complex",
    },
    {
      State: "Gujarat",
      District: "Mahesana",
      Court: "KHERALU,MAHESANA.",
    },
    {
      State: "Gujarat",
      District: "Mahesana",
      Court: "LABOUR COURT, MAHESANA",
    },
    {
      State: "Gujarat",
      District: "Mahesana",
      Court: "VIJAPUR,MAHESANA.",
    },
    {
      State: "Gujarat",
      District: "Mahesana",
      Court: "BECHARAJI, MAHESANA.",
    },
    {
      State: "Gujarat",
      District: "Mahesana",
      Court: "KADI, MAHESANA",
    },
    {
      State: "Gujarat",
      District: "Mahesana",
      Court: "UNJHA,MAHESANA.",
    },
    {
      State: "Gujarat",
      District: "Mahesana",
      Court: "VADNAGAR,MAHESANA.",
    },
    {
      State: "Gujarat",
      District: "Mahesana",
      Court: "CIVIL COURT, VISNAGAR",
    },
    {
      State: "Gujarat",
      District: "Mahesana",
      Court: "ADDL. DISTRICT COURT, VISNAGAR",
    },
    {
      State: "Gujarat",
      District: "Mahisagar at Lunawada",
      Court: "Santrampur, Mahisagar",
    },
    {
      State: "Gujarat",
      District: "Mahisagar at Lunawada",
      Court: "Taluka Court, Kadana",
    },
    {
      State: "Gujarat",
      District: "Mahisagar at Lunawada",
      Court: "District Court, Mahisagar",
    },
    {
      State: "Gujarat",
      District: "Mahisagar at Lunawada",
      Court: "Taluka Court, Virpur",
    },
    {
      State: "Gujarat",
      District: "Mahisagar at Lunawada",
      Court: "Taluka Court, Khanpur",
    },
    {
      State: "Gujarat",
      District: "Mahisagar at Lunawada",
      Court: "Taluka Court, Balasinor",
    },
    {
      State: "Gujarat",
      District: "Mahisagar at Lunawada",
      Court: "Civil Court, Lunawada",
    },
    {
      State: "Gujarat",
      District: "MORBI",
      Court: "CIVIL COURT, MORBI",
    },
    {
      State: "Gujarat",
      District: "MORBI",
      Court: "TALUKA COURT, MALIYA",
    },
    {
      State: "Gujarat",
      District: "MORBI",
      Court: "TALUKA COURT, WAKANER",
    },
    {
      State: "Gujarat",
      District: "MORBI",
      Court: "TALUKA COURT, HALVAD",
    },
    {
      State: "Gujarat",
      District: "MORBI",
      Court: "TALUKA COURT, TANKARA",
    },
    {
      State: "Gujarat",
      District: "MORBI",
      Court: "FAMILY COURT, MORBI",
    },
    {
      State: "Gujarat",
      District: "MORBI",
      Court: "DISTRICT COURT, MORBI",
    },
    {
      State: "Gujarat",
      District: "Narmada",
      Court: "TILAKWADA, NARMADA",
    },
    {
      State: "Gujarat",
      District: "Narmada",
      Court: "NEAR RAJPIPLA BUS STAND, RAJPIPLA",
    },
    {
      State: "Gujarat",
      District: "Narmada",
      Court: "SAGBARA, NARMADA",
    },
    {
      State: "Gujarat",
      District: "Narmada",
      Court: "TALUKA COURT, GARUDESHWAR",
    },
    {
      State: "Gujarat",
      District: "Narmada",
      Court: "DEDIPADA,NARMADA.",
    },
    {
      State: "Gujarat",
      District: "Navsari",
      Court: "Taluka Court, Khergam",
    },
    {
      State: "Gujarat",
      District: "Navsari",
      Court: "TALUKA COURT, SUBIR",
    },
    {
      State: "Gujarat",
      District: "Navsari",
      Court: "FTC, NAVSARI.",
    },
    {
      State: "Gujarat",
      District: "Navsari",
      Court: "GANDEVI, NAVSARI",
    },
    {
      State: "Gujarat",
      District: "Navsari",
      Court: "VANSDA, NAVSARI",
    },
    {
      State: "Gujarat",
      District: "Navsari",
      Court: "TALUKA COURT, VAGHAI",
    },
    {
      State: "Gujarat",
      District: "Navsari",
      Court: "Chikhli taluka court, navasari",
    },
    {
      State: "Gujarat",
      District: "Navsari",
      Court: "District and Civil Court Complex",
    },
    {
      State: "Gujarat",
      District: "Navsari",
      Court: "AHWA, NAVSARI",
    },
    {
      State: "Gujarat",
      District: "Navsari",
      Court: "LABOUR COURT, NAVSARI",
    },
    {
      State: "Gujarat",
      District: "Panchmahal at Godhra",
      Court: "HALOL, PANCH MAHALS",
    },
    {
      State: "Gujarat",
      District: "Panchmahal at Godhra",
      Court: "Labour Court, Godhra",
    },
    {
      State: "Gujarat",
      District: "Panchmahal at Godhra",
      Court: "CIVIL COURT, PANCH MAHALS",
    },
    {
      State: "Gujarat",
      District: "Panchmahal at Godhra",
      Court: "District and Sessions Court",
    },
    {
      State: "Gujarat",
      District: "Panchmahal at Godhra",
      Court: "FAMILY COURT, GODHRA",
    },
    {
      State: "Gujarat",
      District: "Panchmahal at Godhra",
      Court: "Ghoghamba, Godhra",
    },
    {
      State: "Gujarat",
      District: "Panchmahal at Godhra",
      Court: "KALOL, PANCH MAHALS",
    },
    {
      State: "Gujarat",
      District: "Panchmahal at Godhra",
      Court: "Pr. Civil & JMFC, Jambhughoda",
    },
    {
      State: "Gujarat",
      District: "Panchmahal at Godhra",
      Court: "Pr. Civil Judge, Morva (Hadaf)",
    },
    {
      State: "Gujarat",
      District: "Panchmahal at Godhra",
      Court: "Railway Court, Godhra",
    },
    {
      State: "Gujarat",
      District: "Panchmahal at Godhra",
      Court: "SAHERA, PANCH MAHALS",
    },
    {
      State: "Gujarat",
      District: "Patan",
      Court: "SAMI, PATAN",
    },
    {
      State: "Gujarat",
      District: "Patan",
      Court: "District and Civil Court Complex",
    },
    {
      State: "Gujarat",
      District: "Patan",
      Court: "CHANASMA, PATAN",
    },
    {
      State: "Gujarat",
      District: "Patan",
      Court: "HARIJ, PATAN",
    },
    {
      State: "Gujarat",
      District: "Patan",
      Court: "RADHANPUR, PATAN",
    },
    {
      State: "Gujarat",
      District: "Patan",
      Court: "VARAHI, PATAN",
    },
    {
      State: "Gujarat",
      District: "Patan",
      Court: "Taluka Court, Sankheshwar",
    },
    {
      State: "Gujarat",
      District: "Patan",
      Court: "Taluka Court, Saraswati",
    },
    {
      State: "Gujarat",
      District: "Patan",
      Court: "ADDL DISTRICT COURT, RADHANPUR",
    },
    {
      State: "Gujarat",
      District: "Patan",
      Court: "SIDHPUR, PATAN",
    },
    {
      State: "Gujarat",
      District: "Porbandar",
      Court: "KUTIYANA, PORBANDAR.",
    },
    {
      State: "Gujarat",
      District: "Porbandar",
      Court: "DISTRICT AND CIVIL COURT, PORBANDAR",
    },
    {
      State: "Gujarat",
      District: "Porbandar",
      Court: "RANAVAV, PORBANDAR.",
    },
    {
      State: "Gujarat",
      District: "Porbandar",
      Court: "FAMILY COURT, PORBANDAR",
    },
    {
      State: "Gujarat",
      District: "RAJKOT",
      Court: "DHORAJI, RAJKOT",
    },
    {
      State: "Gujarat",
      District: "RAJKOT",
      Court: "CIVIL AND SMALL CAUSES COURT",
    },
    {
      State: "Gujarat",
      District: "RAJKOT",
      Court: "TALUKA COURT, LODHIKA",
    },
    {
      State: "Gujarat",
      District: "RAJKOT",
      Court: "FAMILY COURT, FTC BUILDING, RAJKOT.",
    },
    {
      State: "Gujarat",
      District: "RAJKOT",
      Court: "JAMKANDORANA, RAJKOT",
    },
    {
      State: "Gujarat",
      District: "RAJKOT",
      Court: "TALUKA COURT, VINCHHIYA",
    },
    {
      State: "Gujarat",
      District: "RAJKOT",
      Court: "JASDAN, RAJKOT",
    },
    {
      State: "Gujarat",
      District: "RAJKOT",
      Court: "UPLETA, RAJKOT",
    },
    {
      State: "Gujarat",
      District: "RAJKOT",
      Court: "PADDHARI, RAJKOT",
    },
    {
      State: "Gujarat",
      District: "RAJKOT",
      Court: "BAHUMALI BHAVAN, RAJKOT",
    },
    {
      State: "Gujarat",
      District: "RAJKOT",
      Court: "KOTDA SANGANI, RAJKOT",
    },
    {
      State: "Gujarat",
      District: "RAJKOT",
      Court: "JETPUR, RAJKOT",
    },
    {
      State: "Gujarat",
      District: "RAJKOT",
      Court: "GONDAL, RAJKOT",
    },
    {
      State: "Gujarat",
      District: "RAJKOT",
      Court: "DISTRICT COURT, FTC BUILDING",
    },
    {
      State: "Gujarat",
      District: "Sabarkantha at Himmatnagar",
      Court: "IDAR, SABARKANTHA",
    },
    {
      State: "Gujarat",
      District: "Sabarkantha at Himmatnagar",
      Court: "PRANTIJ, SABAR KANTHA",
    },
    {
      State: "Gujarat",
      District: "Sabarkantha at Himmatnagar",
      Court: "CIVIL COURT COMPLEX, HIMATNAGAR.",
    },
    {
      State: "Gujarat",
      District: "Sabarkantha at Himmatnagar",
      Court: "VADALI, SABARKANTHA",
    },
    {
      State: "Gujarat",
      District: "Sabarkantha at Himmatnagar",
      Court: "VIJAYNAGAR, SABARKANTHA",
    },
    {
      State: "Gujarat",
      District: "Sabarkantha at Himmatnagar",
      Court: "TALOD, SABARKANTHA",
    },
    {
      State: "Gujarat",
      District: "Sabarkantha at Himmatnagar",
      Court: "KHEDBRAHMA, SABAR KANTHA",
    },
    {
      State: "Gujarat",
      District: "Sabarkantha at Himmatnagar",
      Court: "POSHINA, SABARKANTHA",
    },
    {
      State: "Gujarat",
      District: "Sabarkantha at Himmatnagar",
      Court: "HIMATNAGAR, SABAR KANTHA",
    },
    {
      State: "Gujarat",
      District: "Sabarkantha at Himmatnagar",
      Court: "LABOUR COURT, SABARKANTHA",
    },
    {
      State: "Gujarat",
      District: "SURAT",
      Court: "FAST TRACK COURT COMPLEX, SURAT",
    },
    {
      State: "Gujarat",
      District: "SURAT",
      Court: "MAHUVA, SURAT",
    },
    {
      State: "Gujarat",
      District: "SURAT",
      Court: "District and Civil Court Complex",
    },
    {
      State: "Gujarat",
      District: "SURAT",
      Court: "PALSANA, SURAT",
    },
    {
      State: "Gujarat",
      District: "SURAT",
      Court: "KATHOR, SURAT",
    },
    {
      State: "Gujarat",
      District: "SURAT",
      Court: "SEVASADAN, SURAT",
    },
    {
      State: "Gujarat",
      District: "SURAT",
      Court: "OLPAD, SURAT",
    },
    {
      State: "Gujarat",
      District: "SURAT",
      Court: "MANGROL, SURAT",
    },
    {
      State: "Gujarat",
      District: "SURAT",
      Court: "RAILWAY COURT, SURAT.",
    },
    {
      State: "Gujarat",
      District: "SURAT",
      Court: "BARDOLI, SURAT",
    },
    {
      State: "Gujarat",
      District: "SURAT",
      Court: "FAMILY COURT,NANPURA,SURAT",
    },
    {
      State: "Gujarat",
      District: "SURAT",
      Court: "UMARPADA, SURAT",
    },
    {
      State: "Gujarat",
      District: "SURAT",
      Court: "MANDVI, SURAT",
    },
    {
      State: "Gujarat",
      District: "Surendranagar",
      Court: "MULI, SURENDRANAGAR",
    },
    {
      State: "Gujarat",
      District: "Surendranagar",
      Court: "CHUDA, SURENDRANAGAR",
    },
    {
      State: "Gujarat",
      District: "Surendranagar",
      Court: "CHOTILA, SURENDRANAGAR",
    },
    {
      State: "Gujarat",
      District: "Surendranagar",
      Court: "LABOUR COURT, SURENDRANAGAR",
    },
    {
      State: "Gujarat",
      District: "Surendranagar",
      Court: "SAYLA, SURENDRANAGAR",
    },
    {
      State: "Gujarat",
      District: "Surendranagar",
      Court: "CIVIL COURT, SURENDRANAGAR.",
    },
    {
      State: "Gujarat",
      District: "Surendranagar",
      Court: "LAKHTAR, SURENDRANAGAR",
    },
    {
      State: "Gujarat",
      District: "Surendranagar",
      Court: "WADHWAN, SURENDRANAGAR",
    },
    {
      State: "Gujarat",
      District: "Surendranagar",
      Court: "TALUKA COURT,THANGADH",
    },
    {
      State: "Gujarat",
      District: "Surendranagar",
      Court: "LIMBDI, SURENDRANAGAR",
    },
    {
      State: "Gujarat",
      District: "Surendranagar",
      Court: "DHRANGADHRA, SURENDRANAGAR",
    },
    {
      State: "Gujarat",
      District: "Surendranagar",
      Court: "DISTRICT COURT, SURENDRANAGAR",
    },
    {
      State: "Gujarat",
      District: "Surendranagar",
      Court: "PATDI, SURENDRANAGAR",
    },
    {
      State: "Gujarat",
      District: "Surendranagar",
      Court: "FTC, SURENDRANAGAR",
    },
    {
      State: "Gujarat",
      District: "Tapi",
      Court: "DISTRICT AND CIVIL COURT, TAPI",
    },
    {
      State: "Gujarat",
      District: "Tapi",
      Court: "UCHCHHAL, TAPI",
    },
    {
      State: "Gujarat",
      District: "Tapi",
      Court: "NIZAR, TAPI",
    },
    {
      State: "Gujarat",
      District: "Tapi",
      Court: "TALUKA COURT, DOLVAN",
    },
    {
      State: "Gujarat",
      District: "Tapi",
      Court: "SONGADH, TAPI",
    },
    {
      State: "Gujarat",
      District: "Tapi",
      Court: "VALOD, TAPI",
    },
    {
      State: "Gujarat",
      District: "Vadodara",
      Court: "CIVIL AND JMFC COURT, DESAR",
    },
    {
      State: "Gujarat",
      District: "Vadodara",
      Court: "NyayMandir, VADODARA - 390001",
    },
    {
      State: "Gujarat",
      District: "Vadodara",
      Court: "CIVIL AND JMFC COURT, SINOR",
    },
    {
      State: "Gujarat",
      District: "Vadodara",
      Court: "FAMILY COURT, VADODARA",
    },
    {
      State: "Gujarat",
      District: "Vadodara",
      Court: "CIVIL AND JMFC COURT, VAGHODIA",
    },
    {
      State: "Gujarat",
      District: "Vadodara",
      Court: "TALUKA COURT, SAVLI",
    },
    {
      State: "Gujarat",
      District: "Vadodara",
      Court: "ADDL COURT, DABHOI",
    },
    {
      State: "Gujarat",
      District: "Vadodara",
      Court: "INDUSTRIAL COURT, VADODARA",
    },
    {
      State: "Gujarat",
      District: "Vadodara",
      Court: "KARJAN, VADODARA",
    },
    {
      State: "Gujarat",
      District: "Vadodara",
      Court: "CIVIL AND JMFC COURT, PADRA",
    },
    {
      State: "Gujarat",
      District: "Vadodara",
      Court: "CIVIL AND JMFC COURT, DABHOI",
    },
    {
      State: "Gujarat",
      District: "Valsad",
      Court: "CIVIL COURT COMPLEX, VALSAD",
    },
    {
      State: "Gujarat",
      District: "Valsad",
      Court: "DHARAMPUR ROAD, VALSAD",
    },
    {
      State: "Gujarat",
      District: "Valsad",
      Court: "PARDI, VALSAD",
    },
    {
      State: "Gujarat",
      District: "Valsad",
      Court: "DHARAMPUR,VALSAD.",
    },
    {
      State: "Gujarat",
      District: "Valsad",
      Court: "LABOUR COURT, VALSAD",
    },
    {
      State: "Gujarat",
      District: "Valsad",
      Court: "UMBERGAON, VALSAD",
    },
    {
      State: "Gujarat",
      District: "Valsad",
      Court: "VAPI, VALSAD.",
    },
    {
      State: "Gujarat",
      District: "Valsad",
      Court: "KAPARADA, VALSAD.",
    },
    {
      State: "Haryana",
      District: "Ambala",
      Court: "Judicial Complex, Naraingarh",
    },
    {
      State: "Haryana",
      District: "Ambala",
      Court: "Family Complex, Naraingarh",
    },
    {
      State: "Haryana",
      District: "Ambala",
      Court: "District Court, Ambala",
    },
    {
      State: "Haryana",
      District: "Ambala",
      Court: "Railway Complex, Ambala Cantt",
    },
    {
      State: "Haryana",
      District: "Bhiwani",
      Court: "District Court, Bhiwani",
    },
    {
      State: "Haryana",
      District: "Bhiwani",
      Court: "Judicial Complex, Loharu",
    },
    {
      State: "Haryana",
      District: "Bhiwani",
      Court: "Judicial Complex, Tosham",
    },
    {
      State: "Haryana",
      District: "Bhiwani",
      Court: "Judicial Complex, Charkhi Dadri",
    },
    {
      State: "Haryana",
      District: "Bhiwani",
      Court: "Judicial Complex, Siwani",
    },
    {
      State: "Haryana",
      District: "Faridabad",
      Court: "District Court, Faridabad",
    },
    {
      State: "Haryana",
      District: "Fatehabad",
      Court: "Judicial Complex, Tohana",
    },
    {
      State: "Haryana",
      District: "Fatehabad",
      Court: "Judicial Complex, Ratia",
    },
    {
      State: "Haryana",
      District: "Fatehabad",
      Court: "District Court, Fatehabad",
    },
    {
      State: "Haryana",
      District: "Gurugram",
      Court: "District Court, Gurugram",
    },
    {
      State: "Haryana",
      District: "Gurugram",
      Court: "Judicial Complex, Sohna",
    },
    {
      State: "Haryana",
      District: "Gurugram",
      Court: "Judicial Complex, Pataudi",
    },
    {
      State: "Haryana",
      District: "Hisar",
      Court: "Judicial Complex, Hansi",
    },
    {
      State: "Haryana",
      District: "Hisar",
      Court: "District Court, Hisar",
    },
    {
      State: "Haryana",
      District: "Jhajjar",
      Court: "Judicial Complex, Bahadurgarh",
    },
    {
      State: "Haryana",
      District: "Jhajjar",
      Court: "District Court, Jhajjar",
    },
    {
      State: "Haryana",
      District: "Jind",
      Court: "Judicial Complex, Safidon",
    },
    {
      State: "Haryana",
      District: "Jind",
      Court: "District Court, Jind",
    },
    {
      State: "Haryana",
      District: "Jind",
      Court: "Judicial Complex, Narwana",
    },
    {
      State: "Haryana",
      District: "Kaithal",
      Court: "District Court, Kaithal",
    },
    {
      State: "Haryana",
      District: "Kaithal",
      Court: "Judicial Complex, Guhla",
    },
    {
      State: "Haryana",
      District: "Karnal",
      Court: "Judicial Court Complex, Assandh",
    },
    {
      State: "Haryana",
      District: "Karnal",
      Court: "Judicial Court Complex, Indri",
    },
    {
      State: "Haryana",
      District: "Karnal",
      Court: "District and Sessions Court Complex , Karnal",
    },
    {
      State: "Haryana",
      District: "Kurukshetra",
      Court: "District Court, Kurukshetra",
    },
    {
      State: "Haryana",
      District: "Kurukshetra",
      Court: "Judicial Complex, Shahabad",
    },
    {
      State: "Haryana",
      District: "Kurukshetra",
      Court: "Judicial Complex, Pehowa",
    },
    {
      State: "Haryana",
      District: "Narnaul",
      Court: "Judicial Complex, Mohindergarh",
    },
    {
      State: "Haryana",
      District: "Narnaul",
      Court: "District Court, Narnaul",
    },
    {
      State: "Haryana",
      District: "Narnaul",
      Court: "Judicial Complex, Kanina",
    },
    {
      State: "Haryana",
      District: "Nuh",
      Court: "District Court, Nuh",
    },
    {
      State: "Haryana",
      District: "Nuh",
      Court: "Judicial Complex, Ferozepur Jhirka",
    },
    {
      State: "Haryana",
      District: "Nuh",
      Court: "Judicial Complex, Punhana",
    },
    {
      State: "Haryana",
      District: "Palwal",
      Court: "Judicial Complex, Hodal",
    },
    {
      State: "Haryana",
      District: "Palwal",
      Court: "District Court, Palwal",
    },
    {
      State: "Haryana",
      District: "Palwal",
      Court: "Judicial Complex, Hathin",
    },
    {
      State: "Haryana",
      District: "Panchkula",
      Court: "District Court, Panchkula",
    },
    {
      State: "Haryana",
      District: "Panchkula",
      Court: "Judicial Complex, Kalka",
    },
    {
      State: "Haryana",
      District: "Panipat",
      Court: "Judicial Complex, Samalkha",
    },
    {
      State: "Haryana",
      District: "Panipat",
      Court: "District Court, Panipat",
    },
    {
      State: "Haryana",
      District: "Rewari",
      Court: "Judicial Complex, Kosli",
    },
    {
      State: "Haryana",
      District: "Rewari",
      Court: "Judicial Complex, Bawal",
    },
    {
      State: "Haryana",
      District: "Rewari",
      Court: "District Court, Rewari",
    },
    {
      State: "Haryana",
      District: "Rohtak",
      Court: "Judicial Complex, Meham",
    },
    {
      State: "Haryana",
      District: "Rohtak",
      Court: "District Court, Rohtak",
    },
    {
      State: "Haryana",
      District: "Sirsa",
      Court: "Judicial Complex, Ellenabad",
    },
    {
      State: "Haryana",
      District: "Sirsa",
      Court: "District Court, Sirsa",
    },
    {
      State: "Haryana",
      District: "Sirsa",
      Court: "Gramnayalya Complex, Rania",
    },
    {
      State: "Haryana",
      District: "Sirsa",
      Court: "Judicial Complex, Dabwali",
    },
    {
      State: "Haryana",
      District: "Sonepat",
      Court: "Judicial Complex, Gohana",
    },
    {
      State: "Haryana",
      District: "Sonepat",
      Court: "Judicial Complex, Kharkhoda",
    },
    {
      State: "Haryana",
      District: "Sonepat",
      Court: "Judicial Complex, Ganaur",
    },
    {
      State: "Haryana",
      District: "Sonepat",
      Court: "District Court, Sonepat",
    },
    {
      State: "Haryana",
      District: "Yamunanagar",
      Court: "District Court, Yamunanagar",
    },
    {
      State: "Haryana",
      District: "Yamunanagar",
      Court: "Judicial Complex, Bilaspur",
    },
    {
      State: "Himachal Pradesh",
      District: "Bilaspur",
      Court: "Ghumarwin",
    },
    {
      State: "Himachal Pradesh",
      District: "Bilaspur",
      Court: "Bilaspur",
    },
    {
      State: "Himachal Pradesh",
      District: "Bilaspur",
      Court: "Jhanduta",
    },
    {
      State: "Himachal Pradesh",
      District: "Chamba",
      Court: "Dalhousie",
    },
    {
      State: "Himachal Pradesh",
      District: "Chamba",
      Court: "Chamba",
    },
    {
      State: "Himachal Pradesh",
      District: "Chamba",
      Court: "Tissa",
    },
    {
      State: "Himachal Pradesh",
      District: "Hamirpur",
      Court: "Badsar",
    },
    {
      State: "Himachal Pradesh",
      District: "Hamirpur",
      Court: "Hamirpur",
    },
    {
      State: "Himachal Pradesh",
      District: "Hamirpur",
      Court: "Nadaun",
    },
    {
      State: "Himachal Pradesh",
      District: "Kangra",
      Court: "Dehra",
    },
    {
      State: "Himachal Pradesh",
      District: "Kangra",
      Court: "Palampur",
    },
    {
      State: "Himachal Pradesh",
      District: "Kangra",
      Court: "Nurpur",
    },
    {
      State: "Himachal Pradesh",
      District: "Kangra",
      Court: "Kangra",
    },
    {
      State: "Himachal Pradesh",
      District: "Kangra",
      Court: "Baijnath",
    },
    {
      State: "Himachal Pradesh",
      District: "Kangra",
      Court: "Indora",
    },
    {
      State: "Himachal Pradesh",
      District: "Kangra",
      Court: "Dharamshala",
    },
    {
      State: "Himachal Pradesh",
      District: "Kangra",
      Court: "Jawali",
    },
    {
      State: "Himachal Pradesh",
      District: "Kangra",
      Court: "Jaisinghpur",
    },
    {
      State: "Himachal Pradesh",
      District: "Kinnaur",
      Court: "Anni",
    },
    {
      State: "Himachal Pradesh",
      District: "Kinnaur",
      Court: "Reckong Peo",
    },
    {
      State: "Himachal Pradesh",
      District: "Kinnaur",
      Court: "Rampur Bushehr",
    },
    {
      State: "Himachal Pradesh",
      District: "Kullu",
      Court: "Manali",
    },
    {
      State: "Himachal Pradesh",
      District: "Kullu",
      Court: "Kullu",
    },
    {
      State: "Himachal Pradesh",
      District: "Kullu",
      Court: "Banjar",
    },
    {
      State: "Himachal Pradesh",
      District: "Mandi",
      Court: "Mandi",
    },
    {
      State: "Himachal Pradesh",
      District: "Mandi",
      Court: "Joginder Nagar",
    },
    {
      State: "Himachal Pradesh",
      District: "Mandi",
      Court: "Karsog",
    },
    {
      State: "Himachal Pradesh",
      District: "Mandi",
      Court: "Sundernagar",
    },
    {
      State: "Himachal Pradesh",
      District: "Mandi",
      Court: "Sarkaghat",
    },
    {
      State: "Himachal Pradesh",
      District: "Mandi",
      Court: "Gohar",
    },
    {
      State: "Himachal Pradesh",
      District: "Mandi",
      Court: "Thunag",
    },
    {
      State: "Himachal Pradesh",
      District: "Shimla",
      Court: "Shimla",
    },
    {
      State: "Himachal Pradesh",
      District: "Shimla",
      Court: "Rohru",
    },
    {
      State: "Himachal Pradesh",
      District: "Shimla",
      Court: "Jubbal",
    },
    {
      State: "Himachal Pradesh",
      District: "Shimla",
      Court: "Theog",
    },
    {
      State: "Himachal Pradesh",
      District: "Shimla",
      Court: "Chopal",
    },
    {
      State: "Himachal Pradesh",
      District: "Sirmaur",
      Court: "Nahan",
    },
    {
      State: "Himachal Pradesh",
      District: "Sirmaur",
      Court: "Paonta Sahib",
    },
    {
      State: "Himachal Pradesh",
      District: "Sirmaur",
      Court: "Rajgarh",
    },
    {
      State: "Himachal Pradesh",
      District: "Sirmaur",
      Court: "Shillai",
    },
    {
      State: "Himachal Pradesh",
      District: "Solan",
      Court: "Arki",
    },
    {
      State: "Himachal Pradesh",
      District: "Solan",
      Court: "Nalagarh",
    },
    {
      State: "Himachal Pradesh",
      District: "Solan",
      Court: "Kasauli",
    },
    {
      State: "Himachal Pradesh",
      District: "Solan",
      Court: "Kandaghat",
    },
    {
      State: "Himachal Pradesh",
      District: "Solan",
      Court: "Solan",
    },
    {
      State: "Himachal Pradesh",
      District: "Una",
      Court: "Amb",
    },
    {
      State: "Himachal Pradesh",
      District: "Una",
      Court: "Una",
    },
    {
      State: "Jammu and Kashmir",
      District: "Anantnag",
      Court: "Sub Judge Court Complex,Bijbehara",
    },
    {
      State: "Jammu and Kashmir",
      District: "Anantnag",
      Court: "Munsiff Court Pahalgam",
    },
    {
      State: "Jammu and Kashmir",
      District: "Anantnag",
      Court: "Munsiff Court Complex,Aishmuqam",
    },
    {
      State: "Jammu and Kashmir",
      District: "Anantnag",
      Court: "District Court Complex, Anantnag",
    },
    {
      State: "Jammu and Kashmir",
      District: "Anantnag",
      Court: "Munisff Court Complex,Dooru",
    },
    {
      State: "Jammu and Kashmir",
      District: "Anantnag",
      Court: "Sub Judge Court Complex, Vailoo",
    },
    {
      State: "Jammu and Kashmir",
      District: "Bandipora",
      Court: "District Court, Bandipora",
    },
    {
      State: "Jammu and Kashmir",
      District: "Bandipora",
      Court: "Munsif Court, Sumbal",
    },
    {
      State: "Jammu and Kashmir",
      District: "Baramulla",
      Court: "Sub Judge Court, Pattan",
    },
    {
      State: "Jammu and Kashmir",
      District: "Baramulla",
      Court: "ADSJ, Sopore",
    },
    {
      State: "Jammu and Kashmir",
      District: "Baramulla",
      Court: "Munsiff Court Chandoosa",
    },
    {
      State: "Jammu and Kashmir",
      District: "Baramulla",
      Court: "Munsiff Court, Tangmarg",
    },
    {
      State: "Jammu and Kashmir",
      District: "Baramulla",
      Court: "District Court, Baramula",
    },
    {
      State: "Jammu and Kashmir",
      District: "Baramulla",
      Court: "Munsiff Court, Boniyar",
    },
    {
      State: "Jammu and Kashmir",
      District: "Baramulla",
      Court: "Sub Judge Court, Uri",
    },
    {
      State: "Jammu and Kashmir",
      District: "Baramulla",
      Court: "Munsiff Court Dangiwacha, Baramulla",
    },
    {
      State: "Jammu and Kashmir",
      District: "Budgam",
      Court: "Munsiff Court, Chadoora",
    },
    {
      State: "Jammu and Kashmir",
      District: "Budgam",
      Court: "District Court, Budgam",
    },
    {
      State: "Jammu and Kashmir",
      District: "Budgam",
      Court: "Munsiff Court Beerwah",
    },
    {
      State: "Jammu and Kashmir",
      District: "Budgam",
      Court: "Munsiff Court, Charisharief",
    },
    {
      State: "Jammu and Kashmir",
      District: "Budgam",
      Court: "Munsiff Court, Magam",
    },
    {
      State: "Jammu and Kashmir",
      District: "Doda",
      Court: "Munsiff Court Complex, Gandoh",
    },
    {
      State: "Jammu and Kashmir",
      District: "Doda",
      Court: "Addl Distt and Sessions Judge, Doda",
    },
    {
      State: "Jammu and Kashmir",
      District: "Doda",
      Court: "District Court Complex Bhaderwah",
    },
    {
      State: "Jammu and Kashmir",
      District: "Doda",
      Court: "Munsiff Court Complex, Thathri",
    },
    {
      State: "Jammu and Kashmir",
      District: "Ganderbal",
      Court: "District Court Complex, Ganderbal",
    },
    {
      State: "Jammu and Kashmir",
      District: "Ganderbal",
      Court: "Munsiff Court, Kangan",
    },
    {
      State: "Jammu and Kashmir",
      District: "Jammu",
      Court: "Munsiff Court, Bishnah",
    },
    {
      State: "Jammu and Kashmir",
      District: "Jammu",
      Court: "Munsiff Court, RSPura",
    },
    {
      State: "Jammu and Kashmir",
      District: "Jammu",
      Court: "District Court Complex Jammu",
    },
    {
      State: "Jammu and Kashmir",
      District: "Jammu",
      Court: "Munsiff Court, Akhnoor",
    },
    {
      State: "Jammu and Kashmir",
      District: "Kathua",
      Court: "Munsiff Court, Mahanpur",
    },
    {
      State: "Jammu and Kashmir",
      District: "Kathua",
      Court: "Munsiff Court Bani",
    },
    {
      State: "Jammu and Kashmir",
      District: "Kathua",
      Court: "District Court Complex ,Kathua",
    },
    {
      State: "Jammu and Kashmir",
      District: "Kathua",
      Court: "Munsiff Court,Basholi",
    },
    {
      State: "Jammu and Kashmir",
      District: "Kathua",
      Court: "Munsiff Court, Billawar",
    },
    {
      State: "Jammu and Kashmir",
      District: "Kathua",
      Court: "Munsiff Court, Hiranagar",
    },
    {
      State: "Jammu and Kashmir",
      District: "Kishtwar",
      Court: "District Court, Kishtwar",
    },
    {
      State: "Jammu and Kashmir",
      District: "Kulgam",
      Court: "Munsiff Court Complex, Qazigund",
    },
    {
      State: "Jammu and Kashmir",
      District: "Kulgam",
      Court: "PDSJ Court Kulgam",
    },
    {
      State: "Jammu and Kashmir",
      District: "Kulgam",
      Court: "Munsif Court Complex, DH Pora",
    },
    {
      State: "Jammu and Kashmir",
      District: "Kupwara",
      Court: "District Court Complex, Kupwara",
    },
    {
      State: "Jammu and Kashmir",
      District: "Kupwara",
      Court: "Court Complex, Sogam",
    },
    {
      State: "Jammu and Kashmir",
      District: "Kupwara",
      Court: "Court Complex, Handwara",
    },
    {
      State: "Jammu and Kashmir",
      District: "Poonch",
      Court: "Munsif Court, Mendhar",
    },
    {
      State: "Jammu and Kashmir",
      District: "Poonch",
      Court: "District Court Complex, Poonch",
    },
    {
      State: "Jammu and Kashmir",
      District: "Poonch",
      Court: "Sub Judge Court, Surankote",
    },
    {
      State: "Jammu and Kashmir",
      District: "Pulwama",
      Court: "District Court Complex, Pulwama",
    },
    {
      State: "Jammu and Kashmir",
      District: "Pulwama",
      Court: "Munsiff Court, Pampore",
    },
    {
      State: "Jammu and Kashmir",
      District: "Pulwama",
      Court: "Munsiff Court, Tral",
    },
    {
      State: "Jammu and Kashmir",
      District: "Pulwama",
      Court: "Munsiff Court Awaitipora",
    },
    {
      State: "Jammu and Kashmir",
      District: "Rajouri",
      Court: "Sub Judge Court, Nowshera",
    },
    {
      State: "Jammu and Kashmir",
      District: "Rajouri",
      Court: "Munsiff Court, Sunderbani",
    },
    {
      State: "Jammu and Kashmir",
      District: "Rajouri",
      Court: "Munsiff Court, Thanamandi",
    },
    {
      State: "Jammu and Kashmir",
      District: "Rajouri",
      Court: "PDSJ Rajouri",
    },
    {
      State: "Jammu and Kashmir",
      District: "Rajouri",
      Court: "Munsiff Court, Kotranka",
    },
    {
      State: "Jammu and Kashmir",
      District: "Rajouri",
      Court: "Munsiff Court, Kalakote",
    },
    {
      State: "Jammu and Kashmir",
      District: "Rajouri",
      Court: "MUNSIFF COURT, BUDHAL",
    },
    {
      State: "Jammu and Kashmir",
      District: "Ramban",
      Court: "District Court Complex, Ramban",
    },
    {
      State: "Jammu and Kashmir",
      District: "Ramban",
      Court: "Sub Judge Court, Batote",
    },
    {
      State: "Jammu and Kashmir",
      District: "Ramban",
      Court: "Munsiff Court, Banihal",
    },
    {
      State: "Jammu and Kashmir",
      District: "Reasi",
      Court: "Munsiff Court, Mahore",
    },
    {
      State: "Jammu and Kashmir",
      District: "Reasi",
      Court: "DISTRICT COURT COMPLEX REASI",
    },
    {
      State: "Jammu and Kashmir",
      District: "Reasi",
      Court: "Sub Judge Court, Katra",
    },
    {
      State: "Jammu and Kashmir",
      District: "Samba",
      Court: "District Court Samba",
    },
    {
      State: "Jammu and Kashmir",
      District: "Shopian",
      Court: "District Court Complex, Shopian",
    },
    {
      State: "Jammu and Kashmir",
      District: "Srinagar",
      Court: "District Court Srinagar",
    },
    {
      State: "Jammu and Kashmir",
      District: "Srinagar",
      Court: "Munsiff Court Pathachowk",
    },
    {
      State: "Jammu and Kashmir",
      District: "Udhampur",
      Court: "Munsiff Court Chenani",
    },
    {
      State: "Jammu and Kashmir",
      District: "Udhampur",
      Court: "Sub Judge Court Ramnagar",
    },
    {
      State: "Jammu and Kashmir",
      District: "Udhampur",
      Court: "Munsiff Court Majalta",
    },
    {
      State: "Jammu and Kashmir",
      District: "Udhampur",
      Court: "District Court Complex Udhampur",
    },
    {
      State: "Jharkhand",
      District: "Bokaro",
      Court: "2) Ten Court Building Complex -2",
    },
    {
      State: "Jharkhand",
      District: "Chatra",
      Court: "1) Court Complex-1",
    },
    {
      State: "Jharkhand",
      District: "Daltonganj",
      Court: "1) District Court Complex-1",
    },
    {
      State: "Jharkhand",
      District: "Deoghar",
      Court: "1) Old Building Comlplex-1",
    },
    {
      State: "Jharkhand",
      District: "Dhanbad",
      Court: "4) District Court 28 Courts Building",
    },
    {
      State: "Jharkhand",
      District: "Dumka",
      Court: "1) D.J. Court Building Complex-1",
    },
    {
      State: "Jharkhand",
      District: "East Singhbhum at Jamshedpur",
      Court: "1) District Civil Court Complex-1",
    },
    {
      State: "Jharkhand",
      District: "Garhwa",
      Court: "1) District Judges Court Complex-1",
    },
    {
      State: "Jharkhand",
      District: "Giridih",
      Court: "1) Court Complex-1",
    },
    {
      State: "Jharkhand",
      District: "Godda",
      Court: "2) New Four Court Building Comlplex-2",
    },
    {
      State: "Jharkhand",
      District: "Gumla",
      Court: "1) Main Civil Court Complex-1",
    },
    {
      State: "Jharkhand",
      District: "Hazaribagh",
      Court: "1) Annexy Building",
    },
    {
      State: "Jharkhand",
      District: "Jamtara",
      Court: "1) District Court Complex-1",
    },
    {
      State: "Jharkhand",
      District: "KHUNTI",
      Court: "KHUNTI CIVIL COURT",
    },
    {
      State: "Jharkhand",
      District: "Koderma",
      Court: "1) District & Sessions Judge's Building Complex-1",
    },
    {
      State: "Jharkhand",
      District: "Latehar",
      Court: "1) District & Sessions Judge's Building Complex-1",
    },
    {
      State: "Jharkhand",
      District: "Lohardaga",
      Court: "1)District Court Complex-1",
    },
    {
      State: "Jharkhand",
      District: "Pakur",
      Court: "1) D.J. Court Building Complex-1",
    },
    {
      State: "Jharkhand",
      District: "RAMGARH",
      Court: "CIVIL COURT RAMGARH",
    },
    {
      State: "Jharkhand",
      District: "Ranchi",
      Court: "1) Main Building",
    },
    {
      State: "Jharkhand",
      District: "Sahibganj",
      Court: "1) District Court Complex-1",
    },
    {
      State: "Jharkhand",
      District: "Seraikella",
      Court: "1)District Court Complex-1",
    },
    {
      State: "Jharkhand",
      District: "Simdega",
      Court: "1) Court Complex-1",
    },
    {
      State: "Jharkhand",
      District: "West Singhbhum at Chaibasa",
      Court: "1) District Courts Complex-1",
    },
    {
      State: "Karnataka",
      District: "BAGALKOT",
      Court: "Court Complex- Hungund",
    },
    {
      State: "Karnataka",
      District: "BAGALKOT",
      Court: "Court Complex Jamkhandi",
    },
    {
      State: "Karnataka",
      District: "BAGALKOT",
      Court: "Court Complex Mudhol",
    },
    {
      State: "Karnataka",
      District: "BAGALKOT",
      Court: "Court Complex Banhatti",
    },
    {
      State: "Karnataka",
      District: "BAGALKOT",
      Court: "District Court Complex Bagalkot",
    },
    {
      State: "Karnataka",
      District: "BAGALKOT",
      Court: "Court Complex Badami",
    },
    {
      State: "Karnataka",
      District: "BAGALKOT",
      Court: "Court Complex-Bilagi",
    },
    {
      State: "Karnataka",
      District: "BALLARI",
      Court: "Court Complex- Huvina Hadagali",
    },
    {
      State: "Karnataka",
      District: "BALLARI",
      Court: "Court Complex- Kudligi",
    },
    {
      State: "Karnataka",
      District: "BALLARI",
      Court: "Court Complex- Hospet",
    },
    {
      State: "Karnataka",
      District: "BALLARI",
      Court: "Court Complex- Siruguppa",
    },
    {
      State: "Karnataka",
      District: "BALLARI",
      Court: "Court Complex Harapanahalli",
    },
    {
      State: "Karnataka",
      District: "BALLARI",
      Court: "Court Complex- Sandur",
    },
    {
      State: "Karnataka",
      District: "BALLARI",
      Court: "District Court Complex-Ballari",
    },
    {
      State: "Karnataka",
      District: "BALLARI",
      Court: "Court Complex- Hagaribommanahalli",
    },
    {
      State: "Karnataka",
      District: "BELAGAVI",
      Court: "District (Old) Court Complex-Belagavi",
    },
    {
      State: "Karnataka",
      District: "BELAGAVI",
      Court: "Court Complex- Raibag",
    },
    {
      State: "Karnataka",
      District: "BELAGAVI",
      Court: "Court Complex- Gokak",
    },
    {
      State: "Karnataka",
      District: "BELAGAVI",
      Court: "New Court Complex-Belagavi",
    },
    {
      State: "Karnataka",
      District: "BELAGAVI",
      Court: "Court Complex- Nipani",
    },
    {
      State: "Karnataka",
      District: "BELAGAVI",
      Court: "Court Complex- Saundatti",
    },
    {
      State: "Karnataka",
      District: "BELAGAVI",
      Court: "Court Complex- sankeshwar",
    },
    {
      State: "Karnataka",
      District: "BELAGAVI",
      Court: "Court Complex-Athani",
    },
    {
      State: "Karnataka",
      District: "BELAGAVI",
      Court: "Court Complex- Hukkeri",
    },
    {
      State: "Karnataka",
      District: "BELAGAVI",
      Court: "Court Complex- Khanapur",
    },
    {
      State: "Karnataka",
      District: "BELAGAVI",
      Court: "Court Complex- Chikodi",
    },
    {
      State: "Karnataka",
      District: "BELAGAVI",
      Court: "Court Complex- Ramdurga",
    },
    {
      State: "Karnataka",
      District: "BELAGAVI",
      Court: "Court Complex-Bailhongal",
    },
    {
      State: "Karnataka",
      District: "BELAGAVI",
      Court: "Court Complex Mudalgi",
    },
    {
      State: "Karnataka",
      District: "BELAGAVI",
      Court: "Court Complex Kittur",
    },
    {
      State: "Karnataka",
      District: "BENGALURU",
      Court: "CMM Court Complex, Bangalore",
    },
    {
      State: "Karnataka",
      District: "BENGALURU",
      Court: "City Civil Court Complex, Bangalore",
    },
    {
      State: "Karnataka",
      District: "BENGALURU",
      Court: "NyayaDegula Court Complex Bangalore",
    },
    {
      State: "Karnataka",
      District: "BENGALURU",
      Court: "SmallCauses Court Complex,Bangalore",
    },
    {
      State: "Karnataka",
      District: "BENGALURU",
      Court: "Mayo Hall Court Complex, Bangalore",
    },
    {
      State: "Karnataka",
      District: "BENGALURU",
      Court: "COMMERCIAL COURT COMPLEX",
    },
    {
      State: "Karnataka",
      District: "BENGALURU RURAL",
      Court: "Court Complex- Hosakote",
    },
    {
      State: "Karnataka",
      District: "BENGALURU RURAL",
      Court: "City Civil Court Complex-Bengalurur Rual",
    },
    {
      State: "Karnataka",
      District: "BENGALURU RURAL",
      Court: "Court Complex- Anekal",
    },
    {
      State: "Karnataka",
      District: "BENGALURU RURAL",
      Court: "Court Complex- Devanahalli",
    },
    {
      State: "Karnataka",
      District: "BENGALURU RURAL",
      Court: "Court Complex- Doddaballapur",
    },
    {
      State: "Karnataka",
      District: "BENGALURU RURAL",
      Court: "Court Complex- Nelamangala",
    },
    {
      State: "Karnataka",
      District: "BIDAR",
      Court: "Court Complex-Aurad",
    },
    {
      State: "Karnataka",
      District: "BIDAR",
      Court: "District Court Complex-Bidar",
    },
    {
      State: "Karnataka",
      District: "BIDAR",
      Court: "Court Complex-Bhalki",
    },
    {
      State: "Karnataka",
      District: "BIDAR",
      Court: "Court Complex-Basavakalyan",
    },
    {
      State: "Karnataka",
      District: "BIDAR",
      Court: "Court Complex-Humnabad",
    },
    {
      State: "Karnataka",
      District: "CHAMRAJNAGAR",
      Court: "Court Complex- Yelandur",
    },
    {
      State: "Karnataka",
      District: "CHAMRAJNAGAR",
      Court: "Court Complex- Kollegal",
    },
    {
      State: "Karnataka",
      District: "CHAMRAJNAGAR",
      Court: "Court Complex- Gundlupet",
    },
    {
      State: "Karnataka",
      District: "CHAMRAJNAGAR",
      Court: "District Court Complex-Chamarajanagar",
    },
    {
      State: "Karnataka",
      District: "CHIKKABALLAPUR",
      Court: "JMFC Court Complex-Chintamani",
    },
    {
      State: "Karnataka",
      District: "CHIKKABALLAPUR",
      Court: "Court Complex- Sidlaghatta",
    },
    {
      State: "Karnataka",
      District: "CHIKKABALLAPUR",
      Court: "Court Complex-Bagepalli",
    },
    {
      State: "Karnataka",
      District: "CHIKKABALLAPUR",
      Court: "District Court Complex-Chikkaballapur",
    },
    {
      State: "Karnataka",
      District: "CHIKKABALLAPUR",
      Court: "Court Complex- Gowribidanur",
    },
    {
      State: "Karnataka",
      District: "CHIKKABALLAPUR",
      Court: "COURT COMPLEX - GUDIBANDA",
    },
    {
      State: "Karnataka",
      District: "CHIKKAMAGALURU",
      Court: "Court Complex-N.R.Pura",
    },
    {
      State: "Karnataka",
      District: "CHIKKAMAGALURU",
      Court: "Court Complex-Kadur",
    },
    {
      State: "Karnataka",
      District: "CHIKKAMAGALURU",
      Court: "Court Complex-Tarikere",
    },
    {
      State: "Karnataka",
      District: "CHIKKAMAGALURU",
      Court: "District Court Complex-Chikkamagalur",
    },
    {
      State: "Karnataka",
      District: "CHIKKAMAGALURU",
      Court: "Court Complex-Mudigere",
    },
    {
      State: "Karnataka",
      District: "CHIKKAMAGALURU",
      Court: "Court Complex-Sringeri",
    },
    {
      State: "Karnataka",
      District: "CHIKKAMAGALURU",
      Court: "Court Complex-Koppa",
    },
    {
      State: "Karnataka",
      District: "CHITRADURGA",
      Court: "Court Complex-Hiriyur",
    },
    {
      State: "Karnataka",
      District: "CHITRADURGA",
      Court: "Court Complex-Molakalmuru",
    },
    {
      State: "Karnataka",
      District: "CHITRADURGA",
      Court: "Court Complex-Challakere",
    },
    {
      State: "Karnataka",
      District: "CHITRADURGA",
      Court: "Court Complex-Hosadurga",
    },
    {
      State: "Karnataka",
      District: "CHITRADURGA",
      Court: "Court Complex Holalkere",
    },
    {
      State: "Karnataka",
      District: "CHITRADURGA",
      Court: "District Court Complex-Chitradurga",
    },
    {
      State: "Karnataka",
      District: "DAKSHINA KANNADA",
      Court: "Court complex - Mudbidri",
    },
    {
      State: "Karnataka",
      District: "DAKSHINA KANNADA",
      Court: "Court Complex-Belthanagady",
    },
    {
      State: "Karnataka",
      District: "DAKSHINA KANNADA",
      Court: "District Court Complex-Dakshina Kannada",
    },
    {
      State: "Karnataka",
      District: "DAKSHINA KANNADA",
      Court: "Court Complex- Bantwal",
    },
    {
      State: "Karnataka",
      District: "DAKSHINA KANNADA",
      Court: "Court Complex-Puttur",
    },
    {
      State: "Karnataka",
      District: "DAKSHINA KANNADA",
      Court: "Court Complex-Sullia",
    },
    {
      State: "Karnataka",
      District: "DAVANGERE",
      Court: "Court Complex- Channagiri",
    },
    {
      State: "Karnataka",
      District: "DAVANGERE",
      Court: "Court Complex- Harihara",
    },
    {
      State: "Karnataka",
      District: "DAVANGERE",
      Court: "Court Complex- Jagalur",
    },
    {
      State: "Karnataka",
      District: "DAVANGERE",
      Court: "District Court Complex-Davanagere",
    },
    {
      State: "Karnataka",
      District: "DAVANGERE",
      Court: "Court Complex-Honnali",
    },
    {
      State: "Karnataka",
      District: "DHARWAD",
      Court: "District Court Complex-Dharwad",
    },
    {
      State: "Karnataka",
      District: "DHARWAD",
      Court: "Court Complex-Hubli",
    },
    {
      State: "Karnataka",
      District: "DHARWAD",
      Court: "City Court Complex-Dharawd",
    },
    {
      State: "Karnataka",
      District: "DHARWAD",
      Court: "Court Complex- Kundgol",
    },
    {
      State: "Karnataka",
      District: "DHARWAD",
      Court: "Court Complex- Navalgund",
    },
    {
      State: "Karnataka",
      District: "DHARWAD",
      Court: "Court Complex- Kalaghatgi",
    },
    {
      State: "Karnataka",
      District: "GADAG",
      Court: "Court Complex- Nargund",
    },
    {
      State: "Karnataka",
      District: "GADAG",
      Court: "District Court Complex-Gadag",
    },
    {
      State: "Karnataka",
      District: "GADAG",
      Court: "Court Complex mundargi",
    },
    {
      State: "Karnataka",
      District: "GADAG",
      Court: "Court Complex-Lakshmeshwar",
    },
    {
      State: "Karnataka",
      District: "GADAG",
      Court: "Court Complex-Ron",
    },
    {
      State: "Karnataka",
      District: "HASSAN",
      Court: "Court Complex-Belur",
    },
    {
      State: "Karnataka",
      District: "HASSAN",
      Court: "Court Complex-Channarayapatna",
    },
    {
      State: "Karnataka",
      District: "HASSAN",
      Court: "Court Complex-Holenarasipura",
    },
    {
      State: "Karnataka",
      District: "HASSAN",
      Court: "Court Complex-Arakalagudu",
    },
    {
      State: "Karnataka",
      District: "HASSAN",
      Court: "District Court Complex-Hassan",
    },
    {
      State: "Karnataka",
      District: "HASSAN",
      Court: "Court Complex-Alur",
    },
    {
      State: "Karnataka",
      District: "HASSAN",
      Court: "Court Complex-Arasikere",
    },
    {
      State: "Karnataka",
      District: "HASSAN",
      Court: "Court Complex- Sakaleshpur",
    },
    {
      State: "Karnataka",
      District: "HAVERI",
      Court: "Court Complex- Hanagal",
    },
    {
      State: "Karnataka",
      District: "HAVERI",
      Court: "District Court Complex-Haveri",
    },
    {
      State: "Karnataka",
      District: "HAVERI",
      Court: "Court Complex- Savanur",
    },
    {
      State: "Karnataka",
      District: "HAVERI",
      Court: "Court Complex- Shiggaon",
    },
    {
      State: "Karnataka",
      District: "HAVERI",
      Court: "Court Complex- Ranebennur",
    },
    {
      State: "Karnataka",
      District: "HAVERI",
      Court: "Court Complex- Hirekerur",
    },
    {
      State: "Karnataka",
      District: "HAVERI",
      Court: "Court Complex- Byadgi",
    },
    {
      State: "Karnataka",
      District: "KALABURAGI",
      Court: "Court Complex-Aland",
    },
    {
      State: "Karnataka",
      District: "KALABURAGI",
      Court: "District Court Complex-Kalaburagi",
    },
    {
      State: "Karnataka",
      District: "KALABURAGI",
      Court: "Court Complex-Chincholi",
    },
    {
      State: "Karnataka",
      District: "KALABURAGI",
      Court: "Court Complex-Sedam",
    },
    {
      State: "Karnataka",
      District: "KALABURAGI",
      Court: "Court Complex-Chittapur",
    },
    {
      State: "Karnataka",
      District: "KALABURAGI",
      Court: "Court Complex-Jevargi",
    },
    {
      State: "Karnataka",
      District: "KALABURAGI",
      Court: "Court Complex- Afzalpur",
    },
    {
      State: "Karnataka",
      District: "KALABURAGI",
      Court: "Court Complex Shahabad",
    },
    {
      State: "Karnataka",
      District: "KODAGU",
      Court: "COURT COMPLEX KUSHALNAGAR",
    },
    {
      State: "Karnataka",
      District: "KODAGU",
      Court: "Court Complex Ponnampet",
    },
    {
      State: "Karnataka",
      District: "KODAGU",
      Court: "Court Complex- Virajpet",
    },
    {
      State: "Karnataka",
      District: "KODAGU",
      Court: "District Court Complex-Kodagu",
    },
    {
      State: "Karnataka",
      District: "KODAGU",
      Court: "Court Complex- Somwarpet",
    },
    {
      State: "Karnataka",
      District: "KOLAR",
      Court: "Court Complex-Bangarpet",
    },
    {
      State: "Karnataka",
      District: "KOLAR",
      Court: "Court Complex-Mulbagal",
    },
    {
      State: "Karnataka",
      District: "KOLAR",
      Court: "Court Complex-KGF",
    },
    {
      State: "Karnataka",
      District: "KOLAR",
      Court: "Court Complex-Malur",
    },
    {
      State: "Karnataka",
      District: "KOLAR",
      Court: "Court Complex-Srinivaspur",
    },
    {
      State: "Karnataka",
      District: "KOLAR",
      Court: "District Court Complex-Kolar",
    },
    {
      State: "Karnataka",
      District: "KOPPAL",
      Court: "Court Complex-Yelbarga",
    },
    {
      State: "Karnataka",
      District: "KOPPAL",
      Court: "District Court Complex-Koppal",
    },
    {
      State: "Karnataka",
      District: "KOPPAL",
      Court: "Court Complex-Kushtagi",
    },
    {
      State: "Karnataka",
      District: "KOPPAL",
      Court: "Court Complex-Gangavathi",
    },
    {
      State: "Karnataka",
      District: "MANDYA",
      Court: "Court Complex- Nagamangala",
    },
    {
      State: "Karnataka",
      District: "MANDYA",
      Court: "District Court Complex-Mandya",
    },
    {
      State: "Karnataka",
      District: "MANDYA",
      Court: "Court Complex- Maddur",
    },
    {
      State: "Karnataka",
      District: "MANDYA",
      Court: "Court Complex-Pandavapura",
    },
    {
      State: "Karnataka",
      District: "MANDYA",
      Court: "Court Complex-Malavalli",
    },
    {
      State: "Karnataka",
      District: "MANDYA",
      Court: "Court Complex- Srirangapatna",
    },
    {
      State: "Karnataka",
      District: "MANDYA",
      Court: "Court Complex- Krishnarajapet",
    },
    {
      State: "Karnataka",
      District: "MYSURU",
      Court: "Court Complex- Nanjangud",
    },
    {
      State: "Karnataka",
      District: "MYSURU",
      Court: "Law Court Complex, Mysuru",
    },
    {
      State: "Karnataka",
      District: "MYSURU",
      Court: "Court Complex- KrishnarajaNagar",
    },
    {
      State: "Karnataka",
      District: "MYSURU",
      Court: "Court Complex- T.Narasipura",
    },
    {
      State: "Karnataka",
      District: "MYSURU",
      Court: "Court Complex- Hunsur",
    },
    {
      State: "Karnataka",
      District: "MYSURU",
      Court: "Double Road Court Complex, Mysuru",
    },
    {
      State: "Karnataka",
      District: "MYSURU",
      Court: "Court Complex- Heggadadevankote",
    },
    {
      State: "Karnataka",
      District: "MYSURU",
      Court: "ADR COURT COMPLEX, MYSURU",
    },
    {
      State: "Karnataka",
      District: "MYSURU",
      Court: "Court Complex- Periyapatna",
    },
    {
      State: "Karnataka",
      District: "RAICHUR",
      Court: "Court Complex-Manvi",
    },
    {
      State: "Karnataka",
      District: "RAICHUR",
      Court: "Court Complex-Devadurga",
    },
    {
      State: "Karnataka",
      District: "RAICHUR",
      Court: "Court Complex-Sindhanur",
    },
    {
      State: "Karnataka",
      District: "RAICHUR",
      Court: "Court Complex-Lingasugur",
    },
    {
      State: "Karnataka",
      District: "RAICHUR",
      Court: "District Court Complex-Raichur",
    },
    {
      State: "Karnataka",
      District: "RAMANAGARA",
      Court: "Court Complex- Chennapatna",
    },
    {
      State: "Karnataka",
      District: "RAMANAGARA",
      Court: "District Court Complex-Ramanagar",
    },
    {
      State: "Karnataka",
      District: "RAMANAGARA",
      Court: "Court Complex- Kanakapura",
    },
    {
      State: "Karnataka",
      District: "RAMANAGARA",
      Court: "Court Complex- Magadi",
    },
    {
      State: "Karnataka",
      District: "SHIVAMOGGA",
      Court: "Court Complex-Hosanagar",
    },
    {
      State: "Karnataka",
      District: "SHIVAMOGGA",
      Court: "Court Complex- Sorab",
    },
    {
      State: "Karnataka",
      District: "SHIVAMOGGA",
      Court: "Court Complex-Sagar",
    },
    {
      State: "Karnataka",
      District: "SHIVAMOGGA",
      Court: "Court Complex-Bhadravati",
    },
    {
      State: "Karnataka",
      District: "SHIVAMOGGA",
      Court: "Court Complex- Thirthahalli",
    },
    {
      State: "Karnataka",
      District: "SHIVAMOGGA",
      Court: "Court Complex- Shikaripura",
    },
    {
      State: "Karnataka",
      District: "SHIVAMOGGA",
      Court: "District Court Complex-Shimoga",
    },
    {
      State: "Karnataka",
      District: "TUMAKURU",
      Court: "Court Complex- Gubbi",
    },
    {
      State: "Karnataka",
      District: "TUMAKURU",
      Court: "Court Complex- Koratagere",
    },
    {
      State: "Karnataka",
      District: "TUMAKURU",
      Court: "District Court Complex-Tumakuru",
    },
    {
      State: "Karnataka",
      District: "TUMAKURU",
      Court: "Court Complex- Madhugiri",
    },
    {
      State: "Karnataka",
      District: "TUMAKURU",
      Court: "Court Complex- C.N.Halli",
    },
    {
      State: "Karnataka",
      District: "TUMAKURU",
      Court: "Court Complex- Kunigal",
    },
    {
      State: "Karnataka",
      District: "TUMAKURU",
      Court: "Court Complex- Turuvekere",
    },
    {
      State: "Karnataka",
      District: "TUMAKURU",
      Court: "Court Complex- Pavagada",
    },
    {
      State: "Karnataka",
      District: "TUMAKURU",
      Court: "Court Complex- Sira",
    },
    {
      State: "Karnataka",
      District: "TUMAKURU",
      Court: "Court Complex Tiptur",
    },
    {
      State: "Karnataka",
      District: "UDUPI",
      Court: "District Court Complex-Udupi",
    },
    {
      State: "Karnataka",
      District: "UDUPI",
      Court: "Court Complex- Karkala",
    },
    {
      State: "Karnataka",
      District: "UDUPI",
      Court: "Court Complex- Kundapur",
    },
    {
      State: "Karnataka",
      District: "UDUPI",
      Court: "COURT COMPLEX -BAINDURU",
    },
    {
      State: "Karnataka",
      District: "UTTARA KANNADA",
      Court: "Court Complex- Honnavar",
    },
    {
      State: "Karnataka",
      District: "UTTARA KANNADA",
      Court: "Court Complex- Dandeli",
    },
    {
      State: "Karnataka",
      District: "UTTARA KANNADA",
      Court: "Court Complex- Siddapur",
    },
    {
      State: "Karnataka",
      District: "UTTARA KANNADA",
      Court: "Court Complex- Haliyal",
    },
    {
      State: "Karnataka",
      District: "UTTARA KANNADA",
      Court: "Court Complex- Kumta",
    },
    {
      State: "Karnataka",
      District: "UTTARA KANNADA",
      Court: "Court Complex- Mundgod",
    },
    {
      State: "Karnataka",
      District: "UTTARA KANNADA",
      Court: "Court Complex- Sirsi",
    },
    {
      State: "Karnataka",
      District: "UTTARA KANNADA",
      Court: "Court Complex- Yellapur",
    },
    {
      State: "Karnataka",
      District: "UTTARA KANNADA",
      Court: "Court Complex- Bhatkal",
    },
    {
      State: "Karnataka",
      District: "UTTARA KANNADA",
      Court: "JMFC Court Complex-Karwar",
    },
    {
      State: "Karnataka",
      District: "UTTARA KANNADA",
      Court: "Court Complex- Ankola",
    },
    {
      State: "Karnataka",
      District: "UTTARA KANNADA",
      Court: "District Court Complex-Karwar",
    },
    {
      State: "Karnataka",
      District: "VIJAYAPURA",
      Court: "Court Complex- Indi",
    },
    {
      State: "Karnataka",
      District: "VIJAYAPURA",
      Court: "Court complex muddebihal",
    },
    {
      State: "Karnataka",
      District: "VIJAYAPURA",
      Court: "District Court Complex-Vijayapur",
    },
    {
      State: "Karnataka",
      District: "VIJAYAPURA",
      Court: "Court Complex Bagewadi",
    },
    {
      State: "Karnataka",
      District: "VIJAYAPURA",
      Court: "Court Complex- Sindagi",
    },
    {
      State: "Karnataka",
      District: "YADGIR",
      Court: "District Court Complex-Yadgir",
    },
    {
      State: "Karnataka",
      District: "YADGIR",
      Court: "Court Complex-Shahapur",
    },
    {
      State: "Karnataka",
      District: "YADGIR",
      Court: "Court Complex Shorapur",
    },
    {
      State: "Kerala",
      District: "Alappuzha",
      Court: "COURT COMPLEX,RAMANKARI",
    },
    {
      State: "Kerala",
      District: "Alappuzha",
      Court: "Family Court, Alappuzha",
    },
    {
      State: "Kerala",
      District: "Alappuzha",
      Court: "Court Complex, Kayamkulam",
    },
    {
      State: "Kerala",
      District: "Alappuzha",
      Court: "Court Complex, CHERTHALA",
    },
    {
      State: "Kerala",
      District: "Alappuzha",
      Court: "Court Complex, MAVELIKARA",
    },
    {
      State: "Kerala",
      District: "Alappuzha",
      Court: "Gram Nyayalaya, Ambalappuzha",
    },
    {
      State: "Kerala",
      District: "Alappuzha",
      Court: "JUDICIAL FIRST CLASS MAGISTRATE, Alappuzha",
    },
    {
      State: "Kerala",
      District: "Alappuzha",
      Court: "Family Court, Mavelikkara",
    },
    {
      State: "Kerala",
      District: "Alappuzha",
      Court: "DISTRICT COURT, Alappuzha",
    },
    {
      State: "Kerala",
      District: "Alappuzha",
      Court: "COURT of THE CHIEF JUDICIAL MAGiSTRATE, Alapuzhha",
    },
    {
      State: "Kerala",
      District: "Alappuzha",
      Court: "Court Complex, Haripad",
    },
    {
      State: "Kerala",
      District: "Alappuzha",
      Court: "JFCM, Ambalappuzha",
    },
    {
      State: "Kerala",
      District: "Alappuzha",
      Court: "Gram Nyayalaya, Kanjikuzhi",
    },
    {
      State: "Kerala",
      District: "Alappuzha",
      Court: "Court Complex, Chengannur",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "COURT COMPLEX,KOLENCHERRY",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "COURT COMPLEX,PERUMBAVOOR",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "JFCM Njarakal",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "DISTRICT COURT ANNEX, KALOOR,",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "Chottanikara Court Complex",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "JFCM Court,Kakkanad",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "JFCM, Angamaly",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "Court complex Tripunithura",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "Court complex, Mattanchery",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "JFCM Court Piravom",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "DISTRICT COURT ERNAKULAM",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "COURT COMPLEX NORTH PARAVUR",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "Atrocities Trial Court",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "COURT COMPLEX MUVATTUPUZHA",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "COURT COMPLEX,PANAMPILLY NAGAR",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "COURT COMPLEX ,KOCHI",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "COURT COMPLEX KOTHAMANGALAM",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "COURT COMPLEX ,ALUVA",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "JFCM Court Complex, Kalamassery",
    },
    {
      State: "Kerala",
      District: "Ernakulam",
      Court: "JFCM3 Court Complex, North Paravur",
    },
    {
      State: "Kerala",
      District: "Idukki",
      Court: "COURT COMPLEX KATTAPPANA",
    },
    {
      State: "Kerala",
      District: "Idukki",
      Court: "DISTRICT COURT MUTTAM, Idukki, Thoduphuzha",
    },
    {
      State: "Kerala",
      District: "Idukki",
      Court: "COURT COMPLEX DEVIKULAM",
    },
    {
      State: "Kerala",
      District: "Idukki",
      Court: "Nyayalaya Nedumkandam",
    },
    {
      State: "Kerala",
      District: "Idukki",
      Court: "COURT COMPLEX, PEERUMEDU",
    },
    {
      State: "Kerala",
      District: "Idukki",
      Court: "COURT COMPLEX IDUKKI",
    },
    {
      State: "Kerala",
      District: "Idukki",
      Court: "FAMILY COURT THODUPUZHA",
    },
    {
      State: "Kerala",
      District: "Idukki",
      Court: "Nyayalaya Azhutha, Peermade",
    },
    {
      State: "Kerala",
      District: "Idukki",
      Court: "Family Court Kattappana",
    },
    {
      State: "Kerala",
      District: "Idukki",
      Court: "COURT COMPLEX ADIMALY",
    },
    {
      State: "Kerala",
      District: "Idukki",
      Court: "COURT COMPLEX, NEDUMKANDAM",
    },
    {
      State: "Kerala",
      District: "Kannur",
      Court: "Gramnyayalaya Irikkur",
    },
    {
      State: "Kerala",
      District: "Kannur",
      Court: "JFCM, Mattannur",
    },
    {
      State: "Kerala",
      District: "Kannur",
      Court: "DISTRICT COURT THALASSERRY, Kannur",
    },
    {
      State: "Kerala",
      District: "Kannur",
      Court: "Court Complex Thaliparambu",
    },
    {
      State: "Kerala",
      District: "Kannur",
      Court: "COURT COMPLEX KANNUR",
    },
    {
      State: "Kerala",
      District: "Kannur",
      Court: "Court Complex, Sub Court, Payyannur",
    },
    {
      State: "Kerala",
      District: "Kannur",
      Court: "Family court, Thalassery",
    },
    {
      State: "Kerala",
      District: "Kannur",
      Court: "Court Complex Kuthuparambu",
    },
    {
      State: "Kerala",
      District: "Kannur",
      Court: "Gramanyayalaya , Payam",
    },
    {
      State: "Kerala",
      District: "Kannur",
      Court: "Gramanyayalaya , Chokli",
    },
    {
      State: "Kerala",
      District: "Kasaragod",
      Court: "DISTRICT COURT KASARGOD",
    },
    {
      State: "Kerala",
      District: "Kasaragod",
      Court: "Family Court Kasargod",
    },
    {
      State: "Kerala",
      District: "Kasaragod",
      Court: "Court Complex, Hosdurg",
    },
    {
      State: "Kerala",
      District: "Kasaragod",
      Court: "Gramnyayalaya Parappa",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "JFMC Temp Kadakkal",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "Court Center ,Paravur",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "Court Centre JFMC Temp Chavara",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "JFMC Temp Kollam",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "Court Complex Punalur",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "Gram Nyayalaya, Chittumala",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "Spl Court for SC ST POA Act cases",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "MACT, Kollam",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "Munsiff Magistrate Court Paravoor",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "Court Complex, Karunagapally",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "DISTRICT COURT KOLLAM",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "WAKF TRIBUNAL Family Court KOLLAM",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "Munsiff Magistrate Sasthamkotta",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "Court Centre, Chadayamangalam",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "Court Centre, Pathanapuram",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "Court complex,Kottarakkara",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "Court Centre, Chavara",
    },
    {
      State: "Kerala",
      District: "Kollam",
      Court: "FTSC Centre Kollam",
    },
    {
      State: "Kerala",
      District: "Kottayam",
      Court: "COURT COMPLEX KANJIRAPPALLY",
    },
    {
      State: "Kerala",
      District: "Kottayam",
      Court: "COURT COMPLEX, PALA",
    },
    {
      State: "Kerala",
      District: "Kottayam",
      Court: "DISTRICT COURT KOTTAYAM",
    },
    {
      State: "Kerala",
      District: "Kottayam",
      Court: "Gram Nyayalaya Pampady",
    },
    {
      State: "Kerala",
      District: "Kottayam",
      Court: "COURT COMPLEX ETTUMANOOR",
    },
    {
      State: "Kerala",
      District: "Kottayam",
      Court: "MUNSIFF COURT CHANGANASSERY",
    },
    {
      State: "Kerala",
      District: "Kottayam",
      Court: "FAMILY COURT,ETTUMANOOR",
    },
    {
      State: "Kerala",
      District: "Kottayam",
      Court: "JFCM COURT CHANGANASSERRY",
    },
    {
      State: "Kerala",
      District: "Kottayam",
      Court: "COURT COMPLEX VAIKOM",
    },
    {
      State: "Kerala",
      District: "Kottayam",
      Court: "MUNSIFF MAGISTRATE ERATTUPETTA",
    },
    {
      State: "Kerala",
      District: "Kottayam",
      Court: "Gram Nyayalaya Vaikom",
    },
    {
      State: "Kerala",
      District: "Kottayam",
      Court: "FAMILY COURT,PALA",
    },
    {
      State: "Kerala",
      District: "Kozhikode",
      Court: "Court Complex, JFCMC, Kunnamangalam",
    },
    {
      State: "Kerala",
      District: "Kozhikode",
      Court: "DISTRICT COURT, KOZHIKODE",
    },
    {
      State: "Kerala",
      District: "Kozhikode",
      Court: "Family Court, Vatakara",
    },
    {
      State: "Kerala",
      District: "Kozhikode",
      Court: "COURT COMPLEX KOYILANDY",
    },
    {
      State: "Kerala",
      District: "Kozhikode",
      Court: "FAMILY COURT KOZHIKODE",
    },
    {
      State: "Kerala",
      District: "Kozhikode",
      Court: "MUSIFF COURT, JFCM I & II Perambara, Kozhikode",
    },
    {
      State: "Kerala",
      District: "Kozhikode",
      Court: "MUNSIFF-MAGISTRATE COURT, Payoli, Kozhikode",
    },
    {
      State: "Kerala",
      District: "Kozhikode",
      Court: "MUNSIFF-MAGISTRATE COURT, Nadapuram, Kozhikode",
    },
    {
      State: "Kerala",
      District: "Kozhikode",
      Court: "Court complex Thamarassery",
    },
    {
      State: "Kerala",
      District: "Kozhikode",
      Court: "COURT FOR THE Trial OF MARAD CA",
    },
    {
      State: "Kerala",
      District: "Kozhikode",
      Court: "COURT COMPLEX, Addl DC, VATAKARA",
    },
    {
      State: "Kerala",
      District: "Kozhikode",
      Court: "Grama Nyayalaya Kunnummal",
    },
    {
      State: "Kerala",
      District: "Kozhikode",
      Court: "Grama Nyayalaya Koduvally",
    },
    {
      State: "Kerala",
      District: "Lakshadweep",
      Court: "MUNSIFF COURT ANDROTH, LAKSHADWEEP",
    },
    {
      State: "Kerala",
      District: "Lakshadweep",
      Court: "DISTRICT COURT, LAKSHADWEEP",
    },
    {
      State: "Kerala",
      District: "Malappuram",
      Court: "Court complex, Sub Court, Tirur",
    },
    {
      State: "Kerala",
      District: "Malappuram",
      Court: "Family Court, manjeri Malappuram",
    },
    {
      State: "Kerala",
      District: "Malappuram",
      Court: "MOTOR ACCIDENT CLAIMS TRIBUNAL, Tirur",
    },
    {
      State: "Kerala",
      District: "Malappuram",
      Court: "JFCMC-Forest, Manjeri",
    },
    {
      State: "Kerala",
      District: "Malappuram",
      Court: "JUDICIAL First CLASS MAGISTRATE, Mallapuram",
    },
    {
      State: "Kerala",
      District: "Malappuram",
      Court: "JFCM NILAMBUR, manjeri",
    },
    {
      State: "Kerala",
      District: "Malappuram",
      Court: "Court complex, Parappanangadi",
    },
    {
      State: "Kerala",
      District: "Malappuram",
      Court: "Court Complex, perinthalmanna",
    },
    {
      State: "Kerala",
      District: "Malappuram",
      Court: "SCST Court,Manjeri",
    },
    {
      State: "Kerala",
      District: "Malappuram",
      Court: "MUNSIFF MAGISTRATE COURT PONNANI, manjeri",
    },
    {
      State: "Kerala",
      District: "Malappuram",
      Court: "DISTRICT COURT MANJERI",
    },
    {
      State: "Kerala",
      District: "Malappuram",
      Court: "Grama Nyayalaya Ponnani Edappal",
    },
    {
      State: "Kerala",
      District: "Malappuram",
      Court: "Grama Nyayalaya Pulamanthole",
    },
    {
      State: "Kerala",
      District: "Palakkad",
      Court: "COURT COMPLEX ,ALATHUR",
    },
    {
      State: "Kerala",
      District: "Palakkad",
      Court: "Gram Nyayalaya Thenkurissi",
    },
    {
      State: "Kerala",
      District: "Palakkad",
      Court: "Gram Nyayalaya Pudupariyaram",
    },
    {
      State: "Kerala",
      District: "Palakkad",
      Court: "FAMILY COURT OTTAPALAM",
    },
    {
      State: "Kerala",
      District: "Palakkad",
      Court: "COURT COMPLEX,CHITTUR",
    },
    {
      State: "Kerala",
      District: "Palakkad",
      Court: "JUDICIAL First CLASS MAGISTRATE, Ottapalam",
    },
    {
      State: "Kerala",
      District: "Palakkad",
      Court: "MUNSIFF MAGISTRATE COURT ,PATTAMBI",
    },
    {
      State: "Kerala",
      District: "Palakkad",
      Court: "MUNSIFF MAGISTRATE COURT,MANNARKKAD",
    },
    {
      State: "Kerala",
      District: "Palakkad",
      Court: "FAMILY COURT,PALAKKAD",
    },
    {
      State: "Kerala",
      District: "Palakkad",
      Court: "COURT COMPLEX, MACT, OTTAPALAM",
    },
    {
      State: "Kerala",
      District: "Palakkad",
      Court: "DISTRICT COURT PALAKKAD",
    },
    {
      State: "Kerala",
      District: "Palakkad",
      Court: "Gram Nyayalaya Sreekrishnapuram",
    },
    {
      State: "Kerala",
      District: "Pathanamthitta",
      Court: "FAMILY COURT THIRUVALLA",
    },
    {
      State: "Kerala",
      District: "Pathanamthitta",
      Court: "Family Court Pathanamthitta",
    },
    {
      State: "Kerala",
      District: "Pathanamthitta",
      Court: "DISTRICT COURT PATHANAMTHITTA",
    },
    {
      State: "Kerala",
      District: "Pathanamthitta",
      Court: "COURT COMPLEX, Munsiff Court, ADOOR",
    },
    {
      State: "Kerala",
      District: "Pathanamthitta",
      Court: "COURT COMPLEX, THIRUVALLA",
    },
    {
      State: "Kerala",
      District: "Pathanamthitta",
      Court: "Judicial First class MAJISTRATE COURT RANNI",
    },
    {
      State: "Kerala",
      District: "Pathanamthitta",
      Court: "MUNSIFF COURT RANNI",
    },
    {
      State: "Kerala",
      District: "Pathanamthitta",
      Court: "GRAM NYAYALAYA PANDALAM",
    },
    {
      State: "Kerala",
      District: "Pathanamthitta",
      Court: "Gramnyayalaya Ranni",
    },
    {
      State: "Kerala",
      District: "Thiruvananthapuram",
      Court: "COURT COMPLEX VANCHIYOOR",
    },
    {
      State: "Kerala",
      District: "Thiruvananthapuram",
      Court: "JFMC KATTAKKADA",
    },
    {
      State: "Kerala",
      District: "Thiruvananthapuram",
      Court: "COURT COMPLEX, VARKALA",
    },
    {
      State: "Kerala",
      District: "Thiruvananthapuram",
      Court: "COURT COMPLEX NEDUMANGAD",
    },
    {
      State: "Kerala",
      District: "Thiruvananthapuram",
      Court: "COURT COMPLEX ATTINGAL",
    },
    {
      State: "Kerala",
      District: "Thiruvananthapuram",
      Court: "COURT COMPLEX NEYYATTINKARA",
    },
    {
      State: "Kerala",
      District: "Thiruvananthapuram",
      Court: "Grama Nyayalaya Parasala",
    },
    {
      State: "Kerala",
      District: "Thiruvananthapuram",
      Court: "JFMC (FOREST) Nedumangad",
    },
    {
      State: "Kerala",
      District: "Thrissur",
      Court: "COURT COMPLEX, MACT, IRINJALAKKUDA",
    },
    {
      State: "Kerala",
      District: "Thrissur",
      Court: "COURT COMPLEX ,CHAVAKKAD",
    },
    {
      State: "Kerala",
      District: "Thrissur",
      Court: "COURT COMPLEX.KODUNGALLUR",
    },
    {
      State: "Kerala",
      District: "Thrissur",
      Court: "Court complex Chalakudy",
    },
    {
      State: "Kerala",
      District: "Thrissur",
      Court: "DISTRICT COURT THRISSUR",
    },
    {
      State: "Kerala",
      District: "Thrissur",
      Court: "Court Complex, Kunnamkulam",
    },
    {
      State: "Kerala",
      District: "Thrissur",
      Court: "JFCM, Irinijalakuda, thrissur",
    },
    {
      State: "Kerala",
      District: "Thrissur",
      Court: "COURT COMPLEX , Munsiff Court, WADAKKANCHERY",
    },
    {
      State: "Kerala",
      District: "Thrissur",
      Court: "Family Court Complex, Thrissur",
    },
    {
      State: "Kerala",
      District: "Thrissur",
      Court: "Gramanyayalaya, Pazhayannur",
    },
    {
      State: "Kerala",
      District: "Thrissur",
      Court: "Gramanyayalaya, Mathilakam",
    },
    {
      State: "Kerala",
      District: "Wayanad",
      Court: "DISTRICT COURT KALPETTA",
    },
    {
      State: "Kerala",
      District: "Wayanad",
      Court: "Gram Nyayalaya, Kalpetta",
    },
    {
      State: "Kerala",
      District: "Wayanad",
      Court: "COURT COMPLEX MANANTHAVADY",
    },
    {
      State: "Kerala",
      District: "Wayanad",
      Court: "FAMILY COURT KALPETTA",
    },
    {
      State: "Kerala",
      District: "Wayanad",
      Court: "SUB COURT SULTHAN BATHERY",
    },
    {
      State: "Ladakh",
      District: "Kargil",
      Court: "District Court, Kargil",
    },
    {
      State: "Ladakh",
      District: "Kargil",
      Court: "Munisff Court Zanskar",
    },
    {
      State: "Ladakh",
      District: "Kargil",
      Court: "Munsiff Court Sanku",
    },
    {
      State: "Ladakh",
      District: "Leh",
      Court: "District Court ,Leh",
    },
    {
      State: "Ladakh",
      District: "Leh",
      Court: "Munsiff Court Nobra",
    },
    {
      State: "Madhya Pradesh",
      District: "Alirajpur",
      Court: "Civil Court Jobat",
    },
    {
      State: "Madhya Pradesh",
      District: "Alirajpur",
      Court: "District and Session Court",
    },
    {
      State: "Madhya Pradesh",
      District: "Alirajpur",
      Court: "Family Court Alirajpur",
    },
    {
      State: "Madhya Pradesh",
      District: "Anuppur",
      Court: "Civil Court, Rajendragram, MP",
    },
    {
      State: "Madhya Pradesh",
      District: "Anuppur",
      Court: "Civil Court ,Kotma",
    },
    {
      State: "Madhya Pradesh",
      District: "Anuppur",
      Court: "District Court Anuppur MP",
    },
    {
      State: "Madhya Pradesh",
      District: "Anuppur",
      Court: "Family Court District Anuppur",
    },
    {
      State: "Madhya Pradesh",
      District: "Ashoknagr",
      Court: "civil court mugauli",
    },
    {
      State: "Madhya Pradesh",
      District: "Ashoknagr",
      Court: "civil court chanderi",
    },
    {
      State: "Madhya Pradesh",
      District: "Ashoknagr",
      Court: "District Court AshokNagar",
    },
    {
      State: "Madhya Pradesh",
      District: "Ashoknagr",
      Court: "Link Court Eshagarh",
    },
    {
      State: "Madhya Pradesh",
      District: "Balaghat",
      Court: "Civil Court, Katangi",
    },
    {
      State: "Madhya Pradesh",
      District: "Balaghat",
      Court:
        "District & Sessions Court, BalaghatDistrict & Sessions Court,Balaghat",
    },
    {
      State: "Madhya Pradesh",
      District: "Balaghat",
      Court:
        "Civil Court, WaraseoniCivil court ,Waraseoni distt. balaghat m.p.",
    },
    {
      State: "Madhya Pradesh",
      District: "Balaghat",
      Court: "Civil Court, BaiharCivil Court, Baihar Distt.-Balaghat, M.P.",
    },
    {
      State: "Madhya Pradesh",
      District: "Balaghat",
      Court: "Civil Court Lanji Balaghat",
    },
    {
      State: "Madhya Pradesh",
      District: "Barwani",
      Court: "Civil Court , Sendhwa",
    },
    {
      State: "Madhya Pradesh",
      District: "Barwani",
      Court: "Civil Court ,Rajpur",
    },
    {
      State: "Madhya Pradesh",
      District: "Barwani",
      Court: "Civil Court ,Khetiya",
    },
    {
      State: "Madhya Pradesh",
      District: "Barwani",
      Court: "District & Sessions Court,Barwani",
    },
    {
      State: "Madhya Pradesh",
      District: "Barwani",
      Court: "Civil Court, Anjad",
    },
    {
      State: "Madhya Pradesh",
      District: "Betul",
      Court:
        "District & Sessions Court,BetulDistrict & Sessions Court, Near Bus Stand, Betul",
    },
    {
      State: "Madhya Pradesh",
      District: "Betul",
      Court: "Civil Court,MultaiCivil Court, Near Bus Stand, Multai",
    },
    {
      State: "Madhya Pradesh",
      District: "Betul",
      Court: "Civil Court, BhainsdehiCivil Court, Bhainsdehi,",
    },
    {
      State: "Madhya Pradesh",
      District: "Betul",
      Court: "Civil Court, Amla, District Betul",
    },
    {
      State: "Madhya Pradesh",
      District: "Bhind",
      Court: "District & Sessions Court BhindLahar Road Bhind",
    },
    {
      State: "Madhya Pradesh",
      District: "Bhind",
      Court: "Civil Court, GohadKila Gohad Distt. Bhind MP",
    },
    {
      State: "Madhya Pradesh",
      District: "Bhind",
      Court: "Civil Court, MehgaonGandhi Road, Mehgaon Distt. Bhind MP",
    },
    {
      State: "Madhya Pradesh",
      District: "Bhind",
      Court: "Civil Court, LaharCourt Road, Lahar Distt. Bhind MP",
    },
    {
      State: "Madhya Pradesh",
      District: "Bhopal",
      Court: "Civil Court, Berasia",
    },
    {
      State: "Madhya Pradesh",
      District: "Bhopal",
      Court: "District & sessions Court, BhopalArera Hills ,Bhopal",
    },
    {
      State: "Madhya Pradesh",
      District: "Bhopal",
      Court: "Family Court District Bhopal",
    },
    {
      State: "Madhya Pradesh",
      District: "Burhanpur",
      Court: "District and Session Court",
    },
    {
      State: "Madhya Pradesh",
      District: "Burhanpur",
      Court: "Civil Court Nepanagar",
    },
    {
      State: "Madhya Pradesh",
      District: "Chhatarpur",
      Court: "Civil Court, Bada Malehra",
    },
    {
      State: "Madhya Pradesh",
      District: "Chhatarpur",
      Court:
        "District & Sessions Court, Chhatarpurnear Chhatrasal Chauraha, Chhatarpur m.p.",
    },
    {
      State: "Madhya Pradesh",
      District: "Chhatarpur",
      Court: "Civil Court, LaundiCivil Court, maharajpur road, Laundi",
    },
    {
      State: "Madhya Pradesh",
      District: "Chhatarpur",
      Court: "Civil Court, Rajnagar",
    },
    {
      State: "Madhya Pradesh",
      District: "Chhatarpur",
      Court: "Civil court, BijawarCivil court,Main road,Bijawar",
    },
    {
      State: "Madhya Pradesh",
      District: "Chhatarpur",
      Court:
        "Civil court ,NowgaonCivil court,behind polytechnic collage,Nowgaon",
    },
    {
      State: "Madhya Pradesh",
      District: "Chhindwara",
      Court: "Civil Court, SausarCivil Court, Sausar",
    },
    {
      State: "Madhya Pradesh",
      District: "Chhindwara",
      Court: "Civil Court, AmarwaraCivil Court, Amarwara",
    },
    {
      State: "Madhya Pradesh",
      District: "Chhindwara",
      Court: "Civil Court, Junnardeo",
    },
    {
      State: "Madhya Pradesh",
      District: "Chhindwara",
      Court: "Civil Court, ParasiyaCivil Court, Parasiya",
    },
    {
      State: "Madhya Pradesh",
      District: "Chhindwara",
      Court: "Civil Court, ChoraiCivil Court, Chorai",
    },
    {
      State: "Madhya Pradesh",
      District: "Chhindwara",
      Court: "Civil Court, PandurnaCivil Court, Pandurna",
    },
    {
      State: "Madhya Pradesh",
      District: "Chhindwara",
      Court: "Civil Court Harrai Dist Chhindwara",
    },
    {
      State: "Madhya Pradesh",
      District: "Chhindwara",
      Court:
        "District & Sessions Court, ChhindwaraOffice of the District & Sessions Court, Chhindwara M.P. PIN- 480001",
    },
    {
      State: "Madhya Pradesh",
      District: "Chhindwara",
      Court: "Link Court Tamia",
    },
    {
      State: "Madhya Pradesh",
      District: "Damoh",
      Court: "CIVIL COURT TENDUKHERA",
    },
    {
      State: "Madhya Pradesh",
      District: "Damoh",
      Court: "Civil Court,HattaCivil Court Building Patera Road Hatta MP",
    },
    {
      State: "Madhya Pradesh",
      District: "Damoh",
      Court:
        "District & Sessions Court,DamohNear Old Collectorate Complex Damoh MP PIN 470661",
    },
    {
      State: "Madhya Pradesh",
      District: "Damoh",
      Court: "Civil court, Pathariya District Damoh",
    },
    {
      State: "Madhya Pradesh",
      District: "Datia",
      Court: "Civil Court, Seodha",
    },
    {
      State: "Madhya Pradesh",
      District: "Datia",
      Court: "Civil Court, Bhander",
    },
    {
      State: "Madhya Pradesh",
      District: "Datia",
      Court:
        "District and Session Court , DatiaDistrict and Session Court , Datia",
    },
    {
      State: "Madhya Pradesh",
      District: "Dewas",
      Court: "Civil Court,BagliChapda Road,Bagli",
    },
    {
      State: "Madhya Pradesh",
      District: "Dewas",
      Court: "District & Sessions Court, DewasDistrict & Sessions Court, Dewas",
    },
    {
      State: "Madhya Pradesh",
      District: "Dewas",
      Court: "Civil Court,KhategaonNew Building,Civil Court,Khategaon",
    },
    {
      State: "Madhya Pradesh",
      District: "Dewas",
      Court: "Civil Court, Tonkkhurd",
    },
    {
      State: "Madhya Pradesh",
      District: "Dewas",
      Court: "Civil Court,KannodCivil Court,Kannod",
    },
    {
      State: "Madhya Pradesh",
      District: "Dewas",
      Court: "Civil Court,SonkatchSonkatch main road,Sonkatch",
    },
    {
      State: "Madhya Pradesh",
      District: "Dhar",
      Court:
        "District & Sessions Court, DharDistrict & Sessions Court, Collectorate campus, Dhar",
    },
    {
      State: "Madhya Pradesh",
      District: "Dhar",
      Court: "Civil Court, SardarpurCivil Court,Sardarpur",
    },
    {
      State: "Madhya Pradesh",
      District: "Dhar",
      Court: "Civil Court ,DharampuriCivil Court,Dhamnod Road ,Dharampuri",
    },
    {
      State: "Madhya Pradesh",
      District: "Dhar",
      Court: "Civil Court ,BadnawarCivil Court,Badnawar",
    },
    {
      State: "Madhya Pradesh",
      District: "Dhar",
      Court: "Civil Court ,KukshiCivil Court,Alirajpur Road, Kukshi",
    },
    {
      State: "Madhya Pradesh",
      District: "Dhar",
      Court: "Civil Court, ManawarCivil Court ,Behind Bus Stand ,Manawar",
    },
    {
      State: "Madhya Pradesh",
      District: "Dindori",
      Court: "Civil Court,DindoriCivil Court,Dindori",
    },
    {
      State: "Madhya Pradesh",
      District: "Dindori",
      Court: "Civil Court Shahpura Dist. Dindori",
    },
    {
      State: "Madhya Pradesh",
      District: "Guna",
      Court: "District & Sessions Court, GunaA.B. Road, Guna",
    },
    {
      State: "Madhya Pradesh",
      District: "Guna",
      Court: "Civil Court, RaghogarhNear Old Bus Stand, Raghogarh",
    },
    {
      State: "Madhya Pradesh",
      District: "Guna",
      Court: "Civil Court, AaronRaghogarh Road, Aaron",
    },
    {
      State: "Madhya Pradesh",
      District: "Guna",
      Court: "Civil Court, ChachodaTehsil Campus, Chachoda",
    },
    {
      State: "Madhya Pradesh",
      District: "Gwalior",
      Court:
        "District and Sessions Court,GwaliorJayendra ganj, Lashkar, Gwalior",
    },
    {
      State: "Madhya Pradesh",
      District: "Gwalior",
      Court: "Civil Court, DabraCivil Court, Dabra",
    },
    {
      State: "Madhya Pradesh",
      District: "Gwalior",
      Court: "Civil Court, BhitarwarCivil Court,Bhitarwar",
    },
    {
      State: "Madhya Pradesh",
      District: "Gwalior",
      Court: "Family court district Gwalior MP",
    },
    {
      State: "Madhya Pradesh",
      District: "Harda",
      Court: "District & Sessions Court, HardaDistrict & Sessions Court, Harda",
    },
    {
      State: "Madhya Pradesh",
      District: "Harda",
      Court: "Family Court Harda",
    },
    {
      State: "Madhya Pradesh",
      District: "Harda",
      Court: "Civil Court khirkiya",
    },
    {
      State: "Madhya Pradesh",
      District: "Harda",
      Court: "Civil Court Timarni District Harda",
    },
    {
      State: "Madhya Pradesh",
      District: "Hoshangabad",
      Court:
        "Civil Court, Seoni MalwaCivil Court, Seoni Malwa, Distt. Hoshangabad",
    },
    {
      State: "Madhya Pradesh",
      District: "Hoshangabad",
      Court: "Civil Court, PipariyaCivil Court, Pipariya District Hoshangabad",
    },
    {
      State: "Madhya Pradesh",
      District: "Hoshangabad",
      Court:
        "Civil Court, PachmarhiCivil Court, Pachmarhi District Hoshangabad",
    },
    {
      State: "Madhya Pradesh",
      District: "Hoshangabad",
      Court: "Civil Court, ItarsiCivil court, Itarsi, Distt. Hoshangabad",
    },
    {
      State: "Madhya Pradesh",
      District: "Hoshangabad",
      Court: "District & Sessions Court, HoshagabadHoshangabad",
    },
    {
      State: "Madhya Pradesh",
      District: "Hoshangabad",
      Court: "Civil Court, SohagpurCivil Court, Sohagpur District Hoshangabad",
    },
    {
      State: "Madhya Pradesh",
      District: "Indore",
      Court: "Civil Court,MhowCivil Court,Mhow",
    },
    {
      State: "Madhya Pradesh",
      District: "Indore",
      Court: "Civil Court,DepalpurCivil Court,Depalpur",
    },
    {
      State: "Madhya Pradesh",
      District: "Indore",
      Court:
        "District & Sessions Court,IndoreDistrict & Sessions Court,512,M.G Road,Indore",
    },
    {
      State: "Madhya Pradesh",
      District: "Indore",
      Court: "Civil Court,SanwerCivil Court,Sanwer",
    },
    {
      State: "Madhya Pradesh",
      District: "Indore",
      Court: "Civil Court, Hatod",
    },
    {
      State: "Madhya Pradesh",
      District: "Indore",
      Court: "Family Court District Indore",
    },
    {
      State: "Madhya Pradesh",
      District: "Jabalpur",
      Court: "Civil Court,Patancivil court patan",
    },
    {
      State: "Madhya Pradesh",
      District: "Jabalpur",
      Court: "Civil Court,sihoracivil court sihora",
    },
    {
      State: "Madhya Pradesh",
      District: "Jabalpur",
      Court:
        "District & Sessions Court ,JabalpurDistrict & Sessions Court,Jabalpur",
    },
    {
      State: "Madhya Pradesh",
      District: "Jhabua",
      Court: "District And Sessions Court Jhabua",
    },
    {
      State: "Madhya Pradesh",
      District: "Jhabua",
      Court: "Civil Court,PetlawadCivil Court,Petlawad",
    },
    {
      State: "Madhya Pradesh",
      District: "Jhabua",
      Court: "Civil Court,ThandlaCivil Court,Thandla",
    },
    {
      State: "Madhya Pradesh",
      District: "Katni",
      Court: "Civil Court Dhimarkheda katni",
    },
    {
      State: "Madhya Pradesh",
      District: "Katni",
      Court: "District & Sessions Court,KatniDistrict & Sessions Court,Katni",
    },
    {
      State: "Madhya Pradesh",
      District: "Katni",
      Court: "Civil Court ,VijayraghavgarhJanpad Building, Vijayraghavgarh",
    },
    {
      State: "Madhya Pradesh",
      District: "Katni",
      Court: "Civil Court Barhi District Katni",
    },
    {
      State: "Madhya Pradesh",
      District: "Khandwa",
      Court: "Civil Court, Harsud",
    },
    {
      State: "Madhya Pradesh",
      District: "Khandwa",
      Court: "Civil Court Punasa District khandwa",
    },
    {
      State: "Madhya Pradesh",
      District: "Khandwa",
      Court:
        "District & Sessions Court ,KhandwaCourt complex, civil line, khandwa mp 450001",
    },
    {
      State: "Madhya Pradesh",
      District: "Mandla",
      Court: "Civil Court, Nainpur",
    },
    {
      State: "Madhya Pradesh",
      District: "Mandla",
      Court: "Civil Court,NiwasCivil Court,Niwas",
    },
    {
      State: "Madhya Pradesh",
      District: "Mandla",
      Court: "District & Sessions Court,MandlaDistrict & Sessions Court,Mandla",
    },
    {
      State: "Madhya Pradesh",
      District: "Mandla",
      Court: "Civil court bichhiya district mandl",
    },
    {
      State: "Madhya Pradesh",
      District: "Mandleshwar",
      Court:
        "Civil Court, KasrawadKhargone-Mandleshwar Road, Opp. Bus stand Kasrawad",
    },
    {
      State: "Madhya Pradesh",
      District: "Mandleshwar",
      Court: "District & Session Court, MandleshwarBarwaha Road Mandleshwar",
    },
    {
      State: "Madhya Pradesh",
      District: "Mandleshwar",
      Court: "Civil Court, MaheshwarDhamnod-Barwaha Road, Maheshwar",
    },
    {
      State: "Madhya Pradesh",
      District: "Mandleshwar",
      Court:
        "Civil Court, KhargoneOpp. Nagar Palika Town, Sanawad Road Khargone",
    },
    {
      State: "Madhya Pradesh",
      District: "Mandleshwar",
      Court: "Civil Court, BhikangaonBehind Bus Stand, Bhikangaon",
    },
    {
      State: "Madhya Pradesh",
      District: "Mandleshwar",
      Court: "Civil Court, BarwahaM.G. Road, Barwaha",
    },
    {
      State: "Madhya Pradesh",
      District: "Mandsaur",
      Court: "District & Sessions Court ,MandsaurKila Road Fort Mandsaur",
    },
    {
      State: "Madhya Pradesh",
      District: "Mandsaur",
      Court:
        "Civil Court, BhanpuraBhanpura Garoth Road Near Bus Stand Tehasil Bhanpura District Mandsaur",
    },
    {
      State: "Madhya Pradesh",
      District: "Mandsaur",
      Court:
        "Civil Court, SitamauBehind Tehasil Office Mandsaur Road Tehasil Sitamau District Mandsaur",
    },
    {
      State: "Madhya Pradesh",
      District: "Mandsaur",
      Court: "Civil Court, GarothBhanpura Road Garoth District Mandsaur",
    },
    {
      State: "Madhya Pradesh",
      District: "Mandsaur",
      Court:
        "Civil Court, NarayangarhManasa Road Naraynagarh Tehasil Malhargarh District Mandsaur",
    },
    {
      State: "Madhya Pradesh",
      District: "Morena",
      Court: "District & Sessions Court,MorenaDistrict & Sessions Court,Morena",
    },
    {
      State: "Madhya Pradesh",
      District: "Morena",
      Court: "Civil court , AmbahCivil court,Ambah",
    },
    {
      State: "Madhya Pradesh",
      District: "Morena",
      Court: "Civil Court, SabalgarhCivil Court, Sabalgarh",
    },
    {
      State: "Madhya Pradesh",
      District: "Morena",
      Court: "Civil Court ,JoraCivil Court ,M.S.Road,Jora",
    },
    {
      State: "Madhya Pradesh",
      District: "Narsinghpur",
      Court: "LinkCourtTendukhedaDist Narsinghpur",
    },
    {
      State: "Madhya Pradesh",
      District: "Narsinghpur",
      Court:
        "District & Sessions Court, NarsinghpurDistrict & Sessions Court,Pathak ward, Narsinghpur",
    },
    {
      State: "Madhya Pradesh",
      District: "Narsinghpur",
      Court:
        "Civil Court ,GadarwaraCivil Court ,Gadarwara Bhama Ward Gadarwara",
    },
    {
      State: "Madhya Pradesh",
      District: "Narsinghpur",
      Court: "Civil Court Gotegaon",
    },
    {
      State: "Madhya Pradesh",
      District: "Neemuch",
      Court: "Civil Court Complex JawadCivil Court Jawad District Neemuch",
    },
    {
      State: "Madhya Pradesh",
      District: "Neemuch",
      Court: "Civil Court Complex ManasaCivil Court Manasa District Neemuch",
    },
    {
      State: "Madhya Pradesh",
      District: "Neemuch",
      Court:
        "District & Sessions Court ,NeemuchDistrict & Sessions Court, Neemuch",
    },
    {
      State: "Madhya Pradesh",
      District: "Neemuch",
      Court: "Civil Court Rampura Neemuch",
    },
    {
      State: "Madhya Pradesh",
      District: "Panna",
      Court: "Civil Court, AjaygarhCivil Court,Ajaygarh",
    },
    {
      State: "Madhya Pradesh",
      District: "Panna",
      Court: "District & Sessions Court,PannaMahendra Bhawan, Panna",
    },
    {
      State: "Madhya Pradesh",
      District: "Panna",
      Court: "Civil Court,PawaiCivil Court,Pawai",
    },
    {
      State: "Madhya Pradesh",
      District: "Raisen",
      Court: "Civil Court ,UdaipuraCivil Court,Udaipura",
    },
    {
      State: "Madhya Pradesh",
      District: "Raisen",
      Court:
        "District & Sessions Court ,RaisenDistrict & Sessions Court,Sanchi Road, Raisen",
    },
    {
      State: "Madhya Pradesh",
      District: "Raisen",
      Court: "Civil Court,BareliCivil Court,Bareli",
    },
    {
      State: "Madhya Pradesh",
      District: "Raisen",
      Court: "Civil Court,SilwaniCivil Court,Silwani",
    },
    {
      State: "Madhya Pradesh",
      District: "Raisen",
      Court: "Civil Court ,BegumganjCivil Court ,Begumganj",
    },
    {
      State: "Madhya Pradesh",
      District: "Raisen",
      Court: "Civil Court,GairatganjCivil Court,Gairatganj",
    },
    {
      State: "Madhya Pradesh",
      District: "Raisen",
      Court: "Civil Court,GoharganjCivil Court,Goharganj",
    },
    {
      State: "Madhya Pradesh",
      District: "Rajgarh",
      Court:
        "District & Sessions court ,RajgarhDistrict & Sessions court,Old Collectorate building, Rajgarh",
    },
    {
      State: "Madhya Pradesh",
      District: "Rajgarh",
      Court: "Civil Court ,KhilchipurCivil Court ,Main road ,Khilchipur",
    },
    {
      State: "Madhya Pradesh",
      District: "Rajgarh",
      Court: "Civil Court Sarangpur",
    },
    {
      State: "Madhya Pradesh",
      District: "Rajgarh",
      Court: "Civil Court, NarsinghgarhCivil Court,Lanka puri ,Narsinghgarh",
    },
    {
      State: "Madhya Pradesh",
      District: "Rajgarh",
      Court: "Civil Court,Near Bus Stand, Zirapur",
    },
    {
      State: "Madhya Pradesh",
      District: "Rajgarh",
      Court: "Civil Court,BiaoraCivil Court,Juna ,Biaora",
    },
    {
      State: "Madhya Pradesh",
      District: "Ratlam",
      Court: "Civil Court, JaoraCivil Court,jaora",
    },
    {
      State: "Madhya Pradesh",
      District: "Ratlam",
      Court: "Civil Court ,AloteCivil Court,Alote",
    },
    {
      State: "Madhya Pradesh",
      District: "Ratlam",
      Court: "Civil Court ,SailanaCivil Court ,Sailana",
    },
    {
      State: "Madhya Pradesh",
      District: "Ratlam",
      Court:
        "District & Sessions Court, RatlamDistrict & Sessions Court,Ratlam",
    },
    {
      State: "Madhya Pradesh",
      District: "Rewa",
      Court:
        "District & Sessions Court ,RewaDistrict & Sessions Court , Kothi Compound, Rewa",
    },
    {
      State: "Madhya Pradesh",
      District: "Rewa",
      Court: "Civil Court, TeontherCivil Court,Teonther",
    },
    {
      State: "Madhya Pradesh",
      District: "Rewa",
      Court: "Civil Court,MauganjCivil Court, Mauganj",
    },
    {
      State: "Madhya Pradesh",
      District: "Rewa",
      Court: "Civil Court, Hanumana",
    },
    {
      State: "Madhya Pradesh",
      District: "Rewa",
      Court: "Civil Court,SirmourCivil Court,Sirmour",
    },
    {
      State: "Madhya Pradesh",
      District: "Sagar",
      Court: "Civil Court,KhuraiCivil Court,Khurai",
    },
    {
      State: "Madhya Pradesh",
      District: "Sagar",
      Court: "Civil COURT KESLI",
    },
    {
      State: "Madhya Pradesh",
      District: "Sagar",
      Court: "Civil Court,BinaCivil Court,Bina",
    },
    {
      State: "Madhya Pradesh",
      District: "Sagar",
      Court:
        "District & Sessions Court ,SagarNew Court Building, Collectrate Area Civil Lines Sagar",
    },
    {
      State: "Madhya Pradesh",
      District: "Sagar",
      Court: "Civil Court,BandaCivil Court,Banda",
    },
    {
      State: "Madhya Pradesh",
      District: "Sagar",
      Court: "civil court shahgarh",
    },
    {
      State: "Madhya Pradesh",
      District: "Sagar",
      Court: "Civil Court,DeoriCivil Court,Deori",
    },
    {
      State: "Madhya Pradesh",
      District: "Sagar",
      Court: "Civil Court, RehliCivil Court ,Rehli",
    },
    {
      State: "Madhya Pradesh",
      District: "Sagar",
      Court: "Civil Court,GaracotaCivil Court,Garacota",
    },
    {
      State: "Madhya Pradesh",
      District: "Sagar",
      Court: "Civil Court Malthone District sagar",
    },
    {
      State: "Madhya Pradesh",
      District: "Satna",
      Court: "District & Sessions Court, satnaDistrict & sessions court satna",
    },
    {
      State: "Madhya Pradesh",
      District: "Satna",
      Court: "Civil Court,RampurBaghelaCivil Court,RampurBaghela",
    },
    {
      State: "Madhya Pradesh",
      District: "Satna",
      Court: "Civil Court, Uchehara",
    },
    {
      State: "Madhya Pradesh",
      District: "Satna",
      Court: "Civil Court , MaiharCivil Court ,Maihar",
    },
    {
      State: "Madhya Pradesh",
      District: "Satna",
      Court: "Civil Court, Chitrakoot",
    },
    {
      State: "Madhya Pradesh",
      District: "Satna",
      Court: "Civil Court,AmarpatanCivil Court,Amarpatan",
    },
    {
      State: "Madhya Pradesh",
      District: "Satna",
      Court: "Civil Court,NagodCivil Court,Nagod",
    },
    {
      State: "Madhya Pradesh",
      District: "Sehore",
      Court: "Civil Court, BudhniCivil Court ,Budhni District Sehore",
    },
    {
      State: "Madhya Pradesh",
      District: "Sehore",
      Court: "Civil Court,IchhawarCivil Court,Ichhawar",
    },
    {
      State: "Madhya Pradesh",
      District: "Sehore",
      Court:
        "Civil Court, NasrullaganjCivil Court,Rehti Road, Near Bus Stand, Nasrullaganj",
    },
    {
      State: "Madhya Pradesh",
      District: "Sehore",
      Court: "Civil Court, AshtaCivil Court,Kannod Road, Ashta",
    },
    {
      State: "Madhya Pradesh",
      District: "Sehore",
      Court:
        "District & Sessions Court ,Sehore Indore-Bhopal Road, near mprtc depo, Sehore",
    },
    {
      State: "Madhya Pradesh",
      District: "Seoni",
      Court: "Civil Court, Lakhnadon.Civil Court, Lakhnadon Distt. Seoni.",
    },
    {
      State: "Madhya Pradesh",
      District: "Seoni",
      Court: "District & Session Court Seoni.District Court Seoni.",
    },
    {
      State: "Madhya Pradesh",
      District: "Seoni",
      Court: "civil court keolari dist. Seoni",
    },
    {
      State: "Madhya Pradesh",
      District: "Shahdol",
      Court: "Civil Court ,BeohariMartand Ganj, Beohari M.P.",
    },
    {
      State: "Madhya Pradesh",
      District: "Shahdol",
      Court: "District & Sessions Court, Shahdolpali road shahdol m.p.",
    },
    {
      State: "Madhya Pradesh",
      District: "Shahdol",
      Court: "Civil Court ,JaisinghnagarNear Bus Stand, Jaisinghnagar, M.P.",
    },
    {
      State: "Madhya Pradesh",
      District: "Shahdol",
      Court: "Civil Court ,BurharFront of Police Station, Burhar, M.P.",
    },
    {
      State: "Madhya Pradesh",
      District: "Shajapur",
      Court: "Civil Court, AgarCivil Court, Agar",
    },
    {
      State: "Madhya Pradesh",
      District: "Shajapur",
      Court: "District & Sessions Court, ShajapurA.B. Road ,Shajapur",
    },
    {
      State: "Madhya Pradesh",
      District: "Shajapur",
      Court: "Civil Court ,SusnerCivil Court ,Susner",
    },
    {
      State: "Madhya Pradesh",
      District: "Shajapur",
      Court: "Civil Court ,ShujalpurCivil Court, Shujalpur",
    },
    {
      State: "Madhya Pradesh",
      District: "Shajapur",
      Court: "Civil Court, Nalkheda",
    },
    {
      State: "Madhya Pradesh",
      District: "Sheopur",
      Court: "District & Sessions Court,SheopurShivpuri road ,sheopur",
    },
    {
      State: "Madhya Pradesh",
      District: "Sheopur",
      Court: "Civil Court, Vijaypur",
    },
    {
      State: "Madhya Pradesh",
      District: "Shivpuri",
      Court: "Civil Court ,KolarasCivil Court, Kolaras Distt. Shivpuri",
    },
    {
      State: "Madhya Pradesh",
      District: "Shivpuri",
      Court: "Civil Court ,PichhoreCourt Campus Pichhore Distt. Shivpuri",
    },
    {
      State: "Madhya Pradesh",
      District: "Shivpuri",
      Court: "Civil Court ,KhaniadhanaCivil Court, Khaniadhana Distt. Shivpuri",
    },
    {
      State: "Madhya Pradesh",
      District: "Shivpuri",
      Court:
        "District & Sessions Court ,ShivpuriDistrict & Sessions Court ,Shivpuri",
    },
    {
      State: "Madhya Pradesh",
      District: "Shivpuri",
      Court: "Civil Court,PohariCivil Court,Pohari,Distt.Shivpuri",
    },
    {
      State: "Madhya Pradesh",
      District: "Shivpuri",
      Court: "Civil Court, KareraCivil Court, Karera",
    },
    {
      State: "Madhya Pradesh",
      District: "Sidhi",
      Court: "Civil Court, Rampurnaikin, District Sidhi",
    },
    {
      State: "Madhya Pradesh",
      District: "Sidhi",
      Court: "District & Sessions Court, SidhiDistrict & Sessions Court, Sidhi",
    },
    {
      State: "Madhya Pradesh",
      District: "Sidhi",
      Court: "Civil Court, ChurhatCivil Court Churhat Distt Sidhi",
    },
    {
      State: "Madhya Pradesh",
      District: "Sidhi",
      Court: "Civil Court, MajhouliCivil Court, Majhouli",
    },
    {
      State: "Madhya Pradesh",
      District: "Singrauli",
      Court: "District Session Court, Singrauli",
    },
    {
      State: "Madhya Pradesh",
      District: "Singrauli",
      Court: "Civil Court Deosar",
    },
    {
      State: "Madhya Pradesh",
      District: "Tikamgarh",
      Court:
        "District & Sessions Court,TikamgarhNear Collectorate,Jhansi Road,Tikamgarh",
    },
    {
      State: "Madhya Pradesh",
      District: "Tikamgarh",
      Court: "Civil Court,JataraCivil Court,Jatara",
    },
    {
      State: "Madhya Pradesh",
      District: "Tikamgarh",
      Court: "Civil Court,orchhaCivil Court,orchha",
    },
    {
      State: "Madhya Pradesh",
      District: "Tikamgarh",
      Court: "Civil Court,NiwariCivil Court,Station Road,Niwari",
    },
    {
      State: "Madhya Pradesh",
      District: "Ujjain",
      Court: "District & Sessions Court, UjjainKothi Palce, Dewas Road, Ujjain",
    },
    {
      State: "Madhya Pradesh",
      District: "Ujjain",
      Court: "Civil Court, Nagda",
    },
    {
      State: "Madhya Pradesh",
      District: "Ujjain",
      Court: "Civil Court, Badnagar",
    },
    {
      State: "Madhya Pradesh",
      District: "Ujjain",
      Court: "Civil Court, Khachraud",
    },
    {
      State: "Madhya Pradesh",
      District: "Ujjain",
      Court: "Family Court Ujjain",
    },
    {
      State: "Madhya Pradesh",
      District: "Ujjain",
      Court: "Civil Court, Tarana",
    },
    {
      State: "Madhya Pradesh",
      District: "Ujjain",
      Court: "Civil Court, Mahidpur",
    },
    {
      State: "Madhya Pradesh",
      District: "Umaria",
      Court:
        "Civil Court Birsinghpur paliNagar Panchayat Bhawan, Birsinghpur pali, M.P.",
    },
    {
      State: "Madhya Pradesh",
      District: "Umaria",
      Court: "Civil Court, UmariaCivil Court,Near Ranvijay Chowk, Umaria",
    },
    {
      State: "Madhya Pradesh",
      District: "Umaria",
      Court: "Family Court Umaria",
    },
    {
      State: "Madhya Pradesh",
      District: "Umaria",
      Court: "Civil Court Manpur District Umaria",
    },
    {
      State: "Madhya Pradesh",
      District: "Vidisha",
      Court: "Civil Court,LateriCivil Court,Lateri",
    },
    {
      State: "Madhya Pradesh",
      District: "Vidisha",
      Court: "Civil Court, Sironj",
    },
    {
      State: "Madhya Pradesh",
      District: "Vidisha",
      Court: "Civil Court,KurwaiCivil Court,Kurwai",
    },
    {
      State: "Madhya Pradesh",
      District: "Vidisha",
      Court: "Civil Court, Ganjbasoda, District Vidisha",
    },
    {
      State: "Madhya Pradesh",
      District: "Vidisha",
      Court:
        "District & Sessions Court VidishaDistrict & Sessions Court, Vidisha",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Rahuri, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Ahmednagar, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Shrigonda, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Karjat Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Pathardi, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Sangamner, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Parner, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Jamkhed, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Sangamner, Dist and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Newasa, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Shevgaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Akole, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Newasa, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Kopergaon, Dist and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Ahmednagar, Dist and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Rahata, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Ahmednagar",
      Court: "Shrirampur, Dist and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Akola",
      Court: "Murtizapur, Civil and Criminal Cour",
    },
    {
      State: "Maharashtra",
      District: "Akola",
      Court: "Akola, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Akola",
      Court: "Akot, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Akola",
      Court: "Barshitakli, Civil and Criminal",
    },
    {
      State: "Maharashtra",
      District: "Akola",
      Court: "Patur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Akola",
      Court: "Balapur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Akola",
      Court: "Telhara, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Amravati",
      Court: "Surji, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Amravati",
      Court: "Daryapur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Amravati",
      Court: "Morshi, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Amravati",
      Court: "Dharni, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Amravati",
      Court: "Teosa, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Amravati",
      Court: "Amravati, District and Sessions",
    },
    {
      State: "Maharashtra",
      District: "Amravati",
      Court: "Achalpur, District and ASJ Court",
    },
    {
      State: "Maharashtra",
      District: "Amravati",
      Court: "Warud, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Amravati",
      Court: "Nandgaon Khandeshwar, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Amravati",
      Court: "Bhatkuli, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Amravati",
      Court: "Chandur Bazar, Civil and Criminal C",
    },
    {
      State: "Maharashtra",
      District: "Amravati",
      Court: "Chandur Railway, Civil and Criminal",
    },
    {
      State: "Maharashtra",
      District: "Amravati",
      Court: "Dhamangaon, Railway Court",
    },
    {
      State: "Maharashtra",
      District: "Aurangabad",
      Court: "Aurangabad, District and Sessions",
    },
    {
      State: "Maharashtra",
      District: "Aurangabad",
      Court: "Sillod, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Aurangabad",
      Court: "Phulambri, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Aurangabad",
      Court: "Soygaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Aurangabad",
      Court: "Khultabad, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Aurangabad",
      Court: "Vaijapur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Aurangabad",
      Court: "Kannad, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Aurangabad",
      Court: "Vaijapur, Dist and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Aurangabad",
      Court: "Gangapur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Aurangabad",
      Court: "Paithan, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Aurangabad",
      Court: "Aurangabad, District and Sesion Old",
    },
    {
      State: "Maharashtra",
      District: "Beed",
      Court: "Ambajogai, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Beed",
      Court: "Majalgaon, Dist and Session Court",
    },
    {
      State: "Maharashtra",
      District: "Beed",
      Court: "Ambajogai, Dist and Session Court",
    },
    {
      State: "Maharashtra",
      District: "Beed",
      Court: "Beed, Dist and Session Court",
    },
    {
      State: "Maharashtra",
      District: "Beed",
      Court: "Dharur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Beed",
      Court: "Parli Vaijnath, Civil and Criminal",
    },
    {
      State: "Maharashtra",
      District: "Beed",
      Court: "Wadwani, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Beed",
      Court: "Georai, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Beed",
      Court: "Shirur K, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Beed",
      Court: "Patoda, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Beed",
      Court: "Ashti, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Beed",
      Court: "Kaij, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Bhandara",
      Court: "Pauni, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Bhandara",
      Court: "Bhandara, Dist and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Bhandara",
      Court: "Mohadi, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Bhandara",
      Court: "Lakhandur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Bhandara",
      Court: "Lakhani, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Bhandara",
      Court: "Tumsar, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Bhandara",
      Court: "Sakoli, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Buldhana",
      Court: "Buldhana,District and Session Court",
    },
    {
      State: "Maharashtra",
      District: "Buldhana",
      Court: "Chikhli, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Buldhana",
      Court: "Khamgaon, Dist and Session court",
    },
    {
      State: "Maharashtra",
      District: "Buldhana",
      Court: "Lonar, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Buldhana",
      Court: "Deulgaon Raja, Civil and Criminal",
    },
    {
      State: "Maharashtra",
      District: "Buldhana",
      Court: "Nandura, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Buldhana",
      Court: "Sangrampur,Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Buldhana",
      Court: "Mehkar, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Buldhana",
      Court: "Malkapur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Buldhana",
      Court: "Jalgaon Jamod, Civil Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Buldhana",
      Court: "Shegaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Buldhana",
      Court: "Motala, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Buldhana",
      Court: "Sindkhed Raja, Civil and Criminal",
    },
    {
      State: "Maharashtra",
      District: "Chandrapur",
      Court: "Gondpipari, Civil and Cri Court",
    },
    {
      State: "Maharashtra",
      District: "Chandrapur",
      Court: "Mul, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Chandrapur",
      Court: "Sindewahi, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Chandrapur",
      Court: "Rajura, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Chandrapur",
      Court: "Ballarpur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Chandrapur",
      Court: "Warora, District and ASJ Court",
    },
    {
      State: "Maharashtra",
      District: "Chandrapur",
      Court: "Warora, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Chandrapur",
      Court: "Chandrapur, Dist and Session Court",
    },
    {
      State: "Maharashtra",
      District: "Chandrapur",
      Court: "Nagbhid, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Chandrapur",
      Court: "Pombhurna, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Chandrapur",
      Court: "Saoli, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Chandrapur",
      Court: "Chimur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Chandrapur",
      Court: "Bhadrawati,Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Chandrapur",
      Court: "Bramhapuri, Civil and Cri Court",
    },
    {
      State: "Maharashtra",
      District: "Chandrapur",
      Court: "Korpana, Civil and Cri. Court",
    },
    {
      State: "Maharashtra",
      District: "Dhule",
      Court: "Dondaicha, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Dhule",
      Court: "Sakri, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Dhule",
      Court: "Dhule, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Dhule",
      Court: "Shirpur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Dhule",
      Court: "Shindkheda,Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Gadchiroli",
      Court: "Charmoshi, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Gadchiroli",
      Court: "Armori, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Gadchiroli",
      Court: "Kurkheda, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Gadchiroli",
      Court: "Aheri, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Gadchiroli",
      Court: "Sironcha, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Gadchiroli",
      Court: "Desaiganj Wadsa, Civil and Cri Ct",
    },
    {
      State: "Maharashtra",
      District: "Gadchiroli",
      Court: "Dhanora, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Gadchiroli",
      Court: "Gadchiroli, Dist and Session Court",
    },
    {
      State: "Maharashtra",
      District: "Gondia",
      Court: "Amgaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Gondia",
      Court: "Gondia, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Gondia",
      Court: "Arjuni Morgaon, Civil and Criminal",
    },
    {
      State: "Maharashtra",
      District: "Gondia",
      Court: "Sadak Arjuni, Civil and Criminal",
    },
    {
      State: "Maharashtra",
      District: "Gondia",
      Court: "Deori, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Gondia",
      Court: "Tirora, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalgaon",
      Court: "Yawal, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalgaon",
      Court: "Dharangaon,Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalgaon",
      Court: "Bhusawal, Railway Court",
    },
    {
      State: "Maharashtra",
      District: "Jalgaon",
      Court: "Jalgaon,District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Jalgaon",
      Court: "Jamner, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalgaon",
      Court: "Chalisgaon Civil Court SD",
    },
    {
      State: "Maharashtra",
      District: "Jalgaon",
      Court: "Chalisgaon,Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalgaon",
      Court: "Amalner, District and Session Court",
    },
    {
      State: "Maharashtra",
      District: "Jalgaon",
      Court: "Bhusawal, Dist and Session Court",
    },
    {
      State: "Maharashtra",
      District: "Jalgaon",
      Court: "Parola, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalgaon",
      Court: "Raver, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalgaon",
      Court: "Pachora, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalgaon",
      Court: "Bhadgaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalgaon",
      Court: "Chopda, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalgaon",
      Court: "Muktainagar, Civil and Criminal Ct",
    },
    {
      State: "Maharashtra",
      District: "Jalgaon",
      Court: "Erandol, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalna",
      Court: "Bhokardan, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalna",
      Court: "Ghansavangi, Civil and Cri Court",
    },
    {
      State: "Maharashtra",
      District: "Jalna",
      Court: "Jalna, Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalna",
      Court: "Mantha, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalna",
      Court: "Partur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalna",
      Court: "Ambad, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalna",
      Court: "Jafrabad, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalna",
      Court: "Jalna, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Jalna",
      Court: "Badnapur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Jalna",
      Court: "Jalna, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Jalna",
      Court: "Ambad, Dist and Addl.Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Peth Vadgaon, Civil and Cri Court",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Gadhinglaj,Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Jaysingpur, Civil and Criminal Ct",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Kolhapur District and Session Court",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Gargoti, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Ajara, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Kolhapur, Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Panhala, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Gadhinglaj, Dist and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Jaisingpur, Dist and Sessions Ct",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Chandgad, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Ichalkaranji, Dist and Session Ct",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Kurundwad, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Civil and Cri Court Kale Kheriwade",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Kagal, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Radhanagari, Civil and Criminal Ct",
    },
    {
      State: "Maharashtra",
      District: "Kolhapur",
      Court: "Shahuwadi, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Latur",
      Court: "Ahmedpur, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Latur",
      Court: "Udgir, Dist and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Latur",
      Court: "Renapur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Latur",
      Court: "Deoni, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Latur",
      Court: "Nilanga, Dist and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Latur",
      Court: "Latur, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Latur",
      Court: "Ausa, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Latur",
      Court: "Chakur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Latur",
      Court: "Ausa, Civil Court S.D.",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Bandra, Family Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Family Court , Osmanabad",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Nashik, Family Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Aurangabad, Family Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Akola, Family Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Nagpur, Family Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Ahmednagar Family Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Solapur, Family Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Nanded, Family Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Family Court, Latur",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Thane, Family Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Amravati, Family Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Kolhapur, Family Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Pune, Family Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Alibag, Family Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Family Court, Buldana",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Family Courts",
      Court: "Parbhani, Family Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Jalna, Industrial and Labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Chandrapur, Industrial and Labour",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Aurangabad, Industrial and Labour",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Mahad, Labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Akola, Industrial and Labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Satara, Industrial and labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Bhandara, Industrial and Labour",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Thane, Industrial and Labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Pune, Industrial and Labour",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Buldhana, Labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Sangli, Industrial and Labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Solapur, Industrial and Labour Ct",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Ratnagiri, Labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Kolhapur Industrial andLabour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Bandra, Industrial and Labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Amravati, Industrial and Labour",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Nashik, Indl and Labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Nagpur, Industrial and Labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Ahmednagar, IC and LC",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Dhule, Industrial and Labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Jalgaon,Industrial and Labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Wardha, Labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Yavatmal, Industrial and Labour",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Nanded, Labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Latur, Industrial and Labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra Industrial and Lab",
      Court: "Gondia, Labour Court",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra School Tribunals",
      Court: "Amravati, School Tribunal",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra School Tribunals",
      Court: "Aurangabad, School Tribunal",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra School Tribunals",
      Court: "Nashik, School Tribunal",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra School Tribunals",
      Court: "Solapur, School Tribunal",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra School Tribunals",
      Court: "Latur, School Tribunal",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra School Tribunals",
      Court: "Navi Mumbai, School Tribunal",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra School Tribunals",
      Court: "Chandrapur, School Tribunal",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra School Tribunals",
      Court: "Nagpur, School Tribunal",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra School Tribunals",
      Court: "Pune, School Tribunal",
    },
    {
      State: "Maharashtra",
      District: "Maharashtra School Tribunals",
      Court: "Kolhapur, School Tribunal",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Thane, Coop Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Satara, Cooperative Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Kopargaon, Cooperative Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Shrirampur, Cooperative Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Solapur, Cooperative Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Aurangabad, Cooperative Appellate",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Nashik, Judge Cooperative Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Amravati, Cooperative Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Nagpur, Mah State Coop App Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Sangli, Cooperative Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Ahmednagar, Cooperative Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Mumbai, MH State Coop Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Jalgaon, Cooperative Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Latur, Cooperative Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Alibag, Judge, Cooperative Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Swarget,Pune, Co Op Appellate Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Akola, Cooperative Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Nanded, Cooperative Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Ratnagiri, Cooperative Link Court",
    },
    {
      State: "Maharashtra",
      District: "Mah State Cooperative Appellat",
      Court: "Kolhapur, Cooperative Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai City Civil Court",
      Court: "Sewree, City Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai City Civil Court",
      Court: "Mumbai City Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai City Civil Court",
      Court: "Dindoshi, City Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai City Civil Court",
      Court: "Mumbai, City Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai CMM Courts",
      Court: "Dongari, Juvenile, MM Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai CMM Courts",
      Court: "Dadar Bhoiwada, MM Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai CMM Courts",
      Court: "Borivali, MM Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai CMM Courts",
      Court: "Bandra, MM Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai CMM Courts",
      Court: "Ballard Estate Mumbai, MM Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai CMM Courts",
      Court: "Girgaon, MM Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai CMM Courts",
      Court: "Dadar Shindewadi, MM Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai CMM Courts",
      Court: "Andheri, MM Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai CMM Courts",
      Court: "Sewree, MM Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai CMM Courts",
      Court: "CST Mumbai, MM Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai CMM Courts",
      Court: "Vikhroli, MM Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai CMM Courts",
      Court: "Mumbai Central, MM Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai CMM Courts",
      Court: "Vile Parle, MM Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai CMM Courts",
      Court: "Esplanade, CMM Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai CMM Courts",
      Court: "Mulund, MM Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai CMM Courts",
      Court: "Kurla, MM Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai Motor Accident Claims T",
      Court: "Mumbai, MotorAccidentClaimsTribunal",
    },
    {
      State: "Maharashtra",
      District: "Mumbai Small Causes Court",
      Court: "Mumbai, Small Causes Court",
    },
    {
      State: "Maharashtra",
      District: "Mumbai Small Causes Court",
      Court: "Bandra, Small Causes Court",
    },
    {
      State: "Maharashtra",
      District: "Nagpur",
      Court: "Hingna, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nagpur",
      Court: "Bhiwapur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nagpur",
      Court: "Mouda, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nagpur",
      Court: "Kalmeshwar, Civil and Criminal Cour",
    },
    {
      State: "Maharashtra",
      District: "Nagpur",
      Court: "Narkhed, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nagpur",
      Court: "Katol, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nagpur",
      Court: "Kuhi, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nagpur",
      Court: "Kamptee, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nagpur",
      Court: "Paresoni, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nagpur",
      Court: "Ramtek, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nagpur",
      Court: "Saoner, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nagpur",
      Court: "Umrer, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nagpur",
      Court: "Nagpur, District Sessions Court III",
    },
    {
      State: "Maharashtra",
      District: "Nagpur",
      Court: "Nagpur, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Nanded",
      Court: "Mahoor, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nanded",
      Court: "Bhokar, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nanded",
      Court: "Nanded, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Nanded",
      Court: "Ardhapur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nanded",
      Court: "Umri, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nanded",
      Court: "Hadgaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nanded",
      Court: "Biloli, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Nanded",
      Court: "Loha, CJJD and JMFC Court",
    },
    {
      State: "Maharashtra",
      District: "Nanded",
      Court: "Degloor, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nanded",
      Court: "Mudkhed, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nanded",
      Court: "Kandhar,District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Nanded",
      Court: "Himayatnagar, Civil and Cri Court",
    },
    {
      State: "Maharashtra",
      District: "Nanded",
      Court: "Kinwat, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nanded",
      Court: "Dharmabad, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nanded",
      Court: "Naigaon Bazar, Civil and Cri Court",
    },
    {
      State: "Maharashtra",
      District: "Nanded",
      Court: "Mukhed, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nandurbar",
      Court: "Nandurbar, Dist and Session Court",
    },
    {
      State: "Maharashtra",
      District: "Nandurbar",
      Court: "Shahada, District and Session Court",
    },
    {
      State: "Maharashtra",
      District: "Nandurbar",
      Court: "Taloda, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nandurbar",
      Court: "Akkalkuwa, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nandurbar",
      Court: "Nawapur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nandurbar",
      Court: "Dhadgaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Igatpuri, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Pimpalgaon Bs, Civ and Cri Ct",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Chandwad, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Sinnar, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Yeola, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Satana, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Nashik, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Kalwan, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Manmad, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Nandgaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Manmad, Judicial Magistrate Railway",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Malegaon, Dist and Sessions Ct",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Nashik Road, Motor Vehicle Court",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Niphad, Dist Judge and ASJ",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Dindori, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Nashik, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Nashik",
      Court: "Yeola, Additional District Court",
    },
    {
      State: "Maharashtra",
      District: "Osmanabad",
      Court: "Tuljapur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Osmanabad",
      Court: "Washi, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Osmanabad",
      Court: "Omerga, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Osmanabad",
      Court: "Lohara, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Osmanabad",
      Court: "Kallam, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Osmanabad",
      Court: "Bhoom, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Osmanabad",
      Court: "Osmanabad, Dist and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Osmanabad",
      Court: "Paranda, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Osmanabad",
      Court: "Kallam, ADJ Court",
    },
    {
      State: "Maharashtra",
      District: "Osmanabad",
      Court: "Kallam, Civil Judge Sr. Div.",
    },
    {
      State: "Maharashtra",
      District: "Parbhani",
      Court: "Pathri, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Parbhani",
      Court: "Manwat, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Parbhani",
      Court: "Aundha Nagnath, Civil and Cri Court",
    },
    {
      State: "Maharashtra",
      District: "Parbhani",
      Court: "Gangakhed, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Parbhani",
      Court: "Senagaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Parbhani",
      Court: "Basmath, Dist and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Parbhani",
      Court: "Palam, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Parbhani",
      Court: "Selu, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Parbhani",
      Court: "Purna, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Parbhani",
      Court: "Sonpeth, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Parbhani",
      Court: "Jintur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Parbhani",
      Court: "Parbhani, Dist and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Parbhani",
      Court: "Kalamnuri, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Parbhani",
      Court: "Hingoli, Dist and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Parbhani",
      Court: "Basmath, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Khed, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Khadki, Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Shirur, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Pune, JMFC, Railway Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Daund, Railway Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Baramati, Dist and Session Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Junner, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Saswad, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Ghodegaon, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Pune, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Maval, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Bhor, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Indapur, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Daund, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Akurdi, Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Pune, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Pimpri, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Pune, JMFC, Cantonment Court",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Vadgaon Civil Court Senior Division",
    },
    {
      State: "Maharashtra",
      District: "Pune",
      Court: "Vadgaon Maval Add. District Court",
    },
    {
      State: "Maharashtra",
      District: "Raigad",
      Court: "Mahad, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Raigad",
      Court: "Murud, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Raigad",
      Court: "Pen, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Raigad",
      Court: "Shriwardhan, Civil and Cri Court",
    },
    {
      State: "Maharashtra",
      District: "Raigad",
      Court: "Karjat, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Raigad",
      Court: "Uran, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Raigad",
      Court: "Roha, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Raigad",
      Court: "Pali, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Raigad",
      Court: "Panvel, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Raigad",
      Court: "Khalapur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Raigad",
      Court: "Mangaon, Raigad, District Court",
    },
    {
      State: "Maharashtra",
      District: "Raigad",
      Court: "RaigadAlibag,Dist and Session Court",
    },
    {
      State: "Maharashtra",
      District: "Raigad",
      Court: "Panvel, District and Addl. Sessions",
    },
    {
      State: "Maharashtra",
      District: "Ratnagiri",
      Court: "Lanja, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Ratnagiri",
      Court: "Deorukh, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Ratnagiri",
      Court: "Khed, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Ratnagiri",
      Court: "Ratnagiri, Dist and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Ratnagiri",
      Court: "Chiplun, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Ratnagiri",
      Court: "Dapoli, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Ratnagiri",
      Court: "Guhagar, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Ratnagiri",
      Court: "Rajapur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Ratnagiri",
      Court: "Chiplun District Judge 1 and Addl.",
    },
    {
      State: "Maharashtra",
      District: "Sangli",
      Court: "Palus, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Sangli",
      Court: "KawatheMahakal, Civil and Criminal",
    },
    {
      State: "Maharashtra",
      District: "Sangli",
      Court: "Islampur, District Judge and ASJ",
    },
    {
      State: "Maharashtra",
      District: "Sangli",
      Court: "Kadegaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Sangli",
      Court: "Atpati, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Sangli",
      Court: "Shirala, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Sangli",
      Court: "Sangli, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Sangli",
      Court: "Miraj, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Sangli",
      Court: "Sangli, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Sangli",
      Court: "Tasgaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Sangli",
      Court: "Vita, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Sangli",
      Court: "Jath, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Satara",
      Court: "Khandala, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Satara",
      Court: "Satara, District Court, New Bldg",
    },
    {
      State: "Maharashtra",
      District: "Satara",
      Court: "Medha, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Satara",
      Court: "Phaltan, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Satara",
      Court: "Karad, District Court I",
    },
    {
      State: "Maharashtra",
      District: "Satara",
      Court: "Karad, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Satara",
      Court: "Mhaswad Man, Civil and Criminal Ct",
    },
    {
      State: "Maharashtra",
      District: "Satara",
      Court: "Wai, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Satara",
      Court: "Mahabaleshwar, Civil and Cri Court",
    },
    {
      State: "Maharashtra",
      District: "Satara",
      Court: "Koregaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Satara",
      Court: "Patan, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Satara",
      Court: "Vaduj Khatav, Civil and Cri Court",
    },
    {
      State: "Maharashtra",
      District: "Satara",
      Court: "Dahiwadi, Man, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Satara",
      Court: "Vaduj Khatav,Dist and Session Court",
    },
    {
      State: "Maharashtra",
      District: "Satara",
      Court: "Wai, Additional District Court",
    },
    {
      State: "Maharashtra",
      District: "Satara",
      Court: "Wai, Civi Court Senior Division",
    },
    {
      State: "Maharashtra",
      District: "Sindhudurg",
      Court: "Kankavli, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Sindhudurg",
      Court: "Kudal, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Sindhudurg",
      Court: "Sindhudurg Oros, District Court",
    },
    {
      State: "Maharashtra",
      District: "Sindhudurg",
      Court: "Dodamarg, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Sindhudurg",
      Court: "Malvan, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Sindhudurg",
      Court: "Deogad, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Sindhudurg",
      Court: "Sawantwadi, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Sindhudurg",
      Court: "Vengurla, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Solapur",
      Court: "Malshiras, District and Session Ct",
    },
    {
      State: "Maharashtra",
      District: "Solapur",
      Court: "Barshi, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Solapur",
      Court: "Pandharpur, Dist and Session Court",
    },
    {
      State: "Maharashtra",
      District: "Solapur",
      Court: "Barshi, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Solapur",
      Court: "Solapur, Dist and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Solapur",
      Court: "Sangola, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Solapur",
      Court: "Mangalweda, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Solapur",
      Court: "Karmala, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Solapur",
      Court: "Solapur, Civil and Municipal Court",
    },
    {
      State: "Maharashtra",
      District: "Solapur",
      Court: "Mohol, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Solapur",
      Court: "Madha, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Solapur",
      Court: "Akkalkot, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Solapur",
      Court: "Pandharpur, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Thane",
      Court: "Kalyan, Railway Court",
    },
    {
      State: "Maharashtra",
      District: "Thane",
      Court: "Thane, District and Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Thane",
      Court: "Shahapur, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Thane",
      Court: "Palghar, District and Addl Session",
    },
    {
      State: "Maharashtra",
      District: "Thane",
      Court: "Vasai, District and Addl Sessions",
    },
    {
      State: "Maharashtra",
      District: "Thane",
      Court: "Bhiwandi, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Thane",
      Court: "Dahanu, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Thane",
      Court: "Ulhasnagar, Civil and Criminal Cour",
    },
    {
      State: "Maharashtra",
      District: "Thane",
      Court: "Kalyan, District and Addl Sessions",
    },
    {
      State: "Maharashtra",
      District: "Thane",
      Court: "Wada, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Thane",
      Court: "Jawhar, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Thane",
      Court: "Vasai, Railway Court",
    },
    {
      State: "Maharashtra",
      District: "Thane",
      Court: "Murbad, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Thane",
      Court: "Belapur, Add. District Court",
    },
    {
      State: "Maharashtra",
      District: "Wardha",
      Court: "Wardha, District and Sessions New B",
    },
    {
      State: "Maharashtra",
      District: "Wardha",
      Court: "Karanaja, Gh, Civil and Criminal",
    },
    {
      State: "Maharashtra",
      District: "Wardha",
      Court: "Seloo, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Wardha",
      Court: "Wardha, District and Sessions Old B",
    },
    {
      State: "Maharashtra",
      District: "Wardha",
      Court: "Hinganghat, Civil and CriminalCourt",
    },
    {
      State: "Maharashtra",
      District: "Wardha",
      Court: "Arvi, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Wardha",
      Court: "Samudrapur,Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Wardha",
      Court: "Ashti, Civil and Criminal",
    },
    {
      State: "Maharashtra",
      District: "Wardha",
      Court: "Pulgaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Washim",
      Court: "Risod, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Washim",
      Court: "Manora, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Washim",
      Court: "Mangrulpir,Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Washim",
      Court: "Malegaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Washim",
      Court: "Karanaja, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Washim",
      Court: "Washim, District and ASJ Court",
    },
    {
      State: "Maharashtra",
      District: "Yavatmal",
      Court: "Yavatmal, District and Sessions",
    },
    {
      State: "Maharashtra",
      District: "Yavatmal",
      Court: "Arni, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Yavatmal",
      Court: "Wani, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Yavatmal",
      Court: "Ner, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Yavatmal",
      Court: "Babulgaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Yavatmal",
      Court: "Ghatanji, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Yavatmal",
      Court: "Umarkhed, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Yavatmal",
      Court: "Kalamb, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Yavatmal",
      Court: "Pusad, Addl Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Yavatmal",
      Court: "Maregaon, Civil Court",
    },
    {
      State: "Maharashtra",
      District: "Yavatmal",
      Court: "Ralegaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Yavatmal",
      Court: "Darwha,Addl District Sessions Court",
    },
    {
      State: "Maharashtra",
      District: "Yavatmal",
      Court: "Digras, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Yavatmal",
      Court: "Jamni, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Yavatmal",
      Court: "Mahagaon, Civil and Criminal Court",
    },
    {
      State: "Maharashtra",
      District: "Yavatmal",
      Court: "Kelapur, District and ASJ Court",
    },
    {
      State: "Manipur",
      District: "Bishnupur",
      Court: "Bishnupur Court Complex, Bishnupur",
    },
    {
      State: "Manipur",
      District: "Chandel",
      Court: "Chandel Court Complex",
    },
    {
      State: "Manipur",
      District: "Chandel",
      Court: "Moreh Court Complex",
    },
    {
      State: "Manipur",
      District: "Churachandpur",
      Court: "Churachandpur Court Complex",
    },
    {
      State: "Manipur",
      District: "Imphal East",
      Court: "Lamphel Court Complex, Lamphel",
    },
    {
      State: "Manipur",
      District: "Imphal East",
      Court: "Jiribam Court Complex",
    },
    {
      State: "Manipur",
      District: "Imphal East",
      Court: "Cheirap Court Complex, Uripok",
    },
    {
      State: "Manipur",
      District: "Imphal West",
      Court: "Lamphel Court Complex",
    },
    {
      State: "Manipur",
      District: "Imphal West",
      Court: "MACT Court Complex",
    },
    {
      State: "Manipur",
      District: "Imphal West",
      Court: "Cheirap Court Complex",
    },
    {
      State: "Manipur",
      District: "Senapati",
      Court: "DJ Senapati Court Complex",
    },
    {
      State: "Manipur",
      District: "Senapati",
      Court: "Kangpokpi Court Complex",
    },
    {
      State: "Manipur",
      District: "Tamenglong",
      Court: "CJM Tamenglong",
    },
    {
      State: "Manipur",
      District: "Thoubal",
      Court: "Thoubal Court Complex",
    },
    {
      State: "Manipur",
      District: "Ukhrul",
      Court: "DJ Court Complex Ukhrul",
    },
    {
      State: "Manipur",
      District: "Ukhrul",
      Court: "CJM Ukhrul Court Complex,Ukhrul",
    },
    {
      State: "Meghalaya",
      District: "East Garo Hills",
      Court: "District Court Complex Williamnagar",
    },
    {
      State: "Meghalaya",
      District: "East Jaintia Hills",
      Court: "District Court Complex Khliehriat",
    },
    {
      State: "Meghalaya",
      District: "East Khasi Hills",
      Court: "District Court Complex Shillong",
    },
    {
      State: "Meghalaya",
      District: "East Khasi Hills",
      Court: "Sub Divisional Court, Sohra",
    },
    {
      State: "Meghalaya",
      District: "KHADC Court",
      Court: "KHADC Court Complex, Shillong",
    },
    {
      State: "Meghalaya",
      District: "North Garo Hills",
      Court: "District Court Complex Resubelpara",
    },
    {
      State: "Meghalaya",
      District: "Ri Bhoi",
      Court: "Nongpoh, Dist and Session Court",
    },
    {
      State: "Meghalaya",
      District: "South Garo Hills",
      Court: "District Court Complex, Baghmara",
    },
    {
      State: "Meghalaya",
      District: "South West Garo Hills",
      Court: "District Court Complex Ampati",
    },
    {
      State: "Meghalaya",
      District: "South West Khasi Hills",
      Court: "District Court Complex Mawkyrwat",
    },
    {
      State: "Meghalaya",
      District: "West Garo Hills",
      Court: "District Court Complex Tura",
    },
    {
      State: "Meghalaya",
      District: "West Garo Hills",
      Court: "Sub Divisional Court, Dadenggre",
    },
    {
      State: "Meghalaya",
      District: "West Jaintia Hills",
      Court: "District Court Complex Jowai",
    },
    {
      State: "Meghalaya",
      District: "West Jaintia Hills",
      Court: "Sub Divisional Court, Amlarem",
    },
    {
      State: "Meghalaya",
      District: "West Khasi Hills",
      Court: "District Court Complex Nongstoin",
    },
    {
      State: "Mizoram",
      District: "Aizawl",
      Court: "Aizawl District Court",
    },
    {
      State: "Mizoram",
      District: "Aizawl",
      Court: "Serchhip District Court",
    },
    {
      State: "Mizoram",
      District: "Aizawl",
      Court: "Mamit District Court",
    },
    {
      State: "Mizoram",
      District: "Aizawl",
      Court: "Kolasib District Court",
    },
    {
      State: "Mizoram",
      District: "Champhai",
      Court: "Champhai District Court",
    },
    {
      State: "Mizoram",
      District: "Lunglei",
      Court: "Lawngtlai District Court",
    },
    {
      State: "Mizoram",
      District: "Lunglei",
      Court: "Lunglei District Court",
    },
    {
      State: "Mizoram",
      District: "Lunglei",
      Court: "Saiha District Court",
    },
    {
      State: "Nagaland",
      District: "Dimapur",
      Court: "District Court Complex Dimapur",
    },
    {
      State: "Nagaland",
      District: "Kiphire",
      Court: "District Court Complex Kiphire",
    },
    {
      State: "Nagaland",
      District: "Kohima",
      Court: "District Court, Kohima",
    },
    {
      State: "Nagaland",
      District: "Longleng",
      Court: "District Court Complex Longleng",
    },
    {
      State: "Nagaland",
      District: "Mokokchung",
      Court: "District Court Complex Mokokchung",
    },
    {
      State: "Nagaland",
      District: "Mon",
      Court: "District Court Complex Mon",
    },
    {
      State: "Nagaland",
      District: "Peren",
      Court: "District Court Complex Peren",
    },
    {
      State: "Nagaland",
      District: "Phek",
      Court: "District Court Complex Phek",
    },
    {
      State: "Nagaland",
      District: "Tuensang",
      Court: "District Court Complex Tuensang",
    },
    {
      State: "Nagaland",
      District: "Wokha",
      Court: "District Court Complex Wokha",
    },
    {
      State: "Nagaland",
      District: "Zunheboto",
      Court: "District Court Complex Zunheboto",
    },
    {
      State: "Odisha",
      District: "Anugul",
      Court: "Civil Court Complex, Talcher",
    },
    {
      State: "Odisha",
      District: "Anugul",
      Court: "Civil Court Complex, Athamallik",
    },
    {
      State: "Odisha",
      District: "Anugul",
      Court: "Civil Court Complex, Angul",
    },
    {
      State: "Odisha",
      District: "Anugul",
      Court: "Civil Court Complex, Palahara",
    },
    {
      State: "Odisha",
      District: "Balangir",
      Court: "Civil Court Complex, Bolangir",
    },
    {
      State: "Odisha",
      District: "Balangir",
      Court: "SDJM Court Complex, Patnagarh",
    },
    {
      State: "Odisha",
      District: "Balangir",
      Court: "SDJM Court Complex, Titilagarh",
    },
    {
      State: "Odisha",
      District: "Balangir",
      Court: "Civil Court Complex, Kantabanjhi",
    },
    {
      State: "Odisha",
      District: "Balasore",
      Court: "Court Complex, Jaleswar",
    },
    {
      State: "Odisha",
      District: "Balasore",
      Court: "Civil Court Complex, Balasore",
    },
    {
      State: "Odisha",
      District: "Balasore",
      Court: "JMFC Court Complex, Soro",
    },
    {
      State: "Odisha",
      District: "Balasore",
      Court: "Court Complex, Nilagiri",
    },
    {
      State: "Odisha",
      District: "Balasore",
      Court: "Collectorate Complex, Balasore",
    },
    {
      State: "Odisha",
      District: "Balasore",
      Court: "Kacheri Complex, Nilagiri",
    },
    {
      State: "Odisha",
      District: "Bargarh",
      Court: "Civil Court Complex, Sohella",
    },
    {
      State: "Odisha",
      District: "Bargarh",
      Court: "Fast Track Court Complex, Padmapur",
    },
    {
      State: "Odisha",
      District: "Bargarh",
      Court: "Sub Judge Court Complex, Padmapur",
    },
    {
      State: "Odisha",
      District: "Bargarh",
      Court: "Civil Court Complex, Barapalli",
    },
    {
      State: "Odisha",
      District: "Bargarh",
      Court: "Civil Court Complex, Bargarh",
    },
    {
      State: "Odisha",
      District: "Bhadrak",
      Court: "Civil Court Complex, Chandbali",
    },
    {
      State: "Odisha",
      District: "Bhadrak",
      Court: "Civil Court Complex, Bhadrak",
    },
    {
      State: "Odisha",
      District: "Bhadrak",
      Court: "Court Complex, Basudevpur",
    },
    {
      State: "Odisha",
      District: "Bhadrak",
      Court: "Civil Court Complex, Dhamnagar",
    },
    {
      State: "Odisha",
      District: "Boudh",
      Court: "Civil Court Complex, Kantamal",
    },
    {
      State: "Odisha",
      District: "Boudh",
      Court: "Civil Court Complex, Boudh",
    },
    {
      State: "Odisha",
      District: "Cuttack",
      Court: "JMFC Court Complex,Tigiria",
    },
    {
      State: "Odisha",
      District: "Cuttack",
      Court: "Civil Court Complex Salipur",
    },
    {
      State: "Odisha",
      District: "Cuttack",
      Court: "Civil Court Complex, Narasinghpur",
    },
    {
      State: "Odisha",
      District: "Cuttack",
      Court: "Collectorate Campus, Cuttack",
    },
    {
      State: "Odisha",
      District: "Cuttack",
      Court: "Civil Court Complex, Baramba",
    },
    {
      State: "Odisha",
      District: "Cuttack",
      Court: "Civil Court Complex, Banki",
    },
    {
      State: "Odisha",
      District: "Cuttack",
      Court: "Civil Court Complex, Athagarh",
    },
    {
      State: "Odisha",
      District: "Cuttack",
      Court: "Civil Courts Complex, Cuttack",
    },
    {
      State: "Odisha",
      District: "Deogarh",
      Court: "District Court Complex, Deogarh",
    },
    {
      State: "Odisha",
      District: "Dhenkanal",
      Court: "Civil Court Complex, Dhenkanal",
    },
    {
      State: "Odisha",
      District: "Dhenkanal",
      Court: "Civil Court Complex, Hindol",
    },
    {
      State: "Odisha",
      District: "Dhenkanal",
      Court: "Civil Court Complex, Kamakshyanagar",
    },
    {
      State: "Odisha",
      District: "Gajapati",
      Court: "Civil Court Complex, Parlakhemundi",
    },
    {
      State: "Odisha",
      District: "Gajapati",
      Court: "Civil Court Complex, R. Udayagiri",
    },
    {
      State: "Odisha",
      District: "Ganjam",
      Court: "District Court Complex, Berhampur",
    },
    {
      State: "Odisha",
      District: "Ganjam",
      Court: "Civil Court Complex, Khallikote",
    },
    {
      State: "Odisha",
      District: "Ganjam",
      Court: "SDJM Court Complex, Berhampur",
    },
    {
      State: "Odisha",
      District: "Ganjam",
      Court: "Civil Court Complex, Aska",
    },
    {
      State: "Odisha",
      District: "Ganjam",
      Court: "Civil Court Complex, Bhanjanagar",
    },
    {
      State: "Odisha",
      District: "Ganjam",
      Court: "Civil Court Complex, Digapahandi",
    },
    {
      State: "Odisha",
      District: "Ganjam",
      Court: "Civil Court Complex, Kodala",
    },
    {
      State: "Odisha",
      District: "Ganjam",
      Court: "Civil Court Complex, Patrapur",
    },
    {
      State: "Odisha",
      District: "Ganjam",
      Court: "JMFC Court Complex, Soroda",
    },
    {
      State: "Odisha",
      District: "Ganjam",
      Court: "Civil Court Complex, Purushottampur",
    },
    {
      State: "Odisha",
      District: "Ganjam",
      Court: "Court Complex, Chhatrapur",
    },
    {
      State: "Odisha",
      District: "Ganjam",
      Court: "Civil Court , HINJILI",
    },
    {
      State: "Odisha",
      District: "Ganjam",
      Court: "Gram Nayalaya, Sanakhemundi",
    },
    {
      State: "Odisha",
      District: "Jagatsinghpur",
      Court: "Civil Court Complex, Kujanga",
    },
    {
      State: "Odisha",
      District: "Jagatsinghpur",
      Court: "Revenue Building, Jagatsinghpur",
    },
    {
      State: "Odisha",
      District: "Jagatsinghpur",
      Court: "D.I. of School Building, Jagatsinghpur",
    },
    {
      State: "Odisha",
      District: "Jajpur",
      Court: "JMFC Court Complex, Jajpur Road",
    },
    {
      State: "Odisha",
      District: "Jajpur",
      Court: "Civil Court Complex, Jajpur",
    },
    {
      State: "Odisha",
      District: "Jharsuguda",
      Court: "SDJM Court Complex",
    },
    {
      State: "Odisha",
      District: "Jharsuguda",
      Court: "District Court Complex, Jharsuguda",
    },
    {
      State: "Odisha",
      District: "Kalahandi",
      Court: "Civil Court Complex, Dharamgarh",
    },
    {
      State: "Odisha",
      District: "Kalahandi",
      Court: "Civil Court Complex, Junagarh",
    },
    {
      State: "Odisha",
      District: "Kalahandi",
      Court: "District Court Complex, Bhawanipatna",
    },
    {
      State: "Odisha",
      District: "Kalahandi",
      Court: "CJM Court Complex, Bhawanipatna",
    },
    {
      State: "Odisha",
      District: "Kalahandi",
      Court: "JMFC Court Complex, Kesinga",
    },
    {
      State: "Odisha",
      District: "Kalahandi",
      Court: "JMFC Court Complex, Jaipatna",
    },
    {
      State: "Odisha",
      District: "Kalahandi",
      Court: "Civil Court Complex, M.Rampur",
    },
    {
      State: "Odisha",
      District: "Kandhamal",
      Court: "Civil Court Complex, Daringibadi",
    },
    {
      State: "Odisha",
      District: "Kandhamal",
      Court: "Civil Court Complex, Phulbani",
    },
    {
      State: "Odisha",
      District: "Kandhamal",
      Court: "Civil Court Complex, Baliguda",
    },
    {
      State: "Odisha",
      District: "Kandhamal",
      Court: "Civil Court Complex, G. Udayagiri",
    },
    {
      State: "Odisha",
      District: "Kendrapada",
      Court: "Civil Court Complex, Kendrapara",
    },
    {
      State: "Odisha",
      District: "Keonjhar",
      Court: "Civil Court Complex, Champua",
    },
    {
      State: "Odisha",
      District: "Keonjhar",
      Court: "District & Sessions Judge, Keonjhar",
    },
    {
      State: "Odisha",
      District: "Keonjhar",
      Court: "Gramnayalaya Ghasipura",
    },
    {
      State: "Odisha",
      District: "Keonjhar",
      Court: "SDJM Court Complex, Badbil",
    },
    {
      State: "Odisha",
      District: "Keonjhar",
      Court: "Civil Court Complex, Anandpur",
    },
    {
      State: "Odisha",
      District: "Khurda",
      Court: "JMFC Court Complex, Banapur",
    },
    {
      State: "Odisha",
      District: "Khurda",
      Court: "Civil Court Complex, Khurda",
    },
    {
      State: "Odisha",
      District: "Khurda",
      Court: "Civil Court Complex, Bhubaneswar",
    },
    {
      State: "Odisha",
      District: "Khurda",
      Court: "Civil Court Complex, Jatni",
    },
    {
      State: "Odisha",
      District: "Koraput",
      Court: "Civil Court Complex, Laxmipur",
    },
    {
      State: "Odisha",
      District: "Koraput",
      Court: "Civil Court Complex, Jeypore",
    },
    {
      State: "Odisha",
      District: "Koraput",
      Court: "Civil Court Complex, Kotpad",
    },
    {
      State: "Odisha",
      District: "Koraput",
      Court: "SDJM Court Complex, Koraput",
    },
    {
      State: "Odisha",
      District: "Koraput",
      Court: "GRAM NYAYALAYA,SEMILIGUDA",
    },
    {
      State: "Odisha",
      District: "Malkangiri",
      Court: "Revenue Court Complex, Malkangiri",
    },
    {
      State: "Odisha",
      District: "Malkangiri",
      Court: "SDJM Court Complex, Malkangiri",
    },
    {
      State: "Odisha",
      District: "Malkangiri",
      Court: "Civil Court Complex, Mottu",
    },
    {
      State: "Odisha",
      District: "Mayurbhanj",
      Court: "SDJM Court Complex, Rairangpur",
    },
    {
      State: "Odisha",
      District: "Mayurbhanj",
      Court: "SDJM Court Complex, Udalla",
    },
    {
      State: "Odisha",
      District: "Mayurbhanj",
      Court: "Civil Court Complex, Karanjia",
    },
    {
      State: "Odisha",
      District: "Mayurbhanj",
      Court: "District Court Complex, Baripada",
    },
    {
      State: "Odisha",
      District: "Mayurbhanj",
      Court: "Civil Judge-cum- J.M.F.C., Betnoti",
    },
    {
      State: "Odisha",
      District: "Nabarangpur",
      Court: "District Court Complex, Nabarangpur",
    },
    {
      State: "Odisha",
      District: "Nabarangpur",
      Court: "Civil Court Complex Umerkotte",
    },
    {
      State: "Odisha",
      District: "Nayagarh",
      Court: "JMFC Court Complex, Khandapara",
    },
    {
      State: "Odisha",
      District: "Nayagarh",
      Court: "Civil Court Complex, Ranapur",
    },
    {
      State: "Odisha",
      District: "Nayagarh",
      Court: "District Court Complex, Nayagarh",
    },
    {
      State: "Odisha",
      District: "Nayagarh",
      Court: "JMFC Court Complex, Daspalla",
    },
    {
      State: "Odisha",
      District: "Nayagarh",
      Court: "Civil Court Complex, Odagaon",
    },
    {
      State: "Odisha",
      District: "Nuapada",
      Court: "ADJ Court Complex, Nuapada",
    },
    {
      State: "Odisha",
      District: "Nuapada",
      Court: "Civil Court Complex, Khariar",
    },
    {
      State: "Odisha",
      District: "Nuapada",
      Court: "Sub Judge Court Complex, Nuapada",
    },
    {
      State: "Odisha",
      District: "Puri",
      Court: "Civil Court Complex, Pipilli",
    },
    {
      State: "Odisha",
      District: "Puri",
      Court: "Civil Court Complex, Nimapara",
    },
    {
      State: "Odisha",
      District: "Puri",
      Court: "Old Civil Court Complex, Puri",
    },
    {
      State: "Odisha",
      District: "Puri",
      Court: "New Civil Court Complex, Puri",
    },
    {
      State: "Odisha",
      District: "Rayagada",
      Court: "ADJ Court Complex, Rayagada",
    },
    {
      State: "Odisha",
      District: "Rayagada",
      Court: "Civil Court Complex, Kashipur",
    },
    {
      State: "Odisha",
      District: "Rayagada",
      Court: "Civil Court Complex, Gunupur",
    },
    {
      State: "Odisha",
      District: "Rayagada",
      Court: "Civil Court Complex, Bissamcuttack",
    },
    {
      State: "Odisha",
      District: "Sambalpur",
      Court: "Civil Court Complex, Rairakhol",
    },
    {
      State: "Odisha",
      District: "Sambalpur",
      Court: "Old SDJM Court Complex, Sambalpur",
    },
    {
      State: "Odisha",
      District: "Sambalpur",
      Court: "SDJM Kunchinda",
    },
    {
      State: "Odisha",
      District: "Sambalpur",
      Court: "Civil Court Complex, Kuchinda",
    },
    {
      State: "Odisha",
      District: "Sambalpur",
      Court: "New Civil Court Complex Sambalpur",
    },
    {
      State: "Odisha",
      District: "Sonepur",
      Court: "Chief Judicial Magistrate , Biramaharajpur",
    },
    {
      State: "Odisha",
      District: "Sonepur",
      Court: "Civil Court Complex, Sonepur",
    },
    {
      State: "Odisha",
      District: "Sundargarh",
      Court: "Civil Court Complex, Rourkela at Birjapalli",
    },
    {
      State: "Odisha",
      District: "Sundargarh",
      Court: "Civil Court Complex, Bonai",
    },
    {
      State: "Odisha",
      District: "Sundargarh",
      Court: "JMFC Court Complex, Rajgangpur",
    },
    {
      State: "Odisha",
      District: "Sundargarh",
      Court: "District Court Complex, Sundargarh",
    },
    {
      State: "Puducherry",
      District: "Karaikal",
      Court: "Combined Courts, Karaikal",
    },
    {
      State: "Puducherry",
      District: "Mahe",
      Court: "Sub Court Complex , Mahe",
    },
    {
      State: "Puducherry",
      District: "Puducherry",
      Court: "Integrated Courts, Puducherry",
    },
    {
      State: "Puducherry",
      District: "Yanam",
      Court: "Sub Court Complex, Yanam",
    },
    {
      State: "Punjab",
      District: "Amritsar",
      Court: "Judicial Complex, Baba Bakala",
    },
    {
      State: "Punjab",
      District: "Amritsar",
      Court: "Judicial Complex, Ajnala",
    },
    {
      State: "Punjab",
      District: "Amritsar",
      Court: "District Court, Amritsar",
    },
    {
      State: "Punjab",
      District: "Barnala",
      Court: "District Court, Barnala",
    },
    {
      State: "Punjab",
      District: "Bathinda",
      Court: "District as Court, Bathinda",
    },
    {
      State: "Punjab",
      District: "Bathinda",
      Court: "Judicial Complex, Phul",
    },
    {
      State: "Punjab",
      District: "Bathinda",
      Court: "Judicial Complex, Talwandi Sabo",
    },
    {
      State: "Punjab",
      District: "Faridkot",
      Court: "District Court, Faridkot",
    },
    {
      State: "Punjab",
      District: "Faridkot",
      Court: "Judicial Complex, Jaitu",
    },
    {
      State: "Punjab",
      District: "Fatehgarh Sahib",
      Court: "Judicial Complex, Amloh",
    },
    {
      State: "Punjab",
      District: "Fatehgarh Sahib",
      Court: "District Court, Fatehgarh Sahib",
    },
    {
      State: "Punjab",
      District: "Fatehgarh Sahib",
      Court: "Judicial Complex, Khamanon",
    },
    {
      State: "Punjab",
      District: "Fazilka",
      Court: "District Court, Fazilka",
    },
    {
      State: "Punjab",
      District: "Fazilka",
      Court: "Judicial Complex, Jalalabad",
    },
    {
      State: "Punjab",
      District: "Fazilka",
      Court: "Judicial Court Complex, Abohar",
    },
    {
      State: "Punjab",
      District: "Ferozepur",
      Court: "Judicial Courts Complex, Zira.",
    },
    {
      State: "Punjab",
      District: "Ferozepur",
      Court: "District and Sessions Court Complex, Ferozepur",
    },
    {
      State: "Punjab",
      District: "Ferozepur",
      Court: "Judicial Complex, Guruharsahai",
    },
    {
      State: "Punjab",
      District: "Gurdaspur",
      Court: "District Court, Gurdaspur",
    },
    {
      State: "Punjab",
      District: "Gurdaspur",
      Court: "Judicial Complex, Batala",
    },
    {
      State: "Punjab",
      District: "Hoshiarpur",
      Court: "Judicial Courts Complex, Mukerian.",
    },
    {
      State: "Punjab",
      District: "Hoshiarpur",
      Court: "Judicial Complex, Dasuya",
    },
    {
      State: "Punjab",
      District: "Hoshiarpur",
      Court: "District Court, Hoshiarpur",
    },
    {
      State: "Punjab",
      District: "Hoshiarpur",
      Court: "Judicial Complex, Garhshankar",
    },
    {
      State: "Punjab",
      District: "Jalandhar",
      Court: "Judicial Complex, Phillaur",
    },
    {
      State: "Punjab",
      District: "Jalandhar",
      Court: "Judicial Complex, Nakodar",
    },
    {
      State: "Punjab",
      District: "Jalandhar",
      Court: "District Court, Jalandhar",
    },
    {
      State: "Punjab",
      District: "Kapurthala",
      Court: "District Court, Kapurthala",
    },
    {
      State: "Punjab",
      District: "Kapurthala",
      Court: "Judicial Complex, Sultanpur Lodhi",
    },
    {
      State: "Punjab",
      District: "Kapurthala",
      Court: "Judicial Complex, Bholath",
    },
    {
      State: "Punjab",
      District: "Kapurthala",
      Court: "Judicial Complex, Phagwara",
    },
    {
      State: "Punjab",
      District: "Ludhiana",
      Court: "Judicial Complex, Samrala",
    },
    {
      State: "Punjab",
      District: "Ludhiana",
      Court: "Judicial Complex, Payal",
    },
    {
      State: "Punjab",
      District: "Ludhiana",
      Court: "Judicial Complex, Jagraon",
    },
    {
      State: "Punjab",
      District: "Ludhiana",
      Court: "Judicial Complex, Khanna",
    },
    {
      State: "Punjab",
      District: "Ludhiana",
      Court: "District Court, Ludhiana",
    },
    {
      State: "Punjab",
      District: "Mansa",
      Court: "District Court Complex, Mansa",
    },
    {
      State: "Punjab",
      District: "Mansa",
      Court: "Judicial Complex, Budhlada",
    },
    {
      State: "Punjab",
      District: "Mansa",
      Court: "Judicial Complex, Sardulgarh",
    },
    {
      State: "Punjab",
      District: "Moga",
      Court: "Judicial Complex, Nihalsinghwala",
    },
    {
      State: "Punjab",
      District: "Moga",
      Court: "Judicial Complex, Baghapurana",
    },
    {
      State: "Punjab",
      District: "Moga",
      Court: "District Court, Moga",
    },
    {
      State: "Punjab",
      District: "Moga",
      Court: "Gram Nyayalay Kot Ise Khan",
    },
    {
      State: "Punjab",
      District: "Mohali",
      Court: "Judicial Complex, Kharar",
    },
    {
      State: "Punjab",
      District: "Mohali",
      Court: "District Court, SAS Nagar",
    },
    {
      State: "Punjab",
      District: "Mohali",
      Court: "Judicial Court, Derabassi",
    },
    {
      State: "Punjab",
      District: "Pathankot",
      Court: "District Court, Pathankot",
    },
    {
      State: "Punjab",
      District: "Patiala",
      Court: "District Court, Patiala",
    },
    {
      State: "Punjab",
      District: "Patiala",
      Court: "Judicial Complex, Samana",
    },
    {
      State: "Punjab",
      District: "Patiala",
      Court: "Judicial Complex, Rajpura",
    },
    {
      State: "Punjab",
      District: "Patiala",
      Court: "Judicial Complex, Nabha",
    },
    {
      State: "Punjab",
      District: "Rupnagar",
      Court: "District Courts, Rupnagar",
    },
    {
      State: "Punjab",
      District: "Rupnagar",
      Court: "Judicial Complex, Anandpur Sahib",
    },
    {
      State: "Punjab",
      District: "Rupnagar",
      Court: "Judicial Complex, Nangal",
    },
    {
      State: "Punjab",
      District: "Sangrur",
      Court: "District Court, Sangrur",
    },
    {
      State: "Punjab",
      District: "Sangrur",
      Court: "Judicial Complex, Dhuri",
    },
    {
      State: "Punjab",
      District: "Sangrur",
      Court: "Judicial Complex, Sunam",
    },
    {
      State: "Punjab",
      District: "Sangrur",
      Court: "Judicial Complex, Moonak",
    },
    {
      State: "Punjab",
      District: "Sangrur",
      Court: "Judicial Complex, Malerkotla",
    },
    {
      State: "Punjab",
      District: "SBS Nagar",
      Court: "Family Complex, Balachaur",
    },
    {
      State: "Punjab",
      District: "SBS Nagar",
      Court: "Judicial Complex, Balachaur",
    },
    {
      State: "Punjab",
      District: "SBS Nagar",
      Court: "District Court, SBS Nagar",
    },
    {
      State: "Punjab",
      District: "Sri Muktsar Sahib",
      Court: "Judicial Complex, Malout",
    },
    {
      State: "Punjab",
      District: "Sri Muktsar Sahib",
      Court: "District Court, Sri Muktsar Sahib",
    },
    {
      State: "Punjab",
      District: "Sri Muktsar Sahib",
      Court: "Judicial Complex, Gidderbaha",
    },
    {
      State: "Punjab",
      District: "Tarn Taran",
      Court: "Judicial Complex, Khadur Sahib",
    },
    {
      State: "Punjab",
      District: "Tarn Taran",
      Court: "Judicial Complex, Patti",
    },
    {
      State: "Punjab",
      District: "Tarn Taran",
      Court: "District Court, Tarn Taran",
    },
    {
      State: "Rajasthan",
      District: "Ajmer",
      Court: "Old R.P.S.C. Building Ajmer",
    },
    {
      State: "Rajasthan",
      District: "Ajmer",
      Court: "Gram Nyayalay Pisangan CourtComplex",
    },
    {
      State: "Rajasthan",
      District: "Ajmer",
      Court: "Kekri Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Ajmer",
      Court: "Ajmer Railway Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Ajmer",
      Court: "Ajmer District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Ajmer",
      Court: "Sarwar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Ajmer",
      Court: "Pushkar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Ajmer",
      Court: "Nasirabad Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Ajmer",
      Court: "Bijay Nagar Court Compex",
    },
    {
      State: "Rajasthan",
      District: "Ajmer",
      Court: "Beawar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Ajmer",
      Court: "Kishangarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "Neemrana Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "Kishangarh Bas Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "JJB Court Complex Alwar",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "Thangazi Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "Behror Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "Bansur Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "Kherli Mandi Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "Bhiwadi Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "Malakhera Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "Laxmangarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "Kotkasim Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "Mundawar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "Kathumar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "Rajgarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "Alwar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "Tijara Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Alwar",
      Court: "Ramgarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Balotra Barmer",
      Court: "Balotra Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Balotra Barmer",
      Court: "Barmer Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Balotra Barmer",
      Court: "Siwana Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Balotra Barmer",
      Court: "Chouthan Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Balotra Barmer",
      Court: "Pachpadra Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Balotra Barmer",
      Court: "Gudamalani Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Banswara",
      Court: "Bagidora Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Banswara",
      Court: "Ghatol Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Banswara",
      Court: "JJB Court Complex Banswara",
    },
    {
      State: "Rajasthan",
      District: "Banswara",
      Court: "Banswara Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Banswara",
      Court: "Garhi Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Banswara",
      Court: "Kushalgarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Banswara",
      Court: "Gram Nyayalaya Talwara Court Comple",
    },
    {
      State: "Rajasthan",
      District: "Banswara",
      Court: "Aanandpuri Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Baran",
      Court: "JJB Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Baran",
      Court: "Shahbad Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Baran",
      Court: "Kishanganj Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Baran",
      Court: "Baran Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Baran",
      Court: "Chhabra Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Baran",
      Court: "Anta Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Baran",
      Court: "Chhipabarod Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Baran",
      Court: "Mangrol Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Baran",
      Court: "Atru Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Baran",
      Court: "Family Court Complex Baran",
    },
    {
      State: "Rajasthan",
      District: "Bharatpur",
      Court: "JJB Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bharatpur",
      Court: "COURT COMPLEX, KAMAN",
    },
    {
      State: "Rajasthan",
      District: "Bharatpur",
      Court: "Bayana ADJ Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bharatpur",
      Court: "Weir Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bharatpur",
      Court: "COURT COMPLEX, NAGAR",
    },
    {
      State: "Rajasthan",
      District: "Bharatpur",
      Court: "Roopbas Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bharatpur",
      Court: "COLLECTORATE CAMPUS, BHARATPUR",
    },
    {
      State: "Rajasthan",
      District: "Bharatpur",
      Court: "Kumher Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bharatpur",
      Court: "Bharatpur Railway Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bharatpur",
      Court: "Bhusawar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bharatpur",
      Court: "Gram Nyayalaya Roopwas Court Comple",
    },
    {
      State: "Rajasthan",
      District: "Bharatpur",
      Court: "Deeg Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bharatpur",
      Court: "Bharatpur Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bharatpur",
      Court: "TEHSIL COMPLEX, NADBAI",
    },
    {
      State: "Rajasthan",
      District: "Bharatpur",
      Court: "Ucchain Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bhilwara",
      Court: "Gram Nyayalaya Suwana Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bhilwara",
      Court: "Labour Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bhilwara",
      Court: "Gram Nyayalaya Mandal Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bhilwara",
      Court: "Mandal Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bhilwara",
      Court: "JJB Court Complex Bhilwara",
    },
    {
      State: "Rajasthan",
      District: "Bhilwara",
      Court: "Gangapur Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bhilwara",
      Court: "Jahazpur Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bhilwara",
      Court: "Shahpura Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bhilwara",
      Court: "Gulabpura Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bhilwara",
      Court: "Kotari Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bhilwara",
      Court: "Asind Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bhilwara",
      Court: "Mandalgarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bhilwara",
      Court: "Bijolian Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bhilwara",
      Court: "Bhilwara District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bhilwara",
      Court: "Raipur Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bikaner",
      Court: "Bikaner Rly Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bikaner",
      Court: "Kolayat Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bikaner",
      Court: "Sridungargarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bikaner",
      Court: "Nokha Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bikaner",
      Court: "Bikaner District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bikaner",
      Court: "Loonkaransar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bikaner",
      Court: "JJB Court Complex Bikaner",
    },
    {
      State: "Rajasthan",
      District: "Bikaner",
      Court: "Khajuwala Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bundi",
      Court: "Keshorapatan Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bundi",
      Court: "Bundi Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bundi",
      Court: "Indergarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bundi",
      Court: "Talera Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bundi",
      Court: "JJB Court Complex Bundi",
    },
    {
      State: "Rajasthan",
      District: "Bundi",
      Court: "Lakheri Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bundi",
      Court: "Nainwa Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Bundi",
      Court: "Family Court Complex Bundi",
    },
    {
      State: "Rajasthan",
      District: "Bundi",
      Court: "Hindoli Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Chittorgarh",
      Court: "Chittorgarh District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Chittorgarh",
      Court: "Mandphiya Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Chittorgarh",
      Court: "Nimbahera Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Chittorgarh",
      Court: "Rawatbhata Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Chittorgarh",
      Court: "Bari Sadri Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Chittorgarh",
      Court: "Rashmi Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Chittorgarh",
      Court: "Kapasan Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Chittorgarh",
      Court: "Bhadesar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Chittorgarh",
      Court: "Gangrar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Chittorgarh",
      Court: "Dungla Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Chittorgarh",
      Court: "Begu Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Chittorgarh",
      Court: "JJB Court Complex Chittorgarh",
    },
    {
      State: "Rajasthan",
      District: "Churu",
      Court: "Rajgarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Churu",
      Court: "Churu District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Churu",
      Court: "Sujangarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Churu",
      Court: "JJB Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Churu",
      Court: "Ratangarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Churu",
      Court: "Sardarshahar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Churu",
      Court: "Taranagar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Churu",
      Court: "Bidasar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dausa",
      Court: "Mahwa Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dausa",
      Court: "JJB Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dausa",
      Court: "Sikrai Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dausa",
      Court: "Bandikui Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dausa",
      Court: "Dausa District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dausa",
      Court: "Lalsot Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dholpur",
      Court: "Baseri Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dholpur",
      Court: "Rajakhera Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dholpur",
      Court: "ADJ Bari Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dholpur",
      Court: "Dholpur District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dholpur",
      Court: "Bari Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dholpur",
      Court: "JJB Court Complex Dholpur",
    },
    {
      State: "Rajasthan",
      District: "Dholpur",
      Court: "Saipau Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dholpur",
      Court: "Sarmathura Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dungarpur",
      Court: "Simalwara Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dungarpur",
      Court: "Gram Nyayalaya Bichhiwara Complex",
    },
    {
      State: "Rajasthan",
      District: "Dungarpur",
      Court: "JJB Court Complex Dungarpur",
    },
    {
      State: "Rajasthan",
      District: "Dungarpur",
      Court: "Aaspur Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dungarpur",
      Court: "Sagwara Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Dungarpur",
      Court: "Dungarpur Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Ganganagar",
      Court: "Sadulshahar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Ganganagar",
      Court: "Sriganganagar District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Ganganagar",
      Court: "Anupgarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Ganganagar",
      Court: "Gharsana Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Ganganagar",
      Court: "Karanpur Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Ganganagar",
      Court: "Padampur Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Ganganagar",
      Court: "Sriganganagar Labour Court Premises",
    },
    {
      State: "Rajasthan",
      District: "Ganganagar",
      Court: "Vijaynagar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Ganganagar",
      Court: "Suratgarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Ganganagar",
      Court: "Raisinghnagar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Hanumangarh",
      Court: "Rawatsar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Hanumangarh",
      Court: "Hanumangarh District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Hanumangarh",
      Court: "Pilibanga Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Hanumangarh",
      Court: "Gram Nyayalaya Hanumangarh Court Co",
    },
    {
      State: "Rajasthan",
      District: "Hanumangarh",
      Court: "Sangaria Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Hanumangarh",
      Court: "Tibbi Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Hanumangarh",
      Court: "Nohar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Hanumangarh",
      Court: "JJB Court Complex Hanumangarh",
    },
    {
      State: "Rajasthan",
      District: "Hanumangarh",
      Court: "Bhadra Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaipur District",
      Court: "Rawatsar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaipur District",
      Court: "Hanumangarh District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaipur District",
      Court: "Pilibanga Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaipur District",
      Court: "Gram Nyayalaya Hanumangarh Court Co",
    },
    {
      State: "Rajasthan",
      District: "Jaipur District",
      Court: "Sangaria Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaipur District",
      Court: "Tibbi Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaipur District",
      Court: "Nohar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaipur District",
      Court: "JJB Court Complex Hanumangarh",
    },
    {
      State: "Rajasthan",
      District: "Jaipur District",
      Court: "Bhadra Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaipur Metro I",
      Court: "Sanganer Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaipur Metro I",
      Court: "Jaipur Waqf Board Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaipur Metro I",
      Court: "Chaksu court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaipur Metro I",
      Court: "Jaipur Nagar Nigam Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaipur Metro I",
      Court: "Mini Sectt. Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaipur Metro I",
      Court: "Family Court Complex Jaipur",
    },
    {
      State: "Rajasthan",
      District: "Jaipur Metro I",
      Court: "Bassi Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaipur Metro I",
      Court: "JJB Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaipur Metro I",
      Court: "JAIPUR METRO COURT COMPLEX I",
    },
    {
      State: "Rajasthan",
      District: "Jaipur Metro II",
      Court: "Chomu Court Complex Jaipur Metro",
    },
    {
      State: "Rajasthan",
      District: "Jaipur Metro II",
      Court: "Commercial Court Complex Jaipur Met",
    },
    {
      State: "Rajasthan",
      District: "Jaipur Metro II",
      Court: "Mini Sectt Jaipur Metro II",
    },
    {
      State: "Rajasthan",
      District: "Jaipur Metro II",
      Court: "JDA PREMISSES",
    },
    {
      State: "Rajasthan",
      District: "Jaipur Metro II",
      Court: "JAIPUR METRO COURT COMPLEX II",
    },
    {
      State: "Rajasthan",
      District: "Jaipur Metro II",
      Court: "Jaipur Railway Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaipur Metro II",
      Court: "Amer Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaisalmer",
      Court: "Jaisalmer District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaisalmer",
      Court: "Pokran Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaisalmer",
      Court: "Jaisalmer ADJ Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jaisalmer",
      Court: "JJB Court Complex Jaisalmer",
    },
    {
      State: "Rajasthan",
      District: "Jaisalmer",
      Court: "Gram Nyayalya Sankra Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jalore",
      Court: "Bhinmal Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jalore",
      Court: "Sanchore Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jalore",
      Court: "Raniwara Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jalore",
      Court: "Jalore District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jalore",
      Court: "AHORE Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jhalawar",
      Court: "Manoharthana Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jhalawar",
      Court: "Khanpur Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jhalawar",
      Court: "Aklera Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jhalawar",
      Court: "Choumehla Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jhalawar",
      Court: "Jhalawar District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jhalawar",
      Court: "Bhawani Mandi Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jhalawar",
      Court: "Pirawa Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jhalawar",
      Court: "Jhalarapatan Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jhunjhunu",
      Court: "Buhana Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jhunjhunu",
      Court: "JJB Court Complex Jhunjhunu",
    },
    {
      State: "Rajasthan",
      District: "Jhunjhunu",
      Court: "Chirawa Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jhunjhunu",
      Court: "Jhunjhunu District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jhunjhunu",
      Court: "Pilani Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jhunjhunu",
      Court: "Nawalgarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jhunjhunu",
      Court: "Udaipurwati Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jhunjhunu",
      Court: "Khetri Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jhunjhunu",
      Court: "Surajgarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jodhpur District",
      Court: "Piparcity Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jodhpur District",
      Court: "Phalodi Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jodhpur District",
      Court: "Jodhpur District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jodhpur District",
      Court: "Balesar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jodhpur District",
      Court: "Bilara Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jodhpur District",
      Court: "Osian Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jodhpur District",
      Court: "Lohawat Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jodhpur District",
      Court: "Bap Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jodhpur District",
      Court: "Bhopalgarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Jodhpur Metro",
      Court: "Labour Court Complex Jodhpur Metro",
    },
    {
      State: "Rajasthan",
      District: "Jodhpur Metro",
      Court: "JJB Court Complex Jodhpur Metro",
    },
    {
      State: "Rajasthan",
      District: "Jodhpur Metro",
      Court: "Mandore Court Complex Jodhpur Metro",
    },
    {
      State: "Rajasthan",
      District: "Jodhpur Metro",
      Court: "Family Court Complex Jodhpur Metro",
    },
    {
      State: "Rajasthan",
      District: "Jodhpur Metro",
      Court: "Railway Court Complex Jodhpur Metro",
    },
    {
      State: "Rajasthan",
      District: "Jodhpur Metro",
      Court: "Jodhpur Metro Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Karauli",
      Court: "Sapotra Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Karauli",
      Court: "JJB Court Complex Karauli",
    },
    {
      State: "Rajasthan",
      District: "Karauli",
      Court: "MACT Court Complex Karauli",
    },
    {
      State: "Rajasthan",
      District: "Karauli",
      Court: "Srimahaveerji Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Karauli",
      Court: "Karauli District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Karauli",
      Court: "Todabhim Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Karauli",
      Court: "Hindauncity Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Karauli",
      Court: "Nadauti Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Kota",
      Court: "Itawa Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Kota",
      Court: "Sangod Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Kota",
      Court: "Kanwas Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Kota",
      Court: "Kota District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Kota",
      Court: "Kota MACT Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Kota",
      Court: "JJB Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Kota",
      Court: "Ramganjmandi Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Kota",
      Court: "Digod Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Kota",
      Court: "Family Court Complex Kota",
    },
    {
      State: "Rajasthan",
      District: "Kota",
      Court: "Kota Railway Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Merta Nagaur",
      Court: "Didwana Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Merta Nagaur",
      Court: "Nawa Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Merta Nagaur",
      Court: "Ladnun Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Merta Nagaur",
      Court: "Degana Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Merta Nagaur",
      Court: "Jayal Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Merta Nagaur",
      Court: "Nagaur Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Merta Nagaur",
      Court: "Parbatsar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Merta Nagaur",
      Court: "Kuchamancity Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Merta Nagaur",
      Court: "Mertacity District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Merta Nagaur",
      Court: "Makrana Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Pali",
      Court: "Pali District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Pali",
      Court: "JJB Court Complex Pali",
    },
    {
      State: "Rajasthan",
      District: "Pali",
      Court: "Sumerpur Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Pali",
      Court: "Raipur Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Pali",
      Court: "Sadri Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Pali",
      Court: "Bali Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Pali",
      Court: "Bar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Pali",
      Court: "Desuri Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Pali",
      Court: "Sojat Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Pali",
      Court: "Jaitaran Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Pali",
      Court: "Marwar Jn. Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Pali",
      Court: "Rani Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Pratapgarh",
      Court: "Pratapgarh District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Pratapgarh",
      Court: "Dhariyawad Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Pratapgarh",
      Court: "JJB Pratapgarh District HQ",
    },
    {
      State: "Rajasthan",
      District: "Pratapgarh",
      Court: "Chhoti Sadri Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Pratapgarh",
      Court: "Arnod Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Rajsamand",
      Court: "Family Court Complex Rajsamand",
    },
    {
      State: "Rajasthan",
      District: "Rajsamand",
      Court: "JJB Court Complex Rajsamand",
    },
    {
      State: "Rajasthan",
      District: "Rajsamand",
      Court: "Bhim Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Rajsamand",
      Court: "Kumbhalgarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Rajsamand",
      Court: "Nathdwara Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Rajsamand",
      Court: "Railmagra Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Rajsamand",
      Court: "Rajsamand Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Rajsamand",
      Court: "Deogarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Rajsamand",
      Court: "Amet Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sawai Madhopur",
      Court: "Family court complex",
    },
    {
      State: "Rajasthan",
      District: "Sawai Madhopur",
      Court: "Gangapurcity Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sawai Madhopur",
      Court: "Sawaimadhopur District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sawai Madhopur",
      Court: "Bonli Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sawai Madhopur",
      Court: "Khandar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sawai Madhopur",
      Court: "Bamanwas Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sawai Madhopur",
      Court: "JJB court complex Sawaimadhopur",
    },
    {
      State: "Rajasthan",
      District: "Sikar",
      Court: "Laxmangarh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sikar",
      Court: "Gram Nyayalaya Kudli Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sikar",
      Court: "Srimadhopur Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sikar",
      Court: "JJB Court Complex Sikar",
    },
    {
      State: "Rajasthan",
      District: "Sikar",
      Court: "Fatehpur Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sikar",
      Court: "Reengus Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sikar",
      Court: "Danta Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sikar",
      Court: "Neemkathana Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sikar",
      Court: "Sikar District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sikar",
      Court: "Khandela Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sikar",
      Court: "DHOD Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sirohi",
      Court: "Reodar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sirohi",
      Court: "Mt. Abu Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sirohi",
      Court: "Sirohi District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sirohi",
      Court: "Sheoganj Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sirohi",
      Court: "Abu Road Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Sirohi",
      Court: "Pindwara Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Tonk",
      Court: "Tonk District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Tonk",
      Court: "Family Court Complex Tonk",
    },
    {
      State: "Rajasthan",
      District: "Tonk",
      Court: "JJB Court Complex Tonk",
    },
    {
      State: "Rajasthan",
      District: "Tonk",
      Court: "Uniara Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Tonk",
      Court: "Deoli Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Tonk",
      Court: "Malpura Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Tonk",
      Court: "Niwai Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Tonk",
      Court: "Todaraisingh Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Tonk",
      Court: "Dooni Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Tonk",
      Court: "Peeplu Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Udaipur",
      Court: "JJB Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Udaipur",
      Court: "Udaipur District Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Udaipur",
      Court: "Bhindar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Udaipur",
      Court: "Sarada Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Udaipur",
      Court: "Vallabhnagar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Udaipur",
      Court: "Salumbar Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Udaipur",
      Court: "Mavli Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Udaipur",
      Court: "Kherwara Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Udaipur",
      Court: "Jhadol Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Udaipur",
      Court: "Gogunda Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Udaipur",
      Court: "KaNore Court Complex",
    },
    {
      State: "Rajasthan",
      District: "Udaipur",
      Court: "Kotra Court Complex",
    },
    {
      State: "Sikkim",
      District: "Gangtok",
      Court: "District & Sessions Court Complex,",
    },
    {
      State: "Sikkim",
      District: "Gyalshing",
      Court: "District & Sessions Court Complex",
    },
    {
      State: "Sikkim",
      District: "Mangan",
      Court: "District & Sessions Court Complex",
    },
    {
      State: "Sikkim",
      District: "Namchi",
      Court: "Jorethang Court Complex",
    },
    {
      State: "Sikkim",
      District: "Namchi",
      Court: "Yangang Court Complex ,South Sikkim",
    },
    {
      State: "Sikkim",
      District: "Namchi",
      Court: "District & Sessions Court Complex",
    },
    {
      State: "Sikkim",
      District: "PAKYONG",
      Court: "Rangpo Sub-Division",
    },
    {
      State: "Sikkim",
      District: "PAKYONG",
      Court: "Rongli Sub-Division",
    },
    {
      State: "Sikkim",
      District: "SORENG",
      Court: "CCJM Soreng, West Sikkim",
    },
    {
      State: "Tamil Nadu",
      District: "Ariyalur",
      Court: "Family Court Complex, Ariyalur",
    },
    {
      State: "Tamil Nadu",
      District: "Ariyalur",
      Court: "DM cum JM Court Complex, Sendurai",
    },
    {
      State: "Tamil Nadu",
      District: "Ariyalur",
      Court: "Combined Courts, Ariyalur",
    },
    {
      State: "Tamil Nadu",
      District: "Ariyalur",
      Court: "Combined Courts, Jayankondam",
    },
    {
      State: "Tamil Nadu",
      District: "Chennai",
      Court: "Singaravelar Maaligai Complex",
    },
    {
      State: "Tamil Nadu",
      District: "Chennai",
      Court: "C M M Court, Egmore",
    },
    {
      State: "Tamil Nadu",
      District: "Chennai",
      Court: "M M Courts, George Town",
    },
    {
      State: "Tamil Nadu",
      District: "Chennai",
      Court: "M M Courts, Saidapet",
    },
    {
      State: "Tamil Nadu",
      District: "Chennai",
      Court: "City Civil Court Complex, Chennai",
    },
    {
      State: "Tamil Nadu",
      District: "Chennai",
      Court: "Court of Small Causes, Chennai",
    },
    {
      State: "Tamil Nadu",
      District: "Chennai",
      Court: "Bomb Blast Court, Poonamallee",
    },
    {
      State: "Tamil Nadu",
      District: "Chennai",
      Court: "M M Courts, Egmore at Allikulam",
    },
    {
      State: "Tamil Nadu",
      District: "Coimbatore",
      Court: "DM Court and JM Court, Sulur",
    },
    {
      State: "Tamil Nadu",
      District: "Coimbatore",
      Court: "DM cum JM Court, Madukkarai",
    },
    {
      State: "Tamil Nadu",
      District: "Coimbatore",
      Court: "Combined Courts, Coimbatore",
    },
    {
      State: "Tamil Nadu",
      District: "Coimbatore",
      Court: "DM Court and JM Court, Pollachi",
    },
    {
      State: "Tamil Nadu",
      District: "Coimbatore",
      Court: "Sub Court, Pollachi",
    },
    {
      State: "Tamil Nadu",
      District: "Coimbatore",
      Court: "Bomb Blast Court, Coimbatore",
    },
    {
      State: "Tamil Nadu",
      District: "Coimbatore",
      Court: "Combined Courts, Mettupalayam",
    },
    {
      State: "Tamil Nadu",
      District: "Coimbatore",
      Court: "DM cum JM Court, Valparai",
    },
    {
      State: "Tamil Nadu",
      District: "Cuddalore",
      Court: "DM cum JM Court, Parangipettai",
    },
    {
      State: "Tamil Nadu",
      District: "Cuddalore",
      Court: "District Court Complex, Cuddalore",
    },
    {
      State: "Tamil Nadu",
      District: "Cuddalore",
      Court: "Combined Courts, Tittagudi",
    },
    {
      State: "Tamil Nadu",
      District: "Cuddalore",
      Court: "Combined Courts, Chidambaram",
    },
    {
      State: "Tamil Nadu",
      District: "Cuddalore",
      Court: "Combined Courts, Neyveli",
    },
    {
      State: "Tamil Nadu",
      District: "Cuddalore",
      Court: "Combined Courts, Panruti",
    },
    {
      State: "Tamil Nadu",
      District: "Cuddalore",
      Court: "Combined Courts, Virudhachalam",
    },
    {
      State: "Tamil Nadu",
      District: "Cuddalore",
      Court: "DM cum JM Court, Kattumannarkoil",
    },
    {
      State: "Tamil Nadu",
      District: "Cuddalore",
      Court: "New Combined Courts, Cuddalore",
    },
    {
      State: "Tamil Nadu",
      District: "Cuddalore",
      Court: "DMcumJM Court, Kurunjipadi",
    },
    {
      State: "Tamil Nadu",
      District: "Dharmapuri",
      Court: "Magistrate Court, Harur",
    },
    {
      State: "Tamil Nadu",
      District: "Dharmapuri",
      Court: "Combined Courts, Dharmapuri",
    },
    {
      State: "Tamil Nadu",
      District: "Dharmapuri",
      Court: "Sub Court, Harur",
    },
    {
      State: "Tamil Nadu",
      District: "Dharmapuri",
      Court: "DM and JM Courts, Pappireddipatti",
    },
    {
      State: "Tamil Nadu",
      District: "Dharmapuri",
      Court: "DM cum JM Court, Pennagaram",
    },
    {
      State: "Tamil Nadu",
      District: "Dharmapuri",
      Court: "Combined Courts, Palacode",
    },
    {
      State: "Tamil Nadu",
      District: "Dindigul",
      Court: "DM cum JM Court, Natham",
    },
    {
      State: "Tamil Nadu",
      District: "Dindigul",
      Court: "DM cum JM Court, Athoor",
    },
    {
      State: "Tamil Nadu",
      District: "Dindigul",
      Court: "Combined Courts, Palani",
    },
    {
      State: "Tamil Nadu",
      District: "Dindigul",
      Court: "Combined Courts, Kodaikanal",
    },
    {
      State: "Tamil Nadu",
      District: "Dindigul",
      Court: "Combined Courts, Vedasandur",
    },
    {
      State: "Tamil Nadu",
      District: "Dindigul",
      Court: "Combined Courts, Dindigul",
    },
    {
      State: "Tamil Nadu",
      District: "Dindigul",
      Court: "Magistrate Court, Nilakkottai",
    },
    {
      State: "Tamil Nadu",
      District: "Dindigul",
      Court: "Munsif Court, Nilakkottai",
    },
    {
      State: "Tamil Nadu",
      District: "Dindigul",
      Court: "Combined Courts, Oddanchathiram",
    },
    {
      State: "Tamil Nadu",
      District: "Erode",
      Court: "Combined Courts, Bhavani",
    },
    {
      State: "Tamil Nadu",
      District: "Erode",
      Court: "Sub Court Complex, Sathyamangalam",
    },
    {
      State: "Tamil Nadu",
      District: "Erode",
      Court: "Combined Courts, Perundurai",
    },
    {
      State: "Tamil Nadu",
      District: "Erode",
      Court: "New Combined Court Building, Erode",
    },
    {
      State: "Tamil Nadu",
      District: "Erode",
      Court: "Combined Courts, Erode",
    },
    {
      State: "Tamil Nadu",
      District: "Erode",
      Court: "District Munsif Court, Anthiyur",
    },
    {
      State: "Tamil Nadu",
      District: "Erode",
      Court: "Combined Courts, Gobichettipalayam",
    },
    {
      State: "Tamil Nadu",
      District: "Erode",
      Court: "DM cum JM Court, Kodumudi",
    },
    {
      State: "Tamil Nadu",
      District: "Kancheepuram",
      Court: "Combined Courts, Chengalpet",
    },
    {
      State: "Tamil Nadu",
      District: "Kancheepuram",
      Court: "II Magistrate Court, Kancheepuram",
    },
    {
      State: "Tamil Nadu",
      District: "Kancheepuram",
      Court: "Combined Courts, Sriperumbudur",
    },
    {
      State: "Tamil Nadu",
      District: "Kancheepuram",
      Court: "Combined Courts, Tambaram",
    },
    {
      State: "Tamil Nadu",
      District: "Kancheepuram",
      Court: "Combined Courts, Kancheepuram",
    },
    {
      State: "Tamil Nadu",
      District: "Kancheepuram",
      Court: "DM cum JM Court, Thirukalukundram",
    },
    {
      State: "Tamil Nadu",
      District: "Kancheepuram",
      Court: "DM cum JM Court, Uthiramerur",
    },
    {
      State: "Tamil Nadu",
      District: "Kancheepuram",
      Court: "Combined Courts, Alandur",
    },
    {
      State: "Tamil Nadu",
      District: "Kancheepuram",
      Court: "Combined Courts, Maduranthakam",
    },
    {
      State: "Tamil Nadu",
      District: "Kancheepuram",
      Court: "Pocso Court, Chengalpet",
    },
    {
      State: "Tamil Nadu",
      District: "Kancheepuram",
      Court: "Labour Court, Kancheepuram",
    },
    {
      State: "Tamil Nadu",
      District: "Kancheepuram",
      Court: "DM cum JM Court, Cheyyur",
    },
    {
      State: "Tamil Nadu",
      District: "Kancheepuram",
      Court: "DM cum JM Court, Pallavaram",
    },
    {
      State: "Tamil Nadu",
      District: "Kancheepuram",
      Court: "DM cum JM Court, Thiruporur",
    },
    {
      State: "Tamil Nadu",
      District: "Kanniyakumari",
      Court: "Combined Courts, Kuzhithurai",
    },
    {
      State: "Tamil Nadu",
      District: "Kanniyakumari",
      Court: "Combined Courts, Padmanabhapuram",
    },
    {
      State: "Tamil Nadu",
      District: "Kanniyakumari",
      Court: "Combined Courts, Nagercoil",
    },
    {
      State: "Tamil Nadu",
      District: "Kanniyakumari",
      Court: "Combined Courts, Eraniel",
    },
    {
      State: "Tamil Nadu",
      District: "Kanniyakumari",
      Court: "DM cum JM Court, Boothapandy",
    },
    {
      State: "Tamil Nadu",
      District: "Karur",
      Court: "DM cum JM Court, Aravakurichi",
    },
    {
      State: "Tamil Nadu",
      District: "Karur",
      Court: "Combined Courts, Kulithalai",
    },
    {
      State: "Tamil Nadu",
      District: "Karur",
      Court: "Combined Courts, Karur",
    },
    {
      State: "Tamil Nadu",
      District: "Karur",
      Court: "JM Mahila Court, Karur",
    },
    {
      State: "Tamil Nadu",
      District: "Karur",
      Court: "DM cum JM Court, Krishnarayapuram",
    },
    {
      State: "Tamil Nadu",
      District: "Krishnagiri",
      Court: "DM cum JM Court, Pochampalli",
    },
    {
      State: "Tamil Nadu",
      District: "Krishnagiri",
      Court: "Combined Courts, Uthangarai",
    },
    {
      State: "Tamil Nadu",
      District: "Krishnagiri",
      Court: "II Magistrate Court, Hosur",
    },
    {
      State: "Tamil Nadu",
      District: "Krishnagiri",
      Court: "Combined Courts, Krishnagiri",
    },
    {
      State: "Tamil Nadu",
      District: "Krishnagiri",
      Court: "Combined Courts, Denkanikottai",
    },
    {
      State: "Tamil Nadu",
      District: "Krishnagiri",
      Court: "Combined Courts, Hosur",
    },
    {
      State: "Tamil Nadu",
      District: "Madurai",
      Court: "DM cum JM Court, Peraiyur",
    },
    {
      State: "Tamil Nadu",
      District: "Madurai",
      Court: "Combined Courts, Vadipatti",
    },
    {
      State: "Tamil Nadu",
      District: "Madurai",
      Court: "Magistrate Court, Thirumangalam",
    },
    {
      State: "Tamil Nadu",
      District: "Madurai",
      Court: "Munsif Court, Thirumangalam",
    },
    {
      State: "Tamil Nadu",
      District: "Madurai",
      Court: "Sub Court, Thirumangalam",
    },
    {
      State: "Tamil Nadu",
      District: "Madurai",
      Court: "Munsif Court, Melur",
    },
    {
      State: "Tamil Nadu",
      District: "Madurai",
      Court: "Magistrate Court, Melur",
    },
    {
      State: "Tamil Nadu",
      District: "Madurai",
      Court: "Combined Courts, Usilampatti",
    },
    {
      State: "Tamil Nadu",
      District: "Madurai",
      Court: "Combined Courts, Madurai",
    },
    {
      State: "Tamil Nadu",
      District: "Mayiladuthurai",
      Court: "Combined Courts, Mayiladuthurai",
    },
    {
      State: "Tamil Nadu",
      District: "Mayiladuthurai",
      Court: "DM cum JM Court, Tharangambadi",
    },
    {
      State: "Tamil Nadu",
      District: "Mayiladuthurai",
      Court: "Combined Courts, Sirkali",
    },
    {
      State: "Tamil Nadu",
      District: "Nagapattinam",
      Court: "DM cum JM Court, Vedaranyam",
    },
    {
      State: "Tamil Nadu",
      District: "Nagapattinam",
      Court: "Combined Courts, Nagapattinam",
    },
    {
      State: "Tamil Nadu",
      District: "Nagapattinam",
      Court: "DM cum JM Court, Kilvelur",
    },
    {
      State: "Tamil Nadu",
      District: "Namakkal",
      Court: "Combined Courts, Namakkal",
    },
    {
      State: "Tamil Nadu",
      District: "Namakkal",
      Court: "Combined Courts, Rasipuram",
    },
    {
      State: "Tamil Nadu",
      District: "Namakkal",
      Court: "Combined Courts, Tiruchengode",
    },
    {
      State: "Tamil Nadu",
      District: "Namakkal",
      Court: "Combined Courts, Kumarapalayam",
    },
    {
      State: "Tamil Nadu",
      District: "Namakkal",
      Court: "Combined Courts, Sendamangalam",
    },
    {
      State: "Tamil Nadu",
      District: "Namakkal",
      Court: "Combined Courts, Paramathy",
    },
    {
      State: "Tamil Nadu",
      District: "Perambalur",
      Court: "Combined Courts, Perambalur",
    },
    {
      State: "Tamil Nadu",
      District: "Perambalur",
      Court: "DM cum JM Court, Veppanthattai",
    },
    {
      State: "Tamil Nadu",
      District: "Perambalur",
      Court: "DM cum JM Court, Kunnam",
    },
    {
      State: "Tamil Nadu",
      District: "Pudukkottai",
      Court: "Magistrate Court, Keeranur",
    },
    {
      State: "Tamil Nadu",
      District: "Pudukkottai",
      Court: "Combined Courts, Thirumayam",
    },
    {
      State: "Tamil Nadu",
      District: "Pudukkottai",
      Court: "Munsif Court, Keeranur",
    },
    {
      State: "Tamil Nadu",
      District: "Pudukkottai",
      Court: "Mahila Court, Pudukkottai",
    },
    {
      State: "Tamil Nadu",
      District: "Pudukkottai",
      Court: "District Court Complex, Pudukkottai",
    },
    {
      State: "Tamil Nadu",
      District: "Pudukkottai",
      Court: "Combined Courts, Aranthangi",
    },
    {
      State: "Tamil Nadu",
      District: "Pudukkottai",
      Court: "DM cum JM Court, Alangudi",
    },
    {
      State: "Tamil Nadu",
      District: "Pudukkottai",
      Court: "DM cum JM Court, Illuppur",
    },
    {
      State: "Tamil Nadu",
      District: "Pudukkottai",
      Court: "DM cum JM Court, Gandarvakkottai",
    },
    {
      State: "Tamil Nadu",
      District: "Ramanathapuram",
      Court: "DM cum JM Court, Rameswaram",
    },
    {
      State: "Tamil Nadu",
      District: "Ramanathapuram",
      Court: "SCST JMMAHILACourts, Ramanathapuram",
    },
    {
      State: "Tamil Nadu",
      District: "Ramanathapuram",
      Court: "DM cum JM Court, Kadaladi",
    },
    {
      State: "Tamil Nadu",
      District: "Ramanathapuram",
      Court: "Combined Courts, Paramakudi",
    },
    {
      State: "Tamil Nadu",
      District: "Ramanathapuram",
      Court: "Combined Courts, Ramanathapuram",
    },
    {
      State: "Tamil Nadu",
      District: "Ramanathapuram",
      Court: "Combined Courts, Mudukulathur",
    },
    {
      State: "Tamil Nadu",
      District: "Ramanathapuram",
      Court: "Combined Courts, Thiruvadanai",
    },
    {
      State: "Tamil Nadu",
      District: "Ramanathapuram",
      Court: "DM cum JM Court, Kamudhi",
    },
    {
      State: "Tamil Nadu",
      District: "Salem",
      Court: "Combined Courts, Mettur",
    },
    {
      State: "Tamil Nadu",
      District: "Salem",
      Court: "Sub Court Complex, Attur",
    },
    {
      State: "Tamil Nadu",
      District: "Salem",
      Court: "Combined Courts, Sankari",
    },
    {
      State: "Tamil Nadu",
      District: "Salem",
      Court: "Magistrate Court FTC Level, Attur",
    },
    {
      State: "Tamil Nadu",
      District: "Salem",
      Court: "Combined Courts, Omalur",
    },
    {
      State: "Tamil Nadu",
      District: "Salem",
      Court: "DM cum JM Court, Vazhapadi",
    },
    {
      State: "Tamil Nadu",
      District: "Salem",
      Court: "Combined Courts, Salem",
    },
    {
      State: "Tamil Nadu",
      District: "Salem",
      Court: "DM cum JM Court, Edappadi",
    },
    {
      State: "Tamil Nadu",
      District: "Salem",
      Court: "DM cum JM Court, Yercaud",
    },
    {
      State: "Tamil Nadu",
      District: "Sivagangai",
      Court: "Sub Court, Devakottai",
    },
    {
      State: "Tamil Nadu",
      District: "Sivagangai",
      Court: "Old Combined Courts, Sivagangai",
    },
    {
      State: "Tamil Nadu",
      District: "Sivagangai",
      Court: "DM cum JM Court, Tiruppathur",
    },
    {
      State: "Tamil Nadu",
      District: "Sivagangai",
      Court: "DM cum JM Court, Ilayangudi",
    },
    {
      State: "Tamil Nadu",
      District: "Sivagangai",
      Court: "Combined Courts, Karaikudi",
    },
    {
      State: "Tamil Nadu",
      District: "Sivagangai",
      Court: "New Combined Courts, Sivagangai",
    },
    {
      State: "Tamil Nadu",
      District: "Sivagangai",
      Court: "DM cum JM Court, Singampunari",
    },
    {
      State: "Tamil Nadu",
      District: "Sivagangai",
      Court: "Combined Courts, Manamadurai",
    },
    {
      State: "Tamil Nadu",
      District: "Sivagangai",
      Court: "DM cum JM Court, Thiruppuvanam",
    },
    {
      State: "Tamil Nadu",
      District: "Sivagangai",
      Court: "Sub Court, Manamadurai",
    },
    {
      State: "Tamil Nadu",
      District: "Thanjavur",
      Court: "Combined Courts, Thiruvaiyaru",
    },
    {
      State: "Tamil Nadu",
      District: "Thanjavur",
      Court: "Combined Courts, Kumbakonam",
    },
    {
      State: "Tamil Nadu",
      District: "Thanjavur",
      Court: "Combined Courts, Pattukkottai",
    },
    {
      State: "Tamil Nadu",
      District: "Thanjavur",
      Court: "Combined Courts, Thanjavur",
    },
    {
      State: "Tamil Nadu",
      District: "Thanjavur",
      Court: "DM cum JM Court, Thiruvidaimaruthur",
    },
    {
      State: "Tamil Nadu",
      District: "Thanjavur",
      Court: "DM cum JM Court, Orathanadu",
    },
    {
      State: "Tamil Nadu",
      District: "Thanjavur",
      Court: "DM cum JM Court, Papanasam",
    },
    {
      State: "Tamil Nadu",
      District: "Thanjavur",
      Court: "DM cum JM Court, Peravurani",
    },
    {
      State: "Tamil Nadu",
      District: "Theni",
      Court: "Combined Courts, Theni",
    },
    {
      State: "Tamil Nadu",
      District: "Theni",
      Court: "Combined Courts, Periyakulam",
    },
    {
      State: "Tamil Nadu",
      District: "Theni",
      Court: "Combined Courts, Uthamapalayam",
    },
    {
      State: "Tamil Nadu",
      District: "Theni",
      Court: "Combined Courts, Aundipatti",
    },
    {
      State: "Tamil Nadu",
      District: "Theni",
      Court: "Combined Courts, Bodinayakkanur",
    },
    {
      State: "Tamil Nadu",
      District: "The Nilgiris",
      Court: "Magistrate Court, The Nilgiris",
    },
    {
      State: "Tamil Nadu",
      District: "The Nilgiris",
      Court: "Munsif Court, The Nilgiris",
    },
    {
      State: "Tamil Nadu",
      District: "The Nilgiris",
      Court: "DM cum JM Court, Kotagiri",
    },
    {
      State: "Tamil Nadu",
      District: "The Nilgiris",
      Court: "Magistrate Court, Panthalur",
    },
    {
      State: "Tamil Nadu",
      District: "The Nilgiris",
      Court: "Family Court Complex, The Nilgiris",
    },
    {
      State: "Tamil Nadu",
      District: "The Nilgiris",
      Court: "Magistrate Court, Coonoor",
    },
    {
      State: "Tamil Nadu",
      District: "The Nilgiris",
      Court: "DJ cum CJM Court, The Nilgiris",
    },
    {
      State: "Tamil Nadu",
      District: "The Nilgiris",
      Court: "Combined Courts, Gudalur",
    },
    {
      State: "Tamil Nadu",
      District: "The Nilgiris",
      Court: "Combined Courts, Coonoor",
    },
    {
      State: "Tamil Nadu",
      District: "Thoothukudi",
      Court: "DM cum JM Court, Vilathikulam",
    },
    {
      State: "Tamil Nadu",
      District: "Thoothukudi",
      Court: "Combined Courts, Tiruchendur",
    },
    {
      State: "Tamil Nadu",
      District: "Thoothukudi",
      Court: "Combined Courts, Srivaikuntam",
    },
    {
      State: "Tamil Nadu",
      District: "Thoothukudi",
      Court: "Combined Courts, Thoothukudi",
    },
    {
      State: "Tamil Nadu",
      District: "Thoothukudi",
      Court: "Sub Court Complex, Kovilpatti",
    },
    {
      State: "Tamil Nadu",
      District: "Thoothukudi",
      Court: "Combined Courts, Sathankulam",
    },
    {
      State: "Tamil Nadu",
      District: "Thoothukudi",
      Court: "DM cum JM Court, Ottapidaram",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruchirappalli",
      Court: "Combined Courts, Lalgudi",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruchirappalli",
      Court: "Combined Courts, Tiruchirappalli",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruchirappalli",
      Court: "Combined Courts, Manapparai",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruchirappalli",
      Court: "Combined Courts, Musiri",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruchirappalli",
      Court: "Combined Courts, Thuraiyur",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruchirappalli",
      Court: "DM cum JM Court, Srirangam",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruchirappalli",
      Court: "DM cum JM Court, Thottiyam",
    },
    {
      State: "Tamil Nadu",
      District: "Tirunelveli",
      Court: "Combined Courts, Nanguneri",
    },
    {
      State: "Tamil Nadu",
      District: "Tirunelveli",
      Court: "Combined Courts, Tirunelveli",
    },
    {
      State: "Tamil Nadu",
      District: "Tirunelveli",
      Court: "DM cum JM court, Rathapuram",
    },
    {
      State: "Tamil Nadu",
      District: "Tirunelveli",
      Court: "Magistrate Court, Alangulam",
    },
    {
      State: "Tamil Nadu",
      District: "Tirunelveli",
      Court: "DM cum JM Court, Shengottah",
    },
    {
      State: "Tamil Nadu",
      District: "Tirunelveli",
      Court: "Principal Court Complex, Tenkasi",
    },
    {
      State: "Tamil Nadu",
      District: "Tirunelveli",
      Court: "Additional Court Complex, Tenkasi",
    },
    {
      State: "Tamil Nadu",
      District: "Tirunelveli",
      Court: "Combined Courts, Ambasamaduram",
    },
    {
      State: "Tamil Nadu",
      District: "Tirunelveli",
      Court: "Combined Courts, Sivagiri",
    },
    {
      State: "Tamil Nadu",
      District: "Tirunelveli",
      Court: "DM cum JM Court, Cheranmahadevi",
    },
    {
      State: "Tamil Nadu",
      District: "Tirunelveli",
      Court: "Combined Courts, Valliyoor",
    },
    {
      State: "Tamil Nadu",
      District: "Tirunelveli",
      Court: "Combined Courts, Sankarankovil",
    },
    {
      State: "Tamil Nadu",
      District: "Tirunelveli",
      Court: "Munsif Court, Alangulam",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruppur",
      Court: "Combined Courts, Palladam",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruppur",
      Court: "Combined Courts, Kangeyam",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruppur",
      Court: "Sub Court Complex, Dharapuram",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruppur",
      Court: "Sub Court Complex, Avinashi",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruppur",
      Court: "Combined Courts, Avinashi",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruppur",
      Court: "Magistrate Court, Dharapuram",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruppur",
      Court: "Combined Courts Campus, Tiruppur",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruppur",
      Court: "DM cum JM Court, Madathukulam",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruppur",
      Court: "Sub Court Complex, Udumalpet",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruppur",
      Court: "I Magistrate Court, Udumalpet",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruppur",
      Court: "DM cum JM Court, Uthukuli",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvallur",
      Court: "Combined Courts, Tiruvallur",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvallur",
      Court: "Combined Courts, Tiruttani",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvallur",
      Court: "Combined Courts, Poonamallee",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvallur",
      Court: "Sub Court Complex, Ponneri",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvallur",
      Court: "DM cum JM Court, Madhavaram",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvallur",
      Court: "Combined Courts, Ambattur",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvallur",
      Court: "Munsif Court, Ponneri",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvallur",
      Court: "Combined Courts, Tiruvottiyur",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvallur",
      Court: "DM cum JM Court, Pallipattu",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvallur",
      Court: "DM cum JM Court, Uthukottai",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvallur",
      Court: "I Magistrate Court, Ponneri",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvallur",
      Court: "II Magistrate Court, Ponneri",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvallur",
      Court: "DM cum JM Court, Gummidipoondi",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvannamalai",
      Court: "Combined Courts, Arni",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvannamalai",
      Court: "Sub Court Complex, Cheyyar",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvannamalai",
      Court: "Combined Courts, Tiruvannamalai",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvannamalai",
      Court: "Combined Courts, Chengam",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvannamalai",
      Court: "DM cum JM Court, Kalasapakkam",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvannamalai",
      Court: "Combined Courts, Vandavasi",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvannamalai",
      Court: "DM cum JM Court, Thandarampattu",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvannamalai",
      Court: "Combined Courts, Polur",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvarur",
      Court: "Combined Courts, Mannargudi",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvarur",
      Court: "Munsif Court, Mannargudi",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvarur",
      Court: "DM cum JM Court, Nannilam",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvarur",
      Court: "DM cum JM Court, Needamangalam",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvarur",
      Court: "Magistrate Court, Mannargudi",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvarur",
      Court: "DM cum JM Court, Valangaiman",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvarur",
      Court: "Combined Courts, Tiruvarur",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvarur",
      Court: "Combined Courts, Thiruthuraipoondi",
    },
    {
      State: "Tamil Nadu",
      District: "Tiruvarur",
      Court: "Mahila Court (JM Level), Tiruvarur",
    },
    {
      State: "Tamil Nadu",
      District: "Vellore",
      Court: "Combined Courts, Ambur",
    },
    {
      State: "Tamil Nadu",
      District: "Vellore",
      Court: "Combined Courts, Vellore",
    },
    {
      State: "Tamil Nadu",
      District: "Vellore",
      Court: "Combined Courts, Katpadi",
    },
    {
      State: "Tamil Nadu",
      District: "Vellore",
      Court: "Sub Court, Vaniyambadi",
    },
    {
      State: "Tamil Nadu",
      District: "Vellore",
      Court: "Combined Courts, Walajah",
    },
    {
      State: "Tamil Nadu",
      District: "Vellore",
      Court: "Munsif Court, Vaniyambadi",
    },
    {
      State: "Tamil Nadu",
      District: "Vellore",
      Court: "Combined Courts, Ranipet",
    },
    {
      State: "Tamil Nadu",
      District: "Vellore",
      Court: "Combined Courts, Sholinghur",
    },
    {
      State: "Tamil Nadu",
      District: "Vellore",
      Court: "Combined Courts, Tirupathur",
    },
    {
      State: "Tamil Nadu",
      District: "Vellore",
      Court: "Magistrate Court, Vaniyambadi",
    },
    {
      State: "Tamil Nadu",
      District: "Vellore",
      Court: "Combined Courts, Arakkonam",
    },
    {
      State: "Tamil Nadu",
      District: "Vellore",
      Court: "DM cum JM Court, Arcot",
    },
    {
      State: "Tamil Nadu",
      District: "Vellore",
      Court: "Combined Courts, Gudiyatham",
    },
    {
      State: "Tamil Nadu",
      District: "Viluppuram",
      Court: "DM cum JM Court, Vanur",
    },
    {
      State: "Tamil Nadu",
      District: "Viluppuram",
      Court: "Combined Courts, Sankarapuram",
    },
    {
      State: "Tamil Nadu",
      District: "Viluppuram",
      Court: "Combined Courts, Ulundurpet",
    },
    {
      State: "Tamil Nadu",
      District: "Viluppuram",
      Court: "Combined Courts, Kallakurichi",
    },
    {
      State: "Tamil Nadu",
      District: "Viluppuram",
      Court: "Combined Courts, Viluppuram",
    },
    {
      State: "Tamil Nadu",
      District: "Viluppuram",
      Court: "Combined Courts, Tindivanam",
    },
    {
      State: "Tamil Nadu",
      District: "Viluppuram",
      Court: "Combined Courts, Gingee",
    },
    {
      State: "Tamil Nadu",
      District: "Viluppuram",
      Court: "DM cum JM Court, Vikravandi",
    },
    {
      State: "Tamil Nadu",
      District: "Viluppuram",
      Court: "Combined Courts, Thirukkoyilur",
    },
    {
      State: "Tamil Nadu",
      District: "Virudhunagar",
      Court: "Combined Courts, Sivakasi",
    },
    {
      State: "Tamil Nadu",
      District: "Virudhunagar",
      Court: "Combined Courts, Virudhunagar",
    },
    {
      State: "Tamil Nadu",
      District: "Virudhunagar",
      Court: "Combined Courts, Sattur",
    },
    {
      State: "Tamil Nadu",
      District: "Virudhunagar",
      Court: "Combined Courts, Srivilliputtur",
    },
    {
      State: "Tamil Nadu",
      District: "Virudhunagar",
      Court: "Combined Courts, Aruppukkottai",
    },
    {
      State: "Tamil Nadu",
      District: "Virudhunagar",
      Court: "Combined Courts, Rajapalayam",
    },
    {
      State: "Tamil Nadu",
      District: "Virudhunagar",
      Court: "DM cum JM Court, Tiruchuli",
    },
    {
      State: "Telangana",
      District: "Adilabad",
      Court: "Adilabad, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Adilabad",
      Court: "Utnoor, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Adilabad",
      Court: "Boath, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Bhadradri Kothagudem",
      Court: "Kothagudem, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Bhadradri Kothagudem",
      Court: "Yellandu, PJCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Bhadradri Kothagudem",
      Court: "Bhadrachalam, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Bhadradri Kothagudem",
      Court: "Manuguru, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Bhadradri Kothagudem",
      Court: "Yellandu, AJCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Hanumakonda",
      Court: "Hanumakonda, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Hanumakonda",
      Court: "Parkal, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Hanumakonda",
      Court: "Kazipet, JFCM Railway Court Complex",
    },
    {
      State: "Telangana",
      District: "Hyderabad Central",
      Court: "HYD, CBI Court Complex",
    },
    {
      State: "Telangana",
      District: "Hyderabad Central",
      Court: "HYD, City SmallCauses Court Complex",
    },
    {
      State: "Telangana",
      District: "Hyderabad Central",
      Court: "SEC-BAD, CCC-CSCC-MSJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Hyderabad Central",
      Court: "HYD, City Civil Court Complex",
    },
    {
      State: "Telangana",
      District: "Hyderabad Central",
      Court: "HYD, Criminal Court Complex",
    },
    {
      State: "Telangana",
      District: "Hyderabad Central",
      Court: "HYD, V ACMM Juvanile Court Complex",
    },
    {
      State: "Telangana",
      District: "Hyderabad Central",
      Court: "HYD, MM Court Complex",
    },
    {
      State: "Telangana",
      District: "Hyderabad Central",
      Court: "HYD, II MM Railway Court Complex",
    },
    {
      State: "Telangana",
      District: "Hyderabad Central",
      Court: "HACA Bhavan, POCSO Court Complex",
    },
    {
      State: "Telangana",
      District: "Hyderabad Central",
      Court: "Hyd,Integrated Family Court Complex",
    },
    {
      State: "Telangana",
      District: "Jagitial",
      Court: "Jagitial, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Jagitial",
      Court: "Kotutla, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Jagitial",
      Court: "Metpalli, SCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Jagitial",
      Court: "Dharmapuri, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Jangoan",
      Court: "Jangoan, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Jayashankar Bhupalapally",
      Court: "JS Bhupalapally, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Jogulamba Gadwal",
      Court: "Jogulamba Gadwal, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Jogulamba Gadwal",
      Court: "Alampur, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Kamareddy",
      Court: "Kamareddy, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Kamareddy",
      Court: "Banswada, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Kamareddy",
      Court: "Bichkunda, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Kamareddy",
      Court: "Yellareddy, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Karimnagar",
      Court: "Huzurabad, SCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Karimnagar",
      Court: "Karimnagar, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Khammam",
      Court: "Madhira, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Khammam",
      Court: "Satupally, SCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Khammam",
      Court: "Khammam, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Kumuram Bheem Asifabad",
      Court: "Asifabad, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Kumuram Bheem Asifabad",
      Court: "Sirpur, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Mahabubabad",
      Court: "Mahabubabad, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Mahabubabad",
      Court: "Thorrur, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Mahabubnagar",
      Court: "Mahabubnagar, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Mahabubnagar",
      Court: "Jadcherla, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Mancherial",
      Court: "Mancherial, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Mancherial",
      Court: "Mancherial, SCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Mancherial",
      Court: "Mancherial, Spl.JFCM Court Complex",
    },
    {
      State: "Telangana",
      District: "Mancherial",
      Court: "Luxettipet, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Mancherial",
      Court: "Bellampalli, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Mancherial",
      Court: "Chennur, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Medak",
      Court: "Medak, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Medak",
      Court: "Narsapur, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Medchal Malkajgiri",
      Court: "Malkajgiri, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Medchal Malkajgiri",
      Court: "Kukatpally, ADJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Medchal Malkajgiri",
      Court: "Medchal, ADJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Medchal Malkajgiri",
      Court: "Athivelli, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Mulugu",
      Court: "Mulugu, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Nagarkurnool",
      Court: "Nagarkurnool, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Nagarkurnool",
      Court: "Achampet, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Nagarkurnool",
      Court: "Kalwakurthy, SCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Nagarkurnool",
      Court: "Kollapur, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Nalgonda",
      Court: "Miryalaguda, V ADJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Nalgonda",
      Court: "Nalgonda, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Nalgonda",
      Court: "Nidamanoor, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Nalgonda",
      Court: "Nakrekal, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Nalgonda",
      Court: "Devarakonda, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Narayanpet",
      Court: "Narayanpet, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Narayanpet",
      Court: "Kosgi, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Nirmal",
      Court: "Nirmal, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Nirmal",
      Court: "Nirmal, SCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Nirmal",
      Court: "Bhainsa, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Nirmal",
      Court: "Khanapur JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Nizamabad",
      Court: "Bodhan, V ADJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Nizamabad",
      Court: "Nizamabad, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Nizamabad",
      Court: "Armoor, SCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Peddapalli",
      Court: "Peddpalli - PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Peddapalli",
      Court: "Peddpalli - SCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Peddapalli",
      Court: "Godavarikhani - JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Peddapalli",
      Court: "Manthani - SCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Peddapalli",
      Court: "Sulthanabad, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Peddapalli",
      Court: "Godavarikhani, ADJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Peddapalli",
      Court: "JCJ Complex, Nandimyadaram",
    },
    {
      State: "Telangana",
      District: "Rajanna Siricilla",
      Court: "Rajanna Sircilla, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Rajanna Siricilla",
      Court: "Vemulawada, SCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Rangareddy",
      Court: "Maheshwaram, JCJ Compex",
    },
    {
      State: "Telangana",
      District: "Rangareddy",
      Court: "Rajendra Nagar, Addl.dist.Complex",
    },
    {
      State: "Telangana",
      District: "Rangareddy",
      Court: "Hayathnagar,JCJcumXIV AMM Complex",
    },
    {
      State: "Telangana",
      District: "Rangareddy",
      Court: "Rangareddy, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Rangareddy",
      Court: "Chevella, SCJ Complex",
    },
    {
      State: "Telangana",
      District: "Rangareddy",
      Court: "Kukatpally, ADJ-cum-Family Court",
    },
    {
      State: "Telangana",
      District: "Rangareddy",
      Court: "Ibrahimpatnam,PSCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Rangareddy",
      Court: "Shadnagar, Addl.dist.court complex",
    },
    {
      State: "Telangana",
      District: "Rangareddy",
      Court: "Amangal, JCJ Complex,",
    },
    {
      State: "Telangana",
      District: "Sangareddy",
      Court: "Sangareddy, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Sangareddy",
      Court: "Zaheerabad, SCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Sangareddy",
      Court: "Jogipet, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Sangareddy",
      Court: "Narayankhed, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Siddipet",
      Court: "Siddipet, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Siddipet",
      Court: "Gajwel, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Siddipet",
      Court: "Dubbak, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Siddipet",
      Court: "Husnabad, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Suryapet",
      Court: "Suryapet, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Suryapet",
      Court: "Huzurnagar, SCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Suryapet",
      Court: "Thungathurthy, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Suryapet",
      Court: "Kodad, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Vikarabad",
      Court: "Vikarabad, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Vikarabad",
      Court: "Kodangal, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Vikarabad",
      Court: "Parigi, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Vikarabad",
      Court: "Tandur, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Wanaparthy",
      Court: "Wanaparthy, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Wanaparthy",
      Court: "Atmakur, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Warangal",
      Court: "Warangal, PDJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Warangal",
      Court: "Narsampet, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Yadadri Bhuvanagiri",
      Court: "Bhuvanagiri, PDJ Cout Complex",
    },
    {
      State: "Telangana",
      District: "Yadadri Bhuvanagiri",
      Court: "Choutuppal, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Yadadri Bhuvanagiri",
      Court: "Ramannapet, JCJ Court Complex",
    },
    {
      State: "Telangana",
      District: "Yadadri Bhuvanagiri",
      Court: "Alair, JCJ Court Complex",
    },
    {
      State: "Tripura",
      District: "Dhalai Tripura",
      Court: "SDJM Gandachera",
    },
    {
      State: "Tripura",
      District: "Dhalai Tripura",
      Court: "DJ Court Complex Ambassa",
    },
    {
      State: "Tripura",
      District: "Dhalai Tripura",
      Court: "SDJM LongtaraiValley",
    },
    {
      State: "Tripura",
      District: "Dhalai Tripura",
      Court: "ADJ Kamalpur",
    },
    {
      State: "Tripura",
      District: "Gomati Tripura",
      Court: "District and Sessions Judge Udaipur",
    },
    {
      State: "Tripura",
      District: "Gomati Tripura",
      Court: "SDJM Amarpur",
    },
    {
      State: "Tripura",
      District: "Khowai Tripura",
      Court: "District Judge Khowai",
    },
    {
      State: "Tripura",
      District: "North Tripura",
      Court: "District Judge Dharmanagar",
    },
    {
      State: "Tripura",
      District: "North Tripura",
      Court: "SDJM Kanchanpur",
    },
    {
      State: "Tripura",
      District: "Sepahijala Tripura",
      Court: "Addl District Judge Bishalgarh",
    },
    {
      State: "Tripura",
      District: "Sepahijala Tripura",
      Court: "District Judge Sepahijala",
    },
    {
      State: "Tripura",
      District: "South Tripura",
      Court: "District Judge Belonia",
    },
    {
      State: "Tripura",
      District: "South Tripura",
      Court: "SDJM Sabroom",
    },
    {
      State: "Tripura",
      District: "Unakoti Tripura",
      Court: "District Judge Kailasahar",
    },
    {
      State: "Tripura",
      District: "Unakoti Tripura",
      Court: "CJM Kailasahar",
    },
    {
      State: "Tripura",
      District: "West Tripura",
      Court: "District Judge Agartala",
    },
    {
      State: "Uttarakhand",
      District: "Almora",
      Court: "Civil Court Bhikiyasain",
    },
    {
      State: "Uttarakhand",
      District: "Almora",
      Court: "Civil Court Dwarahat",
    },
    {
      State: "Uttarakhand",
      District: "Almora",
      Court: "Civil Court Ranikhet",
    },
    {
      State: "Uttarakhand",
      District: "Almora",
      Court: "District Court Almora",
    },
    {
      State: "Uttarakhand",
      District: "Almora",
      Court: "Family Court Almora",
    },
    {
      State: "Uttarakhand",
      District: "Almora",
      Court: "Juvenile Justice Board Almora",
    },
    {
      State: "Uttarakhand",
      District: "Bageshwar",
      Court: "Civil Court Garur",
    },
    {
      State: "Uttarakhand",
      District: "Bageshwar",
      Court: "District Court Bageshwar",
    },
    {
      State: "Uttarakhand",
      District: "Bageshwar",
      Court: "Juvenile Justice Board Bageshwar",
    },
    {
      State: "Uttarakhand",
      District: "Chamoli",
      Court: "Civil Court Joshimath",
    },
    {
      State: "Uttarakhand",
      District: "Chamoli",
      Court: "Civil Court Pokhari",
    },
    {
      State: "Uttarakhand",
      District: "Chamoli",
      Court: "Civil Court Karanprayag",
    },
    {
      State: "Uttarakhand",
      District: "Chamoli",
      Court: "Civil Court Tharali",
    },
    {
      State: "Uttarakhand",
      District: "Chamoli",
      Court: "District Court Chamoli",
    },
    {
      State: "Uttarakhand",
      District: "Chamoli",
      Court: "Civil Court Gairsain",
    },
    {
      State: "Uttarakhand",
      District: "Chamoli",
      Court: "Juvenile Justice Board Chamoli",
    },
    {
      State: "Uttarakhand",
      District: "Champawat",
      Court: "District Court Champawat",
    },
    {
      State: "Uttarakhand",
      District: "Champawat",
      Court: "Civil Court Tanakpur",
    },
    {
      State: "Uttarakhand",
      District: "Dehradun",
      Court: "Civil Court Vikasnagar",
    },
    {
      State: "Uttarakhand",
      District: "Dehradun",
      Court: "Civil Court Chakrata",
    },
    {
      State: "Uttarakhand",
      District: "Dehradun",
      Court: "Family Court Dehradun",
    },
    {
      State: "Uttarakhand",
      District: "Dehradun",
      Court: "Civil Court Rishikesh",
    },
    {
      State: "Uttarakhand",
      District: "Dehradun",
      Court: "District Court Dehradun",
    },
    {
      State: "Uttarakhand",
      District: "Dehradun",
      Court: "Family Court Rishikesh",
    },
    {
      State: "Uttarakhand",
      District: "Dehradun",
      Court: "FTC court Dehradun",
    },
    {
      State: "Uttarakhand",
      District: "Dehradun",
      Court: "Civil Court Doiwala",
    },
    {
      State: "Uttarakhand",
      District: "Dehradun",
      Court: "Commercial Court Dehradun",
    },
    {
      State: "Uttarakhand",
      District: "Dehradun",
      Court: "Family Court Vikasnagar",
    },
    {
      State: "Uttarakhand",
      District: "Dehradun",
      Court: "Civil Court Mussoorie",
    },
    {
      State: "Uttarakhand",
      District: "Dehradun",
      Court: "Juvenile Justice Board Dehradun",
    },
    {
      State: "Uttarakhand",
      District: "Haridwar",
      Court: "Family Court Roorkee",
    },
    {
      State: "Uttarakhand",
      District: "Haridwar",
      Court: "Civil Court Laksar",
    },
    {
      State: "Uttarakhand",
      District: "Haridwar",
      Court: "District Court Haridwar",
    },
    {
      State: "Uttarakhand",
      District: "Haridwar",
      Court: "Civil Court Roorkree",
    },
    {
      State: "Uttarakhand",
      District: "Haridwar",
      Court: "Family Court Haridwar",
    },
    {
      State: "Uttarakhand",
      District: "Haridwar",
      Court: "Family Court Laksar",
    },
    {
      State: "Uttarakhand",
      District: "Nainital",
      Court: "District Court Nainital",
    },
    {
      State: "Uttarakhand",
      District: "Nainital",
      Court: "Civil Court Haldwani",
    },
    {
      State: "Uttarakhand",
      District: "Nainital",
      Court: "Civil Court Dhari",
    },
    {
      State: "Uttarakhand",
      District: "Nainital",
      Court: "Civil Court Ramnager",
    },
    {
      State: "Uttarakhand",
      District: "Nainital",
      Court: "Family Court Nainital",
    },
    {
      State: "Uttarakhand",
      District: "Nainital",
      Court: "Family Court Haldwani",
    },
    {
      State: "Uttarakhand",
      District: "Pauri Garhwal",
      Court: "District Court Pauri Garhwal",
    },
    {
      State: "Uttarakhand",
      District: "Pauri Garhwal",
      Court: "Civil Court Dhumakot",
    },
    {
      State: "Uttarakhand",
      District: "Pauri Garhwal",
      Court: "Civil Court Srinagar",
    },
    {
      State: "Uttarakhand",
      District: "Pauri Garhwal",
      Court: "Civil Court Lansdowne",
    },
    {
      State: "Uttarakhand",
      District: "Pauri Garhwal",
      Court: "Civil Court Kotdwar",
    },
    {
      State: "Uttarakhand",
      District: "Pauri Garhwal",
      Court: "Family Court Pauri Garhwal",
    },
    {
      State: "Uttarakhand",
      District: "Pauri Garhwal",
      Court: "Juvenile Justice Board Pauri",
    },
    {
      State: "Uttarakhand",
      District: "Pauri Garhwal",
      Court: "Family Court Kotdwar",
    },
    {
      State: "Uttarakhand",
      District: "Pithoragarh",
      Court: "Civil Court Dharchula",
    },
    {
      State: "Uttarakhand",
      District: "Pithoragarh",
      Court: "District Court Pithoragarh",
    },
    {
      State: "Uttarakhand",
      District: "Pithoragarh",
      Court: "CIVIL COURT GANGOLIHAT",
    },
    {
      State: "Uttarakhand",
      District: "Pithoragarh",
      Court: "Civil Court Didihat",
    },
    {
      State: "Uttarakhand",
      District: "Pithoragarh",
      Court: "Juvenile Justice Board Pithoragarh",
    },
    {
      State: "Uttarakhand",
      District: "Rudraprayag",
      Court: "CIVIL COURT UKHIMATH",
    },
    {
      State: "Uttarakhand",
      District: "Rudraprayag",
      Court: "District Court Rudraprayag",
    },
    {
      State: "Uttarakhand",
      District: "Rudraprayag",
      Court: "Juvenile Justice Board Rudraprayag",
    },
    {
      State: "Uttarakhand",
      District: "Tehri Garhwal",
      Court: "District Court Tehri Garhwal",
    },
    {
      State: "Uttarakhand",
      District: "Tehri Garhwal",
      Court: "Civil Court Kirti Nagar",
    },
    {
      State: "Uttarakhand",
      District: "Tehri Garhwal",
      Court: "Civil Court Pratapnagar",
    },
    {
      State: "Uttarakhand",
      District: "Tehri Garhwal",
      Court: "Civil Court Narendra Nagar",
    },
    {
      State: "Uttarakhand",
      District: "Tehri Garhwal",
      Court: "Juvenile Justice Board, Tehri",
    },
    {
      State: "Uttarakhand",
      District: "Tehri Garhwal",
      Court: "Family Court Tehri Garhwal",
    },
    {
      State: "Uttarakhand",
      District: "Udham Singh Nagar",
      Court: "District Court U S Nagar",
    },
    {
      State: "Uttarakhand",
      District: "Udham Singh Nagar",
      Court: "Civil Court Jaspur",
    },
    {
      State: "Uttarakhand",
      District: "Udham Singh Nagar",
      Court: "Civil Court Kashipur",
    },
    {
      State: "Uttarakhand",
      District: "Udham Singh Nagar",
      Court: "Civil Court Bazpur",
    },
    {
      State: "Uttarakhand",
      District: "Udham Singh Nagar",
      Court: "Civil Court Khatima",
    },
    {
      State: "Uttarakhand",
      District: "Udham Singh Nagar",
      Court: "Civil Court Sitarganj",
    },
    {
      State: "Uttarakhand",
      District: "Udham Singh Nagar",
      Court: "Family Court Rudrapur",
    },
    {
      State: "Uttarakhand",
      District: "Udham Singh Nagar",
      Court: "Family Court-II Rudrapur",
    },
    {
      State: "Uttarakhand",
      District: "Udham Singh Nagar",
      Court: "Juvenile Justice Board U.S.Nagar",
    },
    {
      State: "Uttarakhand",
      District: "Udham Singh Nagar",
      Court: "Civil Court Kichha",
    },
    {
      State: "Uttarakhand",
      District: "Udham Singh Nagar",
      Court: "Family Court Kashipur",
    },
    {
      State: "Uttarakhand",
      District: "Udham Singh Nagar",
      Court: "Family Court Khatima",
    },
    {
      State: "Uttarakhand",
      District: "Uttarkashi",
      Court: "District Court Uttarkashi",
    },
    {
      State: "Uttarakhand",
      District: "Uttarkashi",
      Court: "Civil Court Barkot",
    },
    {
      State: "Uttarakhand",
      District: "Uttarkashi",
      Court: "Civil Court Purola",
    },
    {
      State: "Uttarakhand",
      District: "Uttarkashi",
      Court: "Juvenile Justice Board, Uttarkashi",
    },
    {
      State: "Uttar Pradesh",
      District: "Agra",
      Court: "Railway Court, Agra",
    },
    {
      State: "Uttar Pradesh",
      District: "Agra",
      Court: "Agra District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Agra",
      Court: "CJJD, Fatehabad",
    },
    {
      State: "Uttar Pradesh",
      District: "Agra",
      Court: "Commercial Court Agra",
    },
    {
      State: "Uttar Pradesh",
      District: "Aligarh",
      Court: "Iglas Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Aligarh",
      Court: "ACJM, Railway Court, Aligarh",
    },
    {
      State: "Uttar Pradesh",
      District: "Aligarh",
      Court: "Atrauli Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Aligarh",
      Court: "Court Complex, Khair",
    },
    {
      State: "Uttar Pradesh",
      District: "Aligarh",
      Court: "Aligarh District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Aligarh",
      Court: "Commercial Court Aligarh",
    },
    {
      State: "Uttar Pradesh",
      District: "Aligarh",
      Court: "Gram Nyaylay Gabhana",
    },
    {
      State: "Uttar Pradesh",
      District: "Ambedkar Nagar",
      Court: "AmbedkarNagar DistrictCourt Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Ambedkar Nagar",
      Court: "Court Complex, Tanda",
    },
    {
      State: "Uttar Pradesh",
      District: "Amroha",
      Court: "Hasanpur Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Amroha",
      Court: "Amroha District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Auraiya",
      Court: "Court Complex, Bidhuna",
    },
    {
      State: "Uttar Pradesh",
      District: "Auraiya",
      Court: "Auraiya District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Ayodhya",
      Court: "Ayodhya District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Ayodhya",
      Court: "Commercial Court Ayodhya",
    },
    {
      State: "Uttar Pradesh",
      District: "Azamgarh",
      Court: "Azamgarh District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Baghpat",
      Court: "Baghpat District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Bahraich",
      Court: "Bahraich District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Ballia",
      Court: "Ballia District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Balrampur",
      Court: "Court Complex, Utraula",
    },
    {
      State: "Uttar Pradesh",
      District: "Balrampur",
      Court: "Balrampur District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Banda",
      Court: "Railway Court, Banda",
    },
    {
      State: "Uttar Pradesh",
      District: "Banda",
      Court: "Banda District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Banda",
      Court: "Court Complex, Atarra",
    },
    {
      State: "Uttar Pradesh",
      District: "Banda",
      Court: "Court Complex, Baberu",
    },
    {
      State: "Uttar Pradesh",
      District: "Barabanki",
      Court: "Court Complex Ramsanehighat",
    },
    {
      State: "Uttar Pradesh",
      District: "Barabanki",
      Court: "Court Complex, Haidergarh",
    },
    {
      State: "Uttar Pradesh",
      District: "Barabanki",
      Court: "Barabanki District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Bareilly",
      Court: "Railway NER, Bareilly",
    },
    {
      State: "Uttar Pradesh",
      District: "Bareilly",
      Court: "Baheri Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Bareilly",
      Court: "Bareilly District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Bareilly",
      Court: "Faridpur",
    },
    {
      State: "Uttar Pradesh",
      District: "Bareilly",
      Court: "Civil Judge Junior Division, Aonla",
    },
    {
      State: "Uttar Pradesh",
      District: "Bareilly",
      Court: "CJJD, Nawabganj",
    },
    {
      State: "Uttar Pradesh",
      District: "Bareilly",
      Court: "NR Bareilly",
    },
    {
      State: "Uttar Pradesh",
      District: "Bareilly",
      Court: "Commercial Court Bareilly",
    },
    {
      State: "Uttar Pradesh",
      District: "Basti",
      Court: "Basti District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Basti",
      Court: "Court Complex, Khalilabdad",
    },
    {
      State: "Uttar Pradesh",
      District: "Bhadohi SR Nagar",
      Court: "Bhadohi District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Bijnor",
      Court: "Bijnor District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Bijnor",
      Court: "Nagina Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Bijnor",
      Court: "Court Complex, Chandpur",
    },
    {
      State: "Uttar Pradesh",
      District: "Bijnor",
      Court: "Najibabad Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Budaun",
      Court: "Bisauli Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Budaun",
      Court: "Sahaswan Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Budaun",
      Court: "Budaun District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Bulandshahr",
      Court: "Court Complex, Khurja",
    },
    {
      State: "Uttar Pradesh",
      District: "Bulandshahr",
      Court: "Bulandshahar District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Bulandshahr",
      Court: "Court Complex, Anoopshahar",
    },
    {
      State: "Uttar Pradesh",
      District: "Chandauli",
      Court: "Chakia Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Chandauli",
      Court: "Chandauli",
    },
    {
      State: "Uttar Pradesh",
      District: "Chandauli",
      Court: "Railway Court Chandauli",
    },
    {
      State: "Uttar Pradesh",
      District: "Chitrakoot",
      Court: "Chitrakoot District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Chitrakoot",
      Court: "Court Complex, Mau",
    },
    {
      State: "Uttar Pradesh",
      District: "Deoria",
      Court: "Deoria District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Etah",
      Court: "Etah District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Etah",
      Court: "Court Complex, Jalesar",
    },
    {
      State: "Uttar Pradesh",
      District: "Etawah",
      Court: "Etawah District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Farrukhabad",
      Court: "Railway Court, Farrukhabad",
    },
    {
      State: "Uttar Pradesh",
      District: "Farrukhabad",
      Court: "Farrukhabad District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Farrukhabad",
      Court: "Kayamganj Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Fatehpur",
      Court: "Fatehpur District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Fatehpur",
      Court: "Court Complex, Khaga",
    },
    {
      State: "Uttar Pradesh",
      District: "Firozabad",
      Court: "Firozabad District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Gautam Buddha Nagar",
      Court: "Court Complex, Jewar",
    },
    {
      State: "Uttar Pradesh",
      District: "Gautam Buddha Nagar",
      Court: "G B Nagar District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Gautam Buddha Nagar",
      Court: "Commercial Court Gautam Buddha Naga",
    },
    {
      State: "Uttar Pradesh",
      District: "Ghaziabad",
      Court: "Ghaziabad District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Ghaziabad",
      Court: "Railway Court Ghaziabad",
    },
    {
      State: "Uttar Pradesh",
      District: "Ghazipur",
      Court: "Mohammdabad Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Ghazipur",
      Court: "Ghazipur District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Ghazipur",
      Court: "Saidpur Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Gonda",
      Court: "Gonda District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Gonda",
      Court: "Railway Court Gonda",
    },
    {
      State: "Uttar Pradesh",
      District: "Gorakhpur",
      Court: "Railway Court Gorakhpur",
    },
    {
      State: "Uttar Pradesh",
      District: "Gorakhpur",
      Court: "Gorakhpur District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Gorakhpur",
      Court: "Bansgaon Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Gorakhpur",
      Court: "Commercial Court Gorakhpur",
    },
    {
      State: "Uttar Pradesh",
      District: "Hamirpur",
      Court: "Rath Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Hamirpur",
      Court: "Maudaha Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Hamirpur",
      Court: "Hamirpur District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Hapur",
      Court: "Garhmukteshwar Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Hapur",
      Court: "Hapur Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Hardoi",
      Court: "Hardoi District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Hathras",
      Court: "Sadabad Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Hathras",
      Court: "Outlying Court Sikandra Rao",
    },
    {
      State: "Uttar Pradesh",
      District: "Hathras",
      Court: "Hathras District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Jalaun",
      Court: "Konch Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Jalaun",
      Court: "Jalaun District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Jalaun",
      Court: "Kalpi Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Jaunpur",
      Court: "Jaunpur District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Jhansi",
      Court: "Jhansi District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Jhansi",
      Court: "Jhansi Railway Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Jhansi",
      Court: "Garotha Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Jhansi",
      Court: "Mauranipur Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Jhansi",
      Court: "Moth Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Jhansi",
      Court: "Commercial Court Jhansi",
    },
    {
      State: "Uttar Pradesh",
      District: "Kannauj",
      Court: "Kannauj District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Kannauj",
      Court: "Chhibramau Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Kanpur Dehat",
      Court: "Ghatampur",
    },
    {
      State: "Uttar Pradesh",
      District: "Kanpur Dehat",
      Court: "Bhognipur",
    },
    {
      State: "Uttar Pradesh",
      District: "Kanpur Dehat",
      Court: "Kanpur Dehat District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Kanpur Nagar",
      Court: "Kanpur Nagar District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Kanpur Nagar",
      Court: "Commercial Court Kanpur Nagar",
    },
    {
      State: "Uttar Pradesh",
      District: "Kasganj",
      Court: "Kasganj District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Kaushambi",
      Court: "Kaushambi District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Kushinagar",
      Court: "Kasia Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Kushinagar",
      Court: "Kushinagar District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Lakhimpur Kheri",
      Court: "Mohammadi Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Lakhimpur Kheri",
      Court: "Kheri District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Lalitpur",
      Court: "Mehrauni Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Lalitpur",
      Court: "Gram Nyaylay Talbehat",
    },
    {
      State: "Uttar Pradesh",
      District: "Lalitpur",
      Court: "Lalitpur District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Lucknow",
      Court: "Railway NER, Lucknow",
    },
    {
      State: "Uttar Pradesh",
      District: "Lucknow",
      Court: "Lucknow District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Lucknow",
      Court: "Railway NR, Lucknow",
    },
    {
      State: "Uttar Pradesh",
      District: "Lucknow",
      Court: "Commercial Court Lucknow",
    },
    {
      State: "Uttar Pradesh",
      District: "Maharajganj",
      Court: "Pharenda",
    },
    {
      State: "Uttar Pradesh",
      District: "Maharajganj",
      Court: "Maharjganj District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Mahoba",
      Court: "Charkari Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Mahoba",
      Court: "Mahoba District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Mahoba",
      Court: "Kulpahar District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Mainpuri",
      Court: "Mainpuri District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Mathura",
      Court: "Railway Court",
    },
    {
      State: "Uttar Pradesh",
      District: "Mathura",
      Court: "Mathura District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Mathura",
      Court: "Chhata Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Mau",
      Court: "Mau District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Meerut",
      Court: "Sardhana Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Meerut",
      Court: "Meerut District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Meerut",
      Court: "Mawana Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Meerut",
      Court: "Commercial Court Meerut",
    },
    {
      State: "Uttar Pradesh",
      District: "Mirzapur",
      Court: "Mirzapur District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Moradabad",
      Court: "Moradabad District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Moradabad",
      Court: "Railway Court Moradabad",
    },
    {
      State: "Uttar Pradesh",
      District: "Moradabad",
      Court: "Outlying Court Thakurdwara",
    },
    {
      State: "Uttar Pradesh",
      District: "Muzaffarnagar",
      Court: "Buddhana Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Muzaffarnagar",
      Court: "Muzaffar Nagar District Court Comp",
    },
    {
      State: "Uttar Pradesh",
      District: "Pilibhit",
      Court: "Pilibhit District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Pilibhit",
      Court: "Bisalpur Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Pratapgarh",
      Court: "Kunda Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Pratapgarh",
      Court: "Lalganj Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Pratapgarh",
      Court: "Pratapgarh District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Prayagraj",
      Court: "District Court Complex, Prayagraj",
    },
    {
      State: "Uttar Pradesh",
      District: "Prayagraj",
      Court: "Railway Court Prayagraj",
    },
    {
      State: "Uttar Pradesh",
      District: "Prayagraj",
      Court: "Commercial Court Prayagraj",
    },
    {
      State: "Uttar Pradesh",
      District: "Raebareli",
      Court: "Raebareli District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Rampur",
      Court: "Rampur District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Saharanpur",
      Court: "Saharanpur District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Saharanpur",
      Court: "Deoband",
    },
    {
      State: "Uttar Pradesh",
      District: "Sambhal at Chandausi",
      Court: "Court Complex Sambhal",
    },
    {
      State: "Uttar Pradesh",
      District: "Sambhal at Chandausi",
      Court: "Chandausi Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Sambhal at Chandausi",
      Court: "Court Complex Gunnaur",
    },
    {
      State: "Uttar Pradesh",
      District: "Sambhal at Chandausi",
      Court: "Sambhal at Chandausi",
    },
    {
      State: "Uttar Pradesh",
      District: "Santkabir Nagar",
      Court: "S K Nagar District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Shahjahanpur",
      Court: "Tilhar",
    },
    {
      State: "Uttar Pradesh",
      District: "Shahjahanpur",
      Court: "Puwayein",
    },
    {
      State: "Uttar Pradesh",
      District: "Shahjahanpur",
      Court: "Shahjahanpur District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Shahjahanpur",
      Court: "Jalalabad",
    },
    {
      State: "Uttar Pradesh",
      District: "Shamli at Kairana",
      Court: "Court Complex Kairana",
    },
    {
      State: "Uttar Pradesh",
      District: "Shamli at Kairana",
      Court: "Shamli",
    },
    {
      State: "Uttar Pradesh",
      District: "Shravasti",
      Court: "Shravasti District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Siddharthnagar",
      Court: "Sidharth Nagar District Court Compl",
    },
    {
      State: "Uttar Pradesh",
      District: "Siddharthnagar",
      Court: "CJSD, Dumariaganj",
    },
    {
      State: "Uttar Pradesh",
      District: "Siddharthnagar",
      Court: "Bansi Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Sitapur",
      Court: "Mahmoodabad District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Sitapur",
      Court: "Biswan Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Sitapur",
      Court: "Sitapur District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Sonbhadra",
      Court: "Sonebhadra District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Sonbhadra",
      Court: "Duddhi Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Sonbhadra",
      Court: "Anpara at Obra",
    },
    {
      State: "Uttar Pradesh",
      District: "Sultanpur",
      Court: "Sultanpur District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Sultanpur",
      Court: "CJJD, Kadipur",
    },
    {
      State: "Uttar Pradesh",
      District: "Sultanpur",
      Court: "CJJD, Musafirkhana",
    },
    {
      State: "Uttar Pradesh",
      District: "Unnao",
      Court: "Unnao District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Unnao",
      Court: "Purva",
    },
    {
      State: "Uttar Pradesh",
      District: "Varanasi",
      Court: "Railway Court NR, Varanasi",
    },
    {
      State: "Uttar Pradesh",
      District: "Varanasi",
      Court: "NER, Varanasi",
    },
    {
      State: "Uttar Pradesh",
      District: "Varanasi",
      Court: "Varanasi District Court Complex",
    },
    {
      State: "Uttar Pradesh",
      District: "Varanasi",
      Court: "Commercial Court Varanasi",
    },
    {
      State: "West Bengal",
      District: "Alipurduar",
      Court: "Alipurduar District Court Complex",
    },
    {
      State: "West Bengal",
      District: "Bankura",
      Court: "KHATRA COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Bankura",
      Court: "BISHNUPUR COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Bankura",
      Court: "BANKURA DISTRICT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Birbhum",
      Court: "RAMPURHAT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Birbhum",
      Court: "DUBRAJPUR COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Birbhum",
      Court: "SURI DISTRICT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Birbhum",
      Court: "BOLPUR COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Calcutta",
      Court: "CITY SESSIONS COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Calcutta",
      Court: "CITY CIVIL COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Calcutta",
      Court: "METROPOLITAN MAGISTRATE COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Calcutta",
      Court: "MUNICIPAL MAGISTRATE COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Calcutta",
      Court: "PRESIDENCY SMALL CAUSES COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Coochbehar",
      Court: "MEKHLIGANJ COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Coochbehar",
      Court: "JUVENILE COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Coochbehar",
      Court: "COOCHBEHAR DISTRICT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Coochbehar",
      Court: "DINHATA COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Coochbehar",
      Court: "MATHABHANGA COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Coochbehar",
      Court: "TUFANGANJ COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Darjeeling",
      Court: "DARJEELING DISTRICT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Darjeeling",
      Court: "SILIGURI COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Darjeeling",
      Court: "KURSEONG COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Darjeeling",
      Court: "commercial court siliguri",
    },
    {
      State: "West Bengal",
      District: "Darjeeling",
      Court: "MIRIK COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Darjeeling",
      Court: "MUNGPOO COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Hooghly",
      Court: "CHANDANNAGORE COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Hooghly",
      Court: "CHINSURAH DISTRICT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Hooghly",
      Court: "SERAMPORE COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Hooghly",
      Court: "ARAMBAGH COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Howrah",
      Court: "AMTA COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Howrah",
      Court: "ULUBERIA COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Howrah",
      Court: "HOWRAH CRIMINIAL COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Howrah",
      Court: "RAILWAY MAGISTRATE COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Howrah",
      Court: "HOWRAH CIVIL COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Jalpaiguri",
      Court: "MAL BAZAR COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Jalpaiguri",
      Court: "RAILWAY COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Jalpaiguri",
      Court: "JALPAIGURI DISTRICT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Jhargram",
      Court: "JHARGRAM COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "kalimpong",
      Court: "Gorubathan Court Complex",
    },
    {
      State: "West Bengal",
      District: "kalimpong",
      Court: "KALIMPONG DISTRICT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Malda",
      Court: "MALDA DISTRICT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Murshidabad",
      Court: "BERHAMPUR CIVIL COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Murshidabad",
      Court: "KANDI COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Murshidabad",
      Court: "LALBAGH CIVIL COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Murshidabad",
      Court: "JANGIPORE COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Murshidabad",
      Court: "LALBAGH CRIMINAL COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Murshidabad",
      Court: "BERHAMPUR CRIMINAL COURT",
    },
    {
      State: "West Bengal",
      District: "Nadia",
      Court: "KALYANI COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Nadia",
      Court: "TEHATTA COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Nadia",
      Court: "KRISHNANAGAR DISTRICT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Nadia",
      Court: "NABADWIP COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Nadia",
      Court: "RANAGHAT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "North Dinajpur",
      Court: "RAIGANJ DISTRICT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "North Dinajpur",
      Court: "ISLAMPUR COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "North Twenty Four Parganas",
      Court: "BARRACKPUR COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "North Twenty Four Parganas",
      Court: "BONGAO COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "North Twenty Four Parganas",
      Court: "BASIRHAT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "North Twenty Four Parganas",
      Court: "BARASAT DISTRICT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "North Twenty Four Parganas",
      Court: "JUVENILE COURT COMPLEX SALT LAKE",
    },
    {
      State: "West Bengal",
      District: "North Twenty Four Parganas",
      Court: "BIDHANAGAR COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "North Twenty Four Parganas",
      Court: "Commercial Court Rajarhat",
    },
    {
      State: "West Bengal",
      District: "North Twenty Four Parganas",
      Court: "MP-MLA Court Complex",
    },
    {
      State: "West Bengal",
      District: "Paschim Bardhaman",
      Court: "DURGAPUR COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Paschim Bardhaman",
      Court: "Asansol Commercial Court",
    },
    {
      State: "West Bengal",
      District: "Paschim Bardhaman",
      Court: "ASANSOL COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Paschim Medinpur",
      Court: "MEDINIPUR DISTRICT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Paschim Medinpur",
      Court: "KHARAGPUR COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Paschim Medinpur",
      Court: "GHATAL COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Paschim Medinpur",
      Court: "GARBETA COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Paschim Medinpur",
      Court: "DANTAN COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Purba Bardhaman",
      Court: "PURBA BARDHAMAN DIST COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Purba Bardhaman",
      Court: "KALNA COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Purba Bardhaman",
      Court: "KATWA COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Purba Medinipur",
      Court: "TAMLUK DISTRICT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Purba Medinipur",
      Court: "HALDIA COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Purba Medinipur",
      Court: "CONTAI COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Purulia",
      Court: "RAGHUNATH COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "Purulia",
      Court: "PURULIA DISTRICT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "South Dinajpur",
      Court: "BALURGHAT DISTRICT COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "South Dinajpur",
      Court: "BUNIADPUR COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "South Twenty Four Parganas",
      Court: "DIAMOND HARBOUR CIVIL COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "South Twenty Four Parganas",
      Court: "RAILWAY MAGISTRATE COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "South Twenty Four Parganas",
      Court: "BARUIPUR COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "South Twenty Four Parganas",
      Court: "Alipur CBI Court Complex",
    },
    {
      State: "West Bengal",
      District: "South Twenty Four Parganas",
      Court: "SEALDAH CRIMINAL COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "South Twenty Four Parganas",
      Court: "KAKDWIP COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "South Twenty Four Parganas",
      Court: "ALIPUR CIVIL COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "South Twenty Four Parganas",
      Court: "DIAMOND HARBOUR CRIMINAL COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "South Twenty Four Parganas",
      Court: "ALIPUR CRIMINAL COURT COMPLEX",
    },
    {
      State: "West Bengal",
      District: "South Twenty Four Parganas",
      Court: "Commercial Court Alipur",
    },
  ];
  let state = new Map();
  let district = new Map();
  let court = [];

  for (let index = 0; index < file.length; index++) {
    const element = file[index];
    if (!state.has(element.State)) {
      district = new Map();
      court = [];
      court.push(element.Court);
      district.set(element.District, court);
      state.set(element.State, district);
    } else {
      if (!district.has(element.District)) {
        court = [];
      }
      court.push(element.Court);
      district.set(element.District, court);
      state.set(element.State, district);
    }
  }

  let res = [];
  res.push({
    state: "All",
    district: [
      {
        name: "All",
        complex: ["All"],
      },
    ],
  });
  state.forEach((value, key) => {
    let result = {};
    result.state = key;
    let districtArr = [];
    districtArr.push({
          name: "All",
          complex: ["All"],
    });

    value.forEach((val2, district) => {
      let districtRes = {
        name: district,
        complex: ['All',...val2],
      };
      districtArr.push(districtRes);
    });
    result.district = districtArr;
    res.push(result);
  });
  return res;
}


