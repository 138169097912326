import { useNavigate } from "react-router-dom";
import * as Styled from "./menuStyled";
import { sideMenuList } from "../../utils/staticValues";
const Menu = () => {
  const navigate = useNavigate();
  const url = window.location.href;
  const userAccess = localStorage.getItem("userAccess");
  return (
    <Styled.MenuDiv>
      {
        sideMenuList?.map((menuItem, index) => 
          userAccess && parseInt(userAccess) >= menuItem?.accessLevel ?
            <Styled.MenuItem 
              active = {url.includes(`${process.env.REACT_APP_BASE_URL || ''}${menuItem?.url}`)}
              key = {`${menuItem?.name}-${index}`}
            >
              <img 
                src={url.includes(`${process.env.REACT_APP_BASE_URL || ''}${menuItem?.url}`) ? menuItem?.activeImageSrc : menuItem?.inActiveImageSrc} 
                alt={menuItem?.inActiveImageSrc || menuItem?.name}
                onClick={()=> navigate(menuItem?.url)}
                />
              <span onClick={()=> navigate(menuItem?.url)}>{menuItem?.name}</span>
            </Styled.MenuItem>
            :
            <>
            </>
          
        )
      }
    </Styled.MenuDiv>
  );
};

export default Menu;
