import "./App.css";
import { Provider } from "react-redux";
import {
  Route,
  Routes,
} from "react-router-dom";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import Home from './containers/Home'
import Search from './containers/Search'
import SignIn from "./containers/SignIn";
import SearchHistory from "./containers/SearchHistory";
import Users from "./containers/Users";
import store from "./store/store"; // Import your Redux store here
import { useEffect } from "react";
import { isValidToken } from "./utils/utilFunctions";
import Services from "./containers/Services";
import Categories from "./containers/Categories";

function App({msalInstance}) {

  useEffect(()=>{
    if(isValidToken() === false){
        localStorage.removeItem("token");
        localStorage.removeItem("userAccess");
        localStorage.removeItem("userId");
        // navigate('/')
    }
  },[])


	return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>
	  <Provider store={store}>
        <Routes>
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/search" element={<Search />} />
          <Route exact path="/history" element={<SearchHistory />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/categories" element={<Categories />} />
          <Route exact path="/" element={<SignIn />} />
        </Routes>
		</Provider>
      </AuthenticatedTemplate>
	  <UnauthenticatedTemplate>
	  <Routes>
          <Route exact path="/home" element={<SignIn />} />
          <Route exact path="/search" element={<SignIn />} />
          <Route exact path="/history" element={<SignIn />} />
          <Route exact path="/users" element={<SignIn />} />
          <Route exact path="/services" element={<SignIn />} />
          <Route exact path="/categories" element={<SignIn />} />
          <Route exact path="/" element={<SignIn />} />
        </Routes>
	  </UnauthenticatedTemplate>
    </MsalProvider>
  );
}

export default App;
