import { useEffect, useState, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import * as Styled from "./searchStyled";
import { useDispatch } from "react-redux";
import { submitRequest } from "../../store/actions/userActions";
import Layout from "../../components/Layout";
import Modal from "./../../components/Modal";
import PartialRunConfirmationModal from "./../../components/PartialRunConfirmationModal";

import "react-datepicker/dist/react-datepicker.css";
import * as axios from "../../utils/axios";

import {Main} from '../../utils/stateListDistrict-Ecourt'
import {list } from '../../utils/highCourtList'
const Search = () => {
  const [searchCategory, setSearchCategory] = useState(null);
  const [showMandatoryFields,setShowMandatoryFields] = useState(false);
  const [openPartialRunConfirmation,setOpenPartialRunConfirmation] = useState(false);
  const [stepNo, setStepNo] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [apiErrorMessage, setApiErrorMessage] = useState(null);
  const [highCourts, setHighCourts] = useState([]);
  const [portalPointer, setPortalPointer] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [benchCity,setBenchCity]=useState([])
  const states=Main()

  const [state,setState]=useState(states.map((item)=>item.state))
  const [district,setDistrict]=useState([])
  const [district_court_complex,setComplex]=useState([])
  const [currentDistrict,setCurrentDistrict]=useState([])

  const [caseDetailFields, setCaseDetailFields] = useState([
    {
      name: "case_id",
      type: "input",
      label: "Case ID",
      placeholder: "Enter the case ID",
      required: true,
      value: null,
      error: false,
    },
    {
      name: "names",
      type: "input",
      label: "Name(s)",
      placeholder: "Enter the subject(s) name",
      required: true,
      value: null,
      error: false,
    },
    {
      name: "from_date",
      type: "date",
      label: "From Year",
      placeholder: "Select year",
      required: true,
      value: null,
      error: false,
    },
    {
      name: "to_date",
      type: "date",
      label: "To Year",
      placeholder: "Select year",
      required: false,
      value: null,
      error: false,
    },
  ]);
  const [identifierFields, setIdentifierFields] = useState([
    {
      name: "date_of_birth",
      type: "date",
      label: "Date of Birth",
      placeholder: "Select date",
      required: false,
      value: null,
      error: false,
      field_for: 'common'
    },
    {
      name: "pan_number",
      type: "input",
      label: "PAN",
      placeholder: "Enter PAN",
      required: false,
      value: null,
      error: false,
      field_for: 'common'
    },
    {
      name: "aadhaar_number",
      type: "input",
      label: "Aadhaar",
      placeholder: "Enter aadhaar",
      required: false,
      value: null,
      error: false,
      field_for: 'common'
    },
    {
      name: "address",
      type: "input",
      label: "Address",
      placeholder: "Enter address",
      required: false,
      value: null,
      error: false,
      field_for: 'common'
    },

    {
      name: "state",
      type: "dropdown",
      label: "State",
      placeholder: "Enter state",
      required: false,
      value: null,
      error: false,
      field_for: 'e-court'
    },
    {
      name: "pincode",
      type: "input",
      label: "Pin Code",
      placeholder: "Enter pin code",
      required: false,
      value: null,
      error: false,
      field_for: 'common'
    },
    {
      name: "phone_number",
      type: "input",
      label: "Phone Number",
      placeholder: "Enter phone number",
      required: false,
      value: null,
      error: false,
      field_for: 'common'
    },
    {
      name: "city",
      type: "dropdown",
      label: "Bench/City",
      placeholder: "Enter city",
      required: false,
      value: null,
      error: false,
      field_for: 'common'
    },
    {
    	name: "high_courts",
    	type: "dropdown",
    	label: "High Court",
    	placeholder: "Select High Court",
    	required: false,
    	value: null,
    	error: false,
      field_for: 'high_court'
    },
    {
    	name: "district",
    	type: "dropdown",
    	label: "District",
    	placeholder: "Enter district",
    	required: false,
    	value: null,
    	error: false,
      field_for: 'e-court'
    },
    {
    	name: "district_court_complex",
    	type: "dropdown",
    	label: "District Court Complex",
    	placeholder: "Enter district court complex",
    	required: false,
    	value: null,
    	error: false,
      field_for: 'e-court'
    },
    // {
    // 	name: "court_establishment",
    // 	type: "dropdown",
    // 	label: "Court Establishment",
    // 	placeholder: "Enter court establishment",
    // 	required: false,
    // 	value: null,
    // 	error: false,
    //   field_for: 'e-court'
    // },
  ]);
  const [errorList, setErrorList] = useState([]);
  const [liveList, setLiveList] = useState([]);
  const [targetNamesArray, setTargetNamesArray] = useState([]);
  const [duplicateTargetNames, setDuplicateTargetNames] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const categoryBoxes = [
    {
      id: "24b054a4-cfd5-411d-9603-733a8bd63a23",
      imageSrc: "/all-icon.svg", ////// todo  here i have matfched all the ids to all's id's
      categoryName: "ALL",
      category: "all",
    },
    {
      id: "24b054a4-cfd5-411d-9603-733a8bd63a23",
      imageSrc: "/aml-icon.svg",
      categoryName: "AML",
      category: "aml",
    },
    {
      id: "24b054a4-cfd5-411d-9603-733a8bd63a23",
      imageSrc: "/ikyc-icon.svg",
      categoryName: "I-KYC",
      category: "ikyc",
    },
    {
      id: "24b054a4-cfd5-411d-9603-733a8bd63a23",
      imageSrc: "/osint-icon.svg",
      categoryName: "OSINT",
      category: "osint",
    },
    {
      id: "24b054a4-cfd5-411d-9603-733a8bd63a23",
      imageSrc: "/india-other-icon.svg",
      categoryName: "India - Other",
      category: "india_other",
    },
    {
      id: "24b054a4-cfd5-411d-9603-733a8bd63a23",
      imageSrc: "/international-other-icon.svg",
      categoryName: "International - Other",
      category: "international_other",
    },
  ];

  const courts = [
    "All",
    "High Court at Calcutta",
    "High Court of Chhattisgarh",
    "High Court of Delhi",
    "High Court of Orissa",
    "High Court of Rajasthan - Jaipur Bench",
    "High Court of Rajasthan (E-Court) - Jaipur Bench",
    "High Court of Rajasthan - Jodhpur Bench",
    "High Court of Rajasthan (E-Court) - Jodhpur Bench",
    "High Court of Jharkhand",
    "High Court of Karnataka",
    "High Court of Kerala",
    "High Court of Manipur at Imphal",
    "High Court of Meghalaya",
    "High Court of Judicature at Patna",
    "High Court of Punjab and Haryana",
    "High Court of Sikkim",
    "High Court of Tripura",
    "High Court of Uttarakhand",
    "High Court of Jammu and Kashmir- Srinagar Wing",
    "High Court of Jammu and Kashmir- Jammu Wing",
  ];
  const stateList = [
    "Agra",
    "Ahmedabad",
    "Allahabad",
    "Amaravati",
    "Amritsar",
    "Bangalore",
    "Chandigarh",
    "Chennai",
    "Cochin",
    "Cuttack",
    "Dehradun",
    "Delhi",
    "Guwahati",
    "Hyderabad",
    "Indore",
    "Jabalpur",
    "Jaipur",
    "Jodhpur",
    "Kolkata",
    "Lucknow",
    "Mumbai",
    "Nagpur",
    "Panaji",
    "Patna",
    "Pune",
    "Raipur",
    "Rajkot",
    "Ranchi",
    "Surat",
    "Varanasi",
    "Visakhapatnam"
];

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        const response = await axios.get("services/get-all-services");
        let services = response?.data?.data?.services;

        if(!services)
          setServiceData(categoryBoxes);

        setServiceData(services.map(service => {
          let temp = categoryBoxes?.find(category => category?.categoryName?.toLocaleLowerCase() === service?.name?.toLocaleLowerCase())

            return {
              id: service?.id,
              imageSrc: temp?.imageSrc,
              categoryName: service?.name,
              category: temp?.category,

            }
        }))
      } catch (error) {
        console.log("Failed to fetch data:", error);
        setServiceData(categoryBoxes);
      }
    };

    const fetchServiceMandatoryFields = async (service_id) => {
      try {
        if (service_id === null) {

          let tempArr = [...identifierFields];
          tempArr?.map((arr, index) => {
            tempArr[index].required = false;
            return null;
          });

          setIdentifierFields(tempArr);

          tempArr = [...caseDetailFields];
          tempArr?.map((arr, index) => {
            if (arr.name === "to_date") tempArr[index].required = false;
            else tempArr[index].required = true;
            return null;
          });
          setCaseDetailFields(tempArr);
        } else {
          const response = await axios.get(
            `services/get-service-fields/${service_id}`
          );
          let mandatoryFields = response?.data?.data?.portalSet;

          let tempArr = [...identifierFields];
          await mandatoryFields.map((mandatfield) => {
            tempArr?.map((arr, index) => {
              if (arr.name?.toLowerCase() === mandatfield) {
                tempArr[index].required = true;
              }
              return null;
            });
            return null;
          });

          setIdentifierFields(tempArr);

          tempArr = [...caseDetailFields];
          await mandatoryFields.map((mandatfield) => {
            tempArr?.map((arr, index) => {
              if (arr.name?.toLowerCase() === mandatfield) {
                tempArr[index].required = true;
              }
              return null;
            });
            return null;
          });

          setCaseDetailFields(tempArr);
        }

      } catch (error) {
        console.log("Failed to fetch data:", error);
      }
    };

    if (stepNo === null) fetchServiceData();
    fetchServiceMandatoryFields(portalPointer);
  }, [portalPointer]);
  const dispatch = useDispatch();
  const DateInputField = forwardRef(({ value, onClick, onChange }, ref) => (
    <Styled.DateInputField
      placeholder="Select date"
      value={value}
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    />
  ));
  const updateFieldValue = (index, type, value, arrayName) => {
    let tempArr;
    if (arrayName === "caseDetails") tempArr = [...caseDetailFields];
    else tempArr = [...identifierFields];
    tempArr[index].value = value;
    if (arrayName === "caseDetails") setCaseDetailFields(tempArr);
    else {
      setIdentifierFields(tempArr);
    }
  };

  const resetState = () => {
    setSearchCategory(null);
    setPortalPointer(null);
    let tempArr = [...caseDetailFields];
    tempArr?.map((arr, index) => {
      tempArr[index].error = false;
      tempArr[index].value = null;
    });
    setCaseDetailFields(tempArr);
    tempArr = [...identifierFields];
    tempArr?.map((arr, index) => {
      tempArr[index].error = false;
      tempArr[index].value = null;
    });
    setIdentifierFields(tempArr);
    setHighCourts([])
  };
  const handleBack = () => {
    if (stepNo === 1) resetState();
    else setStepNo(1);
  };

  const checkForError =  (caseID, targetNames) => {
    let error = false;
    let tempArr = [...caseDetailFields];
    caseDetailFields?.map((field, index) => {
      if (field.required === true && (field.value === null || field.value === "")) {
        tempArr[index].error = true;
        error = true;
      } else if(field.required === true){
        tempArr[index].error = false;
        // error = false;
      }
    });
    setCaseDetailFields(tempArr);

    tempArr = [...identifierFields];
    identifierFields?.map((field, index) => {
      if (field.required === true && (field.value === null || field.value === "")) {
        tempArr[index].error = true;
        error = true;
      } else if(field.required === true){
        tempArr[index].error = false;
        // error = false;
      }
    });
    setIdentifierFields(tempArr);

    return error;
  }
  const handleNext = async () => {

    try {
      setErrorMessage(null);
      setApiErrorMessage(null);
      setDuplicateTargetNames([]);
      setTargetNamesArray([]);
      let response = null;
      let error = checkForError();

      identifierFields?.map((field, index) => {
        if(field.name === "high_courts")
          updateFieldValue(index, "input", highCourts, "identifierValues")
        if(field.name === "city"&&benchCity.length>0){
            // let str=""
            // identifierFields[index].value=benchCity.join(", ");
            updateFieldValue(index, "input",benchCity.join(", ") , "identifierValues")


        }

        return null;
      })

      if(error === true)
        return;

      if (
        error === false &&
        caseDetailFields?.[1]?.value !== "" ||
        caseDetailFields?.[1]?.value !== null
      )
        response = await axios.post("request/check-duplicates", {
          caseID: caseDetailFields?.[0]?.value,
          targetNames: caseDetailFields?.[1]?.value,
        });


      // setErrorMessage(response?.data?.data?.errorMessage);
      let tempArr = caseDetailFields?.[1]?.value;
      let target_names = [];
      setDuplicateTargetNames(response?.data?.data?.duplicates);
      tempArr?.split(',')?.map(target => {
        if(target?.trim() !== '')
          target_names.push(target?.trim());

        return null;
      });


      target_names?.map(target => {
        setTargetNamesArray(prevState => [...prevState, {
          service_id: portalPointer,
          request_id: null,
          partial_run : false,
          name: target
        }]);
        return null;
      })

      if(target_names?.length === 0)
        setApiErrorMessage("Provide target names")
      if(new Set(target_names?.map(target => target?.toLowerCase())).size !== target_names.length)
        setApiErrorMessage("Target names have duplicate values.")

      else if (error === false && response?.data?.data?.errorMessage === "NO ERROR")
        setStepNo(2);
      else {
        setOpenPartialRunConfirmation(true);
      }

    } catch (err) {
      if(err?.response?.status === 401)
        setApiErrorMessage("Token Expired, Redirecting to login page.");
      else
        setApiErrorMessage("Error, Please contact Tech Team.")
    }
  };

  const handleRunTypeSelection = (partialRun) =>{
    setOpenPartialRunConfirmation(false);
    let tempArr = targetNamesArray;
    setTargetNamesArray([]);

    tempArr?.map(target => {
      let duplicate = duplicateTargetNames?.filter(duplicate => duplicate?.target_name?.toLowerCase() === target?.name?.toLowerCase())?.[0];

      if(duplicate !== undefined && partialRun === false && moment(0, 'HH').isSame(duplicate?.created_at, 'day') === true)
        return false;
      else
        setTargetNamesArray(prevState => [...prevState,{
          service_id: duplicate === undefined ? portalPointer : duplicate?.service_id,
          request_id: duplicate === undefined ? null: duplicate?.id,
          partial_run : duplicate === undefined ? false : partialRun,
          name: target?.name
        }]);
          });
    setStepNo(2);
  }
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPortalData = async (id = null) => {
      try {
        let url = `portal/get-all-portals`;
        if (id) {
          url = `portal/get-services-portals/${id}`;
        }
        const response = await axios.get(url);
        const data = response.data;
        // console.log("Fetched data: for the portal devsandh boi", data.data);
        // Check the value of data
        let portalList = data.data.portals;
        let liveListData = [];
        let errorListData = [];
        for (let index = 0; index < portalList.length; index++) {
          const element = portalList[index];
          if (element.status === "LIVE") {
            liveListData.push(element);
          } else {
            errorListData.push(element);
          }
        }
        setErrorList(errorListData);
        setLiveList(liveListData);
      } catch (error) {
        console.log("Failed to fetch data:", error);
      }
    };

    if (stepNo === 2) fetchPortalData(portalPointer);
  }, [stepNo]);
  const handleStartSearch = () => {
    const caseId =
      caseDetailFields.find((field) => field.name === "case_id")?.value || "";
    const targetName =
      caseDetailFields.find((field) => field.name === "names")?.value || "";
    const fromDate =
      caseDetailFields.find((field) => field.name === "from_date")?.value || "";
    const toDate =
      caseDetailFields.find((field) => field.name === "to_date")?.value || "";
    const convertedData = {
      case_id: caseId,
      target_name: targetNamesArray,
      service_id: portalPointer,
      from_date: new Date(fromDate).getFullYear(),
      to_date: new Date(toDate).getFullYear(),
      data: JSON.stringify(identifierFields),
    };
    dispatch(submitRequest(convertedData));
  };
  return (
    <Layout>
      <div style={{ display: "flex" }}>
        <Modal modalOpen={modalOpen} name="SearchStatedModal">
          <img src="/thumbs-up-icon.svg" alt="OK" />
          <p>Your search has been started!</p>
          <p
            style={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px" }}
          >
            You may check the result status in "Search History" section where
            you can download the files.
          </p>
          <Styled.OkayButton onClick={() => navigate("/history")}>
            Okay!
          </Styled.OkayButton>
        </Modal>

        <PartialRunConfirmationModal
          modalOpen={openPartialRunConfirmation}
          name="PartialRunConfirmationModal"
          width={"550px"}
          height={"fit-content"}
          minHeight={"350px"}
          padding={"5px"}
        >
          <p
            style={{
              textAlign: "right",
              margin: "0px 10px",
              cursor: "pointer",
            }}
            onClick={() => setOpenPartialRunConfirmation(false)}
          >
            x
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "10px 30px 30px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src="/attention-icon.svg" alt="!" />
              <p style={{ color: "#F04639", padding: "0px 10px 5px" }}>
                Attention!
              </p>
            </div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              The following target name(s) are already being searched on
              this case id.
            </p>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "600",
                lineHeight: "24px",
              }}
            >
              Target Name(S):
              {duplicateTargetNames?.map((duplicate, index) => (
                <span key={index}>
                  {" " + duplicate?.target_name + " "}
                  {moment(0, "HH").isSame(duplicate?.created_at, "day") ===
                    true && (
                    <span style={{ color: "red" }}>
                      (For this name search on all portals is allowed after 24
                      Hours)
                    </span>
                  )}
                  {index < duplicateTargetNames?.length - 1 ? ", " : ""}
                </span>
              ))}
            </p>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Do you want to search the subject name(s) again on all the portals
              or the incomplete/errored portals?
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Styled.BackButton
                width={"45%"}
                onClick={() => handleRunTypeSelection(true)}
              >
                Search on errored portals
              </Styled.BackButton>
              <Styled.OkayButton
                width={"45%"}
                disabled={
                  duplicateTargetNames?.filter((duplicate) =>
                    moment(0, "HH").isSame(duplicate?.created_at, "day")
                  )?.length === duplicateTargetNames?.length
                }
                onClick={() => handleRunTypeSelection(false)}
              >
                Search on all portals
              </Styled.OkayButton>
            </div>
          </div>
        </PartialRunConfirmationModal>

        <Modal
          modalOpen={showMandatoryFields}
          padding={"40px"}
          width={"700px"}
          height={"250px"}
          name="MandatoryFieldsModal"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ color: "#048FA5" }}>Mandatory fields for search</p>
              <p
                style={{ margin: "0px", cursor: "pointer" }}
                onClick={() => setShowMandatoryFields(false)}
              >
                x
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ color: "#181917", width: "50%", fontSize: "16px" }}>
                Portal Name
              </p>
              <p style={{ color: "#181917", fontSize: "16px" }}>
                Fields Required
              </p>
            </div>
            <div style={{ fontSize: "14px", display: "flex" }}>
              <p style={{ color: "#181917", fontSize: "14px", width: "50%" }}>
                DORIS
              </p>
              <p style={{ color: "#919EAB", fontSize: "14px" }}>Address</p>
            </div>
            <div style={{ fontSize: "14px", display: "flex" }}>
              <p style={{ color: "#181917", fontSize: "14px", width: "50%" }}>
                RBI
              </p>
              <p style={{ color: "#919EAB", fontSize: "14px" }}>
                From year, To year
              </p>
            </div>
            <div style={{ fontSize: "14px", display: "flex" }}>
              <p style={{ color: "#181917", fontSize: "14px", width: "50%" }}>
                ITAT, NCLT, NCLAT
              </p>
              <p style={{ color: "#919EAB", fontSize: "14px" }}>City</p>
            </div>
            <div style={{ fontSize: "14px", display: "flex" }}>
              <p style={{ color: "#181917", fontSize: "14px", width: "50%" }}>
                District E Court
              </p>
              <p
                style={{
                  color: "#919EAB",
                  fontSize: "14px",
                  marginLeft: "auto",
                  width: "50%",
                }}
              >
                State, District, District court complex, court establishment
              </p>
            </div>
          </div>
        </Modal>
        {searchCategory === null ? (
          <Styled.CategorySelectDiv>
            <Styled.HeadingText1>
              Search & Let AI do the work
            </Styled.HeadingText1>
            <Styled.HeadingText2>
              Select the services from which you want to search the cases.
            </Styled.HeadingText2>
            <Styled.CategoryBoxesDiv>
              {serviceData?.map((box) => (
                <Styled.CategoryBoxDiv
                  onClick={() => {
                    setSearchCategory(box?.categoryName);
                    setPortalPointer(box?.id);
                    setStepNo(1);
                  }}
                >
                  <img
                    src={box?.imageSrc || "/services-icon-inactive.svg"}
                    alt={box?.categoryName}
                  />
                  <div>{box?.categoryName}</div>
                </Styled.CategoryBoxDiv>
              ))}
            </Styled.CategoryBoxesDiv>
          </Styled.CategorySelectDiv>
        ) : (
          <Styled.SearchPageDiv>
            <Styled.BackLinkDiv onClick={handleBack}>
              <img src="/back-icon.svg" alt="<-" /> Back
            </Styled.BackLinkDiv>
            <Styled.CategoryNameDiv>{searchCategory}</Styled.CategoryNameDiv>
            <Styled.HeadingText>
              Start your search for the target(s) on the portals.
            </Styled.HeadingText>
            <Styled.SearchStepsDiv>
              <Styled.StepDiv
                lineColor={stepNo === 2 ? "#048FA5" : "#C9C9C9"}
                active={stepNo === 1}
              >
                <img
                  src={
                    stepNo === 1
                      ? "/step-one-active.svg"
                      : "/step-one-completed.svg"
                  }
                  alt="1"
                />
                <span>Fill the details</span>
              </Styled.StepDiv>
              <Styled.StepDiv left={"400px"} active={stepNo === 2}>
                <img
                  src={
                    stepNo === 2
                      ? "/step-two-active.svg"
                      : "/step-two-inactive.svg"
                  }
                  alt="2"
                />
                <span>Preview & Start Search</span>
              </Styled.StepDiv>
            </Styled.SearchStepsDiv>
            {stepNo === 2 ? (
              <Styled.FormPreviewDiv>
                <Styled.PortalsDetailsDiv>
                  {errorList.length > 0 && (
                    <Styled.PortalsListDiv type="error" marginBottom="30px">
                      <Styled.ListHeading type="error">
                        Error in portals
                      </Styled.ListHeading>
                      <Styled.ListText>
                        These portals contain errors, which is why the search cannot be executed on them. Please advise the research team to perform a manual search for these portals.
                      </Styled.ListText>
                      {errorList.length > 0 && (
                        <>
                          {errorList
                            ?.filter((portal) => portal.status !== "LIVE")
                            ?.map((portal) => (
                              <p key={portal.id}>{portal.name}</p>
                            ))}
                        </>
                      )}
                    </Styled.PortalsListDiv>
                  )}
                  <Styled.PortalsListDiv>
                    <Styled.ListHeading>Live portals</Styled.ListHeading>
                    {liveList
                      ?.filter((portal) => portal.status === "LIVE")
                      ?.map((portal) => (
                        <p>{portal.name}</p>
                      ))}
                    {/* <Styled.ViewMore>View More</Styled.ViewMore> */}
                  </Styled.PortalsListDiv>
                </Styled.PortalsDetailsDiv>

                <Styled.DetailsPreviewDiv>
                  <Styled.ListHeading>Search Preview</Styled.ListHeading>
                  <Styled.ValuesList borderBottom={true}>
                    {caseDetailFields?.map(
                      (field, index) =>
                        field.type === "input" &&
                        (field.name === "names" ? (
                          <p>
                            {field.label} :{" "}
                            {targetNamesArray?.map((target, index) => (
                              <span>
                                {target?.name}
                                {index !== targetNamesArray?.length - 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))}
                          </p>
                        ) : (
                          <p>
                            {field.label} : <span>{field?.value}</span>
                          </p>
                        ))
                    )}
                    <p>
                      Date :{" "}
                      <span>
                        {caseDetailFields[2]?.value
                          ? moment(caseDetailFields[2]?.value).format("YYYY")
                          : "-"}{" "}
                      </span>
                      &nbsp;to&nbsp;
                      <span>
                        {caseDetailFields[3]?.value
                          ? moment(caseDetailFields[3]?.value).format("YYYY")
                          : moment().format("YYYY")}
                      </span>
                    </p>
                  </Styled.ValuesList>

                  <Styled.ValuesList>
                    <span style={{ margin: "5px 0px 10px" }}>Identifiers</span>
                    <p>
                      Date of birth :{" "}
                      <span>
                        {identifierFields[0]?.value
                          ? moment(identifierFields[0]?.value).format(
                              "DD MMM, YYYY"
                            )
                          : "-"}{" "}
                      </span>
                    </p>
                    {identifierFields?.map(
                      (field) =>
                        field.type === "input" &&
                        field.field_for === "common" && (
                          <p>
                            {field.label} : <span>{field?.value || "-"}</span>
                          </p>
                        )
                    )}
                  </Styled.ValuesList>

                  <Styled.ValuesList>
                    <span style={{ margin: "5px 0px 10px" }}>
                      For Ecourt services
                    </span>
                    {identifierFields?.map(
                      (field) =>
                        (field.type === "input" || field.type === "dropdown") &&
                        field.field_for === "e-court" && (
                          <p>
                            {field.label} : <span>{field?.value || "-"}</span>
                          </p>
                        )
                    )}
                  </Styled.ValuesList>

                  <Styled.ValuesList>
                    <span style={{ margin: "5px 0px 10px" }}>
                      For High Court services
                    </span>
                    {identifierFields?.map(
                      (field) =>
                        field.type === "dropdown" &&
                        field.field_for === "high_court" && (
                          <p>
                            {field.label} :{" "}
                            <span>{field?.value?.join(", ") || "-"}</span>
                          </p>
                        )
                    )}
                  </Styled.ValuesList>
                  <Styled.ButtonsDiv>
                    <Styled.BackButton onClick={() => setStepNo(1)}>
                      Back
                    </Styled.BackButton>
                    <Styled.SearchButton
                      onClick={() => {
                        setModalOpen(true);
                        handleStartSearch();
                      }}
                    >
                      Start Search
                    </Styled.SearchButton>
                  </Styled.ButtonsDiv>
                </Styled.DetailsPreviewDiv>
              </Styled.FormPreviewDiv>
            ) : (
              stepNo === 1 && (
                <Styled.SearchFormDiv>
                  <Styled.SearchBoxHeading>
                    Please enter the details to start the search
                    {/* <img
                      src="/info-icon.svg"
                      alt="i"
                      title="View mandatory fields"
                      onClick={() => setShowMandatoryFields(true)}
                    /> */}
                  </Styled.SearchBoxHeading>
                  <Styled.CaseDetailsDiv>
                    {caseDetailFields?.map((field, index) =>
                      field?.type === "input" ? (
                        <Styled.InputDiv
                          marginRight={"30px"}
                          marginBottom={"20px"}
                        >
                          <Styled.InputLabel>{field?.label}</Styled.InputLabel>
                          <Styled.InputField
                            placeholder={field?.placeholder}
                            value={field?.value}
                            onChange={(e) =>
                              updateFieldValue(
                                index,
                                field.type,
                                e.target.value,
                                "caseDetails"
                              )
                            }
                          />
                          {field?.error === true ? (
                            <Styled.InputErrorDiv>
                              {`${field.label} required`}
                            </Styled.InputErrorDiv>
                          ) : (
                            ""
                          )}
                        </Styled.InputDiv>
                      ) : (
                        <Styled.DateDiv
                          marginRight={
                            field?.name === "from_date" ? "30px" : "0px"
                          }
                        >
                          <Styled.InputLabel required={field?.required}>
                            {field.label}
                          </Styled.InputLabel>
                          <DatePicker
                            isClearable
                            showYearPicker
                            dateFormat="yyyy"
                            customInput={
                              <DateInputField value={field?.value} />
                            }
                            selected={field?.value}
                            onChange={(date) =>
                              updateFieldValue(
                                index,
                                field.type,
                                date,
                                "caseDetails"
                              )
                            }
                          />
                          {field?.error === true ? (
                            <Styled.InputErrorDiv>
                              {`${field.label} required`}
                            </Styled.InputErrorDiv>
                          ) : (
                            ""
                          )}
                        </Styled.DateDiv>
                      )
                    )}
                  </Styled.CaseDetailsDiv>
                  {errorMessage && errorMessage !== "NO ERROR" ? (
                    <p style={{ margin: "0px 0px 20px", color: "red" }}>
                      {errorMessage}
                    </p>
                  ) : (
                    <></>
                  )}
                  <Styled.SearchBoxHeading>Identifiers</Styled.SearchBoxHeading>
                  <Styled.IdentifiersDiv>
                    {identifierFields?.map(
                      (field, index) =>
                        field.field_for === "common" &&
                        (field?.type === "input" ? (
                          <Styled.InputDiv
                            marginRight={"30px"}
                            marginBottom={"20px"}
                          >
                            <Styled.InputLabel required={field?.required}>
                              {field?.label}
                            </Styled.InputLabel>
                            <Styled.InputField
                              placeholder={field?.placeholder}
                              value={field?.value}
                              onChange={(e) =>
                                updateFieldValue(
                                  index,
                                  field.type,
                                  e.target.value,
                                  "identifierValues"
                                )
                              }
                            />
                            {field?.error === true ? (
                            <Styled.InputErrorDiv>
                              {`${field.label} required`}
                            </Styled.InputErrorDiv>
                          ) : (
                            ""
                          )}
                          </Styled.InputDiv>
                        ) : (
                          field?.type === "date" ? (
                            <Styled.DateDiv
                              marginRight={"30px"}
                              width={"calc((100% - 60px)/3)"}
                            >
                              <Styled.InputLabel required={field?.required}>
                                {field.label}
                              </Styled.InputLabel>
                              <DatePicker
                                isClearable
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="scroll"
                                yearDropdownItemNumber={50}
                                scrollableYearDropdown
                                customInput={
                                  <DateInputField value={field?.value} />
                                }
                                selected={field?.value}
                                onChange={(date) =>
                                  updateFieldValue(
                                    index,
                                    field.type,
                                    date,
                                    "identifierValues"
                                  )
                                }
                              />
                              {field?.error === true ? (
                                <Styled.InputErrorDiv>
                                  {`${field.label} required`}
                                </Styled.InputErrorDiv>
                              ) : (
                                ""
                              )}
                            </Styled.DateDiv>
                          ):field?.type === "dropdown" && (
                          <Box
                            sx={{
                              width: "calc((100% - 60px) / 3)",
                              minWidth: "350px",
                              marginRight:"30px"
                            }}
                          >
                            <Styled.InputLabel required={field?.required}>
                              {field.label}
                            </Styled.InputLabel>
                            <Autocomplete
                              multiple
                              // style={{marginRight:"30px",width: "calc((100% - 60px) / 3)"}}
                              options={stateList}
                              getOptionLabel={(option) => option}
                              value={benchCity}
                              autoComplete={false}

                              onChange={(e, newValue) =>{
                                if (newValue[newValue.length-1] === 'All'){
                                  setBenchCity(list)
                                }else{
                                  setBenchCity(newValue)
                                }
                              }}
                              renderTags={() => null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Select city"
                                />
                              )}
                              sx={{
                                "& .MuiInputBase-root": {
                                  height: "45px",
                                  padding: "0px 10px",
                                },
                              }}
                            />
                            <Box
                              mt={3}
                              sx={{
                                "& > :not(:last-child)": { marginRight: 1 },
                                "& > div": { marginBottom: 1 },
                              }}
                            >
                              {benchCity.map((v, index) => (
                                <Chip
                                  key={index}
                                  label={v}
                                  onDelete={() =>
                                    setBenchCity((prevState) =>
                                      prevState.filter((city) => city !== v)
                                    )
                                  }
                                />
                              ))}
                            </Box>
                          </Box>
                        )
                        ))
                    )}









                  </Styled.IdentifiersDiv>
                  <Styled.SearchBoxHeading>
                    For Ecourt services
                  </Styled.SearchBoxHeading>
                  <Styled.IdentifiersDiv>
                    {identifierFields?.map(
                      (field, index) =>
                        field.field_for === "e-court" &&
                        (field?.type === "input" ? (
                          <Styled.InputDiv
                            marginRight={"30px"}
                            marginBottom={"20px"}
                          >
                            <Styled.InputLabel required={field?.required}>
                              {field?.label}
                            </Styled.InputLabel>
                            <Styled.InputField
                              placeholder={field?.placeholder}
                              value={field?.value}
                              onChange={(e) =>
                                updateFieldValue(
                                  index,
                                  field.type,
                                  e.target.value,
                                  "identifierValues"
                                )
                              }
                            />
                            {field?.error === true ? (
                            <Styled.InputErrorDiv>
                              {`${field.label} required`}
                            </Styled.InputErrorDiv>
                          ) : (
                            ""
                          )}
                          </Styled.InputDiv>
                        ) : field?.type === "date" ? (
                          <Styled.DateDiv
                            marginRight={"30px"}
                            width={"calc((100% - 60px)/3)"}
                          >
                            <Styled.InputLabel required={field?.required}>
                              {field.label}
                            </Styled.InputLabel>
                            <DatePicker
                              isClearable
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="scroll"
                              yearDropdownItemNumber={50}
                              scrollableYearDropdown
                              customInput={
                                <DateInputField value={field?.value} />
                              }
                              selected={field?.value}
                              onChange={(date) =>
                                updateFieldValue(
                                  index,
                                  field.type,
                                  date,
                                  "identifierValues"
                                )
                              }
                            />
                            {field?.error === true ? (
                              <Styled.InputErrorDiv>
                                {`${field.label} required`}
                              </Styled.InputErrorDiv>
                            ) : (
                              ""
                            )}
                          </Styled.DateDiv>
                        ) : (
                          field?.type === "dropdown" && (
                            <Styled.InputDiv
                              marginRight={"30px"}
                              marginBottom={"20px"}
                            >
                              <Styled.InputLabel required={field?.required}>
                                {field?.label}
                              </Styled.InputLabel>
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={
                                  field.name === "state"
                                    ? state.map((option) => option)
                                    : field.name === "district"
                                    ? district.map((item) => item)
                                    : district_court_complex
                                }
                                onChange={(e, newValue) => {
                                  field.name === "state"
                                    ? states?.map((item) => {
                                        const x = item.state === newValue;
                                        if (x) {
                                          setDistrict(
                                            item.district.map(
                                              (item) => item.name
                                            )
                                          );
                                          setCurrentDistrict(item.district);
                                        }
                                      })
                                    : currentDistrict?.map(
                                        (item) =>
                                          item.name === newValue &&
                                          setComplex(item.complex)
                                      );

                                  updateFieldValue(
                                    index,
                                    field.type,
                                    newValue,
                                    "identifierValues"
                                  );
                                }}
                                sx={{
                                  "& .MuiInputBase-root": {
                                    height: "45px",
                                    padding: "0px 10px",
                                    borderRadius: "8px",
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        marginTop: "-5px",
                                        color: "#919EAB",
                                      },
                                    }}
                                    // label="Service"
                                    placeholder={field?.placeholder}
                                  />
                                )}
                              />
                              {field?.error === true ? (
                            <Styled.InputErrorDiv>
                              {`${field.label} required`}
                            </Styled.InputErrorDiv>
                          ) : (
                            ""
                          )}
                            </Styled.InputDiv>
                          )
                        ))
                    )}
                  </Styled.IdentifiersDiv>
                  <Styled.SearchBoxHeading>
                    For High Court services
                  </Styled.SearchBoxHeading>
                  <Styled.IdentifiersDiv>
                    {identifierFields?.map(
                      (field, index) =>
                        field.field_for === "high_court" &&
                        field?.type === "dropdown" && (
                          <Box
                            sx={{
                              width: "calc((100% - 60px) / 3)",
                              minWidth: "350px",
                            }}
                          >
                            <Styled.InputLabel required={field?.required}>
                              {field.label}
                            </Styled.InputLabel>
                            <Autocomplete
                              multiple
                              options={courts}
                              getOptionLabel={(option) => option}
                              value={highCourts}
                              onChange={(e, newValue) =>{
                                if (newValue[newValue.length-1] === 'All'){
                                  setHighCourts(list)
                                }else{
                                  setHighCourts(newValue)
                                }
                              }}
                              renderTags={() => null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Select high court"
                                />
                              )}
                              sx={{
                                "& .MuiInputBase-root": {
                                  height: "45px",
                                  padding: "0px 10px",
                                },
                              }}
                            />
                            <Box
                              mt={3}
                              sx={{
                                "& > :not(:last-child)": { marginRight: 1 },
                                "& > div": { marginBottom: 1 },
                              }}
                            >
                              {highCourts.map((v, index) => (
                                <Chip
                                  key={index}
                                  label={v}
                                  onDelete={() =>
                                    setHighCourts((prevState) =>
                                      prevState.filter((court) => court !== v)
                                    )
                                  }
                                />
                              ))}
                            </Box>
                          </Box>
                        )
                    )}
                  </Styled.IdentifiersDiv>
                  <Styled.NextButton onClick={handleNext}>
                    Next
                  </Styled.NextButton>
                  {apiErrorMessage && apiErrorMessage !== "NO ERROR" ? (
                    <p style={{ margin: "0px 0px 20px", color: "red" }}>
                      {apiErrorMessage}
                    </p>
                  ) : (
                    <></>
                  )}
                </Styled.SearchFormDiv>
              )
            )}
          </Styled.SearchPageDiv>
        )}
      </div>
    </Layout>
  );
};

export default Search;
