import { useState, forwardRef, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Autocomplete,
  TextField,
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DatePicker from "react-datepicker";
import moment from "moment";
import Layout from "../../components/Layout";
import StartSearchModal from "./../../components/Modal";
import * as Styled from "./searchHistoryStyled";
import * as axios from "../../utils/axios";
import "react-datepicker/dist/react-datepicker.css";

const SearchHistory = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [searchesData, setSearchesData] = useState(null);
  const [requestData, setRequestData] = useState(null);
  const [requestDatabases, setRequestDatabases] = useState(null);
  const [portalName, setPortalName] = useState(null);
  const [searchedByUsers, setSearchedByUsers] = useState([]);
  const userAccess = localStorage.getItem("userAccess");
  const [caseID, setCaseID] = useState(null);
  const [requestID, setRequestID] = useState(null);
  const [searchID, setSearchID] = useState(null);
  const [total, setTotal] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [serviceSearch, setServiceSearch] = useState("");
  const [searchedBy, setSearchedBy] = useState("");
  const [dateSearch, setDateSearch] = useState(null);
  const [targeNameSearch, setTargetNameSearch] = useState("");
  const [caseIdSearch, setcaseIdSearch] = useState("");
  const [reportParams, setReportParams] = useState(null);
  const [pdfData, setpdfData] = useState(null);
  const [reportHeaderData, setReportHeaderData] = useState({});
  const [buttonFlag, setButtonFlag] = useState(false);
  const [filtersUsed, setFiltersUSed] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [deleteFlag, setdeleteFlag] = useState(false);
  const [deleteFile, setdeleteFile] = useState("");

  const columns = [
    {
      id: "case_id",
      label: "Case ID",
      getValue: (i) => (i?.case_id ? i?.case_id : ""),
    },
    {
      id: "service_name",
      label: "Service",
      getValue: (i) => (i?.service_name ? i?.service_name : ""), /// todo change the name variable to servoceName or something more meaningful
    },
    {
      id: "target_name",
      label: "Target(s) Name",
      // format: (value) => value.toLocaleString("en-US"),
      getValue: (i) =>
        i?.target_names
          ? `${i?.target_names[0]}${
              i?.target_names?.length > 1
                ? ` + ${i?.target_names?.length - 1}`
                : ""
            }`
          : i?.target_name
          ? i?.target_name
          : "-",
    },
    {
      id: "searched_by",
      label: "Searched by",
      // format: (value) => value.toLocaleString("en-US"),
      getValue: (i) => (
        <div>
          <p style={{ margin: "0" }}>{i?.searched_by_name || "-"}</p>
          <p style={{ margin: "0", fontSize: "12px" }}>
            {i?.searched_by_email || "-"}
          </p>
        </div>
      ),
    },
    {
      id: "status",
      label: "Status",
      // format: (value) => value.toFixed(2),
      getValue: (i) => (
        <Styled.StatusSpan status={i?.status}>
          {i?.status ? i?.status : "-"}
        </Styled.StatusSpan>
      ),
    },
    {
      id: "date_time",
      label: "Date & Time",
      // format: (value) => value.toLocaleString("en-US"),
      getValue: (i) => (
        <div>
          <p style={{ margin: "0", fontSize: "12px" }}>
            Started on:{" "}
            {i?.started_on
              ? moment(i?.started_on).format("DD MMM, YYYY | h:mm A")
              : "-"}
          </p>
          <p style={{ margin: "0", fontSize: "12px" }}>
            Completed on:{" "}
            {i.status?.toLowerCase() === "in progress" ||
            i.status?.toLowerCase() === "inprogress" ||
            i.status?.toLowerCase() === "inqueue"
              ? "In progress"
              : moment(i?.completed_on).format("DD MMM, YYYY | h:mm A") || "-"}
          </p>
        </div>
      ),
    },
    {
      id: "actions",
      label: "Actions",
      // format: (value) => value.toLocaleString("en-US"),
      getValue: (i) => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          // onClick={() => handleViewResult(i)}
        >
          <img src="/eye-shape.svg" alt="@" height={"15px"} width={"15px"} />
          <span style={{ padding: "0px 0px 0px 7px" }}>
            {searchID === null ? "View all" : "Result"}
          </span>
        </div>
      ),
    },
  ];

  const categoryBoxes = [
    {
      imageSrc: "/all-icon.svg",
      categoryName: "ALL",
      category: "all",
    },
    {
      imageSrc: "/aml-icon.svg",
      categoryName: "AML",
      category: "aml",
    },
    {
      imageSrc: "/ikyc-icon.svg",
      categoryName: "I-KYC",
      category: "ikyc",
    },
    {
      imageSrc: "/osint-icon.svg",
      categoryName: "OSINT",
      category: "osint",
    },
    {
      imageSrc: "/india-other-icon.svg",
      categoryName: "India - Other",
      category: "india_other",
    },
    {
      imageSrc: "/international-other-icon.svg",
      categoryName: "International - Other",
      category: "international_other",
    },
  ];

  const fetchSearchedByUsersList = async () => {
    try {
      const response = await axios.get(`request/get-searchedby-users`);
      setSearchedByUsers(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSearchData = async () => {
    try {
      checkFiltersUsed();
      let url = `request/get-all-searches?pageNo=${page}&limit=${rowsPerPage}`;
      if (serviceSearch && serviceSearch !== "")
        url += `&service=${serviceSearch}`;
      if (searchedBy && searchedBy !== "") {
        url += `&searched_by='${searchedBy}'`;
      }
      if (dateSearch && dateSearch !== "")
        url += `&search_start_date='${dateSearch}'`;
      if (caseIdSearch && caseIdSearch !== "") url += `&caseID=${caseIdSearch}`;
      const response = await axios.get(url);
      // const response = await axios.get(``);
      setTotal(response?.data?.data?.requests?.[0]?.total_count);
      setSearchesData(response?.data?.data?.requests); // Set the fetched search data to the state variable
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSearchRequestsData = async () => {
    try {
      checkFiltersUsed();
      let url = `request/get-search-requests/${searchID}?pageNo=${page}&limit=${rowsPerPage}`;
      if (serviceSearch && serviceSearch !== "")
        url += `&service=${serviceSearch}`;
      if (searchedBy && searchedBy !== "") {
        url += `&searched_by=${searchedBy}`;
      }
      if (dateSearch && dateSearch !== "")
        url += `&search_start_date='${dateSearch}'`;
      if (targeNameSearch && targeNameSearch !== "")
        url += `&target_name=${targeNameSearch}`;
      const response = await axios.get(url);
      setTotal(response?.data?.data?.[0]?.total_count);
      setSearchesData(response?.data?.data); // Set the fetched search data to the state variable
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRequestData = async () => {
    try {
      // setSearchesData([]);
      // console.log('=================>fetchRequestData')
      let url = `request/get-specific-request/${requestID}`;
      const response = await axios.get(url);
      // console.log(response?.data?.data, "this is the resonse data");
      setRequestData(response?.data?.data); // Set the fetched search data to the state variable

      if (response?.data?.data?.[0]?.status === "COMPLETED") {
        setButtonFlag(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const DateInputField = forwardRef(({ value, onClick, onChange }, ref) => (
    <Styled.DateInputField
      placeholder="Select date"
      value={value}
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    />
  ));



  useEffect(() => {
    // console.log(searchID,'===>', requestID, window?.location?.href,  window?.location?.href?.includes('requestID'))
    if (searchedByUsers?.length === 0 && userAccess === '1') {
      fetchSearchedByUsersList();
    }
    if (
      searchID === null &&
      !window?.location?.href?.includes("searchID") &&
      !window?.location?.href?.includes("requestID")
    ) {
      fetchSearchData();
    } else if (
      searchID !== null &&
      requestID === null &&
      !window?.location?.href?.includes("requestID")
    ) {
      fetchSearchRequestsData();
    } else if (requestID !== null) {
      // setButtonFlag(true)
      fetchRequestData();
    }
  }, [
    page,
    rowsPerPage,
    dateSearch,
    searchedBy,
    serviceSearch,
    searchID,
    requestID,
  ]);

  useEffect(() => {
    setSearchID(searchParams.get("searchID"));
    setRequestID(searchParams.get("requestID"));
    setButtonFlag(false);
  }, [window.location.href]);

  const handleViewResult = (row) => {
    setPage(0);
    setRowsPerPage(10);
    if (searchID === null && row?.case_id !== null) {
      setCaseID(row?.case_id);
      setSearchID(row?.search_id);
      navigate(`/history?searchID=${row?.search_id}`);
    } else if (requestID === null && row?.case_id) {
      setRequestID(row?.id);
      // setButtonFlag(true)
      navigate(`/history?searchID=${searchID}&requestID=${row?.id}`);
    }

    const paramsReport = {
      CaseId: row.case_id,
      serviceName: row.service_name,
      targetName: row?.target_names?.[0],
      startTime: requestData?.[0]?.started_on,
      searchCompletedOn: requestData?.[0]?.completed_on,
      errorPortalList: ["err1", "err2"],
      availablePortalList: ["ava1", "ava2", "ava3"],
    };

    if (row.target_names) {
      // console.log("these are the report params ",paramsReport,row,"only to be done once")
      setReportParams(paramsReport);
      setReportHeaderData(paramsReport);
      // console.log("now only html 2 pdf fiunction left")
    }
  };

  const checkFiltersUsed = () => {
    if (
      (searchedBy && searchedBy !== "") ||
      (serviceSearch && serviceSearch !== "") ||
      (dateSearch && dateSearch !== "") ||
      (targeNameSearch && targeNameSearch !== "") ||
      (caseIdSearch && caseIdSearch !== "")
    )
      setFiltersUSed(true);
  };

  const capitalize = (val) => {
    if (val && val !== "") {
      return val.charAt(0)?.toUpperCase() + val.slice(1)?.toLowerCase();
    } else {
      return "";
    }
  };

  const handleDatabaseClick = async (portal_id, portal_name) => {
    setPortalName(portal_name);
    try {
      let url = `request/get-database-status/${requestID}/${portal_id}`;
      const response = await axios.get(url);
      // console.log(response);
      setRequestDatabases(response?.data?.data); // Set the fetched search data to the state variable
    } catch (error) {
      console.error(error);
    }
    setShowModal(true);
  };

  const [showModal, setShowModal] = useState(false);

  const fetchReportData = async () => {
    try {
      const response = await axios.post(
        `report/download-reports/${requestID}`,
        reportHeaderData
      );
      if (response.data) {
        let errorArray = []
        let liveArray = []
        for(let p of requestData){
          if(p.portal_status == "ERROR"  ){
              errorArray.push(p.portal_name)
          }
          if(p.portal_status == "COMPLETED" && (p.databases_error_count == p.total_databases_count )){
            errorArray.push(p.portal_name)
          }
          if(p.portal_status == "COMPLETED" && p.databases_error_count == 0){
              liveArray.push(p.portal_name)
          }
        }
        response.data.errorPortalList = errorArray
        response.data.availablePortals = liveArray
        response.data.searchCompletedOn = requestData?.[0]?.end_time
        response.data.startTime = requestData?.[0]?.start_time

        setpdfData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (pdfData) {
      downloadingDocxReport(pdfData);
    }
  }, [pdfData]);

  useEffect(() => {
    const handleBackButton = () => {
      setButtonFlag(false);
    };

    // Listen for the 'popstate' event
    window.addEventListener("popstate", handleBackButton);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

 
  const downloadingDocxReport = async (pdfData) => {
    try {
      const response = await axios.post(
        `report/final-report-download/${requestID}/${searchID}`,
        pdfData,
      );
  let filepath = response.data.filepath
      const accessToken = localStorage.getItem('token');
      const customHeaders = {
        'Authorization':  `Bearer ${accessToken}`, // Replace with your custom headers
      };
      let url 
      url = `https://temptest1.fios.asia/api/v1/report/report-export/${filepath}?targetname=${pdfData.targetName}&caseid=${pdfData.caseId}`;
      // url = `http://localhost:3002/api/v1/report/report-export/${filepath}?targetname=${pdfData.targetName}&caseid=${pdfData.caseId}`;

      fetch(url, {
        headers: customHeaders,
      })
        .then(response => response.blob())
        .then(blob => {
          const objectURL = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = objectURL;
          a.download = `${pdfData.caseId}-${pdfData.targetName}.doc`; // Set the desired download filename
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(objectURL);
        })
        .catch(error => {
          console.error('Error:', error);
        });
        const sampleresponse = await axios.get(
        `/report/report-export/${filepath}?targetname=${pdfData.targetName}&caseid=${pdfData.caseId}` ,
        "yo.doc"
      ); 
      setdeleteFile(filepath)
      setdeleteFlag(true)
  
    } catch (error) {
      console.error(error);
    }
  
  };
  
  const deleteRep=async()=>{
    if (deleteFlag) {
      const deleteReport  =  async (deleteFile) =>{
          if(deleteFlag){
            // const deleteReport = async (deleteFile) => {
              try {
                await axios.get(`report/report-delete/${deleteFile}`);
                // Process the response if needed
              } catch (error) {
                console.error('Error deleting report:', error);
              }
            // };
          }
        }
      deleteReport(deleteFile);
      setdeleteFlag(false)
    }
  }
  
  useEffect( () => {
deleteRep()
  }, [deleteFlag]);
  

  const handleDownloadClick = async (item) => {
    try {
      const response = await axios.get(
        `s3/get-s3-url?filename=${item}`,
        reportHeaderData
      );
      if(response.status === 200)
        window.open(response?.data?.data?.presignedGETURL, "_blank");
      // else
    } catch (error) {
      setOpenNotification(true);
    }
  }

  return (
    <Layout>
      <Styled.MainDiv>
        {searchID !== null && (
          <Styled.BackLinkDiv
            onClick={() => {
              setCaseID(null);
              setSearchID(null);
              navigate("/history");
            }}
          >
            <img src="/back-icon.svg" alt="<-" /> Back to search history
          </Styled.BackLinkDiv>
        )}
        <Styled.PageHeading>
          {searchID === null
            ? "Search History"
            : `Case ID: ${
                caseID ||
                searchesData?.[0]?.case_id ||
                requestData?.[0]?.case_id ||
                "-"
              }`}
        </Styled.PageHeading>
        <Styled.HeadingText>
          Check search history and their results here.
          {buttonFlag && (
            <buttonContainer>
              <Styled.DownloadButton onClick={fetchReportData}>
                Download Report
              </Styled.DownloadButton>
            </buttonContainer>
          )}
        </Styled.HeadingText>
        {searchesData && searchesData?.length === 0 && filtersUsed === false ? (
          <Styled.NoSearchDiv>
            <img src="/search-icon.svg" alt="No Search" />
            <p>No one has conducted a search thus far.</p>
            <p>Begin searching for the targets within the specified service.</p>
            <Styled.StartSearchButton onClick={() => navigate("/search")}>
              Start Search
            </Styled.StartSearchButton>
          </Styled.NoSearchDiv>
        ) : requestID === null ? (
          <Styled.SearchDataDiv>
            <Styled.FiltersDiv>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={categoryBoxes?.map((option) => option.categoryName)}
                onChange={(e, newValue) => setServiceSearch(newValue)}
                sx={{
                  width: userAccess === "1" ? "20%" : "30%",
                  marginRight: "20px",
                  marginBottom: "20px",
                  '& .MuiInputBase-root' : {
                    height: '45px',
                    padding : '0px 10px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    InputLabelProps={{
                      style : {
                        marginTop:'-5px',
                        color: '#919EAB',
                        borderRadius: '8px',
                      }
                    }}
                    label="Service"
                  />
                )}
              />
              {userAccess === "1" ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={searchedByUsers?.map(
                    (option) => `${option.fullname} - ${option.email}`
                  )}
                  onChange={(e, newValue) =>
                    setSearchedBy(newValue?.split(" - ")?.[1])
                  }
                  sx={{
                    width: userAccess === "1" ? "20%" : "30%",
                    marginRight: "20px",
                    marginBottom: "20px",
                    '& .MuiInputBase-root' : {
                      height: '45px',
                      padding : '0px 10px',
                      borderRadius: '8px',
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      InputLabelProps={{
                        style : {
                          marginTop:'-5px',
                          color: '#919EAB'
                        }
                      }}
                      label="Searched by"
                    />
                  )}
                />
              ) : (
                ""
              )}

              <Styled.DateDiv
                marginRight={"20px"}
                marginBottom={"20px"}
                width={userAccess === "1" ? "20%" : "30%"}
              >
                <DatePicker
                  isClearable
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="scroll"
                  yearDropdownItemNumber={50}
                  scrollableYearDropdown
                  customInput={<DateInputField value={dateSearch} />}
                  selected={dateSearch}
                  onChange={(date) => setDateSearch(date)}
                />
              </Styled.DateDiv>

              <Styled.InputDiv
                width={"calc(40% - 70px)"}
                marginBottom={"20px"}
                style={{ alignSelf: "flex-end" }}
              >
                <Styled.InputField
                  placeholder={`Search with ${
                    searchID === null ? "case ID" : "target name"
                  }`}
                  value={searchID === null ? caseIdSearch : targeNameSearch}
                  onChange={(e) =>
                    searchID === null
                      ? setcaseIdSearch(e.target.value)
                      : setTargetNameSearch(e.target.value)
                  }
                  onKeyDown={(e) =>
                    e.key === "Enter" &&
                    (searchID === null
                      ? fetchSearchData()
                      : fetchSearchRequestsData())
                  }
                />
              </Styled.InputDiv>
            </Styled.FiltersDiv>
            <Paper
              sx={{
                border: "1px solid #919EAB3D",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    "& .MuiTableRow-hover:hover": {
                      backgroundColor: "#ECFDFF !important",
                      cursor: "pointer",
                    },
                    "& .MuiTableRow-hover:hover > td > div": {
                      color: "#181917",
                      fontWeight: "600",
                    },
                    "& .MuiTableRow-hover:hover > td:first-child": {
                      borderLeft: "3px solid #048FA5 !important",
                    },
                  }}
                >
                  <TableHead
                    sx={{
                      "& th": { backgroundColor: "#F4F6F8", cursor: "default" },
                    }}
                  >
                    <TableRow>
                      {columns?.map((column) =>
                        userAccess === "1" ||
                        (userAccess === "0" && column.id !== "searched_by") ? (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#637381",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ) : (
                          <></>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchesData && searchesData?.length > 0 ? (
                      searchesData?.map((row) => {
                        return (
                          <TableRow hover tabIndex={-1} key={row.search_id} onClick={()=> handleViewResult(row)}>
                            {columns?.map((column) => {
                              return userAccess === "1" ||
                                (userAccess === "0" &&
                                  column.id !== "searched_by") ? (
                                <TableCell key={column.id} align={column.align}>
                                  <Styled.TableCellStyled>
                                    {column.getValue &&
                                    column.getValue(row) !== ""
                                      ? column.getValue(row)
                                      : "-"}
                                  </Styled.TableCellStyled>
                                </TableCell>
                              ) : (
                                <></>
                              );
                            })}
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={userAccess === "1" ? 7 : 6}>
                          <Styled.NoDataDiv>
                            <img src="/no-search-found.svg" alt="" />
                            <p>{`No result found${
                              caseIdSearch === null || caseIdSearch === ""
                                ? ""
                                : " for the searched case ID"
                            }.`}</p>
                          </Styled.NoDataDiv>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {searchesData &&
              searchesData?.length > 0 &&
              total > rowsPerPage ? (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={parseInt(total)}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(e) => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                    setPage(0);
                  }}
                />
              ) : (
                ""
              )}
            </Paper>
          </Styled.SearchDataDiv>
        ) : (
          <Styled.RequestDataDiv>
            <Styled.TargetNamesCard>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  Target(s) name:{" "}
                  <Styled.NamesList>
                    {requestData?.[0]?.target_name
                      ? capitalize(requestData?.[0]?.target_name)
                      : "-"}
                  </Styled.NamesList>
                </div>
                <Styled.TimeDetailsDiv>
                  <div>
                    <p style={{ margin: "0", fontSize: "12px" }}>
                      Started on:{" "}
                      {requestData?.[0]?.started_on
                        ? moment(requestData?.[0]?.started_on).format(
                            "DD MMM, YYYY | h:mm A"
                          )
                        : "-"}
                    </p>
                    <p style={{ margin: "0", fontSize: "12px" }}>
                      Completed on:&nbsp;&nbsp;
                      {requestData?.[0]?.status === "IN PROGRESS" ||
                      requestData?.[0]?.status === "INPROGRESS" ||
                      requestData?.[0]?.status === "INQUEUE" ? (
                        <Styled.StatusSpan status={requestData?.[0]?.status}>
                          {capitalize(requestData?.[0]?.status)}
                        </Styled.StatusSpan>
                      ) : requestData?.[0]?.completed_on ? (
                        moment(requestData?.[0]?.completed_on).format(
                          "DD MMM, YYYY | h:mm A"
                        )
                      ) : (
                        "-"
                      )}
                    </p>
                  </div>
                </Styled.TimeDetailsDiv>
              </div>
            </Styled.TargetNamesCard>
            {requestData &&
              requestData?.map((request) => (
                <Styled.DatabaseListingDiv>
                  <div>
                  <Styled.PortalHeading>
                    <div>
                    <div style={{ display: "flex",  }}>
                      {request?.portal_name || "-"}
                      <p style={{ margin: "0px 10px" }}>
                        {request?.databases_error_count !== undefined &&
                        request?.total_databases_count !== undefined &&
                        request?.databases_error_count !== 0 &&
                        request?.total_databases_count ===
                          request?.databases_error_count ? (
                          <span style={{ color: "#F04639", cursor: "default" }}>
                            (Error in Portal)
                          </span>
                        ) : (
                          `(${
                            request?.findings?.filter(
                              (item, index) =>
                                item !== null &&
                                request?.data_status?.[index] === "FOUND"
                            )?.length || "0"
                          } Findings)`
                        )}
                      </p>
                      <Styled.StatusSpan
                        style={{ height: "20px" }}
                        status={
                          request?.portal_status === null
                            ? request?.status
                            : request?.portal_status
                        }
                      >
                        {capitalize(
                          request?.portal_status === null
                            ? request?.status
                            : request?.portal_status
                        )}
                      </Styled.StatusSpan>
                    </div>
                    <p>
                      <span style={{fontSize: '10px', color: '#000000', lineHeight: '18px', minWidth: '200px'}}>
                        Start time : {request?.start_time ? moment(request?.start_time).format("DD MMM, YYYY | h:mm A") :  '-'}
                      </span>
                      <span style={{fontSize: '10px', color: '#000000', lineHeight: '18px',  minWidth: '200px'}}>
                        End time : {request?.end_time ? 
                        request?.portal_status?.toLowerCase() === 'inprogress' ? '-' 
                          : moment(request?.end_time).format("DD MMM, YYYY | h:mm A") 
                        :  '-'}
                      </span>
                    </p>
                    </div>
                    <div>
                      <span
                        onClick={() =>
                          handleDatabaseClick(
                            request.portal_id,
                            request?.portal_name
                          )
                        }
                      >
                        <span>
                          Click here to see database
                          <span
                            style={{
                              color:
                                request?.databases_error_count ===
                                  request?.total_databases_count &&
                                request?.databases_error_count !== 0
                                  ? "#F04639"
                                  : request?.databases_error_count === 0
                                  ? "#00C863"
                                  : "#FFA217",
                            }}
                          >
                            {request?.databases_error_count !== undefined &&
                              ` (${request?.databases_error_count}  ${
                                request?.databases_error_count > 1
                                  ? "Errors"
                                  : "Error"
                              })`}
                          </span>
                        </span>
                      </span>
                    </div>
                  </Styled.PortalHeading>
                  </div>
                  <Styled.ListDiv>
                    {request?.findings?.filter((item) => item !== null)
                      ?.length > 0 && request?.data_status?.filter(status => status === 'FOUND')?.length > 0 ? (
                      <>
                        <Styled.ListHeading>
                          <p>Found at</p>
                          <p style={{ width: "100px" }}>Findings</p>
                        </Styled.ListHeading>
                        {request?.findings?.map(
                          (item, index) =>
                            item !== null &&
                            request?.data_status?.[index] === "FOUND" && (
                              <div key={index}>
                                <Styled.ListEntry>
                                  <a
                                    href={request?.database_urls?.[index]}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {request?.database_urls?.[index] || "-"}
                                  </a>
                                  <p onClick={() => handleDownloadClick(item)}>
                                    <img
                                      src="/download.svg"
                                      alt=""
                                      style={{
                                        marginRight: "10px",
                                        cursor: "pointer",
                                      }}
                                    />
                                    Download
                                  </p>
                                </Styled.ListEntry>
                              </div>
                            )
                        )}
                      </>
                    ) : (
                      <Styled.NoFindingsDiv>
                        <img src="/no-search-found.svg" alt="" />
                        <p>
                          {request?.portal_status?.toLowerCase() === "completed"
                            ? "The searched target name(s) were not found in the database."
                            : request?.portal_status?.toLowerCase() === "error"
                            ? "Error occurred while searching"
                            : "Target name search is 'In Progress'"}
                        </p>
                      </Styled.NoFindingsDiv>
                    )}
                  </Styled.ListDiv>
                </Styled.DatabaseListingDiv>
              ))}
          </Styled.RequestDataDiv>
        )}
      </Styled.MainDiv>
      <StartSearchModal
        modalOpen={showModal && requestDatabases?.length > 0}
        width={"70%"}
        height={"60%"}
        padding="0px"
        overflow="hidden"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Styled.PortalNameDiv>
            <p style={{ margin: "0px" }}>{portalName || "-"}</p>
            <p
              onClick={() => setShowModal((prev) => !prev)}
              style={{ color: "#637381" }}
            >
              X
            </p>
          </Styled.PortalNameDiv>

          <Styled.PortalsDetailsDiv>
            {requestDatabases?.filter(
              (database) =>
                database.status === "ERROR" ||
                (requestData?.[0]?.status?.toLowerCase() === "completed" &&
                  (database?.status?.toLowerCase() === "inprogress" ||
                    database?.status?.toLowerCase() === "in progress"))
            )?.length > 0 && (
              <Styled.PortalsListDiv
                type="error"
                noOfValues={
                  requestDatabases?.filter(
                    (database) =>
                      database.status === "ERROR" ||
                      (requestData?.[0]?.status?.toLowerCase() ===
                        "completed" &&
                        (database?.status?.toLowerCase() === "inprogress" ||
                          database?.status?.toLowerCase() === "in progress"))
                  )?.length
                }
              >
                <Styled.PortalListHeading type="error">
                  Error in databases
                </Styled.PortalListHeading>
                <Styled.ListText>
                  These are the databases which have not being searched due to
                  some error/issues. Please ask team to search target(s)
                  manually on these databases.
                </Styled.ListText>
                <Styled.DatabaseDiv>
                  {requestDatabases?.map(
                    (database, index) =>
                      (database.status === "ERROR" ||
                        (requestData?.[0]?.status?.toLowerCase() ===
                          "completed" &&
                          (database?.status?.toLowerCase() === "inprogress" ||
                            database?.status?.toLowerCase() ===
                              "in progress"))) && <p>{database?.name}</p>
                  )}
                </Styled.DatabaseDiv>
              </Styled.PortalsListDiv>
            )}
            {requestDatabases?.filter(
              (database) =>
                database.status !== "ERROR" &&
                (database?.status === "IN PROGRESS" ||
                database?.status === "INPROGRESS"
                  ? requestData?.[0]?.status?.toLowerCase() !== "completed"
                  : true)
            )?.length > 0 && (
              <Styled.PortalsListDiv
                noOfValues={
                  requestDatabases?.filter(
                    (database) =>
                      database.status !== "ERROR" &&
                      (database?.status === "IN PROGRESS" ||
                      database?.status === "INPROGRESS"
                        ? requestData?.[0]?.status?.toLowerCase() !==
                          "completed"
                        : true)
                  )?.length
                }
              >
                <Styled.PortalListHeading>
                  Live Databses
                </Styled.PortalListHeading>
                <Styled.ListText>
                  These are the databases on which the search has been completed
                  successfully.
                </Styled.ListText>
                <Styled.DatabaseDiv>
                  <Styled.DatabaseDiv>
                    {requestDatabases?.map(
                      (database, index) =>
                        database?.status !== "ERROR" &&
                        (database?.status === "IN PROGRESS"
                          ? requestData?.[0]?.status !== "COMPLETED"
                          : true) && <p>{database?.name}</p>
                    )}
                  </Styled.DatabaseDiv>
                </Styled.DatabaseDiv>
              </Styled.PortalsListDiv>
            )}
          </Styled.PortalsDetailsDiv>
        </div>
      </StartSearchModal>
      <div style={{ display: "none" }}>
        <div id="pdf-content" style={{ margin: "20px" }}>
          <h2 style={{ fontSize: "11pt" }}>
            <span style={{ fontWeight: "bold" }}>Case ID:</span>{" "}
            <span style={{ fontWeight: "normal" }}>{pdfData?.caseId}</span>
          </h2>

          <h2 style={{ fontSize: "11pt" }}>
            <span style={{ fontWeight: "bold" }}>Service Name: </span>{" "}
            <span style={{ fontWeight: "normal" }}>
              {pdfData?.serviceName}{" "}
            </span>
          </h2>

          <h2 style={{ fontSize: "11pt" }}>
            <span style={{ fontWeight: "bold" }}>Target Name : </span>{" "}
            <span style={{ fontWeight: "normal" }}>{pdfData?.targetName} </span>
          </h2>

          <div style={{ fontSize: "11pt" }}>
            <p style={{ color: "red", fontWeight: "bold", marginTop: "0px" }}>
              <span>Error Portal List: </span>
              <span>
                {pdfData?.errorPortalList?.length
                  ? pdfData?.errorPortalList?.join(", ")
                  : "none"}
              </span>
            </p>
          </div>

          <div style={{ fontSize: "11pt" }}>
            <p style={{ fontWeight: "normal", marginTop: 0 }}>
              <span style={{ fontWeight: "bold" }}>Available Portals: </span>
              <span style={{ lineHeight: "26px" }}>
                {pdfData?.availablePortals?.join(", ")}
              </span>
            </p>
          </div>

          <h3 style={{ fontSize: "11pt" }}>Start Time: {pdfData?.startTime}</h3>
          <h3 style={{ fontSize: "11pt" }}>
            Search Completed On: {pdfData?.searchCompletedOn}
          </h3>

          <div
            style={{
              fontWeight: "bold",
              textDecoration: "underline",
              color: "black",
              fontSize: "11pt",
              marginBottom: "10px",
              marginTop: "30px",
            }}
          >
            Findings
          </div>

          {pdfData?.portalsMap?.length > 0 ? (
            <div
              style={{
                textDecoration: "underline",
                color: "blue",
                fontSize: "11pt",
              }}
            >
              Note: The findings are downloadable on the Automation platform
              only. Click on the download link, it will redirect you to the
              Automation platform from where you can download the files.
            </div>
          ) : (
            <div
              style={{
                fontSize: "11pt",
                marginBottom: "20px",
              }}
            >
              There are no findings in the searched portals.
            </div>
          )}

          {pdfData?.portalsMap?.map((portal, portalIndex) =>
            portal?.entries?.filter((entry) => entry.status === "FOUND")
              ?.length ? (
              <div style={{ display: "table", width: "100%" }}>
                <div key={portalIndex} style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      pageBreakInside: "avoid",
                    }}
                  >
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        fontSize: "11pt",
                        tableLayout: "fixed",
                        border: "1px solid #9CC4D8",
                        margin: "20px 0px",
                      }}
                    >
                      <colgroup>
                        <col style={{ width: "25%" }} />{" "}
                        {/* Database column width set to 25% */}
                        <col style={{ width: "25%" }} />{" "}
                        {/* Other columns set to 25% */}
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "25%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "5px",
                              fontSize: "11px",
                            }}
                          >
                            Portal
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "5px",
                              fontSize: "11px",
                            }}
                          >
                            Database
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "5px",
                              fontSize: "11px",
                            }}
                          >
                            Found At
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "5px",
                              fontSize: "11px",
                            }}
                          >
                            Findings
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {portal?.entries
                          ?.filter((entry) => entry.status === "FOUND")
                          ?.map((entry, entryIndex) => (
                            <tr key={entryIndex}>
                              {entryIndex === 0 ? (
                                <td
                                  style={{
                                    border: "1px solid black",
                                    padding: "5px",
                                    verticalAlign: "top",
                                    wordWrap: "break-word",
                                    whiteSpace: "pre-wrap",
                                    fontSize: "11px",
                                  }}
                                  rowSpan={
                                    portal?.entries?.filter(
                                      (entry) => entry.status === "FOUND"
                                    )?.length
                                  }
                                >
                                  {portal.portalName}
                                </td>
                              ) : null}
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "5px",
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                  fontSize: "11px",
                                }}
                              >
                                {entry.Database}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "5px",
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                  fontSize: "11px",
                                }}
                              >
                                {/* Make the Findings value clickable */}
                                <a
                                  href={entry.FindingsLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {entry.FoundAt}
                                </a>
                                <br />
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "5px",
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                  textDecoration: "underline",
                                  fontSize: "11px",
                                }}
                              >
                                {/* Make the "Found At" field clickable */}
                                <a
                                  href={
                                    window?.location?.href ||
                                    `https://test.coffeee.io`
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Download Link
                                </a>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )
          )}

          <div
            style={{
              fontWeight: "bold",
              textDecoration: "underline",
              color: "black",
              fontSize: "11pt",
              marginBottom: "10px",
              marginTop: "30px",
              display: "table-cell",
              pageBreakInside: "avoid",
            }}
          >
            No Findings
          </div>

          {pdfData?.noFindingsPortalsMap?.length && (
            <div style={{ display: "table", width: "100%" }}>
              <div style={{ display: "table-row" }}>
                <div
                  style={{
                    display: "table-cell",
                    pageBreakInside: "avoid",
                  }}
                >
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      fontSize: "11pt",
                      tableLayout: "fixed",
                      margin: "20px 0px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            width: "30%",
                            border: "1px solid black",
                            padding: "5px",
                            fontSize: "11px",
                          }}
                        >
                          Portal
                        </th>
                        <th
                          style={{
                            width: "70%",
                            border: "1px solid black",
                            padding: "5px",
                            fontSize: "11px",
                          }}
                        >
                          Database
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pdfData?.noFindingsPortalsMap?.map((portal) =>
                        portal?.entries?.map((entry, entryIndex) => (
                          <tr key={entryIndex}>
                            {entryIndex === 0 ? (
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "5px",
                                  verticalAlign: "top",
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                  fontSize: "11px",
                                }}
                                rowSpan={portal?.entries?.length}
                              >
                                {portal.portalName}
                              </td>
                            ) : null}
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "5px",
                                wordWrap: "break-word",
                                whiteSpace: "pre-wrap",
                                fontSize: "11px",
                                borderBottom: `${
                                  entryIndex !== portal?.entries?.length - 1
                                    ? "0px"
                                    : "1px solid black"
                                }`,
                              }}
                            >
                              {entry?.Database || "Database Name"}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openNotification}
        autoHideDuration={3000}
        onClose={()=> setOpenNotification(false)}
      >
        <Alert severity="error">Error in downloading file, Please contact DEV team.</Alert>
      </Snackbar>
    </Layout>
  );
};

export default SearchHistory;
