import * as axios from '../../utils/axios';

export const submitRequest = (data) => {
  return async () => {
    try {
      console.log("Trying the POST request...",data);
      const response = await axios.post('request/post-request-data', data );
      console.log('Response:', response.data);
      
      if (response.status === 200) {
        console.log("Response is OK");
        // const responseData = response.data;  todo
        // Dispatch any actions to update the store based on the response data
        // dispatch({ type: 'REQUEST_SUCCESS', payload: responseData });
      } else {
        console.log("Response Error:", response.status);
        // Handle the error response
        // const errorData = response.data; todo
        // Dispatch any actions to handle the error or show error messages
        // dispatch({ type: 'REQUEST_ERROR', payload: errorData });
      }
    } catch (error) {
      console.log("Request Error:", error.message);
      // Handle any errors that occur during the API request
      // Dispatch any actions to handle the error or show error messages
      // dispatch({ type: 'REQUEST_ERROR', payload: error.message });
    }
  };
};


  // Define action creator for ACTION_TYPE_2
  export const userAction2 = () => {
    return {
      type: 'ACTION_TYPE_2',
    };
  };
  
  // Add more action creators as needed
  