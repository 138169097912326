import styled  from 'styled-components';

export const MainDiv = styled.div`
padding: 30px 40px;
box-sizing: border-box;
min-height: calc(100vh - 80px);
background-color: #F8F8F8;
`;

export const PageHeading = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 28px;
font-weight: 600;
line-height: 48px;
letter-spacing: 0px;
`;

export const HeadingText = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 28px;
letter-spacing: 0px;
color: #181917;
margin-bottom: 20px;
`;

export const TableCellStyled = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0px;
color: #181917;
`;

export const DataDiv = styled.div`
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
`;