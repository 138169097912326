// Define initial state
const initialState = {
    // initial state for reducer1
  };
  
  // Define reducer function for reducer1
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      // Handle different action types and update state accordingly
      case 'ACTION_TYPE_1':
        // Update state for ACTION_TYPE_1
        return {
          ...state,
          // Updated state properties
        };
      case 'ACTION_TYPE_2':
        // Update state for ACTION_TYPE_2
        return {
          ...state,
          // Updated state properties
        };
      // Add more cases for other action types as needed
      default:
        return state;
    }
  };
  
  export default userReducer;
  