import * as Styled from "./headerStyled";

const Header = () => {

  return (
    <Styled.MainDiv>
      <img src = "/fios-small-logo.svg" alt = "fios" />
      <img src = "/profile-icon.svg" alt = "user" />
    </Styled.MainDiv>
  );
};

export default Header;
