import Header from "../Header/index";
import Menu from "../Menu/index";
import * as Styled from "./layoutStyled";

const Layout = (props) => {
  const { children, classname, hideSideMenu = false } = props;

  return (
    <Styled.MainLayout>
      <Header />
      <Menu />
      <Styled.LayoutInner>
        {children}
      </Styled.LayoutInner>
    </Styled.MainLayout>
  );
};

export default Layout;
