import styled from "styled-components";


export const MainDiv = styled.div`
position: fixed;
top: 0px;
right: 0px;
bottom: 0px;
left: 0px;
z-index: 5;
display: ${props => props.modalOpen === true ? 'flex' : 'none'};
align-items: center;
justify-content: center;
`;


export const ModalContentDiv = styled.div`
background-color: #4C545D;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
gap: 0.4rem;
width: ${props => props.width ? props.width : '30%'};
height: ${props => props.height ? props.height : '40%'};
padding: ${props => props.padding ? props.padding : '1.3rem'};
min-height: ${props => props.minHeight ? props.minHeight : '250px'};
min-width: ${props => props.minWidth ? props.minWidth : '250px'};
overflow: ${props => props.overflow ? props.overflow : 'unset'};
position: absolute;
background-color: white;
border: 1px solid #ddd;
border-radius: 15px;
z-index: 3;

& img {
    height: 65px;
    width: 65px;
    margin-bottom: 24px;

    @media (max-width: 800px) {
        margin-bottom: 10px;
    }

    @media (max-width: 600px) {
        margin-bottom: 5px;
    }
}

& p{
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    color: #181917;
    margin: 0;
    margin-bottom: 20px;

    @media (max-width: 800px) {
        margin-bottom: 10px;
    }

    @media (max-width: 600px) {
        margin-bottom: 5px;
    }
}

// & p:nth-child(3){
//     font-size: 16px;
//     font-weight: 400;
//     line-height: 24px;
// }
`;


export const OverLayDiv = styled.div`
position: fixed;
top: 0;
bottom: 0;
left: 0;
right: 0;
width: 100vw;
height: 100vh;
background: #212B36CC;
z-index: 3;
`;

