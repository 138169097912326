import axios from "axios";

// console.log(process.env)
const API_PATH = process.env.REACT_APP_API_PATH || 'http://localhost:3002/api/v1'; 

export const get = async (endPoint) => {
    const accessToken = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}` 

    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => { // Anything except 2XX goes to here
        const status = error.response?.status || 500;
        if (status === 401) {
             window.location = "/"
        } else {
            return Promise.reject(error); // Delegate error to calling side
        }
    });
    return await axios.get(`${API_PATH}${endPoint}`);
}

export const post = async (endPoint, payload) => {
    const accessToken = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}` 
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => { // Anything except 2XX goes to here
        const status = error.response?.status || 500;
        if (status === 401 || status === 403) {
             window.location = "/"
        } else {
            return Promise.reject(error); // Delegate error to calling side
        }
    });
    return await axios.post(`${API_PATH}${endPoint}`, payload);
}

export const deleteRequest = async (endPoint, payload) => {
    return await axios.delete(`${API_PATH}${endPoint}`, payload);
}
