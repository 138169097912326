export const sideMenuList = [
  {
    name: "Home",
    activeImageSrc: "/home-icon-active.svg",
    inActiveImageSrc: "/home-icon-inactive.svg",
		url: "/home",
    accessLevel: 0,
  },
  {
    name: "Search",
    activeImageSrc: "/search-icon-active.svg",
    inActiveImageSrc: "/search-icon-inactive.svg",
		url: "/search",
    accessLevel: 0,
  },
	{
    name: "History",
    activeImageSrc: "/history-icon-active.svg",
    inActiveImageSrc: "/history-icon-inactive.svg",
		url: "/history",
    accessLevel: 0,
  },
	{
    name: "Users",
    activeImageSrc: "/users-icon-active.svg",
    inActiveImageSrc: "/users-icon-inactive.svg",
		url: "/users",
    accessLevel: 1,
  },
	{
    name: "Services",
    activeImageSrc: "/services-icon-active.svg",
    inActiveImageSrc: "/services-icon-inactive.svg",
		url: "/services",
    accessLevel: 1,
  },
  {
    name: "Categories",
    activeImageSrc: "/category-icon-active.svg",
    inActiveImageSrc: "/category-icon-inactive.svg",
		url: "/categories",
    accessLevel: 1,
  },
];

export const portalsList = [
	"Interpol", "Interpol", "Interpol",
	"Federal Bureau of Investigation (FBI), US Government", "Federal Bureau of Investigation (FBI), US Government","Federal Bureau of Investigation (FBI), US Government",
	"US-Bureau of International Security & Non-proliferation","US-Bureau of International Security & Non-proliferation","US-Bureau of International Security & Non-proliferation",
	"Asian Infrastructure Investment Bank","Asian Infrastructure Investment Bank","Asian Infrastructure Investment Bank",
	"CSL","CSL","CSL"
]


