import styled  from 'styled-components';

export const MainDiv = styled.div`
width: 100vw;
height: 80px;
display: flex;
justify-content: space-between;
align-items: center;

padding: 0px 20px 0px 30px; 
box-sizing: border-box;
background-color: #FFFFFF;
border-bottom: 1px solid #F8F8F8;

position: fixed;
top: 0px;
z-index: 5;

& img {
    cursor: pointer;
}
`;