
import styled  from 'styled-components';


export const MainDiv = styled.div`
padding: 30px 40px;
box-sizing: border-box;
min-height: calc(100vh - 80px);
background-color: #F8F8F8;
`;

export const PageHeading = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 28px;
font-weight: 600;
line-height: 48px;
letter-spacing: 0px;
display: flex;
justify-content: space-between;
`;

export const BackLinkDiv = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #048FA5;
  // opacity: 20%;

  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  & img {
    padding-right: 8px;
  }
`;

export const HeadingText = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 28px;
letter-spacing: 0px;
color: #181917;
margin-bottom: 20px;
`;

export const TableCellStyled = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0px;
color: #181917;
`;

export const DataDiv = styled.div`
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
`;

export const InputDiv = styled.div`
width: 100%;
`;

export const InputLabel = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 14px;
font-weight: ${props => props.fontWeight ? props.fontWeight : 'normal'};
line-height: 24px;
letter-spacing: 0px;
margin-bottom: ${props => props.marginBottom ? props.marginBottom : '10px'};
margin-left: ${props => props.marginLeft ? props.marginLeft : '0px'};
margin-top: ${props => props.marginTop ? props.marginTop : '0px'};
position: relative;
width: fit-content;
color: #181917;

&:after {
    content: '*';
    color: #F04639;
    position: absolute;
    top: 0px;
    right: -8px;
    bottom: 0;
    display: ${props => props.required === false ? 'none' : 'content'};
}
`;

export const InputField = styled.input`
border: 1px solid rgba(145, 158, 171, 0.32);
border-radius: 8px;
width: 100%;
height: 46px;
padding: 14px;
box-sizing: border-box;
font-size: 14px;
line-height: 24px;
font-family: 'Poppins', sans-serif;

&::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    color: #919EAB;
  }
`;

export const InputErrorDiv = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0px;
color: #F04639;
padding-left: 14px;
padding-top: 20px;
position: relative;
align-self: end;

&:after {
    content: '*';
    color: #F04639;
    position: absolute;
    top: 20px;
    left: 7px;
    bottom: 0;
}
`;

export const EditPortalsDiv = styled.div`
display: flex;
flex-direction: column;
max-width: 890px;
`;

export const PortalsList = styled.div`
display: flex;
flex-wrap: wrap;
padding: 5px;
max-height: 60%;
border: 1px solid #dce0e4;
border-radius: 8px;
margin-bottom: 20px;
`;

export const PortalItem = styled.div`
display: flex;
width: 32%;
padding: 15px;
box-sizing: border-box;
& input {
  height: 20px;
  width: 20px;
  margin: 2px 0px 0px 0px;
  cursor: pointer;
}
`;

export const Button = styled.button`
font-family: 'Poppins', sans-serif;
font-size: 15px;
font-weight: 600;
line-height: 46px;
letter-spacing: 0px;
padding: 0px;
color: #FFFFFF;
cursor: pointer;

background-color: #048FA5;
border: 1px solid #048FA5;
border-radius: 8px;
width: ${props => props.width ? props.width : '150px'};
align-self: end;
`;



export const Container = styled.div`
  padding: 20px;
  background-color: #f4f4f4;
`;

export const ActionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const AddButton = styled.button`
  background-color: #048fa5;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #036a77;
  }
`;

export const Content = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const Error = styled.div`
  color: #f04639;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  margin-top: 20px;
`;