import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userReducer';
// import reducer2 from './reducers/reducer2';
import { combineReducers } from '@reduxjs/toolkit';
// Combine multiple reducers into a root reducer
const rootReducer = combineReducers({
  userReducer,
  // reducer2,
  // Add more reducers here
});

// Create the Redux store using configureStore
const store = configureStore({
  reducer: rootReducer,
});

export default store;
