import styled from 'styled-components';

export const SearchPageDiv = styled.div`
box-sizing: border-box;
width: 100%;
padding: 10px 40px 20px 40px;
background-color: #F8F8F8;
`;

export const BackLinkDiv = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 18px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0px;
color: #048FA5;
// opacity: 80%;

display: flex;
align-items: center;
width: fit-content;
cursor: pointer;

& img {
    padding-right: 8px;
}
`;


export const CategoryNameDiv = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 28px;
font-weight: 600;
line-height: 42px;
letter-spacing: 0px;
`;

export const HeadingText = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 35px;
letter-spacing: 0px;
color: #181917;
`;

export const SearchStepsDiv = styled.div`
display: flex;
width: 100%;
height: 30px;
position: relative;
margin: 30px 0px;
`;


export const StepDiv = styled.div`
display: flex;
position: absolute;
top: 0px;
left: ${props => props.left ? props.left : 0};

& p{
    background-color: ${props => props.active === true ? '#048FA5' : '#939599'};
    box-shadow: 0 0 0 6px ${props => props.active === true ? 'rgba(4, 143, 165, 0.3)' : 'rgba(147, 149, 153, 0.3)'};
    border-radius: 50%;

    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
    padding: 0px;
    margin: 0px;
    height: 24px;
    width: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
}

& span{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    color: ${props => props.active === true ? '#048FA5' : '#181917'};

    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
}


&:first-child:after {
    content: '';
    width: 235px;
    position: absolute;
    top: 13px;
    right: 0px;
    bottom: 0;
    left: 155px;
    border-top: 1px solid ${props => props.lineColor ? props.lineColor : '#C9C9C9'};
}
`;


export const SearchFormDiv = styled.div`
background-color: #FFFFFF;
width: 100%;
border-radius: 8px;
box-sizing: border-box;

padding: 30px;
`;

export const SearchBoxHeading = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 18px;
font-weight: 600;
line-height: 20px;
letter-spacing: 0px;
display: flex;
align-items: center;

& img {
    padding-left: 10px;
    cursor: pointer;
}
`;

export const InputDiv = styled.div`
margin-right: ${props => props.marginRight ? props.marginRight : '10px'};
margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0px'};
width: calc((100% - 60px)/3);
min-width: 350px;
`;

export const DateDiv = styled.div`
margin-right: ${props => props.marginRight ? props.marginRight : '30px'};
margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0px'};
width: ${props => props.width ? props.width : 'calc(((100% - 60px)/3 - 30px)/2)'};
min-width: 175px;

> div.react-datepicker-wrapper {
    width: 100%;
}

> div > div.react-datepicker__input-container input {
    width: 100%;
}

// > div > div > div > div > div > div > div.react-datepicker__year-wrapper {
//     max-width: 210px;
// }
> div > div > div > div > div > div > div > div.react-datepicker__year-text {
    width: 3.5rem;
}
`;

export const InputLabel = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0px;
margin-bottom: 10px;
position: relative;
width: fit-content;
color: #181917;

&:after {
    content: '*';
    color: #F04639;
    position: absolute;
    top: 0px;
    right: -8px;
    bottom: 0;
    display: ${props => props.required === false ? 'none' : 'content'};
}
`;

export const InputField = styled.input`
border: 1px solid rgba(145, 158, 171, 0.32);
border-radius: 8px;
width: 100%;
height: 46px;
padding: 14px;
box-sizing: border-box;
font-size: 14px;
line-height: 24px;
font-family: 'Poppins', sans-serif;

&::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    color: #919EAB;
  }
`;

export const DateInputField = styled.input`
background: ${props => props.value === null || props.value === '' ? 'url("/calendar.svg")' : ''} no-repeat;
background-position: right 14px center;

border: 1px solid rgba(145, 158, 171, 0.32);
border-radius: 8px;
height: 46px;
padding: 14px;
box-sizing: border-box;
font-size: 14px;
line-height: 24px;
font-family: 'Poppins', sans-serif;

background-size: 20px;

&::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    color: #919EAB;
  }
`;

export const InputErrorDiv = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0px;
color: #F04639;
padding-left: 14px;
padding-top: 10px;
position: relative;

&:after {
    content: '*';
    color: #F04639;
    position: absolute;
    top: 10px;
    left: 7px;
    bottom: 0;
}
`;

export const CaseDetailsDiv = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
padding: 30px 0px 0px 0px;

@media (max-width: 1200px) {
    padding-bottom: 20px;
}

`;

export const IdentifiersDiv = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
padding: 30px 0px 10px 0px;

& div:nth-child(3n) {
    margin-right: 0px !important;
}
`;

export const NextButton = styled.button`
font-family: 'Poppins', sans-serif;
font-size: 15px;
font-weight: 600;
line-height: 46px;
letter-spacing: 0px;
padding: 0px;
color: #FFFFFF;
cursor: pointer;

background-color: #048FA5;
border: 1px solid #048FA5;
border-radius: 8px;
width: 150px;
margin-left: calc(100% - 150px);
`;


export const CategorySelectDiv = styled.div`
background-color: #F8F8F8;
display: flex;
flex-direction: column;
row-gap: 25px;
padding: 30px 40px;
box-sizing: border-box;
min-height: calc(100vh - 90px);
width: calc(100vw - 80px);
`;

export const HeadingText1 = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 28px;
font-weight: 600;
line-height: 28px;
letter-spacing: 0px;
`;

export const HeadingText2 = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0px;
`;

export const CategoryBoxesDiv = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
row-gap: 40px;
column-gap: 40px;
`;

export const CategoryBoxDiv = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

font-family: 'Poppins', sans-serif;
font-size: 36px;
font-weight: 600;
line-height: 54px;
letter-spacing: 0px;
color: #181917;

box-sizing: border-box;
background-color: #FFFFFF;
border: 1px solid #FFFFFF;
border-radius: 8px;
width: 31%;
min-width: 340px;
height: 300px;
padding: 30px;
cursor: pointer;

& img {
    height: 80px;
    width: 80px;
}

&:hover {
    border: 1px solid #048FA5;
    color: #048FA5;
}
`;


export const FormPreviewDiv = styled.div`
display: flex;
justify-content: space-between;
width: 100% !important;
align-items: flex-start;
`;

export const PortalsDetailsDiv = styled.div`
display: flex;
flex-direction: column;
width: 70% !important;
`;

export const PortalsListDiv = styled.div`
display: flex;
flex-wrap: wrap;
background-color: #FFFFFF;
border-radius: 8px;
box-sizing: border-box;
padding: 30px;
margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0px'};

& p {
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    width: 33%;
    margin: 0;
    padding: 10px 20px 0px;
    box-sizing: border-box;
    color: ${props => props.type === 'error' ? '#F04639' : '#000000'};
}

& p:after {
    content: '';
    background-color: ${props => props.type === 'error' ? '#F04639' : '#000000'};
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    left: 0;
    top: 17px;
}
`;

export const ListHeading = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 18px;
font-weight: 600;
line-height: 20px;
letter-spacing: 0px;
margin-bottom: 10px;
color: ${props => props.type === 'error' ? '#F04639' : '#048FA5'};
width: 100%;
`;

export const ListText = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0px;
color: #181917;
`;

export const DetailsPreviewDiv = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
padding: 30px;
box-sizing: border-box;
background-color: #FFFFFF;
border-radius: 8px;
width: 30% !important;
margin-left: 30px;
/* width: calc(30% - 30px); */

font-family: 'Poppins', sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0px;
color: #181917;
position: sticky;
top: 100px;
right: 20px;

& span {
    font-weight: 600;
}
`;

export const ValuesList = styled.div`
display: flex;
flex-direction: column;
padding: 15px 0px;
border-bottom: ${props => props.borderBottom === true ? '1px solid #C9C9C9' : 'none'};

& p{
    margin: 5px 0;
}

& p:first-child {
    margin-top: 0px;
}
`;

export const ViewMore = styled.div`
font-family: 'Poppins', sans-serif;
font-size: 14px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0px;
text-decoration: underline;
margin-top: 25px;
margin-left: auto;
cursor: pointer;
`;

export const ButtonsDiv = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
font-family: 'Poppins', sans-serif;
font-size: 15px;
font-weight: 600;
letter-spacing: 0px;
`;

export const BackButton = styled.button`
color: #181917;
line-height: 46px;
background-color: #FFFFFF;
border: 1px solid #181917;
border-radius: 8px;
width: ${props => props.width ? props.width : '30%'};
cursor: pointer;
font-size: 15px;
font-weight: 600;
`;


export const SearchButton = styled.button`
color: #FFFFFF;
line-height: 46px;
background-color: #048FA5;
border: 1px solid #048FA5;
border-radius: 8px;
width: 62%;
cursor: pointer;
`;

export const OkayButton = styled.button`
font-family: 'Poppins', sans-serif;
font-size: 15px;
font-weight: 600;
line-height: 46px;
letter-spacing: 0px;
color: #FFFFFF;
background-color: ${props => props.disabled === true ? 'grey' : '#048FA5'};
border: 1px solid ${props => props.disabled === true ? 'grey' : '#048FA5'};
border-radius: 8px;
padding: 0px 30px;
cursor: ${props => props.disabled === true ? 'not-allowed' : 'pointer'};
width: ${props => props.width ? props.width : 'unset'};
`;
