import {
  // Stack, todo
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  // InputLabel,
  // MenuItem,                  todo
  // FormControl,
  // Select,
} from "@mui/material";
import moment from "moment";
import Layout from "../../components/Layout";
import * as Styled from "./usersStyled";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as axios from "../../utils/axios";

// const apiUrl = process.env.REACT_APP_API_URL;

const Users = () => {
  const [page, setPage] = useState(0);
  const [usersData, setUsersData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(null);

  const columns = [
    {
      id: "name",
      label: "Name",
      getValue: (i) => (i?.fullname ? capitalize(i?.fullname) : "-"),
    },
    {
      id: "email",
      label: "Email",
      getValue: (i) => (i?.email ? i?.email : "-"),
    },
    {
      id: "created_on",
      label: "Created On",
      getValue: (i) => (i?.created_at ?  moment(i?.created_at).format("DD MMM, YYYY | h:mm A") : "-"),
    },
  ];

  const capitalize = (val) => {
    if (val && val !== "") {
      return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
    } else {
      return "";
    }
  };

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const response = await axios.get(`user/get-all-users?pageNo=${page}&limit=${rowsPerPage}`);
        setUsersData(response?.data?.data?.users); // Set the fetched user data to the state variable
        setTotal(response?.data?.data?.users?.[0].total_count);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUsersData();
  }, [page, rowsPerPage]);

  const userAccess = localStorage.getItem("userAccess");
  const navigate = useNavigate();
  useEffect(() => {
    if (userAccess === "0") navigate("/search");
  }, []);

  return (
    <Layout>
      <Styled.MainDiv>
        <Styled.PageHeading>Users</Styled.PageHeading>
        <Styled.HeadingText>
          Check the list of all the users who have access to the platform.
        </Styled.HeadingText>
        <Styled.DataDiv>
          <Paper
            sx={{
              border: "1px solid #919EAB3D",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  "& .MuiTableRow-hover:hover": {
                    backgroundColor: "#ECFDFF !important",
                    cursor: "pointer",
                  },
                  "& .MuiTableRow-hover:hover > td > div": {
                    color: "#181917",
                    fontWeight: "600",
                  },
                  "& .MuiTableRow-hover:hover > td:first-child": {
                    borderLeft: "3px solid #048FA5 !important",
                  },
                }}
              >
                <TableHead
                  sx={{
                    "& th": { backgroundColor: "#F4F6F8", cursor: "default" },
                  }}
                >
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#637381",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersData &&
                    usersData.map((row) => (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        {columns.map((column, columnIndex) => (
                          <TableCell key={column.id} align={column.align}>
                            <Styled.TableCellStyled>
                              {column.getValue && column.getValue(row) !== ""
                                ? column.getValue(row)
                                : "-"}
                            </Styled.TableCellStyled>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {usersData?.length > 0 && total > rowsPerPage && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage(parseInt(e.target.value, 10));
                  setPage(0);
                }}
              />
            )}
          </Paper>
        </Styled.DataDiv>
      </Styled.MainDiv>
    </Layout>
  );
};

export default Users;
