export const list = ["High Court at Calcutta",
    "High Court of Chhattisgarh",
    "High Court of Delhi",
    "High Court of Orissa",
    "High Court of Rajasthan - Jaipur Bench",
    "High Court of Rajasthan (E-Court) - Jaipur Bench",
    "High Court of Rajasthan - Jodhpur Bench",
    "High Court of Rajasthan (E-Court) - Jodhpur Bench",
    "High Court of Jharkhand",
    "High Court of Karnataka",
    "High Court of Kerala",
    "High Court of Manipur at Imphal",
    "High Court of Meghalaya",
    "High Court of Judicature at Patna",
    "High Court of Punjab and Haryana",
    "High Court of Sikkim",
    "High Court of Tripura",
    "High Court of Uttarakhand",
    "High Court of Jammu and Kashmir- Srinagar Wing",
    "High Court of Jammu and Kashmir- Jammu Wing"]