import Layout from "../../components/Layout";
import * as Styled from "./homeStyled";


const Home = () => {

    return (
        <Layout>
            <Styled.MainDiv>
            </Styled.MainDiv>
        </Layout>
    );
}

export default Home;