import styled from "styled-components";

export const MainLayout = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100vw;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const LayoutInner = styled.div`
  padding: 80px 0px 0px 90px;
  position: relative;
`;
