import styled from "styled-components";

export const MenuDiv = styled.div`
height: calc(100vh - 80px);
width: 90px;
flex-shrink: 0;
background-color: #FFFFFF;
position: fixed;
top: 80px;
left: 0px;
padding: 35px 0px;
z-index: 2;
`;

export const MenuItem = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

padding-bottom: 45px;

font-family: 'Poppins', sans-serif;
font-size: 14px;
font-weight: ${props => props.active === true ? '600' : '400'};
line-height: 20px;
letter-spacing: 0px;
color: ${props => props.active === true ? '#048FA5' : '#181917'};

&:last-child {
    padding-bottom: 10px;
}

& img {
    height: 26px;
    width: 26px;
    cursor: pointer;
}

& span {
    cursor: pointer;
}
`;